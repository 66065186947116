import React, { useState, useEffect, useReducer } from "react";
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from "react-router-dom";
import general_fetch from "../../utils/fetch.js";

// 3rd party components
import { FormGroup, Modal, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Stepper from 'react-stepper-horizontal';
import Moment from 'react-moment';

// core components
import Header from "../../components/Headers/Header.jsx";
import OrderDisplay from "../../components/OrderDisplay";
import UsageDisplay from "../../components/UsageDisplay";

//actions
import { get_all_orders } from '../Orders/actions';
import { get_usage, get_user_details } from '../../actions';
import { get_investment_status, get_all_licenses, update_investment, delete_investment, get_store_account_details, assign_free_credits, get_store_orders } from './actions';

import './style.css';
import Logs from "../../components/Logs/index.jsx";
import Dues from "../../components/Dues/index.jsx";
import OrgSettings from "../../components/OrgSettings/index.jsx";
import Submit from "../../utils/Submit.js";
import DeleteNotification from "../../components/DeleteNotification/index.js";
// import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import OrgDates from "../../components/OrgDates/index.jsx";
import { DatePicker, Button as AntdButton, Switch } from "antd";
import moment from 'moment';
import dayjs from 'dayjs';
import { infurnia_add_ons } from "../../utils/infurnia_add_ons.js";
import OrgActivity from "../../components/OrgActivity/index.jsx";
import UsageGraph from "../../components/UsageGraph/index.jsx";
import NA from "../../components/NA/index.js";
import BusinessUnits from "../../components/BusinessUnits/index.js";
import CustomerTickets from "../../views/CustomerTickets";
import mixpanel_event_types from "../../utils/mixpanel_event_types.js";
import { CspModal, CspModalBody, CspModalFooter, CspModalHeader } from "../../components/CspModal/index.jsx";
import CreatableSelect from 'react-select/creatable';
import { colourStyles } from "../../utils/react_select_styles.js";
import UsersTab from "../../components/UsersTab/index.jsx";
import format_date_yyyy_mm_dd from "../../utils/format_date_yyyy_mm_dd.js"
import InventoryTemplates from "../../components/InventoryTemplates/index.js";
import ClientsAtRisk from "../ClientsAtRisk/index.js";
import { StarFilled, StarOutlined } from "@ant-design/icons";

const animatedComponents = makeAnimated();
const cutlist_machine_types = ['Panel Saw', 'Beam Saw']
const cutlist_machine_brand_names = ['Altendorf', 'Biesse', 'Homag', 'SCM', 'KDT', 'Felder', 'Umaboy'];
const cam_machine_types = ['CNC', 'Nesting']
const cam_machine_brand_names = ['Homag', 'Biesse', 'SCM', 'Felder', 'KDT', 'Jay Industries', 'Hua Hua', 'Weho', 'Hunny Impex', 'Sparta', 'Umaboy']

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
		title: 'Confirm to submit',
		message: message,
		customUI: ({ onClose }) => {
			return (
				Custom_modal ?
					<div>
						<Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
					</div>
					:
					<div className='custom-ui'>
						<DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
					</div>
			);
		}
	});
}


const CreditsModal = ({ show_credits_modal, close_credits_modal, get_account_details, store_id, credits_account, generate_badge }) => {
	const [comment, set_comment] = useState('Extending Trial Period');
	const [credits, set_credits] = useState(0);
	const [credit_type, set_credit_type] = useState('');

	const assign_credits = async () => {
		try {
			var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
			console.log(resp);
			get_account_details();
			close_credits_modal();
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		set_comment('Extending Trial Period');
		set_credits(0);
		set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
	}, [show_credits_modal]);

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={show_credits_modal} toggle={close_credits_modal}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel" style={{ margin: 'auto' }}> Assign free credits - {credit_type} </h4>
				<span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={close_credits_modal}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className="pl-lg-4">
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Comments
								</label>
								<Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment" type="text" />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Credits
								</label>
								<Input value={credits} onChange={e => set_credits(e.target.value)} name="credits" className="form-control-alternative" id="credits_free" type="number" />
							</FormGroup>
						</Col>
					</Row>
					<Button color="primary" onClick={assign_credits}>Assign Free Credits</Button>
				</div>
			</div>
		</Modal>
	);
}

const TransferCreditsModal = ({ open, toggle, store_id, generate_badge, fetch_store_info }) => {
	const [comment, set_comment] = useState('');
	const [credits, set_credits] = useState(0);
	// const [credit_type, set_credit_type] = useState('');
	// const alert = useAlert()

	const assign_credits = async () => {
		if (comment.trim()) {
			try {
				// var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
				try {
					var resp = await general_fetch({ url: 'billing/order/assign_free_credits', body: { customer_store_id: store_id, credits: credits, note: comment } });
					// console.log(resp);
					fetch_store_info()
					toastr.success('Transferred Credits Successfully')

				}
				catch (err) {
					// alert.error("Something went Wrong")
					toastr.error('Something went wrong')
				}
				// var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
				// console.log(resp);
				// fetch_store_info()
				toggle();
			} catch (err) {
				console.log(err);
			}
		} else {
			toastr.error('Note is Mandatory')
		}
	}

	useEffect(() => {
		set_comment('');
		set_credits(0);
		// set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
	}, [open]);

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel" style={{ margin: 'auto' }}> Transfer free credits </h4>
				<span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className="pl-lg-4">
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Note
								</label>
								<Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment" type="text" />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Credits
								</label>
								<Input value={credits} onChange={e => set_credits(Number(e.target.value))} name="credits" className="form-control-alternative" id="credits_free" type="number" />
							</FormGroup>
						</Col>
					</Row>
					<Button color="primary" onClick={() => submit(assign_credits, () => { }, '', '', '')}>Assign Free Credits</Button>
				</div>
			</div>
		</Modal>
	);
}

const SuspendStoreModal = ({ open, toggle, store_id, generate_badge, fetch_store_info }) => {
	const [comment, set_comment] = useState('');
	// const [credits, set_credits] = useState(0);
	// const [credit_type, set_credit_type] = useState('');
	// const alert = useAlert()

	const suspend = async () => {
		if (comment.trim()) {
			try {
				// var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
				try {
					var resp = await general_fetch({ url: 'store/suspend', body: { customer_store_id: store_id, message: comment } });
					// console.log(resp);
					fetch_store_info()
					toastr.success('Store Suspended Successfully')

				}
				catch (err) {
					// alert.error("Something went Wrong")
					toastr.error('Something went wrong')
				}
				// var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
				// console.log(resp);
				// fetch_store_info()
				toggle();
			} catch (err) {
				console.log(err);
			}
		} else {
			toastr.error('Note is Mandatory')
		}
	}

	useEffect(() => {
		set_comment('');
		// set_credits(0);
		// set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
	}, [open]);

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel" style={{ margin: 'auto' }}> Suspend Store </h4>
				<span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className="pl-lg-4">
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Note
								</label>
								<Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment" type="text" />
							</FormGroup>
						</Col>
					</Row>
					<Button color="primary" onClick={() => submit(suspend, () => { }, '', '', '')}>Suspend</Button>
				</div>
			</div>
		</Modal>
	);
}


const ExtendTrail = ({ open, toggle, store_id, generate_badge, fetch_store_info, set_page_loader, fetch_all_stores }) => {
	const [comment, set_comment] = useState('');
	// const [credits, set_credits] = useState(0); // days
	const [last_trial_date, set_last_trial_date] = useState();
	// const [credit_type, set_credit_type] = useState('');
	// const alert = useAlert()

	const extend_trail = async () => {
		if (comment.trim()) {
			try {
				// var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
				try {
					set_page_loader(true, 'Please wait...')
					var resp = await general_fetch({ url: 'billing/request_and_extend_trial', body: { customer_store_id: store_id, set_last_trial_date: new Date(last_trial_date).toDateString(), message: comment } });
					set_page_loader(false)
					// console.log(resp);
					fetch_store_info()
					fetch_all_stores()
					toastr.success('Trail Extended')

				}
				catch (err) {
					// alert.error("Something went Wrong")
					toastr.error('Something went wrong')
				}
				// var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
				// console.log(resp);
				// fetch_store_info()
				toggle();
				set_comment('')
				set_last_trial_date(new Date())
			} catch (err) {
				console.log(err);
			}
		} else {
			toastr.error('Note is Mandatory')
		}
	}

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel" style={{ margin: 'auto' }}> Extend Trial Date </h4>
				<span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className="pl-lg-4">
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label mr-2" htmlFor="input-address" >
									Set Last Trial Date
								</label>
								{/* <Input value={credits} onChange={e => set_credits(e.target.value ? Number(e.target.value) : 0)} name="credits" className="form-control-alternative" id="credits_free" type="number" /> */}
								<DatePicker
									format="YYYY-MM-DD"
									onChange={(dateString) => set_last_trial_date(dateString)}
								// defaultValue = {from_date}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Note
								</label>
								<Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment" type="text" />
							</FormGroup>
						</Col>
					</Row>
					<Button color="primary" onClick={() => submit(extend_trail, () => { }, '', '', '')}>Extend Trial</Button>
				</div>
			</div>
		</Modal>
	);
}

const UpdateOnboardingStatus = ({ open, toggle, store_id, store_info, generate_badge, fetch_store_info }) => {
	const [onboarding_status, set_onboarding_status] = useState('business');
	const options = [{ onboarding_status: 'not_started', displayName: 'Not Started' }, { onboarding_status: 'ongoing', displayName: 'Ongoing' }, { onboarding_status: 'completed', displayName: 'Completed' }, { onboarding_status: 'stopped', displayName: 'Stopped' }];
	const update_onboarding_status = async () => {
		try {
			try {
				var resp = await general_fetch({ url: 'csp/update_onboarding_status', body: { customer_store_id: store_id, onboarding_status } });
				fetch_store_info()
				toastr.success('Successfully updated Onboarding Status')
			}
			catch (err) {
				toastr.error('Something went wrong, Please try again')
			}
			toggle();
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (open && store_info) {
			set_onboarding_status(store_info.onboarding_status);
		}
	}, [open, store_info]);

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel" style={{ margin: 'auto' }}> Onboarding Status </h4>
				<span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className="pl-lg-4">
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Status
								</label>
								<Input value={onboarding_status} onChange={e => set_onboarding_status(e.target.value)} name="onboarding_status" className="form-control-alternative" id="onboarding_status_change" type="select">
									{
										options.map((option, idx) => (
											<option key={idx} value={option.onboarding_status}>{option.displayName}</option>
										))
									}
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<Button color="primary" onClick={update_onboarding_status}>Update</Button>
				</div>
			</div>
		</Modal>
	);
}

const UpdateAddonOnboarding = ({open, toggle, store_id, store_info, generate_badge, fetch_store_info}) => {
	const options = [{ onboarding_status: 'not_started', displayName: 'Not Started' }, { onboarding_status: 'ongoing', displayName: 'Ongoing' }, { onboarding_status: 'completed', displayName: 'Completed' }, { onboarding_status: 'not_applicable', displayName: 'Not Applicable' }];

	const [ adv_pric_onboarding_status, set_adv_pric_onboarding_status ] = useState();
	const [ adv_pric_onboarding_date, set_adv_pric_onboarding_date ] = useState();
	const [ cutlist_onboarding_status, set_cutlist_onboarding_status ] = useState();
	const [ cutlist_onboarding_date, set_cutlist_onboarding_date ] = useState();
	const [ manuf_onboarding_status, set_manuf_onboarding_status ] = useState();
	const [ manuf_onboarding_date, set_manuf_onboarding_date ] = useState();

	const onclick_handleclose = () => {
		toggle()
	}

	const update_onboarding_metadata = async() => {
		try {
			let onboarding_metadata = {adv_pric_onboarding_status, adv_pric_onboarding_date, cutlist_onboarding_status, cutlist_onboarding_date, manuf_onboarding_status, manuf_onboarding_date}
			let resp = await general_fetch({url:'csp/update_onboarding_metadata', body: { customer_store_id: store_id, onboarding_metadata}})
			fetch_store_info()
			onclick_handleclose()
			toastr.success(`Successfulyy updated Add-on Onboarding data`)
		}catch (e) {
			console.error(e)
			toastr.error(`Couldn't update Add-on Onboarding data`)
		}
	}

	useEffect(() => {
		if(store_info && store_info.add_ons && store_info.add_ons.active && store_info.add_ons.active.length && store_info.onboarding_metadata){
			set_adv_pric_onboarding_status(store_info.onboarding_metadata.adv_pric_onboarding_status)
			set_adv_pric_onboarding_date(store_info.onboarding_metadata.adv_pric_onboarding_date)

			set_cutlist_onboarding_status(store_info.onboarding_metadata.cutlist_onboarding_status)
			set_cutlist_onboarding_date(store_info.onboarding_metadata.cutlist_onboarding_date)

			set_manuf_onboarding_status(store_info.onboarding_metadata.manuf_onboarding_status)
			set_manuf_onboarding_date(store_info.onboarding_metadata.manuf_onboarding_date)

		}
	}, [store_info]);

	useEffect(() => {
		console.log(adv_pric_onboarding_date)
	}, [adv_pric_onboarding_date]);

	return(
		<CspModal open={open} toggle={onclick_handleclose} size={'md'}>
			<CspModalHeader title={'Add-on Onboarding'} toggle={onclick_handleclose}/>
			<CspModalBody style={{maxHeight:'600px', fontSize:'14px'}}>
			{
				store_info && store_info.add_ons && store_info.add_ons.active && store_info.add_ons.active.length ?(
					<>
					{
						store_info.add_ons.active.includes('5')?
						<div className="mb-4">
							<div className="mb-2 bold" style={{fontSize:'15px'}}>Advanced Pricing</div>
							<div className="">
								<div className="mb-2 flex_property">
									<div className="mb-1 mr-4" style={{width:'40%'}}>Onboarding Status:</div>
									<Input value={adv_pric_onboarding_status} onChange={e => {set_adv_pric_onboarding_status(e.target.value); if(e.target.value != 'completed') set_adv_pric_onboarding_date('')}} className="form-control-alternative standard_input" style={{fontSize:'13px', padding:'4px 8px', width:'60%'}} type='select'>
									{
										options.map((option, idx) => (
											<option key={idx} value={option.onboarding_status}>{option.displayName}</option>
										))
									}
									</Input>
								</div>
								<div className="flex_property">
									<div className="mb-1 mr-4" style={{width:'40%'}}>Onboarding Date:</div>
									<DatePicker 
										style={{width:'60%'}}
										format="YYYY-MM-DD"
										onChange={dateString => set_adv_pric_onboarding_date(format_date_yyyy_mm_dd(new Date(dateString)))}
										value={adv_pric_onboarding_date ? dayjs(adv_pric_onboarding_date, new Date(adv_pric_onboarding_date), 'YYYY-MM-DD') : ''}
										allowClear
										disabled = {adv_pric_onboarding_status != 'completed'}
									/>
								</div>
							</div>
						</div>:""
					}
					{
						store_info.add_ons.active.includes('4') || store_info.add_ons.active.includes('1') || store_info.add_ons.active.includes('6') || store_info.add_ons.active.includes('7')?
						<div className="mb-4">
							<div className="mb-2 bold" style={{fontSize:'15px'}}>Panel Cut-List</div>
							<div className="">
								<div className="mb-2 flex_property">
									<div className="mb-1 mr-4" style={{width:'40%'}}>Onboarding Status:</div>
									<Input value={cutlist_onboarding_status} onChange={e => {set_cutlist_onboarding_status(e.target.value); if(e.target.value != 'completed') set_cutlist_onboarding_date('')}} className="form-control-alternative standard_input" style={{fontSize:'13px', padding:'4px 8px', width:'60%'}} type='select'>
									{
										options.map((option, idx) => (
											<option key={idx} value={option.onboarding_status}>{option.displayName}</option>
										))
									}
									</Input>
								</div>
								<div className="flex_property">
									<div className="mb-1 mr-4" style={{width:'40%'}}>Onboarding Date:</div>
									<DatePicker 
										style={{width:'60%'}}
										format="YYYY-MM-DD"
										onChange={dateString => set_cutlist_onboarding_date(format_date_yyyy_mm_dd(new Date(dateString)))}
										value={cutlist_onboarding_date ? dayjs(cutlist_onboarding_date, new Date(cutlist_onboarding_date), 'YYYY-MM-DD') : ''}
										allowClear
										disabled = {cutlist_onboarding_status != 'completed'}
									/>
								</div>
							</div>
						</div>:""
					}
					{
						store_info.add_ons.active.includes('1') || store_info.add_ons.active.includes('7')?
						<div className="mb-4">
							<div className="mb-2 bold" style={{fontSize:'15px'}}>Manufacturing</div>
							<div className="">
								<div className="mb-2 flex_property">
									<div className="mb-1 mr-4" style={{width:'40%'}}>Onboarding Status:</div>
									<Input value={manuf_onboarding_status} onChange={e => {set_manuf_onboarding_status(e.target.value); if(e.target.value != 'completed') set_manuf_onboarding_date('')}} className="form-control-alternative standard_input" style={{fontSize:'13px', padding:'4px 8px', width:'60%'}} type='select'>
									{
										options.map((option, idx) => (
											<option key={idx} value={option.onboarding_status}>{option.displayName}</option>
										))
									}
									</Input>
								</div>
								<div className="flex_property">
									<div className="mb-1 mr-4" style={{width:'40%'}}>Onboarding Date:</div>
									<DatePicker 
										style={{width:'60%'}}
										format="YYYY-MM-DD"
										onChange={dateString => set_manuf_onboarding_date(format_date_yyyy_mm_dd(new Date(dateString)))}
										value={manuf_onboarding_date ? dayjs(manuf_onboarding_date, new Date(manuf_onboarding_date), 'YYYY-MM-DD') : ''}
										allowClear
										disabled = {manuf_onboarding_status != 'completed'}
									/>
								</div>
							</div>
						</div>:""
					}
					</>
				):'No Add-ons found'
			}
			</CspModalBody>
			<CspModalFooter>
				<Button color='primary' onClick={update_onboarding_metadata}>Submit</Button>
			</CspModalFooter>
		</CspModal>
	)
}

const UpdateResponsiveness = ({ open, toggle, store_id, store_info, generate_badge, fetch_store_info }) => {
	const [responsiveness, set_responsiveness] = useState();
	const update_responsiveness = async () => {
		try {
			try {
				var resp = await general_fetch({ url: 'csp/update_responsiveness', body: { customer_store_id: store_id, responsiveness } });
				fetch_store_info()
				toastr.success('Successfully updated responsiveness')
			}
			catch (err) {
				toastr.error('Something went wrong, Please try again')
			}
			toggle();
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (open && store_info) {
			set_responsiveness(store_info.responsiveness);
		}
	}, [open, store_info]);

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel" style={{ margin: 'auto' }}> Responsiveness </h4>
				<span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className="pl-lg-4">
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Is the Org responsive?
								</label>
								<Input value={responsiveness} onChange={e => set_responsiveness(e.target.value)} name="responsiveness" className="form-control-alternative" id="responsiveness_change" type="select">
									<option value={true}>Yes</option>
									<option value={false}>No</option>
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<Button color="primary" onClick={update_responsiveness}>Update</Button>
				</div>
			</div>
		</Modal>
	);
}

const UpgradePlan = ({ open, toggle, store_id, generate_badge, fetch_store_info }) => {
	// const [comment, set_comment] = useState('');
	const [plan_type, set_plan_type] = useState('business');
	const options = [{ plan_type: 'business', displayName: 'Business' }, { plan_type: 'enterprise', displayName: 'Enterprise' }]
	// const [credit_type, set_credit_type] = useState('');
	// const alert = useAlert()
	const upgrade_plan = async () => {
		try {
			// var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
			// var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
			try {
				var resp = await general_fetch({ url: 'csp/upgrade_plan_type', body: { customer_store_id: store_id, plan_type: plan_type } });
				// console.log(resp);
				fetch_store_info()
				toastr.success('Plan Upgrade Successfull')
				// window['analytics'].group(store_id, {
				//   "plan_type": plan_type,
				//   "company_id": store_id
				// })
			}
			catch (err) {
				toastr.error('Something went wrong')
				// alert.error("Something went Wrong")
			}
			toggle();
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		// set_comment('');
		set_plan_type('business');
		// set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
	}, [open]);

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel" style={{ margin: 'auto' }}>  Upgrade Plan </h4>
				<span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className="pl-lg-4">
					{/* <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Note
                      </label>
                      <Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment"  type="text" />
                    </FormGroup>
                  </Col>
                </Row> */}
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Plan Type
								</label>
								<Input value={plan_type} onChange={e => set_plan_type(e.target.value)} name="plan_type" className="form-control-alternative" id="plan_type_change" type="select">
									{
										options.map((option, idx) => (
											<option key={idx} value={option.plan_type}>{option.displayName}</option>
										))
									}
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<Button color="primary" onClick={() => submit(upgrade_plan, () => { }, '', '', '')}>Upgrade Plan</Button>
				</div>
			</div>
		</Modal>
	);
}

const ManageAddOns = ({ open, toggle, store_id, store_info, existing_add_ons, fetch_store_info, current_bill_start_date, next_bill_generation_date, last_paid_cycle_end_date, fetch_dues, set_page_loader }) => {
	// const [selected_add_ons, set_selected_add_ons] = useState([]);
	const [mode, set_mode] = useState('');
	const [selected_add_on, set_selected_add_on] = useState('');
	const [selected_date, set_selected_date] = useState('');
	const [visible_add_ons, set_visible_add_ons] = useState(infurnia_add_ons);
	// const [fetched_removal_date, set_fetched_removal_date] = useState();

	const onclick_hanndlecose = () => {
		set_selected_add_on('')
		set_selected_date('')
		set_visible_add_ons(infurnia_add_ons)
		set_mode('')
		toggle()
	}

	const add_add_ons = async () => {
		if (selected_add_on && last_paid_cycle_end_date) {
			if (store_id) {
				try {
					let add_on_id = selected_add_on
					set_page_loader(true, 'Please wait...')
					let resp = await general_fetch({ url: 'csp/add_addon', body: { customer_store_id: store_id, add_on_id } })
					set_page_loader(false)
					toastr.success(`Add-on added successfully`)
					fetch_store_info()
					fetch_dues()
					onclick_hanndlecose()
				} catch (err) {
					set_page_loader(false)
					console.log(err)
					toastr.error(`Couldn't update Add-ons, please try again`)
				}
			}
		} else {
			toastr.error('Please select an Add-on && date')
		}
	}

	const generat_payment_link = async () => {
		if (selected_add_on) {
			if (store_id) {
				try {
					let add_on_id = selected_add_on
					set_page_loader(true, 'Please wait...')
					let resp = await general_fetch({ url: 'csp/subscribe_add_on', body: { customer_store_id: store_id, add_on_ids:[add_on_id] } })
					set_page_loader(false)
					if(resp.order_id){
						let link = global.config.onboarding_path + '?due_order_id=' + resp.order_id
                        try {
                            await navigator.clipboard.writeText(link)
                            toastr.success('Payment link copied to clipboard', {timeOut:0})
                        } catch(err) {
                            console.log(err);
                        }
						window.open(link)
					}
				} catch (err) {
					set_page_loader(false)
					console.log(err)
					toastr.error(`Couldn't update Add-ons, please try again`)
				}
			}
		} else {
			toastr.error('Please select an Add-on')
		}
	}

	const remove_add_ons = async () => {
		if (selected_add_on && last_paid_cycle_end_date) {
			if (store_id) {
				try {
					let add_on_id = selected_add_on
					set_page_loader(true, 'Please wait...')
					let resp = await general_fetch({ url: 'csp/remove_addon', body: { customer_store_id: store_id, add_on_id } })
					set_page_loader(false)
					toastr.success(`Add-on removed successfully`)
					fetch_store_info()
					fetch_dues()
					onclick_hanndlecose()
				} catch (err) {
					set_page_loader(false)
					console.log(err)
					toastr.error(`Couldn't update Add-ons, please try again`)
				}
			}
		} else {
			toastr.error('Please select an Add-on')
		}
	}

	const onChangeDate = (date, dateString) => {
		console.log('dateString', dateString)
		set_selected_date(dateString)
	}


	useEffect(() => {
		if (store_info && store_info.add_ons && open) {
			if (store_info.add_ons.subscribed && store_info.add_ons.subscribed.length) {
				if (mode === 'removal') {
					let temp_list = infurnia_add_ons.filter(x => store_info.add_ons.subscribed.find(o => o === x.value) ? true : false)
					set_visible_add_ons(temp_list)
				} else if (mode === 'addition') {
					let temp_list = infurnia_add_ons.filter(x => store_info.add_ons.subscribable.find(o => o === x.value) ? true : false)
					if (store_info.add_ons.subscribed.find(o => o === '1')) {
						temp_list = temp_list.filter(x => x.value != '4')
					}
					if (store_info.add_ons.subscribed.find(o => o === '4')) {
						temp_list = temp_list.filter(x => x.value != '1')
					}
					console.log('visible_add_ons', temp_list, infurnia_add_ons)
					set_visible_add_ons(temp_list)
				}
			} else {
				set_mode('addition')
			}
		} else if (!open) {
			set_mode('')
		}
	}, [store_info, mode, open]);

	// useEffect(() => {
	// 	if(existing_add_ons && open){
	// 		console.log('existing_add_ons', existing_add_ons)
	// 		set_selected_add_ons(existing_add_ons.map(x => infurnia_add_ons[Number(x)-1]))
	// 	}
	// }, [existing_add_ons]);

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={onclick_hanndlecose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel"> {mode === 'addition' ? 'Add' : (mode === 'removal' ? 'Remove' : 'Manage')} Add-ons </h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onclick_hanndlecose}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className="pl-lg-4">
					{
						mode == 'addition' ?
							<Row>
								<Col md="12">
									<FormGroup>
										<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
											<div className='mb-2'>Select Add-on:</div>
											<div>
												{/* <Select
										closeMenuOnSelect={false}
										components={animatedComponents}
										value = {selected_add_ons}
										isMulti
										options={infurnia_add_ons}
										onChange={(selectedOptions) => set_selected_add_ons(selectedOptions)}
									/> */}
												<Input type='select' style={{ height: '42px', fontSize: '14px' }} value={selected_add_on} onChange={(e) => set_selected_add_on(e.target.value)}>
													<option value={''}>Select an Add-on</option>
													{
														visible_add_ons.filter(x => x.value != 1 && x.value != 4).map((add_on) => (
															<option value={add_on.value}>{add_on.label}</option>
														))
													}
												</Input>
											</div>
										</label>
									</FormGroup>
									<div className="flex_property">
										<Button color='primary' onClick={generat_payment_link}>Generate Payment Link (This Cycle)</Button>
										<Button onClick={add_add_ons}>Next Cycle (Added after <b>{new Date(last_paid_cycle_end_date).toString().substring(4, 15)}</b>)</Button>
									</div>
									{/* {
										selected_add_on && last_paid_cycle_end_date ?
											<div className="mb-4" style={{ fontSize: '14px' }}>
												<div className="mb-2" style={{ color: 'red' }}>The selected Add-on will be added after <b>{'\xa0' + new Date(last_paid_cycle_end_date).toString().substring(4, 15)}</b></div>
											</div> : ''
									} */}
								</Col>
							</Row> : (
								mode === 'removal' ?
									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
													<div className='mb-2'>Add-ons:</div>
													<div>
														{/* <Select
											closeMenuOnSelect={false}
											components={animatedComponents}
											value = {selected_add_ons}
											isMulti
											options={infurnia_add_ons}
											onChange={(selectedOptions) => set_selected_add_ons(selectedOptions)}
										/> */}
														<Input type='select' style={{ height: '42px', fontSize: '14px' }} value={selected_add_on} onChange={(e) => { set_selected_add_on(e.target.value) }}>
															<option value={''}>Select an Add-on</option>
															{
																visible_add_ons.filter(x => x.value != 1 && x.value != 4).map((add_on) => (
																	<option value={add_on.value}>{add_on.label}</option>
																))
															}
														</Input>
													</div>
												</label>
											</FormGroup>
											{
												selected_add_on && last_paid_cycle_end_date ?
													<div className="mb-4" style={{ fontSize: '14px' }}>
														<div className="mb-2" style={{ color: 'red' }}>The selected Add-on will be removed after <b>{'\xa0' + new Date(last_paid_cycle_end_date).toString().substring(4, 15)}</b></div>
													</div> : ''
											}
										</Col>
									</Row> :
									<Row>
										<Col md='12' className='flex_center' style={{ justifyContent: 'space-around' }}>
											<Button color='primary' className='mr-3' onClick={() => set_mode('addition')}>Add Add-on</Button>
											<Button color='primary' onClick={() => set_mode('removal')}>Remove Add-on</Button>
										</Col>
									</Row>
							)
					}
				</div>
			</div>
			<div className="modal-footer" style={{ height: '60px' }}>
				<Button color="secondary" onClick={mode ? () => { set_mode(''); set_selected_add_on(''); set_selected_date(''); set_visible_add_ons(infurnia_add_ons) } : () => onclick_hanndlecose()}>{mode ? 'Back' : 'Close'}</Button>
				{
					mode == 'removal' ? <Button color="primary" onClick={mode === 'addition' ? add_add_ons : remove_add_ons}>Submit</Button> : ''
				}
			</div>
		</Modal>
	)
}

const ChangeExecutive = ({ open, toggle, store_id, options, fetch_store_info, sales_or_cs }) => {
	// const [comment, set_comment] = useState('');
	const [customer_success_executive_id, set_customer_success_executive_id] = useState('');
	// const alert = useAlert()
	const change_executive = async () => {
		try {
			// var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
			// var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
			try {
				if (sales_or_cs === 'cs') {
					var resp = await general_fetch({ url: 'csp/assign_customer_success_executive', body: { customer_store_id: store_id, customer_success_executive_id: customer_success_executive_id } });
					toastr.success('Customer Success Executive changed Successfully')
					try {
						let resp_2 = await general_fetch({ url: 'csp/add_customer_success_executive_to_org', body: { customer_store_id: store_id, cs_executive_id: customer_success_executive_id } })
						toastr.success('Successfully added CS Executive to Org')
					} catch (err) {
						// toastr.error("CS Executive could not be added to the Org. You can try manually adding it from the Overview")
					}
				} else {
					var resp = await general_fetch({ url: 'csp/assign_sales_executive', body: { customer_store_id: store_id, sales_executive_id: customer_success_executive_id } });
					toastr.success('Sales Executive changed Successfully')
				}
				// console.log(resp);
				fetch_store_info()
				// window['analytics'].group(store_id, {
				//   "customer_success_poc": customer_success_executive_id,
				//   // "company_id": store_id
				// })
			}
			catch (err) {
				toastr.error('Something went wrong')
				// alert.error("Something went Wrong")
			}
			toggle();
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		// set_comment('');
		// set_customer_success_executive_id('business');
		if (open && options && options.length) {
			set_customer_success_executive_id(options[0].id);
		}
		// set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
	}, [open]);

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel"> Change Executive </h4>
				{/* <span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span> */}
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className="pl-lg-4">
					{/* <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Note
                      </label>
                      <Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment"  type="text" />
                    </FormGroup>
                  </Col>
                </Row> */}
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Select Executive
								</label>
								<Input value={customer_success_executive_id} onChange={e => set_customer_success_executive_id(e.target.value)} name="customer_success_executive_id" className="form-control-alternative" id="customer_success_executive_id_change" type="select">
									{
										options.map((option, idx) => (
											<option key={idx} value={option.id}>{option.first_name}</option>
										))
									}
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<Button color="primary" onClick={() => submit(change_executive, () => { }, '', '', '')}>Submit</Button>
				</div>
			</div>
		</Modal>
	);
}

const AssignCustomerSuccessExecutive = ({ store_info, fetch_store_info, user_details, sales_or_cs, cs_org_users }) => {
	const [all_customer_success_executives, set_all_customer_success_executives] = useState([]);
	const [is_csm_open, set_is_csm_open] = useState(false);

	const toggle_csm = () => set_is_csm_open(!is_csm_open);

	const fetch_account_details = async () => {
		let account_details = cs_org_users
		if (account_details && account_details.length) {
			// account_details = account_details.filter(x => !x.depricated && (x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_admin') || x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_executive')))
			account_details = account_details.filter(x => !x.depricated && (x.role.includes('customer_success_admin') || x.role.includes('customer_success_executive')))
			set_all_customer_success_executives(account_details)
		}
	}

	useEffect(() => {
		if (cs_org_users && cs_org_users.length) fetch_account_details()
	}, [cs_org_users]);

	return (
		<Card className="card-stats mb-4 mb-lg-0">
			<ChangeExecutive open={is_csm_open} toggle={toggle_csm} store_id={store_info ? store_info.id : ''} options={all_customer_success_executives} fetch_store_info={fetch_store_info} sales_or_cs={sales_or_cs} />
			<CardBody>
				<Row>
					<div className="col">
						<CardTitle className="text-uppercase mb-0">
							<h4 className="text-primary" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{sales_or_cs === 'cs' ? "CS Rep" : 'BD Rep'}</h4>
						</CardTitle>
						<span className="h3 font-weight-bold mt-1 mb-0">{store_info && sales_or_cs === 'cs' && store_info.customer_success_executive_id ? store_info.customer_success_executive.first_name : (store_info && sales_or_cs === 'sales' && store_info.sales_executive_details ? store_info.sales_executive_details.first_name : "None")}</span>
					</div>
					<Col className="col-auto">
						<div style={{ cursor: 'pointer' }} onClick={toggle_csm} className="icon icon-shape bg-primary text-white rounded-circle shadow">
							<i className={"fas fa-" + (store_info && store_info.customer_success_executive_id ? "user-edit" : "user-plus")} />
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
		// <div style={{display:'flex', alignItems:'center'}}>
		//   {store_info && store_info.customer_success_executive_id ? 
		//     <span style={{marginRight: '6px'}}>{store_info.customer_success_executive.first_name ? store_info.customer_success_executive.first_name : '' + store_info.customer_success_executive.last_name ? store_info.customer_success_executive.last_name : ''}</span>
		//     :''
		//   }
		//   <div>{store_info && store_info.customer_success_executive_id ? "Change Executive" : "Assign Executive"}</div>
		// </div>
	)
}

const OrgDomain = ({ store_info, fetch_store_info }) => {
	const [show_domain_modal, set_show_domain_modal] = useState(false);

	const toggle_domain_modal = () => set_show_domain_modal(!show_domain_modal);

	return (
		<Card className="card-stats mb-4 mb-lg-0">
			<ModifyDomain open={show_domain_modal} toggle={toggle_domain_modal} store_info={store_info} fetch_store_info={fetch_store_info} given_domain={store_info ? store_info.domain : ''} />
			<CardBody>
				<Row>
					<div className="col">
						<CardTitle className="text-uppercase mb-0">
							<h4 className="text-primary" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>Domain</h4>
						</CardTitle>
						<span className="h3 font-weight-bold mt-1 mb-0">{store_info ? store_info.domain : ''}</span>
					</div>
					<Col className="col-auto">
						<div style={{ cursor: 'pointer' }} onClick={toggle_domain_modal} className="icon icon-shape bg-primary text-white rounded-circle shadow">
							<i className={"fas fa-edit"} />
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
	)
}

const Usage_filterModal = ({ open, toggle, usage_start_time, set_usage_start_time, usage_end_time, set_usage_end_time, selected_mixpanel_events, set_selected_mixpanel_events, fetch_mix_panel_data }) => {

	const [from_date, set_from_date] = useState();
	const [to_date, set_to_date] = useState();
	const [selected_events, set_selected_events] = useState();

	const onChangeFromDate = (date, dateString) => {
		set_from_date(dateString)
		// set_to_date(dateString)
		document.getElementById('to_date').click()
	}

	const onChangeToDate = (date, dateString) => {
		set_to_date(dateString)
	}

	const onClick_handleClose = () => {
		toggle()
	}

	useEffect(() => {
		console.log('dayss111', usage_start_time, usage_end_time)
		if (open) {
			set_from_date(new Date(usage_start_time))
			set_to_date(new Date(usage_end_time))
			console.log('dayjsss', usage_start_time, dayjs(usage_start_time, new Date(usage_start_time), 'YYYY-MM-DD'))
		}
	}, [open, usage_start_time, usage_end_time]);

	const onclick_submit = async () => {
		try {
			await fetch_mix_panel_data(new Date(from_date), new Date(to_date))
			set_usage_start_time(new Date(from_date))
			set_usage_end_time(new Date(to_date))
			onClick_handleClose()
		} catch (e) {
			console.error(e)
			toastr.error('Could not filter Product usage by date')
		}
	}

	return (
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>Filter Product Usage</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{ fontSize: '14px' }}>
				<Row>
					<Col md="6">
						<FormGroup>
							<label className="form-control-label mr-2" htmlFor="input-address" >
								From
							</label>
							{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateTimePicker
								value={from_date}
								onChange={(newValue) => set_from_date(newValue)}
							/>
							</LocalizationProvider> */}
							<DatePicker
								format="YYYY-MM-DD"
								onChange={onChangeFromDate}
								value={from_date ? dayjs(from_date, new Date(from_date), 'YYYY-MM-DD') : ''}
							// disabledDate={(d) => new Date(d) < new Date(today)}
							/>
						</FormGroup>
					</Col>
					<Col md="6">
						<FormGroup>
							<label className="form-control-label mr-2" htmlFor="input-address" >
								To
							</label>
							{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateTimePicker
								value={to_date}
								onChange={(newValue) => set_to_date(newValue)}
							/>
							</LocalizationProvider> */}
							<DatePicker
								id='to_date'
								format="YYYY-MM-DD"
								onChange={onChangeToDate}
								value={to_date ? dayjs(to_date, new Date(to_date), 'YYYY-MM-DD') : ''}
							// disabledDate={(d) => new Date(d) < new Date(from_date)}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mt-3'>
					<Col md="12">
						<FormGroup>
							<label className="form-control-label mr-2" htmlFor="input-address" >
								Events
							</label>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={selected_events}
								isMulti
								options={mixpanel_event_types}
								// styles={colourStyles}
								onChange={(selectedOptions) => set_selected_events(selectedOptions)}
							/>
						</FormGroup>
					</Col>
				</Row>
				{
					to_date < from_date ?
						<Row className='mb-4'>
							<Col md="12" style={{ color: 'red' }}>
								** From date should be less than To date
							</Col>
						</Row> : ""
				}
			</div>
			<div className="modal-footer" style={{ height: '60px' }}>
				<Button color='primary' disabled={(!to_date || !from_date || to_date < from_date) ? true : false} onClick={onclick_submit}>Submit</Button>
			</div>
		</Modal>
	)
}

const ModifyDomain = ({ open, toggle, given_domain, store_info, fetch_store_info }) => {

	const [selected_domain, set_selected_domain] = useState();


	const change_domain = async () => {
		try {
			let body = { customer_store_id: store_info.id, domain: selected_domain }
			let resp = await general_fetch({ url: "csp/update_domain", body })
			fetch_store_info()
			onclick_handleclose()
		} catch (e) {
			toastr.error(`Couldn't Modify Domain`)
		}
	}

	const onclick_handleclose = () => {
		toggle()
		set_selected_domain('')
	}

	useEffect(() => {
		if (given_domain) set_selected_domain(given_domain);
	}, [given_domain, open]);
	return (
		<CspModal open={open} toggle={onclick_handleclose} size={'md'} >
			<CspModalHeader title={'Modify Domain'} toggle={onclick_handleclose} />
			<CspModalBody style={{ minHeight: '150px' }}>
				<div className="pl-lg-4">
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Select Domain
								</label>
								<Input value={selected_domain} onChange={e => set_selected_domain(e.target.value)} className="form-control-alternative" type="select">
									<option value={''}>None</option>
									<option value={'Interior Design'}>Interior Design</option>
									<option value={'Modular Interior'}>Modular Interior</option>
									<option value={'Building Design'}>Building Design</option>
								</Input>
							</FormGroup>
						</Col>
					</Row>
				</div>
			</CspModalBody>
			<CspModalFooter>
				<Button color="primary" onClick={() => submit(change_domain, () => { }, '', '', '')}>Submit</Button>
			</CspModalFooter>
		</CspModal>
	)
}

const Cutlist_Cam_Details = ({ open, toggle, store_info, fetch_org_info, set_page_loader, fetch_all_stores }) => {

	const [cutlist_machine, set_cutlist_machine] = useState([]);
	const [cam_machine, set_cam_machine] = useState([]);
    const [ignored, forceUpdate] = useReducer(x => (x+1)%2, 0);

	const update_details = async () => {
		try {
			let add_on_details = {}
			if (store_info) {
				add_on_details['cutlist_machine'] = cutlist_machine
			} 
			if (store_info) {
				add_on_details['cam_machine'] = cam_machine
			}
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ url: 'csp/update_add_on_details', body: { customer_store_id: store_info.id, add_on_details } })
			set_page_loader(false)
			// onclick_handleclose()
			// fetch_org_info()
			// fetch_all_stores()
			toastr.success('Successfully updated Add-on details')
		} catch (e) {
			set_page_loader(false)
			console.error(e)
			toastr.error(`Couldn't update Add-on details`)
		}
	}

	useEffect(() => {
		if (store_info && store_info.add_on_details) {
			let json = store_info.add_on_details
			set_cutlist_machine(json.cutlist_machine)
			set_cam_machine(json.cam_machine)
		}
	}, [store_info]);

    return (
        <div className="">
            <div className="cutlist mb-4">
                <h3>Cutlist Machines</h3>
                <div className="ml-2">
                    <Row md={3}>
                        <Col style={{ paddingLeft: '10px', paddingRight: 0 }}>
                            <div className='mb-2'>Machine Type</div>
                            {
                                cutlist_machine ? cutlist_machine.map((x, i) => {
                                    return (
                                        <div style={{ zIndex: 2, position: 'relative', paddingBottom: '50px' }}>
                                            <Select
                                                value={{value: cutlist_machine[i].type, label: cutlist_machine[i].type}}
                                                options={cutlist_machine_types.map(a => {return {value: a, label: a}})}
                                                onChange={(selectedOption) => {cutlist_machine[i].type = selectedOption.value; set_cutlist_machine(cutlist_machine); forceUpdate()}}
                                            />
                                        </div>
                                    )
                                }) : ''
                            }
                        </Col>

                        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <div className='mb-2'>Brand Name</div>
                            {
                                cutlist_machine ? cutlist_machine.map((x, i) => {
                                    return (
                                        <div style={{ zIndex: 2, position: 'relative', paddingBottom: '50px' }}>
                                            <CreatableSelect
                                                value={{value: cutlist_machine[i].brand_name, label: cutlist_machine[i].brand_name}}
                                                options={cutlist_machine_brand_names.map(a => {return {value: a, label: a}})}
                                                onChange={(selectedOption) => {cutlist_machine[i].brand_name = selectedOption.value; set_cutlist_machine(cutlist_machine); forceUpdate()}}
                                            />
                                        </div>
                                    )
                                }) : ''
                            }
                        </Col>

                        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <div className='mb-2'>Model Number</div>
                            {
                                cutlist_machine ? cutlist_machine.map((x, i) => {
                                    return (
                                        <div style={{ zIndex: 2, position: 'relative', paddingBottom: '50px' }}>
                                            <Input type='text' style={{ height: '36px' }} placeholder={cutlist_machine[i].model_number} value={cutlist_machine[i].model_number} onChange={(e) => {cutlist_machine[i].model_number = e.target.value; forceUpdate();}} />
                                        </div>
                                    )
                                }) : ''
                            }
                        </Col>

                        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <div className='mb-2'>Note</div>
                            {
                                cutlist_machine ? cutlist_machine.map((x, i) => {
                                    return (
                                        <div style={{ zIndex: 2, position: 'relative' }}>
                                            <textarea className="org_notes_textarea" style={{marginTop: 0, minHeight: '80px'}} placeholder={cutlist_machine[i].note} value={cutlist_machine[i].note} oninput="autoResize(this)" onChange={(e) => {cutlist_machine[i].note = e.target.value; forceUpdate();}}/>
                                        </div>
                                    )
                                }) : ''
                            }
                        </Col>
                    </Row>
                </div>
                <div>
                    <span onClick={() => {cutlist_machine.push({type: 'Panel Saw', brand_name: 'Homag', model_number: '', note: ''}); forceUpdate()}}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>+ Add Cutlist Machine</span>
                </div>
            </div>
            <div className="cam mb-4">
                <h3>CAM Machines</h3>
                <div className="ml-2">
                    <Row md={3}>
                        <Col style={{ paddingLeft: '10px', paddingRight: 0 }}>
                            <div className='mb-2'>Machine Type</div>
                            {
                                cam_machine ? cam_machine.map((x, i) => {
                                    return (
                                        <div style={{ zIndex: 1, position: 'relative', paddingBottom: '50px' }}>
                                            <Select
                                                value={{value: cam_machine[i].type, label: cam_machine[i].type}}
                                                options={cam_machine_types.map(a => {return {value: a, label: a}})}
                                                onChange={(selectedOption) => {cam_machine[i].type = selectedOption.value; set_cam_machine(cam_machine); forceUpdate()}}
                                            />
                                        </div>
                                    )
                                }) : ''
                            }
                        </Col>

                        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <div className='mb-2'>Brand Name</div>
                            {
                                cam_machine ? cam_machine.map((x, i) => {
                                    return (
                                        <div style={{ zIndex: 1, position: 'relative', paddingBottom: '50px' }}>
                                            <CreatableSelect
                                                value={{value: cam_machine[i].brand_name, label: cam_machine[i].brand_name}}
                                                options={cam_machine_brand_names.map(a => {return {value: a, label: a}})}
                                                onChange={(selectedOption) => {cam_machine[i].brand_name = selectedOption.value; set_cam_machine(cam_machine); forceUpdate()}}
                                            />
                                        </div>
                                    )
                                }) : ''
                            }
                        </Col>

                        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <div className='mb-2'>Model Number</div>
                            {
                                cam_machine ? cam_machine.map((x, i) => {
                                    return (
                                        <div style={{ zIndex: 1, position: 'relative', paddingBottom: '50px' }}>
                                            <Input type='text' style={{ height: '36px' }} placeholder={cam_machine[i].model_number} value={cam_machine[i].model_number} onChange={(e) => {cam_machine[i].model_number = e.target.value; forceUpdate();}} />
                                        </div>
                                    )
                                }) : ''
                            }
                        </Col>

                        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <div className='mb-2'>Note</div>
                            {
                                cam_machine ? cam_machine.map((x, i) => {
                                    return (
                                        <div style={{ zIndex: 1, position: 'relative' }}>
                                            <textarea className="org_notes_textarea" style={{marginTop: 0, minHeight: '80px'}} placeholder={cam_machine[i].note} value={cam_machine[i].note} oninput="autoResize(this)" onChange={(e) => {cam_machine[i].note = e.target.value; forceUpdate();}}/>
                                        </div>
                                    )
                                }) : ''
                            }
                        </Col>
                    </Row>
                </div>
                <div>
                    <span onClick={() => {cam_machine.push({type: 'CNC', brand_name: 'Homag', model_number: '', note: ''}); forceUpdate();}}  style={{fontSize:'13px', textAlign:'left', color:'#007BFF', cursor:'pointer', fontWeight:600}}>+ Add CAM Machine</span>
                </div>
            </div>
            {
                <Button color="primary" onClick={update_details}>Update Machine Information</Button>
            }
        </div>
    )
}

let today = new Date(new Date().toDateString());
let last_month = new Date()
last_month.setDate(today.getDate() - 31)

class Home extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			orders: null,
			active_tab: 0,
			store_id: this.props.match.params.store_id,
			store_data: {},
			store_info: {},
			license_plans: [],
			license_plan_map: {},
			account_details: {},
			plugin_account_details: {},
			credits_account: null,
			show_credits_modal: false,
			show_transfer_credits_modal: false,
			show_upgrade_plan_modal: false,
			// store_has_unresolved_requests: false,
			store_can_request_extension: false,
			show_trail_extension_modal: false,
			show_suspend_store_modal: false,
			show_onboarding_status_modal: false,
			show_addon_onboarding_modal: false,
			show_manage_add_ons_modal: false,
			existing_add_ons_string: '',
			next_cycle_add_ons_string: '',
			show_manage_enterprise_smb: false,
			dues: [],
			mixpanel_user_level_events: {},
			usage_start_time: last_month,
			usage_end_time: today,
			show_usage_filter: false,
			show_responsiveness_modal: false,
			show_cutlist_cam_details_modal: false,
		};
	}

	toggle_show_usage_filter = () => { this.setState({ show_usage_filter: !this.state.show_usage_filter }); }

	onChangeFromDate = (date, dateString) => {
		this.setState({ usage_start_time: dateString })
		// set_to_date(dateString)
		document.getElementById('to_date').click()
	}

	onChangeToDate = (date, dateString) => {
		this.setState({ usage_end_time: dateString })
	}


	componentWillMount() {
		this.refresh_data();
		this.fetch_store_info()
		this.fetch_dues()
		this.fetch_last_paid_cycle_end_date(this.props.match.params.store_id)
		console.log('Number', Number(this.props.match.params.tab_id))
		this.setState({ active_tab: Number(this.props.match.params.tab_id) ? Number(this.props.match.params.tab_id) : 0 })
		// this.fetch_user_level_mixpanel_events(this.state.usage_start_time, this.state.usage_end_time)
	}


	componentDidUpdate(prev_props) {
		// if (prev_props.fetching_store_data !== this.props.fetching_store_data) {
		//     this.refresh_store_info();
		// }
		if (prev_props.match.params.store_id != this.props.match.params.store_id) {
			this.fetch_store_info()
		}
		console.log('cs_org_users={this.props.cs_org_users}', this.props.cs_org_users, this.props.user_details)
	}

	fetch_store_info = async () => {
		this.props.set_page_loader(true, 'Please wait...')
        let resp = null
        try {
            resp = await general_fetch({ url: 'csp/get_org_info', body: { customer_store_id: this.state.store_id } })
        } catch(err) {
            console.log(err);
			toastr.error('Something went wrong, please reload')
        }
		this.props.set_page_loader(false)
		let str = ''
		if (resp && resp.add_ons && resp.add_ons.active && resp.add_ons.active.length) {
			resp.add_ons.active.map((x, idx) => { str += infurnia_add_ons[x - 1].label; if (idx != resp.add_ons.active.length - 1) str += ', ' })
		}

		let str2 = ''
		if (resp && resp.add_ons && resp.add_ons.subscribed && resp.add_ons.subscribed.length) {
			resp.add_ons.subscribed.map((x, idx) => { str2 += infurnia_add_ons[x - 1].label; if (idx != resp.add_ons.subscribed.length - 1) str2 += ', ' })
		}
		this.setState({ store_info: resp, existing_add_ons_string: str, next_cycle_add_ons_string: str2 })
		console.log('store info', resp)
		let today = new Date().toISOString()
		let trail_end = new Date(resp.last_trial_date).toISOString()
		this.setState({ store_can_request_extension: today <= trail_end })
		// let resp_unresolved_requests = await general_fetch({url: 'billing/get_all_unresolved_trial_requests_of_store', body:{customer_store_id: this.state.store_id}})
	}

	fetch_last_paid_cycle_end_date = async (store_id) => {
		try {
			let resp = await general_fetch({ url: 'csp/fetch_last_paid_cycle_end_date', body: { customer_store_id: store_id } })
			if (resp && resp.end_date) this.setState({ last_paid_cycle_end_date: resp.end_date })
		} catch (err) {
			console.error(err)
		}
	}

	fetch_user_level_mixpanel_events = async (start_time, end_time) => {
		try {
			const apiUrl = global.config.analytics_path + 'api/user_level_events'; // Replace with your API URL
			const payload = {
				start_time: start_time.toISOString(), // Replace with your start_time
				end_time: end_time.toISOString(),   // Replace with your end_time
				org_id: window.location.pathname.split('/')[3]
			};

			// Make the POST request using fetch
			fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			})
				.then(response => {
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then(data => {
					// Process the response data
					console.log('Response data cassandra:', data);
					this.setState({ mixpanel_user_level_events: data })
				})
				.catch(error => {
					console.error('Error:', error);
				});

		} catch (err) {
			console.error(err)
		}
	}


	// refresh_store_info = () => {
	//     if (this.props.stores && this.props.stores.length) {
	//         var store_data = this.props.stores.filter(x => x.id == this.state.store_id)[0];
	//         console.log('im in', this.props, store_data)
	//         this.setState({store_data});
	//     }
	// }

	group_by = (arr, key) =>
		arr.reduce((final, elem) => {
			(final[elem[key]] = final[elem[key]] || []).push(elem);
			return final;
		}, {});


	get_account_details = async () => {
		var store_id = this.props.match.params.store_id;
		var resp = await get_store_account_details(store_id);
		this.setState({ account_details: resp.infurnia_credits_account, plugin_account_details: resp.render_credits_account });
	}

	refresh_data = () => {
		// this.refresh_store_info();
		// var get_orders = get_store_orders(this.props.match.params.store_id);

		var get_usage_data = Promise.resolve();
		if (!this.props.usage && !this.props.fetching_usage_data) {
			// get_usage_data = this.props.get_usage()
		}

		// this.get_account_details();

		// this.props.get_all_licenses(this.state.store_id)
		// .then(license_plans => {
		//     console.log('usage => ', license_plans);
		//     var license_plan_map = license_plans.reduce((final, elem) => ({...final, [elem.id]: elem}), {});
		//     this.setState({license_plans, license_plan_map});

		//     //handle unassigned licenses
		//     var unassigned_licenses = [];
		//     license_plans.filter(x => (x.subscription_type == 'reassignable' && x.tenor != 'daily' && x.Licenses.length)).map(plan => {
		//         var licenses = plan.Licenses.filter(license => !license.assigned);
		//         if (licenses && licenses.length) {
		//             licenses = this.group_by(licenses, 'days_left');
		//             Object.keys(licenses).map(key => {
		//                 unassigned_licenses.push({plan_id: plan.id, days_left: key, count: licenses[key].length});
		//             })
		//         }
		//     })
		//     // console.log('asdadasd', unassigned_licenses)
		//     this.setState({unassigned_licenses});
		// })
		// .catch(err => toastr.error('Something went wrong'))

		// get_orders
		// .then(orders => {
		//     this.setState({orders});
		// })

	}


	update_inputs = (event) => {
		console.log(this.state);
		this.setState({ [event.target.name]: event.target.value })
	}

	generate_stat_card = (text, number, icon) => (
		<div>
			<Card className="card-stats mb-4 mb-lg-0">
				<CardBody>
					<Row>
						<div className="col">
							<CardTitle className="text-uppercase mb-0">
								<h4 className="text-primary" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{text}</h4>
							</CardTitle>
							<span className="h3 font-weight-bold mt-1 mb-0">{number}</span>
						</div>
						<Col className="col-auto">
							<div className="icon icon-shape bg-primary text-white rounded-circle shadow">
								<i className={"fas fa-" + icon} />
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</div>
	);

	generate_badge = (text, color) => (
		<Badge color={color} className="mb-0" style={{ display: "inline-block", marginLeft: "auto", textTransform: 'capitalize' }}><h3 style={{ fontSize: '15px' }} className="mb-0">{text}</h3></Badge>
	)


	toggle_credits_modal = () => this.setState({ show_credits_modal: !this.state.show_credits_modal });

	open_credits_modal = credits_account => this.setState({ show_credits_modal: true, credits_account })

	close_credits_modal = () => this.setState({ show_credits_modal: false, credits_account: null })

	toggle_transfer_credits_modal = () => this.setState({ show_transfer_credits_modal: !this.state.show_transfer_credits_modal });

	toggle_upgrade_plan_modal = () => this.setState({ show_upgrade_plan_modal: !this.state.show_upgrade_plan_modal });

	toggle_extend_trail_modal = () => this.setState({ show_trail_extension_modal: !this.state.show_trail_extension_modal });

	toggle_suspend_store_modal = () => this.setState({ show_suspend_store_modal: !this.state.show_suspend_store_modal });

	toggle_onboarding_status_modal = () => this.setState({ show_onboarding_status_modal: !this.state.show_onboarding_status_modal });

	toggle_addon_onboarding_modal = () => this.setState({ show_addon_onboarding_modal: !this.state.show_addon_onboarding_modal });

	toggle_responsiveness_modal = () => this.setState({ show_responsiveness_modal: !this.state.show_responsiveness_modal });

	toggle_manage_add_ons_modal = () => this.setState({ show_manage_add_ons_modal: !this.state.show_manage_add_ons_modal });

	toggle_manage_enterprise_smb = () => this.setState({ show_manage_enterprise_smb: !this.state.show_manage_enterprise_smb });

	toggle_cutlist_cam_details_modal = () => this.setState({ show_cutlist_cam_details_modal: !this.state.show_cutlist_cam_details_modal });

	mark_important = async (status) => {
		try {
			let resp = await general_fetch({ url: 'csp/update_important_status', body: { customer_org_id: this.state.store_id, status } })
			this.fetch_store_info()
		} catch (err) {
			console.log(err)
		}
	}

	mark_premium = async () => {
		try {
			let resp = await general_fetch({ url: 'csp/mark_as_premium', body: { customer_store_id: this.state.store_id } })
			this.fetch_store_info()
		} catch (err) {
			console.log(err)
		}
	}

	manage_enterprise_smb = async () => {
		try {
			if (this.state.store_info.enterprise) {
				let resp = await general_fetch({ url: 'csp/downgrade_to_smb', body: { customer_store_id: this.state.store_id } })
				toastr.success('Successfully marked as SMB')
			} else {
				let resp = await general_fetch({ url: 'csp/upgrade_to_enterprise', body: { customer_store_id: this.state.store_id } })
				toastr.success('Successfully marked as Enterprise')
			}

			this.fetch_store_info()
		} catch (err) {
			console.log(err)
		}
	}

	add_cse_to_org = async () => {
		try {
			let resp = await general_fetch({ url: 'csp/add_customer_success_executive_to_org', body: { customer_store_id: this.state.store_id, cs_executive_id: this.state.store_info.customer_success_executive_id } })
			toastr.success('Successfully added CS Rep to Org')
			this.fetch_store_info()
		} catch (err) {
			toastr.error('Something went wrong, please try again')
			console.error(err)
		}
	}

	fetch_dues = async () => {
		try {
			var resp = await general_fetch({ url: 'csp/find_dues', body: { customer_store_id: this.state.store_id } });
			this.setState({ dues: resp })
		}
		catch (err) {
			// alert.error("Something went Wrong")
			toastr.error('Something went wrong')
		}
	}

	mark_org_internal = async () => {
		try {
			this.props.set_page_loader(true, 'Please wait...')
			var resp = await general_fetch({ url: 'csp/mark_org_internal', body: { customer_store_id: this.state.store_id } });
			this.props.set_page_loader(false)
		}
		catch (err) {
			this.props.set_page_loader(false)
			// alert.error("Something went Wrong")
			toastr.error('Something went wrong')
		}
	}

	// open_transfer_credits_modal = credits_account => this.setState({ show_transfer_credits_modal: true, credits_account })

	// close_transfer_credits_modal = () => this.setState({ show_transfer_credits_modal: false, credits_account: null })


	render() {
		const styles = {
			header_row: {
				marginLeft: 0,
				marginRight: 0,
				overflowX: "auto",
			},
			table_header: {
				fontWeight: "bold",
				fontSize: 'small'
			},
			round_status: {
				fontSize: 'small',
				paddingBottom: 0
			},
			nav_pills_rounds: {
				paddingRight: 40,
				marginRight: "0.7rem",
				marginLeft: "0.2rem",
			},
			action_button: {
				margin: 1
			}
		};

		const success_badge = (
			<Badge color="" className="badge-dot mr-4">
				<i className="bg-success" />
				completed
			</Badge>
		);

		const warning_badge = (
			<Badge color="" className="badge-dot mr-4">
				<i className="bg-warning" />
				pending
			</Badge>
		);




		const unassigned_licenses_table = (
			<Row>
				<h4 className="text-primary mb-2 mt--1" style={{ display: "inline-block", margin: "auto" }}>UNATTACHED LICENSES</h4>
				<Table className="align-items-center table-flush text-center" responsive>
					<thead className="thead-light text-center">
						<tr>
							<th scope="col"><span style={styles.table_header}><b>License Plan</b></span><br /></th>
							<th scope="col"><span style={styles.table_header}><b>Days Left</b></span><br /></th>
							<th scope="col"><span style={styles.table_header}><b>Count</b></span> <br /></th>
						</tr>
					</thead>
					<tbody >
						{this.state.unassigned_licenses && this.state.unassigned_licenses.map(row => (
							<tr>
								<td>{this.state.license_plan_map[row.plan_id].name}</td>
								<td>{row.days_left}</td>
								<td>{row.count}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Row>
		)


		const overview_tab = (
			<Row>
				<Container fluid className='mb-4'>
					<Row>
						{/* <Col xs="3">{this.generate_stat_card("Org Name", this.state.store_info.name ? this.state.store_info.name : "N/A", 'fingerprint')}</Col> */}
						<Col xs="3">
							<OrgDomain store_info={this.state.store_info} fetch_store_info={this.fetch_store_info} />
						</Col>
						<Col xs="3">{this.generate_stat_card('Total Users', this.state.store_info && this.state.store_info.all_users ? this.state.store_info.all_users.length : '0', 'user-astronaut')}</Col>
						<Col xs="3">
							<AssignCustomerSuccessExecutive store_info={this.state.store_info} fetch_store_info={this.fetch_store_info} user_details={this.props.user_details} sales_or_cs='sales' cs_org_users={this.props.cs_org_users}></AssignCustomerSuccessExecutive>
						</Col>
						<Col xs="3">
							<AssignCustomerSuccessExecutive store_info={this.state.store_info} fetch_store_info={this.fetch_store_info} user_details={this.props.user_details} sales_or_cs='cs' cs_org_users={this.props.cs_org_users}></AssignCustomerSuccessExecutive>
						</Col>
					</Row>
				</Container>
				<Col className="order-xl-2 mb-5 mb-xl-0" xl="6">
					<Card className="card-profile bg-secondary shadow mb-2">
						<CardHeader className="bg-white border-0" >
							<Row className="align-items-center text-center mb-2" style={styles.header_row}>
								<h3 className="mb-0">Onboarding Status</h3>
								{this.generate_badge(this.state.store_info.onboarding_status, 'info')}
								<i className="fa fa-edit ml-3 cp" style={{ color: '#5e72e4', fontSize: '18px' }} onClick={this.toggle_onboarding_status_modal} />
							</Row>
							<Row className="align-items-center text-center mb-2 flex_between" style={styles.header_row}>
								<h3 className="mb-0">Add-on Onboarding</h3>
								{/* {this.generate_badge(this.state.store_info.onboarding_status, 'info')} */}
								<i className="fa fa-edit ml-3 cp" style={{ color: '#5e72e4', fontSize: '18px' }} onClick={this.toggle_addon_onboarding_modal} />
							</Row>
							<Row className="align-items-center text-center mb-2 flex_between" style={styles.header_row}>
								<h3 className="mb-0">Important</h3>
                                {
                                    <div className="ml-3">
                                        No
                                        <Switch size="small" className="mr-2 ml-2" value={this.state.store_info.csp_important} onClick={() => this.mark_important(!this.state.store_info.csp_important)} />
                                        Yes
                                    </div>
                                }
							</Row>
							<Row className="align-items-center text-center mb-2" style={styles.header_row}>
								<h3 className="mb-0">Responsive</h3>
								{this.generate_badge(this.state.store_info.responsiveness ? 'Yes' : 'No', 'info')}
								<i className="fa fa-edit ml-3 cp" style={{ color: '#5e72e4', fontSize: '18px' }} onClick={this.toggle_responsiveness_modal} />
							</Row>
							{/* <Row className="align-items-center text-center" style={styles.header_row}>
								<h3 className="mb-0">Domain</h3>
								{this.generate_badge(this.state.store_info.domain, 'info')}
								<i className="fa fa-edit ml-3 cp" style={{ color: '#5e72e4', fontSize: '18px' }} onClick={this.toggle_domain_modal} />
							</Row> */}
						</CardHeader>
						{/* <CardBody className="pt-0">
                  <Row>
                    <div className="col text-center">
                      
                    </div>
                  </Row>
                </CardBody> */}
						{
							this.state.store_info ?
								<CardFooter style={{ fontSize: '14px' }}>
                                    <Cutlist_Cam_Details
                                        open={this.state.show_cutlist_cam_details_modal}
                                        toggle={this.toggle_cutlist_cam_details_modal}
                                        store_info={this.state.store_info}
                                        fetch_org_info={this.fetch_store_info}
                                        set_page_loader={this.props.set_page_loader}
                                        fetch_all_stores={this.props.fetch_all_stores}
                                    />
                                    {
                                        this.state.store_info ? 
                                        <Row>
                                        </Row>
                                        : ''
                                    }
									{
										// this.state.store_info ?
										// 	<Row className='flex_between px-3 mb-2'>
										// 		<div>Cutlist Machine</div>
										// 		<div className="flex_property">
										// 			<div>{this.state.store_info && this.state.store_info.add_on_details && this.state.store_info.add_on_details.cutlist_machine && this.state.store_info.add_on_details.cutlist_machine.length ? this.state.store_info.add_on_details.cutlist_machine.join(', ') : <NA />}</div>
										// 			<i className="fa fa-edit ml-3 cp" style={{ color: '#5e72e4', fontSize: '18px' }} onClick={this.toggle_cutlist_cam_details_modal} />
										// 		</div>
										// 	</Row> : ''
									}
									{
										// this.state.store_info ?
										// 	<>
										// 		<Row className='flex_between px-3 mb-2'>
										// 			<div>CAM Machine</div>
										// 			<div className="flex_property">
										// 				<div>{this.state.store_info && this.state.store_info.add_on_details && this.state.store_info.add_on_details.cam_machine && this.state.store_info.add_on_details.cam_machine.length ? this.state.store_info.add_on_details.cam_machine.join(', ') : <NA />}</div>
										// 				<i className="fa fa-edit ml-3 cp" style={{ color: '#5e72e4', fontSize: '18px' }} onClick={this.toggle_cutlist_cam_details_modal} />
										// 			</div>
										// 		</Row>
										// 	</> : ''
									}
									{
										this.state.store_info.customer_success_executive ?
											<Row className="align-items-center text-center" style={styles.header_row}>
												{
													this.state.store_info.all_users && this.state.store_info.all_users.length && this.state.store_info.all_users.find(o => o.login_id === this.state.store_info.customer_success_executive.login_id) ?
														''
														:
														<div className="flex_property" style={{ width: '100%', justifyContent: 'space-between' }}>
															<div style={{ color: '#FF5247' }}>Customer success Rep is not added to the Org</div>
															<Button color='primary' onClick={this.add_cse_to_org}>Add</Button>
														</div>
												}
											</Row> : ''
									}
								</CardFooter> : ''
						}
					</Card>
					<Card className="card-profile bg-secondary shadow">
						<CardHeader className="bg-white border-0" >
							<Row className="align-items-center text-center" style={styles.header_row}>
								<h3 className="mb-0">Financial Status</h3>
								{this.state.store_info.premium_customer ? this.generate_badge('premium customer', 'info') : this.generate_badge('trial customer', 'warning')}
							</Row>
						</CardHeader>
						<CardBody className="pt-0" style={{ overflow: 'auto' }}>
							<Row>
								<div className="col text-center">
									<br />
									{this.generate_badge((this.state.store_info.enterprise ? 'Enterprise' : 'SMB'), 'info')}
									<br /> <br />
									{this.generate_badge('Add-ons - ' + (this.state.existing_add_ons_string ? this.state.existing_add_ons_string : 'N/A'), 'info')}
									<br /> <br />
									{this.generate_badge('Next Cycle Add-ons - ' + (this.state.next_cycle_add_ons_string ? this.state.next_cycle_add_ons_string : 'N/A'), 'info')}
									<br /> <br />
									{this.generate_badge('Credits - ' + (this.state.store_info.balance ? this.state.store_info.balance.toFixed(3) : 0), 'info')}
									<br /> <br />
									{this.generate_badge('Licenses Purchased - ' + (this.state.store_info.subscription_info ? this.state.store_info.subscription_info.license_count : 0), 'info')}
									{/* <br /> <br />
					  {this.generate_badge('Plan Type - ' + (this.state.store_info.current_plan ? this.state.store_info.current_plan.name : 'N/A'), 'info')} */}
								</div>
							</Row>
						</CardBody>
						<CardFooter>
							<Row className="text-center">
								<Col xs="12" className="text-center">
									<Button className='mb-2' onClick={this.toggle_transfer_credits_modal} color="primary">{"Transfer Free Credits"}</Button>
									{
										this.state.last_paid_cycle_end_date ? <Button className='mb-2' onClick={this.toggle_manage_add_ons_modal} color="primary">{"Manage Add-ons"}</Button> : ''
									}
									{!this.state.store_info.premium_customer && !this.state.store_info.suspended ? <Button className='mb-2' onClick={() => submit(this.mark_org_internal, () => { }, 'Are you sure you want to mark this Org as INTERNAL?', 'Yes', '')} color="primary">{"Mark Org as Internal"}</Button> : ''}
									{!this.state.store_info.premium_customer && this.state.store_info.suspended ? <Button className='mb-2' onClick={this.toggle_extend_trail_modal} color="primary">{"Extend trial"}</Button> : ''}
									{this.state.store_info && this.state.store_info.pending_suspension ? <Button className='mb-2' onClick={this.toggle_suspend_store_modal} color="primary">{"Suspend Store"}</Button> : ''}
									{/* {this.state.store_info && !this.state.store_info.premium_customer ? <Button className='mb-2' onClick={() => submit(this.mark_premium, ()=>{}, '', '','')} color="primary">{"Mark Premium"}</Button> : ''} */}
									{<Button className='mb-2' onClick={() => submit(this.manage_enterprise_smb, () => { }, '', '', '')} color="primary">{this.state.store_info && this.state.store_info.enterprise ? "Mark as SMB" : "Mark as Enterprise"}</Button>}
									{/* <Button color="primary" onClick={() => this.open_credits_modal('infurnia_credits')}><span className="fa fa-plus" /> &nbsp; Add Design Credits</Button>
                            <Button color="primary" onClick={() => this.open_credits_modal('render_credits')}><span className="fa fa-plus" /> &nbsp; Add Render Credits</Button> */}
								</Col>
							</Row>
						</CardFooter>
					</Card>
				</Col>
				<Col className="order-xl-1" xl="6">
					{
						this.state.store_info && this.state.store_info.ongoing_grace_period ?
							<CardFooter>
								<Row className="align-items-center text-center" style={{ ...styles.header_row, color: 'red' }}>
									This Org is on grace period from <b>{'\xa0' + new Date(this.state.store_info.ongoing_grace_period.start_date).toString().substring(4, 15) + '\xa0'}</b> to <b>{'\xa0' + new Date(this.state.store_info.ongoing_grace_period.end_date).toString().substring(4, 15)}</b>
								</Row>
							</CardFooter> : ''
					}
					{
						this.state.store_info && this.state.store_info.churned ?
							<CardFooter>
								<Row className="align-items-center text-center" style={{ ...styles.header_row, color: 'red' }}>
									This Org is churned {this.state.store_info.churn_date ? <>from <b>{'\xa0' + new Date(this.state.store_info.churn_date).toString().substring(4, 15) + '\xa0'}</b></> : ''}
								</Row>
							</CardFooter> : (
								this.state.store_info && this.state.store_info.suspended ?
									<CardFooter>
										<Row className="align-items-center text-center" style={{ ...styles.header_row, color: 'red' }}>
											This Org is suspended {this.state.store_info.last_suspended_date ? <>from <b>{'\xa0' + new Date(this.state.store_info.last_suspended_date).toString().substring(4, 15) + '\xa0'}</b></> : ''}
										</Row>
									</CardFooter> : ''
							)
					}
					<Card className="bg-secondary shadow">
						<CardHeader className="bg-white border-0" >
							<Row className="align-items-center" style={styles.header_row}>
								<h3 className="mb-0">Billing Address</h3>
								{this.state.store_info.gst_number ? this.generate_badge("GST - " + this.state.store_info.gst_number, 'info') : this.generate_badge('GST info unavailable', 'warning')}
							</Row>
						</CardHeader>
						<CardBody>
							<div className="pl-lg-4">
								<Row>
									<Col md="12">
										<FormGroup>
											<label className="form-control-label" htmlFor="input-address" >
												Address (line 1)
											</label>
											<Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.line_1 : "N/A"} name="line_1" className="form-control-alternative" id="input-address" type="text" />
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md="12">
										<FormGroup>
											<label className="form-control-label" htmlFor="input-address" >
												Address (line 2)
											</label>
											<Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.line_2 : "N/A"} name="line_2" className="form-control-alternative" id="input-line-2" type="text" />
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col lg="6">
										<FormGroup>
											<label className="form-control-label" htmlFor="input-city" >
												City
											</label>
											<Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.city : "N/A"} name="city" className="form-control-alternative" id="input-city" type="text" />
										</FormGroup>
									</Col>
									<Col lg="6">
										<FormGroup>
											<label className="form-control-label" htmlFor="input-country">
												State
											</label>
											<Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.state : "N/A"} name="state" className="form-control-alternative" id="input-state" type="text" />
										</FormGroup>
									</Col>
									<Col lg="6">
										<FormGroup>
											<label className="form-control-label" htmlFor="input-country" >
												Country
											</label>
											<Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.country : "N/A"} name="country" className="form-control-alternative" id="input-country" type="text" />
										</FormGroup>
									</Col>
									<Col lg="6">
										<FormGroup>
											<label className="form-control-label" htmlFor="input-country">
												PIN code
											</label>
											<Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.pin_code : "N/A"} name="pin_code" className="form-control-alternative" id="input-postal-code" type="text" />
										</FormGroup>
									</Col>
									<Col md="12">
										<FormGroup>
											<label className="form-control-label" htmlFor="input-address" >
												Contact
											</label>
											<Input disabled="true" value={this.state.store_info.contact ? this.state.store_info.contact : "N/A"} className="form-control-alternative" type="text" />
										</FormGroup>
									</Col>
								</Row>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		)

		const usage_filler = (
			<Table className="align-items-center table-flush text-center" responsive>
				<thead className="thead-light text-center">
					<tr>
						<th scope="col"><span style={styles.table_header}><b>No postpaid usage available</b></span><br /> for this store.<br /> &nbsp;</th>
					</tr>
				</thead>
			</Table>
		)

		const orders_filler = (
			<Table className="align-items-center table-flush text-center" responsive>
				<thead className="thead-light text-center">
					<tr>
						<th scope="col"><span style={styles.table_header}><b>No prepaid orders placed</b></span><br /> for this store.<br /> &nbsp;</th>
					</tr>
				</thead>
			</Table>
		)

		
		
		const orders_tab = (this.state.orders && this.state.orders.length) ? (<OrderDisplay orders={this.state.orders} show_store_id={false} />) : orders_filler;
		const usage_tab = (this.state.store_info) ? (<UsageDisplay store_info={this.state.store_info} usage={this.state.usage} market={this.state.store_info.market} set_page_loader={this.props.set_page_loader} />) : usage_filler;
		const billing_history_tab = (this.state.store_info) ? (<UsageDisplay store_info={this.state.store_info} type='billing_history' usage={this.state.usage} market={this.state.store_info.market} set_page_loader={this.props.set_page_loader} fetch_store_info={this.fetch_store_info} submit={submit} />) : usage_filler;
		const logs = <Logs></Logs>
		const dues = <Dues store_id={this.state.store_id} store_info={this.state.store_info} fetch_store_info={this.fetch_store_info} user_details={this.props.user_details} dues={this.state.dues} fetch_dues={this.fetch_dues} set_page_loader={this.props.set_page_loader} />
		const users_tab = <UsersTab store_info={this.state.store_info}/>
		const org_settings_tab = <OrgSettings store_info={this.state.store_info} fetch_store_info={this.fetch_store_info} />
		const org_dates_tab = <OrgDates user_details={this.props.user_details} store_info={this.state.store_info} fetch_store_info={this.fetch_store_info} last_paid_cycle_end_date={this.state.last_paid_cycle_end_date} fetch_last_paid_cycle_end_date={this.fetch_last_paid_cycle_end_date} set_page_loader={this.props.set_page_loader} is_team_lead={this.props.is_team_lead}/>
		const activity = <OrgActivity all_stores={this.props.all_stores} store_info={this.state.store_info} user_details={this.props.user_details} set_page_loader={this.props.set_page_loader} cs_org_users={this.props.cs_org_users} />
		const usage_graph = <UsageGraph store_info={this.state.store_info} user_details={this.props.user_details} set_page_loader={this.props.set_page_loader} cs_org_users={this.props.cs_org_users} />
		const inventory_templates = <InventoryTemplates store_info={this.state.store_info} user_details={this.props.user_details} set_page_loader={this.props.set_page_loader} />
		const bu_management_tab = <BusinessUnits submit_for_delete_confirm={submit} store_id={this.state.store_id} set_page_loader={this.props.set_page_loader} />
        const cs_tickets = <CustomerTickets user_details={this.props.user_details} store_id={this.state.store_id} cs_org_users={this.props.cs_org_users} all_stores={this.props.all_stores} set_page_loader={this.props.set_page_loader} />
		const risk_history = <ClientsAtRisk 
			all_stores={this.props.all_stores}
			user_details={this.props.user_details}
			cs_org_users={this.props.cs_org_users}
			set_page_loader={this.props.set_page_loader}
			hide_header_row={true}
			permanent_filtration_org_id={this.state.store_id}
		/>
		const tabs = [overview_tab, billing_history_tab, usage_tab, dues, users_tab, bu_management_tab, org_settings_tab, activity, org_dates_tab, usage_graph, inventory_templates, cs_tickets, risk_history]

		return (
			<>
				{/* Page content */}
				<CreditsModal
					show_credits_modal={this.state.show_credits_modal}
					close_credits_modal={this.close_credits_modal}
					get_account_details={this.get_account_details}
					store_id={this.state.store_info.id}
					generate_badge={this.generate_badge}
					credits_account={this.state.credits_account}
					fetch_store_info={this.fetch_store_info}
				/>
				<TransferCreditsModal
					open={this.state.show_transfer_credits_modal}
					toggle={this.toggle_transfer_credits_modal}
					store_id={this.state.store_id}
					generate_badge={this.generate_badge}
					fetch_store_info={this.fetch_store_info}
				/>

				<SuspendStoreModal
					open={this.state.show_suspend_store_modal}
					toggle={this.toggle_suspend_store_modal}
					store_id={this.state.store_id}
					generate_badge={this.generate_badge}
					fetch_store_info={this.fetch_store_info}
				/>

				<ExtendTrail
					open={this.state.show_trail_extension_modal}
					toggle={this.toggle_extend_trail_modal}
					store_id={this.state.store_id}
					generate_badge={this.generate_badge}
					fetch_store_info={this.fetch_store_info}
					set_page_loader={this.props.set_page_loader}
					fetch_all_stores={this.props.fetch_all_stores}
				/>
				<UpgradePlan
					open={this.state.show_upgrade_plan_modal}
					toggle={this.toggle_upgrade_plan_modal}
					store_id={this.state.store_id}
					generate_badge={this.generate_badge}
					fetch_store_info={this.fetch_store_info}
				/>
				<UpdateOnboardingStatus
					open={this.state.show_onboarding_status_modal}
					toggle={this.toggle_onboarding_status_modal}
					store_id={this.state.store_id}
					store_info={this.state.store_info}
					generate_badge={this.generate_badge}
					fetch_store_info={this.fetch_store_info}
				/>
				<UpdateAddonOnboarding
					open={this.state.show_addon_onboarding_modal}
					toggle={this.toggle_addon_onboarding_modal}
					store_id={this.state.store_id}
					store_info={this.state.store_info}
					generate_badge={this.generate_badge}
					fetch_store_info={this.fetch_store_info}
				/>
				<UpdateResponsiveness
					open={this.state.show_responsiveness_modal}
					toggle={this.toggle_responsiveness_modal}
					store_id={this.state.store_id}
					store_info={this.state.store_info}
					generate_badge={this.generate_badge}
					fetch_store_info={this.fetch_store_info}
				/>
				<ManageAddOns
					open={this.state.show_manage_add_ons_modal}
					toggle={this.toggle_manage_add_ons_modal}
					store_id={this.state.store_id}
					store_info={this.state.store_info}
					existing_add_ons={this.state.store_info && this.state.store_info.add_ons ? this.state.store_info.add_ons.active.map(x => x.id) : []}
					fetch_store_info={this.fetch_store_info}
					current_bill_start_date={this.state.store_info ? this.state.store_info.current_bill_start_date : ''}
					next_bill_generation_date={this.state.store_info ? this.state.store_info.next_bill_generation_date : ''}
					last_paid_cycle_end_date={this.state.last_paid_cycle_end_date}
					fetch_dues={this.fetch_dues}
					set_page_loader={this.props.set_page_loader}
				/>

				<Usage_filterModal
					open={this.state.show_usage_filter}
					toggle={this.toggle_show_usage_filter}
					usage_start_time={this.state.usage_start_time}
					set_usage_start_time={(x) => this.setState({ usage_start_time: x })}
					usage_end_time={this.state.usage_end_time}
					set_usage_end_time={(x) => this.setState({ usage_end_time: x })}
					fetch_mix_panel_data={this.fetch_user_level_mixpanel_events}
				/>


				<div className="col">
					<Card className="shadow" style={{ height: '100vh', overflow: 'auto' }}>
						<CardHeader className="border-4 bg-gradient-secondary">
							<div className="flex_property mb-4">
								<i className="fa fa-building mr-2" style={{ fontSize: '17px', color: "#5e72e4" }} />
								{/* <div className="mr-2">Org Name:</div> */}
								<div style={{ fontWeight: 700, fontSize: '17px', maxWidth: '40vw', marginRight: '48px' }}>{this.state.store_info.name}</div>
								<i className="fas fa-fingerprint mr-2" style={{ fontSize: '17px', color: "#5e72e4" }} />
								{/* <div className="mr-2">Org ID:</div> */}
								<div style={{ fontWeight: 700, fontSize: '17px', marginRight: '48px' }}>{this.state.store_info.id}</div>
								<i className="fa fa-user mr-2" style={{ fontSize: '17px', color: "#5e72e4" }} />
								{/* <div className="mr-2">CS Rep:</div> */}
								<div style={{ fontWeight: 700, fontSize: '17px', marginRight: '32px' }}>{this.state.store_info && this.state.store_info.customer_success_executive ? this.state.store_info.customer_success_executive.first_name : <NA />}</div>
                                {
                                    // <AntdButton onClick={() => {}} type="link" icon={this.state.store_info.csp_important ? <StarFilled style={{fontSize: '20px'}}/> : <StarOutlined style={{fontSize: '20px'}}/>}/>
                                }

							</div>
							<Row style={styles.header_row}>
								<Nav pills style={{ paddingBottom: 5, marginTop: 4 }}>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 7} onClick={() => this.setState({ active_tab: 7 })}><b>Activity</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 0} onClick={() => this.setState({ active_tab: 0 })} ><b>Overview</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 1} onClick={() => this.setState({ active_tab: 1 })}><b>Billing History </b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 2} onClick={() => this.setState({ active_tab: 2 })}><b>Unbilled Usage</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 3} onClick={() => this.setState({ active_tab: 3 })}><b>Dues</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 4} onClick={() => this.setState({ active_tab: 4 })}><b>Users</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 5} onClick={() => this.setState({ active_tab: 5 })}><b>BU Management</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 6} onClick={() => this.setState({ active_tab: 6 })}><b>Org Settings</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 8} onClick={() => this.setState({ active_tab: 8 })}><b>Others</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 9} onClick={() => this.setState({ active_tab: 9 })}><b>Usage Graph</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 10} onClick={() => this.setState({ active_tab: 10 })}><b>Catalogue Management</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 11} onClick={() => this.setState({ active_tab: 11 })}><b>CS Tickets</b> </NavLink>
									</NavItem>
									<NavItem style={{ marginBottom: '8px' }}>
										<NavLink href='#' active={this.state.active_tab == 12} onClick={() => this.setState({ active_tab: 12 })}><b>Risk History</b> </NavLink>
									</NavItem>
								</Nav>
							</Row>
						</CardHeader>
						<CardBody>
							{tabs[this.state.active_tab]}
						</CardBody>
					</Card>
				</div>
			</>
		);
	}
}


const mapStateToProps = (state, own_props) => ({
	usage: state.usage,
	stores: state.stores,
	user_details: state.user_details,
	fetching_store_data: state.fetching_store_data,
	fetching_usage_data: state.fetching_usage_data,
	infurnia_teams: state.infurnia_teams,
	cs_org_users: state.cs_org_users,
	all_stores: state.all_stores,	
	...own_props
})


const mapDispatchToProps = dispatch => ({
	get_usage: () => dispatch(get_usage()),
	get_user_details: () => dispatch(get_user_details()),
	get_investment_status: () => dispatch(get_investment_status()),
	get_all_licenses: store_id => dispatch(get_all_licenses(store_id)),
	get_all_orders: () => dispatch(get_all_orders()),
	delete_investment: data => dispatch(delete_investment(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);