import React, { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import { CardBody, Card, Row, CardHeader, Input, Button, Table, Modal, ModalBody, ModalHeader } from 'reactstrap';
import QRCode from 'qrcode';
import { toastr } from 'react-redux-toastr';
import { Switch } from 'antd';

const OrgSettings = ({store_info, fetch_store_info}) => {

    const [enabled_public_sales_channels, set_enabled_public_sales_channels] = useState(false);
	const [enabled_catalog_dump, set_enabled_catalog_dump] = useState(false);
	const [enabled_ar_violation_dump, set_enabled_ar_violation_dump] = useState(false);

    useEffect(() => {
        if(store_info && store_info.public_sales_channels_enabled){
            set_enabled_public_sales_channels(true)
        }else{
            set_enabled_public_sales_channels(false)
        }

		if(store_info && store_info.catalog_dump_enabled){
            set_enabled_catalog_dump(true)
        }else{
            set_enabled_catalog_dump(false)
        }

		if(store_info && store_info.ar_violation_dump_enabled){
            set_enabled_ar_violation_dump(true)
        }else{
            set_enabled_ar_violation_dump(false)
        }
    }, [store_info]);

    const enable_stuff = async(flag_name, val) => {
        try{
			let url = 'csp/enable_public_sales_channels'
			if(flag_name === 'catalog_dump'){	
				url = 'csp/enable_catalog_dump'
			}else if(flag_name === 'ar_violation_dump'){	
				url = 'csp/enable_ar_violation_dump'
			}

            let resp = await general_fetch({url, body:{customer_org_id: store_info.id, status:val}})
            if(val){
                toastr.success('Successfully enabled')
            }else{
                toastr.success('Successfully disabled')
            }
            fetch_store_info()
        }catch(err){
            console.error(err);
            toastr.error('Error! Something went wrong')
        }
    }

    return(
        <div style={{padding:'40px'}}>
            <div className='flex_property mb-4' style={{width:'100%'}}>
                <div style={{flex:5}}>Enable Public Sales Channels</div>
                <Switch className='ml-4' size="small" checked={enabled_public_sales_channels} onChange={() => enable_stuff('public_sales_channels', !enabled_public_sales_channels)} />
            </div>
			<div className='flex_property mb-4' style={{width:'100%'}}>
                <div style={{flex:5}}>Enable Catalog Dump</div>
                <Switch className='ml-4' size="small" checked={enabled_catalog_dump} onChange={() => enable_stuff('catalog_dump', !enabled_catalog_dump)} />
            </div>
			<div className='flex_property mb-4' style={{width:'100%'}}>
                <div style={{flex:5}}>Enable AM Rules Violation Dump</div>
                <Switch className='ml-4' size="small" checked={enabled_ar_violation_dump} onChange={() => enable_stuff('ar_violation_dump', !enabled_ar_violation_dump)} />
            </div>
        </div>
    )
}

export default OrgSettings