const orgs_sort = (a,b,order,column_name,sorting_function_value) => {
	let value_a = sorting_function_value(a)[column_name].value
	let value_b = sorting_function_value(b)[column_name].value
	let field_type = sorting_function_value(a)[column_name].field_type
	if(field_type === 'number'){
		value_a = Number(value_a)
		value_b = Number(value_b)
	}

	if(field_type !== 'number'){
		if(!value_a) return 1*order
		if(!value_b) return -1*order
	}

	if(field_type === 'date'){
		return order*(new Date(value_b) - new Date(value_a))
	}
	if(value_a < value_b) return 1*order
	return -1*order
}

const generic_sort_for_orgs = (column_name, stores, order, sorting_function_value) => {
	let all_stores = JSON.parse(JSON.stringify(stores))
	all_stores.sort((a,b) => orgs_sort(a,b,-1*order,column_name,sorting_function_value))
	return all_stores
}

export default generic_sort_for_orgs

