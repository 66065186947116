let stateList = [
    {name: "Andaman and Nicobar Islands",code: "AN"},
    {name: "Andhra Pradesh",code: "AD"},
    {name: "Arunachal Pradesh",code: "AR"},
    {name: "Assam",code: "AS"},
    {name: "Bihar",code: "BR"},
    {name: "Chandigarh",code: "CH"},
    {name: "Chhattisgarh",code: "CG"},
    {name: "Dadra and Nagar Haveli and Daman and Diu",code: "DN"},
    {name: "Daman and Diu",code: "DD"},
    {name: "Delhi",code: "DL"},
    {name: "Foreign Country",code: "FC"},
    {name: "Goa",code: "GA"},
    {name: "Gujarat",code: "GJ"},
    {name: "Haryana",code: "HR"},
    {name: "Himachal Pradesh",code: "HP"},
    {name: "Jammu and Kashmir",code: "JK"},
    {name: "Jharkhand",code: "JH"},
    {name: "Karnataka",code: "KA"},
    {name: "Kerala",code: "KL"},
    {name: "Ladakh",code: "LA"},
    {name: "Lakshadweep",code: "LD"},
    {name: "Madhya Pradesh",code: "MP"},
    {name: "Maharashtra",code: "MH"},
    {name: "Manipur",code: "MN"},
    {name: "Meghalaya",code: "ML"},
    {name: "Mizoram",code: "MZ"},
    {name: "Nagaland",code: "NL"},
    {name: "Odisha",code: "OD"},
    {name: "Other Territory",code: "OT"},
    {name: "Puducherry",code: "PY"},
    {name: "Punjab",code: "PB"},
    {name: "Rajasthan",code: "RJ"},
    {name: "Sikkim",code: "SK"},
    {name: "Tamil Nadu",code: "TN"},
    {name: "Telangana",code: "TS"},
    {name: "Tripura",code: "TR"},
    {name: "Uttar Pradesh",code: "UP"},
    {name: "Uttarakhand",code: "UK"},
    {name: "West Bengal",code: "WB" }
]

  export default stateList