import React, { useEffect, useState, version } from "react";
import { Button, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from "../../components/ITable";
import { createReleaseNote, deleteReleaseNote, getAllReleaseNotes, getLatestVersions, updateReleaseNote } from "../../utils/api/release_note";
import useStateCallback from "../../utils/custom_hooks";

const AddOrUpdateReleaseNotes = ({open, handle_close, getLatestReleaseNotes, all_versions, active_release_note}) => {
    const [ release, set_release ] = useState('');
    const [ versions, set_versions ] = useState([]);
    const [ release_notes, set_release_notes ] = useState([]);
    const [ re_render, set_re_render ] = useState(false);

    const forceRerender = () => {set_re_render(!re_render)}

    const onclick_handle_close = () => {
        handle_close();
        set_release('')
        set_versions([])
        set_release_notes([])
    }

    // const createReleaseNotefn = () => {
    //     // console.log("jira version id ", release, "release notes", release_notes)
    //     // todo - check for validity
    //     createReleaseNote(release, JSON.stringify(release_notes), false)

    // }

    const createReleaseNotefn = async() => {
        try{
            await createReleaseNote(release, JSON.stringify(release_notes), false)
            onclick_handle_close()
            getLatestReleaseNotes()
        }catch(err){
            console.error(`Error in createReleaseNotefn `, err)
            // handleError(err);
        }
    }

    const updateReleaseNotefn = async() => {
        try{
            await updateReleaseNote(active_release_note.id, release, JSON.stringify(release_notes), false, active_release_note.status)
            onclick_handle_close()
            getLatestReleaseNotes()
        }catch(err){
            console.error(`Error in updateReleaseNotefn `, err)
            // handleError(err);
        }
    }

    useEffect(() => {
        if(open){
            (async () => {
                if(!(all_versions && all_versions.length)){
                    let versions = await getLatestVersions()
                    set_versions(versions)
                    if(active_release_note && active_release_note.jira_version_id){
                        set_release(active_release_note.jira_version_id)
                    }else{
                        set_release(versions[0].id)
                    }
                }else{
                    set_versions(all_versions)
                    if(active_release_note && active_release_note.jira_version_id){
                        set_release(active_release_note.jira_version_id)
                    }else{
                        set_release(all_versions[0].id)
                    }
                }

                if(active_release_note && active_release_note.feature_data){
                    set_release_notes(active_release_note.feature_data)
                }
            })()
        }
    }, [open]);
    return(
        <>
            <Modal centered size={"xl"} isOpen={open} toggle={onclick_handle_close}>
                <ModalHeader toggle={onclick_handle_close}>Add/Update Release Notes</ModalHeader>
                <ModalBody>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                        <div className="flex_property">
                            <div className="mr-3">Release*</div>
                            <Input type='select' value={release} onChange={(e) => {set_release(e.target.value)}}>
                                {
                                    versions && versions.length ? versions.map((version, idx) => (
                                        <option value={version.id} key={idx}>{version.name}</option>
                                    ))
                                    :''
                                }
                            </Input>
                        </div>
                        <ITable coloumnSeparated={true} rowSeparated={true} style_container={{border: '1px solid var(--light-border-color, #E6E9EE)', maxHeight: '400px', overflow: 'auto'}}>
                            <ITableHeader style={{background: "white", position: 'sticky', top: '0'}}>
                                <ITH>Feature Heading</ITH>
                                <ITH>Help Center Link</ITH>
                                <ITD>Actions</ITD>
                            </ITableHeader>
                            <ITableBody>
                                {
                                    release_notes && release_notes.length ? release_notes.map((note, idx) => (
                                        <ITableRow>
                                            <ITD style={{padding: '0px'}}><Input style={{border: 'none'}} value={note.heading} onChange={(e) => {note.heading = e.target.value; forceRerender()}}></Input></ITD>
                                            <ITD style={{padding: '0px'}}><Input style={{border: 'none'}} value={note.help_center_url} onChange={(e) => {note.help_center_url = e.target.value; forceRerender()}}></Input></ITD>
                                            <ITD style={{color: 'red'}}><div style={{cursor: 'pointer'}} onClick={() => {release_notes.splice(idx, 1); forceRerender()}}>Delete</div></ITD>
                                        </ITableRow>
                                    ))
                                    :''
                                }
                            </ITableBody>
                        </ITable>
                        <div>
                            <Button color='primary' onClick={() => {release_notes.push({heading: '', help_center_url: ''}); forceRerender()}}>Add Row</Button>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onclick_handle_close}>Discard</Button>
                    <Button color='primary' onClick={active_release_note && active_release_note.id ? updateReleaseNotefn : createReleaseNotefn}>Apply</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const ReleaseNotes = ({}) => {
    const [ is_release_notes_modal_open, set_is_release_notes_modal_open ] = useState(false);
    const [ release_notes, set_release_notes ] = useState([]);
    const [ re_render, set_re_render ] = useState(false);
    const [ all_releases, set_all_releases ] = useState([]);
    const [ active_release_note, set_active_release_note ] = useStateCallback({});

    const forceRerender = () => {set_re_render(!re_render)}
    
    const getLatestReleaseNotes = async() => {
        try{
            let releaseNotes = await getAllReleaseNotes();
            set_release_notes(releaseNotes);
            console.log("latest release notes => ", releaseNotes)
        }catch(err){
            console.error(`Error in getLatestReleaseNotes `, err)
            // handleError(err);
        }
    }
    
    const getLatestReleases = async() => {
        try{
            let releases = await getLatestVersions()
            let temp_all_releases = {};
            for(let i in releases){
                let release = releases[i]
                temp_all_releases[release.id] = release
            }
            console.log("all releases ", temp_all_releases)
            set_all_releases(temp_all_releases)
        }catch(err){
            console.error(`Error in getLatestReleases `, err)
            // handleError(err);
        }
    }

    const get_features_included = (feature_data) => {
        let features_included = ""
        if(feature_data && typeof(feature_data) === "string"){
            feature_data = JSON.parse(feature_data);
        }

        if(feature_data && feature_data.length){
            for(let i in feature_data){
                features_included += ((i==0 ? '' : ", ") + feature_data[i].heading)
            }
        }

        return features_included
    }

    const onChangeStatus = async(release_note, status) => {
        try{
            await updateReleaseNote(release_note.id, release_note.jira_version_id, JSON.stringify(release_note.feature_data), Boolean(release_note.highlight), status)
            release_note.status = status;
            forceRerender()
        }catch(err){
            console.error(`Error in onChangeStatus `, err)
            // handleError(err);
        }
    }

    const onDeleteReleaseNote = async(id, idx) => {
        try{
            await deleteReleaseNote(id);
            release_notes.splice(idx, 1);
            forceRerender()
        }catch(err){
            console.error(`Error in onDeleteReleaseNote `, err)
            // handleError(err);
        }
    }

    useEffect(() => {
        if(!is_release_notes_modal_open){
            set_active_release_note({})
        }
    }, [is_release_notes_modal_open]);

    useEffect(() => {
        (async() => {
            getLatestReleaseNotes()
            getLatestReleases()
        })()

    }, []);

    return(
        <>
            <AddOrUpdateReleaseNotes open={is_release_notes_modal_open} handle_close={() => set_is_release_notes_modal_open(false)} getLatestReleaseNotes={getLatestReleaseNotes} active_release_note={active_release_note}/>
            <div style={{height: '100vh', width: '100%', padding: '36px'}}>
                <div className="flex_property justify-content-between mb-3">
                    <h4 style={{margin: 0}}>Release Notes</h4>
                    <Button color='primary' onClick={() => set_is_release_notes_modal_open(true)}>Add New</Button>
                </div>
                {/* Already added release notes */}
                <ITable rowSeparated={true} coloumnSeparated={true} style_container={{maxHeight: '40vh', overflow: 'auto', border: '1px solid #e6e9ee', background: 'white'}}>
                    <ITableHeader>
                        <ITH>Release Date</ITH>
                        <ITH>Features Included</ITH>
                        <ITH>Status</ITH>
                        <ITH>Actions</ITH>
                    </ITableHeader>
                    <ITableBody style={{position: "sticky", top: '0px', background: 'white'}}>
                        {
                            release_notes && release_notes.length ? release_notes.map((release_note, idx) => (
                                <ITableRow key={idx}>
                                    <ITD>{all_releases[release_note.jira_version_id] && all_releases[release_note.jira_version_id].name}</ITD>
                                    <ITD>{get_features_included(release_note.feature_data)}</ITD>
                                    <ITD>
                                        <Input style={{border: 'none', padding: '0px', height: 'unset'}} value={release_note.status ? release_note.status : "draft"} type={"select"} onChange={(e) => onChangeStatus(release_note, e.target.value)}>
                                            <option value={"Draft"}>Save as Draft</option>
                                            <option value={"Live"}>Live</option>
                                        </Input>
                                    </ITD>
                                    <ITD>
                                        <div style={{display: 'flex', gap: '12px'}}>
                                            <div style={{cursor: 'pointer', color: '#0078ff'}} onClick={() => {set_active_release_note(release_note, () => set_is_release_notes_modal_open(true))}}>Edit</div>
                                            <div style={{cursor: 'pointer', color: 'red'}} onClick={() => {onDeleteReleaseNote(release_note.id, idx)}}>Delete</div>
                                        </div>
                                    </ITD>
                                </ITableRow>
                            ))
                            :''
                        }
                    </ITableBody>
                </ITable>
            </div>
        </>
    )
}

export default ReleaseNotes