import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';

// 3rd party components
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Stepper from 'react-stepper-horizontal';
import Moment from 'react-moment';

// core components
import Header from "../../components/Headers/Header.jsx";
import OrderDisplay from "../../components/OrderDisplay";

import {get_user_details} from '../../actions';
import {get_all_orders, get_investment_status, create_investment, update_investment, delete_investment} from './actions';

import './style.css';

class Home extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          orders: null,
      };
  }

  componentWillMount() {
      this.refresh_data();      
  }


  refresh_data = () => 
      this.props.get_all_orders()
      .then(orders => {
          console.log('orders => ', orders);
          this.setState({orders})
      })
      .catch(err => toastr.error('Something went wrong'))

  render() {
    const styles = {
      header_row: {
        marginLeft: 0,
        overflowX: "auto",
      },
      table_header: {
        fontWeight: "bold",
        fontSize: 'small'
      },
      round_status: {
        fontSize: 'small',
        paddingBottom: 0
      },
      nav_pills_rounds: {
        paddingRight: 60,
        marginRight: "0.7rem",
        marginLeft: "0.2rem",
      },
      action_button: {
        margin: 1       
      }
    }; 

    const rupee_symbol = <>&#8377; </>

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--8" fluid>
          {/* Table */}
          <OrderDisplay show_store_id={true} orders={this.state.orders} />
        </Container>
      </>
    );
  }
}


const mapStateToProps = (state, own_props) => ({
    user_details: state.user_details,
    ...own_props
})


const mapDispatchToProps = dispatch => ({
    get_all_orders: () =>  dispatch(get_all_orders()),
    get_user_details: () =>  dispatch(get_user_details()),
    get_investment_status: () =>  dispatch(get_investment_status()),
    create_investment: data =>  dispatch(create_investment(data)),
    update_investment: data =>  dispatch(update_investment(data)),
    delete_investment: data =>  dispatch(delete_investment(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);