import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';

// reactstrap components
import {Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
// core components
import Header from "../../components/Headers/Header.jsx";
import {get_user_details} from '../../actions';

class Meetings extends React.Component {
  
  constructor(props) {
      super(props);
  }

  componentDidMount() {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
  }

  render() {
    
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
            <div className="calendly-inline-widget" data-url="https://calendly.com/nikhil_infurnia/investment_meet" style={{"minWidth":"320px", "height": 630}}></div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    user_details: state.user_details
})

const mapDispatchToProps = dispatch => ({
    get_user_details: () => dispatch(get_user_details())
})

export default connect(mapStateToProps, mapDispatchToProps)(Meetings);