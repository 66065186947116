import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';

// reactstrap components
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Stepper from 'react-stepper-horizontal';
// core components
import Header from "../../components/Headers/Header.jsx";

import {get_user_details} from '../../actions';
import {get_investments, get_investment_status, create_investment, update_investment, delete_investment} from './actions';

import './style.css';

class Home extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          interest_amount: 10,
          commit_amount: 10,
          interested_total: 0,
          committed_total: 0,
          current_stage: 0,
          days_left_ssa: 15,
          active_round: 'angel_1'
      };
  }

  componentWillMount() {
      // this.refresh_data();      
  }


  refresh_data = () => 
      Promise.all([this.props.get_investments(), this.props.get_investment_status()])
      .then(([investments, investment_status]) => {
          var current_stage = 0;
          var  interested_total = 0;
          var committed_total = 0;
          var days_left_ssa = 15;
          if (investment_status) {
              if (investment_status.invested) {
                  current_stage = 4;
              } else if (investment_status.ssa) {
                  current_stage = 3;
              } else if (investment_status.committed) {
                  var now = new Date();
                  var last_update = new Date(investment_status.updated_at)
                  var days_left_ssa = 15 - Math.round(Math.abs((now.getTime() - last_update.getTime())/(24 * 60 * 60 * 1000)));
                  current_stage = 2;
              } else if (investment_status.interested) {
                  this.setState({commit_amount: investment_status.interested})
                  current_stage = 1;
              }
          }
          if (investments && investments.length) {
              investments.map(x => {
                  interested_total += x.interested;
                  committed_total += x.committed;
              })
          }
          this.setState({investments, investment_status, current_stage, interested_total, committed_total, days_left_ssa})
      })
      .catch(err => toastr.error('Something went wrong'))

  update_inputs = (event) => {
      console.log(this.state);
      this.setState({[event.target.name]: event.target.value})
  }


  update_amount = (input, action) => {
      if (input == 'interested') {
          var val = this.state.interest_amount;
          if (action == 'increment') {
              if (val < 140) {
                  val += 5;
              } 
          } else if (action == 'decrement') {
              if (val > 5) {
                  val -= 5;
              }
          }
          this.setState({interest_amount: val});
      }
  }


  create_investment = (event) => {
      event.preventDefault();
      console.log(this.state);
      this.props.create_investment({round: 'angel_1', interested: this.state.interest_amount})
      .then(resp => {
          console.log(resp)
          this.refresh_data();
          toastr.success('Investment Action Success')
      })
      .catch(err => toastr.error('Something went wrong'))
  }


  advance_investment = e => {
      e.preventDefault();
      if ((this.state.commit_amount + this.state.committed_total) <= 140) {
        if (this.state.current_stage == 1) {
            var data = {investment_id: this.state.investment_status.id, committed: this.state.commit_amount};
        }

        return this.props.update_investment(data)
        .then(resp => {
            console.log(resp)
            this.refresh_data();
            toastr.success('Investment Action Success')
        })
        .catch(err => toastr.error('Something went wrong'))
      } else {
        if (this.state.committed_total == 140) {
          toastr.warning('This round is fully committed. You will be notified before next round.')
        } else {
          toastr.error('Your commitment exceeds the limit. You can commit a maximum of Rs ' + (this.state.commit_amount - this.state.committed_total) + " Lakhs. Please revoke your interest to reconsider the amount.")
        }
      }
  }


  delete_investment = () => {
      return this.props.delete_investment({investment_id: this.state.investment_status.id})
      .then(resp => {
          console.log(resp)
          this.refresh_data();
          toastr.success('Investment Action Success')
      })
      .catch(err => toastr.error('Something went wrong'))   
  }


  recede_investment = () => {
      if (this.state.current_stage == 2) {
          var data = {investment_id: this.state.investment_status.id, committed: 0};
          return this.props.update_investment(data)
          .then(resp => {
              console.log(resp)
              this.refresh_data();
              toastr.success('Investment Action Success')
          })
          .catch(err => toastr.error('Something went wrong'))
      }
  }  


  update_round = active_round => {
      this.setState({active_round})
  }


  sort_investors = (b, a) => {
      if (a.invested > b.invested) {
          return 1;
      } else if (b.invested > a.invested) {
          return -1;
      } else if (a.ssa > b.ssa) {
          return 1;
      } else if (b.ssa > a.ssa) {
          return -1;
      } else if (a.committed > b.committed) {
          return 1;
      } else if (b.committed > a.committed) {
          return -1;
      } else if (a.interested > b.interested) {
          return 1;
      } else if (b.interested > a.interested) {
          return -1;
      } else {
          return 0;
      }
  }


  render() {
    const styles = {
      header_row: {
        marginLeft: 0,
        overflowX: "auto",
      },
      table_header: {
        fontWeight: "bold",
        fontSize: 'small'
      },
      round_status: {
        fontSize: 'small',
        paddingBottom: 0
      },
      nav_pills_rounds: {
        paddingRight: 60,
        marginRight: "0.7rem",
        marginLeft: "0.2rem",
      },
      action_button: {
        margin: 1       
      }
    }; 
    
    const interest_form = (
        <Form onSubmit={this.create_investment} role="form">
          <div className="text-center mt--2 mb-3">
            <h5>INVESTMENT AMOUNT</h5>
          </div>
          <Row className="text-center">
            <Col className="text-center" md={7}>
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <Button color="secondary" onClick={() => this.update_amount('interested', 'decrement')}><span className="fa fa-minus"></span></Button>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      &nbsp; &#8377;
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input readOnly id="interest_amount" className="text-center amount_input" placeholder="Amount" type="number" min="10" step="5" max="140" name="interest_amount" onChange={event => this.update_inputs(event)} value={this.state.interest_amount ? this.state.interest_amount : null} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      Lakhs &nbsp;
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="append">
                    <Button color="secondary" onClick={() => this.update_amount('interested', 'increment')}><span className="fa fa-plus"></span></Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={5}>
              <div className="text-center">
                <Button color="primary" style={styles.action_button} type="submit">
                  I'm Interested
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
    );


    const commit_form = (
        <Form onSubmit={this.advance_investment} role="form">
          <div className="text-center mt--3 mb-3">
            <h5>INVESTMENT AMOUNT</h5>
          </div>
          <Row className="text-center">
            <Col className="text-center" md={1} />
            <Col className="text-center" md={5}>
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      &nbsp; &#8377;
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input readOnly id="commit_amount" className="text-center amount_input" placeholder="Amount" type="number" min="10" step="5" max="140" name="commit_amount" onChange={event => this.update_inputs(event)} value={this.state.commit_amount ? this.state.commit_amount : null} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      Lakhs &nbsp;
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={5}>
              <div className="text-center">
                <Button color="primary" style={styles.action_button} type="submit">
                  Commit Investment
                </Button>
              </div>
            </Col>
            <Col className="text-center" md={1} />
          </Row>
          <div className="text-center mt-1 mb--4">
            <Button /*onClick={this.recede_investment} */className="btn-link btn-xs text-xs text-light pt-0" type="button" onClick={this.delete_investment}><b>Revoke Interest</b></Button>
          </div>
        </Form>
    );

    const ssa_form = (
        <div className="text-center text-primary text-lg mb--4 mt--1">
            <span className="fa fa-lg fa-check-circle"></span> Thanks for you commitment. <br />
            <span className="text-sm"><b>{this.state.days_left_ssa} days remain to sign SSA and confirm your investment.</b></span><br />
            <Button onClick={this.recede_investment} className="btn-link btn-xs text-xs text-light "><b>Revoke Commitment</b></Button>
        </div>
    )

    const success_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-success" />
        completed
      </Badge>
    );

    const warning_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-warning" />
        pending
      </Badge>
    );

    const rupee_symbol = <>&#8377; </>

    const investment_table = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>Investor</b></span><br /> &nbsp;</th>
            <th scope="col"><span style={styles.table_header}><b>Interested</b></span><br />(&#8377; {this.state.interested_total} Lakhs)</th>
            <th scope="col"><span style={styles.table_header}><b>Committed</b></span> <br />(&#8377; {this.state.committed_total} Lakhs / &#8377; 1.4 Crore)</th>
            <th scope="col"><span style={styles.table_header}><b>SSA</b></span><br />&nbsp;</th>
            <th scope="col"><span style={styles.table_header}><b>Invested</b></span><br />&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {this.state.investments && this.state.investments.sort(this.sort_investors).map(investment => (
            <tr>
              <th scope="row">
                <Media className="align-items-center">
                  <a className="avatar rounded-circle mr-3" onClick={e => e.preventDefault()} >
                    <img alt="..." src={require("../../assets/img/icons/avatar.png")} />
                  </a>
                  <Media>
                    <span className="mb-0 text-sm">
                      {investment.Investor.User.name}<br /><span style={{fontSize: "x-small"}}>{investment.Investor.designation}</span>
                    </span>
                  </Media>
                </Media>
              </th>
              <td> &#8377; {investment.interested} Lakhs</td>
              <td> {investment.committed ? rupee_symbol: ""}{investment.committed ? investment.committed + " Lakhs" : "N/A"}</td>
              <td> {investment.ssa ? success_badge : warning_badge}</td>
              <td> {investment.invested ? rupee_symbol: ""}{investment.invested ? investment.invested + " Lakhs" : "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )


    const investment_filler = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>This round is not active yet </b></span><br/> It will be live in {this.state.active_round == 'angel_3' ? "H1 2020" : "H2 2019"}<br /> &nbsp;</th>
          </tr>
        </thead>
      </Table>
    )

    const action_panels = [interest_form, commit_form, ssa_form]


    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--9" fluid>
            <Row>
                <div className="col-md-6 mt-1">
                    <Card className="shadow">
                        <CardBody className="border-0">
                            {action_panels[this.state.current_stage]}      
                        </CardBody>
                    </Card>
                </div>
                <div className="col-md-6 mt-1">
                    <Card className="shadow" style={{overflowX: "auto"}}>
                        <CardBody className="border-0" style={{minWidth: 450}}>
                            <Stepper size={16} circleFontSize={0} steps={ [{title: 'Start'}, {title: 'Interest'}, {title: 'Commitment'}, {title: 'SSA'}, {title: 'Investment'}] } activeStep={this.state.current_stage} />
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row style={styles.header_row}>
                      <Nav pills style={{minWidth: 700, paddingBottom: 5}}>
                        <NavItem>
                          <NavLink style={styles.nav_pills_rounds} href="#" active={this.state.active_round == 'angel_1'} onClick={() => this.update_round('angel_1')} ><b>Angel Series A</b> <br /> <span style={styles.round_status}> $200k @ $5M</span> <br /> <Badge color="" className="badge-dot mr-4" style={styles.round_status}><i className="bg-success" />Ongoing</Badge></NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={styles.nav_pills_rounds} href="#" active={this.state.active_round == 'angel_2'} onClick={() => this.update_round('angel_2')}><b>Angel Series B </b> <br /> <span style={styles.round_status}> $200k @ $7M</span> <br /> <Badge color="" className="badge-dot mr-4" style={styles.round_status}><i className="bg-warning" />H2 2019</Badge></NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={styles.nav_pills_rounds} href="#" active={this.state.active_round == 'angel_3'} onClick={() => this.update_round('angel_3')}><b>Angel Series C </b> <br /> <span style={styles.round_status}> $200k @ $10M</span> <br /> <Badge color="" className="badge-dot mr-4" style={styles.round_status}><i className="bg-warning" />H1 2020</Badge></NavLink>
                        </NavItem>
                      </Nav>
                  </Row>
                </CardHeader>
                {this.state.active_round == 'angel_1' ? investment_table : investment_filler}           
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}


const mapStateToProps = (state, own_props) => ({
    user_details: state.user_details,
    ...own_props
})


const mapDispatchToProps = dispatch => ({
    get_investments: () =>  dispatch(get_investments()),
    get_user_details: () =>  dispatch(get_user_details()),
    get_investment_status: () =>  dispatch(get_investment_status()),
    create_investment: data =>  dispatch(create_investment(data)),
    update_investment: data =>  dispatch(update_investment(data)),
    delete_investment: data =>  dispatch(delete_investment(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);