import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import { CspModal, CspModalBody, CspModalFooter, CspModalHeader } from "../CspModal";
import { colourStyles4 } from "../../utils/react_select_styles";
import dayjs from "dayjs";
import { convert_to_readable } from "../../utils/convert_to_readable";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import general_fetch from "../../utils/fetch";
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const activity_types = ['call', 'meeting', 'task', 'deadline', 'email', 'onboarding']
const activity_type_icons = {'call':'phone', 'meeting':'users', 'task':'clock', 'deadline':'flag', 'email':'envelope', 'onboarding':'handshake'}

function calculateDuration(startTime, endTime) {
	const start = new Date(startTime);
	const end = new Date(endTime);
	const durationInMillis = end - start;
  
	// Convert duration to hours, minutes, and seconds
	const hours = Math.floor(durationInMillis / 3600000);
	const minutes = Math.floor((durationInMillis % 3600000) / 60000);
	const seconds = Math.floor((durationInMillis % 60000) / 1000);
  
	return { hours, minutes, seconds };
}

function addDurationToDate(baseDate, duration) {
	const resultDate = new Date(baseDate);
	resultDate.setHours(resultDate.getHours() + duration.hours);
	resultDate.setMinutes(resultDate.getMinutes() + duration.minutes);
	resultDate.setSeconds(resultDate.getSeconds() + duration.seconds);
	console.log('addDurationToDate', new Date(baseDate), duration, resultDate, dayjs(resultDate))
	return resultDate;
}
  

const AddEditActivity = ({open, toggle, mode, active_activity, cs_org_users, user_details, get_activities, all_orgs, set_page_loader, current_org_users, current_org, id_from_all_activities_page, open_create_activity_modal, cs_executive_id_of_org}) => {

	const [subject, set_subject] = useState();
	const [type, set_type] = useState('call');
	const [due_date, set_due_date] = useState(new Date().setHours(10,0));
	// console.log('set_due_date',dayjs(due_date))
	const [to_date, set_to_date] = useState(new Date().setHours(10,30,0,0));
	const [existing_duration, set_existing_duration] = useState({hours:0, minutes:0, seconds:0});
	const [contact_person, set_contact_person] = useState();
	const [email, set_email] = useState();
	const [phone, set_phone] = useState();
	const [org_id, set_org_id] = useState();
	// const [status, set_status] = useState();
	const [assignee_id, set_assignee_id] = useState();
	const [meeting_url, set_meeting_url] = useState();
	const [note, set_note] = useState();
	const [activity_id, set_activity_id] = useState();
	const [schedule_gmeet, set_schedule_gmeet] = useState(false);
	const [selected_guests_array, set_selected_guests_array] = useState([]);
	const [org_users, set_org_users] = useState([]);

	const onclick_handleclose = () => {
		toggle()
		set_activity_id('')
		set_subject('')
		set_type('call')
		set_due_date(new Date().setHours(10,0,0,0))
		set_to_date(new Date().setHours(10,30,0,0))
		set_existing_duration({hours:0, minutes:0, seconds:0})
		set_assignee_id(user_details.id)
		set_contact_person('')
		set_email('')
		set_phone('')
		set_org_id('')
		// set_status()
		set_meeting_url('')
		set_schedule_gmeet(false)
		set_note('')
		set_selected_guests_array([])
		set_org_users([])
	}

	const check_changed = (state_var, changed_something, payload) => {
		if(eval(state_var) != active_activity[state_var]){
			changed_something.x = true
			payload[state_var] = eval(state_var)
		}
	}

	const fetch_org_users = async(id) => {
		try{
			if(id){
				let resp = await general_fetch({url:'csp/get_org_info', body:{customer_store_id:id}})
				set_org_users(resp.all_users)
			}
		}catch(err){
			console.error(err)
		}
	}

	const submit_fn = async({new_status}) => {
		try {
			let payload = {}
			if(mode === 'add'){
				payload = {
					owner_id: user_details.id,
					assignee_id: assignee_id,
					type,
					subject,
					contact_person,
					email,
					phone,
					org_id,
					due_date: new Date(due_date).toISOString(),
					to_date: new Date(to_date).toISOString(),
					meeting_url,
					note,
					schedule_gmeet,
					attendees: selected_guests_array && selected_guests_array.length ? selected_guests_array.map(x => x.value) : []
				}
			}
			if(mode === 'edit'){
				let changed_something = {x : false}
				payload['activity_id']= activity_id
				check_changed('assignee_id', changed_something, payload)
				check_changed('type', changed_something, payload)
				check_changed('subject', changed_something, payload)
				check_changed('contact_person', changed_something, payload)
				check_changed('email', changed_something, payload)
				check_changed('phone', changed_something, payload)
				check_changed('org_id', changed_something, payload)
				check_changed('due_date', changed_something, payload)
				check_changed('to_date', changed_something, payload)
				// check_changed('status', changed_something, payload)
				check_changed('meeting_url', changed_something, payload)
				check_changed('note', changed_something, payload)
				if(schedule_gmeet && !active_activity.meeting_url){
					payload['schedule_gmeet'] = true
					changed_something.x = true
				}
				let prev_attendees_string = active_activity.attendees && active_activity.attendees.length ? active_activity.attendees.sort().join(',') : ''
				let selected_guests_string = selected_guests_array && selected_guests_array.length ? selected_guests_array.map(x => x.value).sort().join(',') : ''
				if(prev_attendees_string != selected_guests_string){
					payload['attendees'] = selected_guests_array && selected_guests_array.length ? selected_guests_array.map(x => x.value) : []
					changed_something.x = true
				}
				if(!changed_something.x && new_status == undefined) return
				payload['status'] = new_status
			}

			if(mode==='add'){
				if(!assignee_id){
					toastr.error('Please Assign the activity to a user')
					return
				}

				if(!subject){
					payload['subject'] = type
				}
			}

			if(!org_id){
				toastr.error('Please select an Org')
				return
			}
			if(schedule_gmeet){
				payload['assignee_email'] = cs_org_users.find(o => o.id==assignee_id).email
				payload['attendees'] = selected_guests_array && selected_guests_array.length ? selected_guests_array.map(x => x.value) : []
			}

			if(mode === 'edit'){
				payload['cs_executive_id_of_org'] = cs_executive_id_of_org
			}

			const apiUrl = 'https://stagebackend5.infurnia.com/' + (mode==='add' ? 'activity/create' : 'activity/update'); 

			set_page_loader(true, 'Please wait...')
			// fetch(apiUrl, {
			// method: 'POST', 
			// headers: {
			// 	'Content-Type': 'application/json',
			// },
			// body: JSON.stringify(payload),
			// })
			// .then(response => {
			// 	if (!response.ok) {
			// 	throw new Error(`HTTP error! Status: ${response.status}`);
			// 	}
			// 	return response.json();
			// })
			// .then(data => {
			// 	onclick_handleclose()
			// 	get_activities()
			// })
			// .catch(error => {
			// 	console.error('Error:', error);
			// });
			let resp = await general_fetch({url:(mode==='add' ? 'cs_backend/activity/create' : 'cs_backend/activity/update'), body:payload})
			set_page_loader(false)
			if(resp.error){
				toastr.error(resp.error.message)
				return
			}
			onclick_handleclose()
			get_activities()
			if(new_status === 'done'){
				open_create_activity_modal(org_id)
			}
		}catch (e) {
			set_page_loader(false)
			console.error(e)
		}
	}

	const set_two_hr_slot = (given_date) => {
		const date = new Date(given_date)
		date.setHours(date.getHours() + 2)
		set_to_date(date)		
		console.log('due date updated', new Date(given_date), new Date(date))
		set_existing_duration({hours:2, minutes:0, seconds:0})
	}

	const set_30_minute_slot = (given_date) => {
		const date = new Date(given_date)
		date.setMinutes(date.getMinutes() + 30)
		set_to_date(date)		
		set_existing_duration({hours:0, minutes:30, seconds:0})
	}

	const set_10_minute_slot = (given_date) => {
		const date = new Date(given_date)
		date.setMinutes(date.getMinutes() + 10)
		set_to_date(date)		
		set_existing_duration({hours:0, minutes:10, seconds:0})
	}

	useEffect(() => {
		if(open && active_activity){
			set_activity_id(active_activity.activity_id)
			set_subject(active_activity.subject)
			set_type(active_activity.type)
			set_due_date(active_activity.due_date)
			set_to_date(active_activity.to_date)
			set_assignee_id(active_activity.assignee_id)
			set_contact_person(active_activity.contact_person)
			set_email(active_activity.email)
			set_phone(active_activity.phone)
			set_org_id(active_activity.org_id)
			// set_status(active_activity.status)
			set_meeting_url(active_activity.meeting_url)
			if(active_activity.meeting_url && (active_activity.type==='meeting' || active_activity.type==='onboarding')) set_schedule_gmeet(true)
			set_note(active_activity.note)
			set_existing_duration(calculateDuration(active_activity.due_date, active_activity.to_date))
		}
	}, [open, active_activity,]);

	useEffect(() => {
		if(open && active_activity&& org_users && org_users.length){
			if(active_activity.attendees && active_activity.attendees.length){
				set_selected_guests_array(active_activity.attendees.map(x => {
					let found_user = org_users.find(o => o.email === x)
					let found_name = found_user ? found_user.first_name : ''
					return {value:x, label: found_name ? found_name : x}
				}))
			}
		}
	}, [open, active_activity, org_users]);

	useEffect(() => {
		console.log('current org', current_org)
		if(open && current_org) set_org_id(current_org.id)
	}, [open, current_org]);

	useEffect(() => {
		if(user_details && user_details.id) set_assignee_id(user_details.id);
	}, [user_details]);

	useEffect(() => {
		console.log('all_userssss', org_users)
	}, [org_users]);

	useEffect(() => {
		if(current_org_users && current_org_users.length > 0) set_org_users(current_org_users)
	}, [current_org_users]);

	useEffect(() => {
		if(id_from_all_activities_page) set_org_id(id_from_all_activities_page)
	}, [id_from_all_activities_page]);

	useEffect(() => {
		if(org_id && !current_org){
			fetch_org_users(org_id)
		}
	}, [org_id, current_org]);

	useEffect(() => {
		if(mode === 'add'){
			if(type === 'onboarding'){
				set_two_hr_slot(due_date)
			}else if(type === 'call'){
				set_10_minute_slot(due_date)
			}else{
				set_30_minute_slot(due_date)
			}
		}
	}, [type, mode]);
	
	return(
		<CspModal open={open} toggle={onclick_handleclose} >
			<CspModalHeader title={mode==='add' ? 'Create Activity' : 'Update Activity'} toggle={onclick_handleclose}/>
			<CspModalBody style={{maxHeight:'600px'}}>
				<label className="form-control-label" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0}}>
					<div className='mb-2'>Subject</div>
					<Input type='text' style={{height:'36px'}} className='px-2 py-1' placeholder={convert_to_readable(type)} value={subject} onChange={(e) => set_subject(e.target.value)}/>
				</label>
				<div className="flex_property">
					<div className="flex_property mt-1 cp" style={{border:'1px solid gray', borderRadius:'4px'}}>
					{
						activity_types.map((single_type, idx) => (
							<Tooltip title={single_type} placement="top">
								<div onClick={() => set_type(single_type)} className={`activity_type ${type===single_type ? 'active' : ''}`}><i className={`fa fa-${activity_type_icons[single_type]}`} /></div>
							</Tooltip>
						))
					}
					</div>
				</div>
				<div className="flex_property mt-4" style={{zIndex:3, position:'relative'}}>
					<div className="flex_property">
						<label className="form-control-label mr-4" htmlFor="input-address" style={{marginBottom:0}}>
							<div className='mb-2'>Due Date</div>
							<div className="flex_center">
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										// orientation="landscape"
										format="DD/MM/YYYY hh:mm A"
										value={dayjs(due_date)}
										onChange={(newValue) => {set_due_date(newValue); type==='onboarding' ? set_two_hr_slot(newValue) : (mode==='add' ? (type === 'call' ? set_10_minute_slot(newValue) : set_30_minute_slot(newValue)) : set_to_date(dayjs(addDurationToDate(newValue, existing_duration))))}}
									/>
								</LocalizationProvider>
								{/* {'\xa0\xa0\xa0'+'-'+'\xa0\xa0\xa0'}
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										// orientation="landscape"
										format="DD/MM/YYYY hh:mm A"
										value={dayjs(to_date)}
										onChange={(newValue) => set_to_date(newValue)}
									/>
								</LocalizationProvider> */}
							</div>
						</label>
						<label className="form-control-label mr-3" htmlFor="input-address" style={{marginBottom:0}}>
							<div className='mb-2'>Duration</div>
							<div className="flex_center">
								<Input value={existing_duration.hours} onChange={(e) => {let new_existing_duration={hours:Number(e.target.value), minutes:existing_duration.minutes, seconds:existing_duration.seconds}; set_existing_duration(new_existing_duration); set_to_date(dayjs(addDurationToDate(due_date, new_existing_duration)))}} type='number' className='standard_input mr-1' style={{width:'64px'}} />
								<div className="mr-2">hr</div>
								<Input value={existing_duration.minutes} onChange={(e) => {let new_existing_duration = {hours:existing_duration.hours, minutes:Number(e.target.value), seconds:existing_duration.seconds}; set_existing_duration(new_existing_duration); set_to_date(dayjs(addDurationToDate(due_date, new_existing_duration)))}} type='number' className='standard_input mr-1' style={{width:'64px'}} />
								<div>min</div>
							</div>
						</label>
					</div>
					{/* <label className="form-control-label" htmlFor="input-address" style={{ marginBottom:0}}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateTimePicker
								// orientation="landscape"
								value={dayjs(to_date)}
								onChange={(newValue) => set_to_date(newValue)}
							/>
						</LocalizationProvider>
					</label> */}
				</div>
				{
					meeting_url && (type==='meeting' || type==='onboarding') ? 
					<label className="form-control-label mt-2" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0}}>
						<div className='mb-2'>Google Meet Url</div>
						<Input readOnly type='text' style={{height:'36px'}} className='px-2 py-1' value={meeting_url} />
					</label>
					:(
						type==='meeting' || type==='onboarding'?
						<div className="flex_property mb-2 mt-3 cp" onClick={() => set_schedule_gmeet(!schedule_gmeet)}>
							<input type="radio" checked = {schedule_gmeet} />
							<div className="ml-2" style={{color:'#ff4400', fontSize:'16px'}}>Link this Activity to Google Calendar</div>
						</div>:''
					)
				}
				<label className="form-control-label mt-2" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0, zIndex:2, position:'relative'}}>
					<div className='mb-2'>Org</div>
					{
						current_org ?
						<Input type='text' style={{height:'36px'}} className='px-2 py-1' value={current_org.name} readOnly/>:
						<Select 
						// closeMenuOnSelect={false}
						// components={animatedComponents}
						value={{value:org_id, label:all_orgs && all_orgs.length && all_orgs.find(o => o.id === org_id) ? all_orgs.find(o => o.id === org_id).name : ''}}
						// isMulti
						options={all_orgs && all_orgs.length ? all_orgs.map(x => {return {value:x.id, label:(x.name + ' (' + x.id + ')')}}) : []}
						onChange={(selectedOptions) => set_org_id(selectedOptions.value)}
					/>
					}
					
				</label>
				<label className="form-control-label mt-2" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0, zIndex:1, position:'relative'}}>
					<div className='mb-2'>Guests </div>
					{/* <Input type='text' style={{height:'36px'}} className='px-2 py-1' value={selected_guests} onChange={(e) => set_selected_guests(e.target.value)}/> */}
					<CreatableSelect 
						// closeMenuOnSelect={false}
						components={animatedComponents}
						placeholder={org_users && org_users.length ? 'Select' : 'Please select an Org first'}
						styles={colourStyles4}
						value={selected_guests_array}
						isMulti
						options={org_users && org_users.length ? org_users.map(x => {return {value:x.email, label:`${x.first_name} (${x.email})`}}) : []}
						onChange={(selectedOptions) => set_selected_guests_array(selectedOptions && selectedOptions.length ? selectedOptions.map(x => {return {value: x.value, label:x.label.split(' ')[0] ? x.label.split(' ')[0] : x.label.split(' ')[1]}}) : [])}
					/>
				</label>
				{/* <label className="form-control-label mt-2" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0}}>
					<div className='mb-2'>Status</div>
					<Input type='text' style={{height:'36px'}} className='px-2 py-1' value={status} onChange={(e) => set_status(e.target.value)}/>
				</label> */}
				<label className="form-control-label mt-2" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0}}>
					<div className='mb-2'>Note</div>
					<textarea style={{height:'100px', width:'100%', backgroundColor:'#faf1e4', borderRadius:'6px', border:'1px solid #cad1d7'}} className='px-2 py-1' value={note} onChange={(e) => set_note(e.target.value)} placeholder='Type...'/>
				</label>
				<label className="form-control-label mt-2" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0, zIndex:0, position:'relative'}}>
					<div className='mb-2'>Assigned to</div>
					<Select 
						// closeMenuOnSelect={false}
						// components={animatedComponents}
						value={{value:assignee_id, label:cs_org_users && cs_org_users.length && assignee_id ? cs_org_users.find(o => o.id === assignee_id).first_name : ''}}
						// isMulti
						options={cs_org_users && cs_org_users.length ? cs_org_users.map(o => {return {value:o.id, label:o.first_name}}):[]}
						styles={colourStyles4}
						onChange={(selectedOptions) => set_assignee_id(selectedOptions.value)}
					/>
				</label>
				<label className="form-control-label mt-2" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0}}>
					<div className='mb-2'>Contact Person</div>
					<Input type='text' style={{height:'36px'}} className='px-2 py-1' value={contact_person} onChange={(e) => set_contact_person(e.target.value)}/>
				</label>
				<label className="form-control-label mt-2" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0}}>
					<div className='mb-2'>Email</div>
					<Input type='text' style={{height:'36px'}} className='px-2 py-1' value={email} onChange={(e) => set_email(e.target.value)}/>
				</label>
				<label className="form-control-label mt-2" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0}}>
					<div className='mb-2'>Phone</div>
					<Input type='text' style={{height:'36px'}} className='px-2 py-1' value={phone} onChange={(e) => set_phone(e.target.value)}/>
				</label>
			</CspModalBody>
			<CspModalFooter>
				{mode==='edit' && active_activity ? <Button color="primary" onClick={() => {submit_fn({new_status:active_activity.status==='done'?'pending':'done'})}}>{active_activity.status==='done' ? 'Update & Mark as not done' : 'Update & Mark as done'}</Button> : ''}
				<Button color="primary" onClick={submit_fn}>{mode==='add' ? 'Create' : 'Update'}</Button>
			</CspModalFooter>
		</CspModal>
	)
}

export default AddEditActivity