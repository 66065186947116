import Header from "../../components/Headers/Header";
import React, { useEffect, useState } from "react";
import {toastr} from 'react-redux-toastr';
import { Button, Card, CardHeader, Col, Container, FormGroup, Input, Modal, Row, Table } from "reactstrap";
import Select from "react-select";
import general_fetch from "../../utils/fetch";


const static_add_ons = {
    '1': 'Manufacturing',
    '2': 'API Integration',
    '3': 'White Labelling',
    '4': 'Panel Cut-List',
	'5': 'Advanced Pricing',
	'6': 'MES Lite',
	'7': 'MES Pro'
};


const styles = {
	header_row: {
		marginLeft: 0,
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	round_status: {
		fontSize: 'small',
		paddingBottom: 0
	},
	nav_pills_rounds: {
		paddingRight: 60,
		marginRight: "0.7rem",
		marginLeft: "0.2rem",
	},
	action_button: {
		margin: 1       
	}
};

const ChangeCSE = ({open, toggle, fetch_cs_assignments, active_store, assign_cs, cs_execs}) => {

	const [selected_user, set_selected_user] = useState();

	const onClick_handleClose = () => {
		set_selected_user()
		toggle()
	}

	useEffect(() => {
		if(active_store && open){
			set_selected_user({value: active_store.tentative_customer_success_executive.customer_success_executive.user_id, label: cs_execs && cs_execs.length && cs_execs.find(o => o.user_id === active_store.tentative_customer_success_executive.customer_success_executive.user_id).first_name})
		}
	}, [active_store, open]);

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>Change CS Rep</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
				<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
				<Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-address" >
							Select Users
                        </label>
                        {/* <Input name="credits" className="form-control-alternative" type="select" placeholder='Type...' value={selected_user} onChange={(e) => set_selected_user(e.target.value)} /> */}
						<Select
                            // closeMenuOnSelect={false}
                            // components={animatedComponents}
                            value = {selected_user}
                            // isMulti
                            options={cs_execs && cs_execs.length && cs_execs.map(c => {return {'value': c.user_id, 'label': c.first_name}})}
                            // styles={colourStyles}
                            onChange={(selectedOptions) => set_selected_user(selectedOptions)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
			</div>
			<div className="modal-footer" style={{height:'60px'}}>
				<Button color='primary' disabled={(selected_user && selected_user.value ? false : true)} onClick={() => {assign_cs(active_store.id, selected_user.value); onClick_handleClose()}}>Submit</Button>
			</div>
		</Modal>
	)
	
}

const CsAssignments = ({cs_org_users, set_page_loader}) => {

	const [cs_assignments, set_cs_assignments] = useState([]);
	const [change_cse_modal, set_change_cse_modal] = useState(false);
	const [active_store, set_active_store] = useState();
	const [cs_execs, set_cs_execs] = useState();
	const [geographies, set_geographies] = useState([]);

	const toggle_change_cse_modal = () => set_change_cse_modal(!change_cse_modal)

	const fetch_cs_execs = async () => {
		try {
			let account_details = cs_org_users
			let resp = await general_fetch({url:'customer_success_executive/get'})
			
			if(resp && resp.length){
				for(let i=0;i<resp.length;i++){
					let user = account_details.find(o => o.id === resp[i].user_id)
					resp[i].first_name = user ? user.first_name : ''
					resp[i].email = user? user.email : ''
				}
				set_cs_execs(resp)
			}
		} catch (err) {
			console.error(err)
		}
	}

	const fetch_geographies = async() => {
		try{
            var resp = await general_fetch({url: 'geography/get'});
			set_geographies(resp)
		}catch(err){
			
		}
	}

	const fetch_cs_assignments = async() => {
		try{	
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({url:'customer_success_executive/get_tentative_assignments'})
			set_page_loader(false)
			set_cs_assignments(resp)
		}catch(e){
			set_page_loader(false)
			console.error(e)
		}
	}

	const assign_cs = async(customer_store_id, cs_user_id) => {
		try{
			let resp = await general_fetch({url:'customer_success_executive/assign', body:{customer_store_id, cs_user_id}})
			toastr.success('Assignment successfull')
			fetch_cs_assignments()
		}catch(e){
			console.error(e)
			toastr.error('Assignment failed')
		}
	}

	useEffect(() => {
		if(cs_org_users && cs_org_users.length){ 
			fetch_cs_assignments()
			fetch_cs_execs()
			fetch_geographies()
		}
	}, [cs_org_users]);
	

	return(
		<>
			<ChangeCSE open={change_cse_modal} toggle={toggle_change_cse_modal} assign_cs={assign_cs} fetch_cs_assignments={fetch_cs_assignments} active_store={active_store} cs_execs={cs_execs}/>
			<Container fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-4 bg-gradient-secondary">

							</CardHeader>
							<div style={{minHeight:'200px'}}>
								<Table className="align-items-center table-flush text-center" responsive>
								<	thead className="thead-light text-center">
										<tr style={{ textAlign: 'left' }}>
											<th scope="col"><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Store Name</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Store ID</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>CS Exec</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Add Ons</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Domain</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Languages</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Geography</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Assignment Criteria</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Ignored Filters</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b></b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b></b></span>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
									{
										cs_org_users && cs_org_users.length && cs_assignments && cs_assignments.length ? cs_assignments.map((single_store, idx) => (
											<tr style={{ textAlign: 'left' }}>
												<td scope="col"><span>{idx + 1}</span>&nbsp;</td>
												<td scope="col"><span>{single_store.name}</span>&nbsp;</td>
												<td scope="col"><span>{single_store.id}</span>&nbsp;</td>
												<td scope="col"><span>{single_store.tentative_customer_success_executive && cs_org_users.find(o => o.id == (single_store.tentative_customer_success_executive.customer_success_executive && single_store.tentative_customer_success_executive.customer_success_executive.user_id)) ? cs_org_users.find(o => o.id == (single_store.tentative_customer_success_executive.customer_success_executive && single_store.tentative_customer_success_executive.customer_success_executive.user_id)).first_name : ''}</span>&nbsp;</td>
												<td scope="col"><span>{single_store.tentative_customer_success_executive ? (single_store.tentative_customer_success_executive.filtration_criteria.add_ons && single_store.tentative_customer_success_executive.filtration_criteria.add_ons.length ? single_store.tentative_customer_success_executive.filtration_criteria.add_ons.map(o => static_add_ons[o]).join(', ') : '') : ''}</span>&nbsp;</td>
												<td scope="col"><span>{single_store.tentative_customer_success_executive ? (single_store.tentative_customer_success_executive.filtration_criteria.domain ? single_store.tentative_customer_success_executive.filtration_criteria.domain : '') : ''}</span>&nbsp;</td>
												<td scope="col"><span>{single_store.tentative_customer_success_executive ? (single_store.tentative_customer_success_executive.filtration_criteria.languages && single_store.tentative_customer_success_executive.filtration_criteria.languages.length ? single_store.tentative_customer_success_executive.filtration_criteria.languages.join(', ') : '') : ''}</span>&nbsp;</td>
												<td scope="col"><span>{single_store.tentative_customer_success_executive ? (single_store.tentative_customer_success_executive.filtration_criteria.geography ? geographies.find(o => o.id === single_store.tentative_customer_success_executive.filtration_criteria.geography).name : '') : ''}</span>&nbsp;</td>
												<td scope="col"><span>{single_store.tentative_customer_success_executive ? single_store.tentative_customer_success_executive.selection_criteria : ''}</span>&nbsp;</td>
												<td scope="col"><span>{single_store.tentative_customer_success_executive ? single_store.tentative_customer_success_executive.excluded_filters.filter(x => x).reduce((acc, curr) => acc + ', ' + curr, '') : ''}</span>&nbsp;</td>
												<td scope="col">
													<Button onClick={() => assign_cs(single_store.id, single_store.tentative_customer_success_executive.customer_success_executive.user_id)} color='primary' className='p-2' style={{fontSize:'12px'}}>Validate</Button>
												</td>
												<td scope="col">
													<Button onClick={() => {toggle_change_cse_modal(); set_active_store(single_store)}} color='primary' className='p-2' style={{fontSize:'12px'}}>Change CSE</Button>
												</td>
											</tr>
										)):''
									}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	)
}

export default CsAssignments