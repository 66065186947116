export const get_investment_status = () => dispatch => 
	fetch(global.config.server_path + 'investment/get_status', {method: 'POST', credentials: 'include'})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const get_usage = () => dispatch => 
	fetch(global.config.server_path + 'license/get_usage_all', {method: 'POST', credentials: 'include'})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const get_store_account_details = customer_store_id => 
	fetch(global.config.server_path + 'store/get_account_details', {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify({customer_store_id})})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const assign_free_credits = ({ customer_store_id, credits, comment, account_type }) => 
	fetch(global.config.server_path + 'store/assign_free_credits', {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify({ customer_store_id, credits, comment, account_type })})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const get_store_orders = customer_store_id => 
	fetch(global.config.server_path + 'store/get_orders', {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify({customer_store_id})})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const get_all_licenses = customer_store_id => dispatch => 
	fetch(global.config.server_path + 'license_plan/get_for_store', {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify({customer_store_id})})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const update_investment = data => dispatch => 
	fetch(global.config.server_path + 'investment/update', {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify(data)})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const delete_investment = data => dispatch => 
	fetch(global.config.server_path + 'investment/delete', {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify(data)})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))