import React from "react";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {Route, Switch, Redirect} from "react-router-dom";
import {toastr} from 'react-redux-toastr'
import FindOrgsByEmail from "../FindOrgsByEmail/index.jsx";
// reactstrap components
import {DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Navbar, Nav, Container, Media} from "reactstrap";

import {get_user_details, logout, update_search_text} from '../../actions.js';

class AdminNavbar extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          redirect: false,
          redirect_url: null,
          search_text: '',
		  show_find_by_email_modal: false
      }
  }

  toggle_find_by_email_modal = () => {this.setState({show_find_by_email_modal: !this.state.show_find_by_email_modal})}

  logout = () => {
      this.props.logout()
      .then(() => {
          window.location.href = global.config.auth_redirect_path
      })
      .catch(err => {
          toastr.error('Logout Failed');
      })
  }

  update_search_text = () => {
      console.log(this.state.search_text);
      this.props.update_search_text(this.state.search_text);
  }

  clear_search_text = () => {
      this.setState({search_text: ''});
      this.props.update_search_text('');
  }

  render() {

    var {redirect, redirect_url} = this.state;
    if (redirect) {
      return (<Redirect to={redirect_url} />)
    }

    return (
      <>
	  	<FindOrgsByEmail 
			open = {this.state.show_find_by_email_modal}
			toggle={this.toggle_find_by_email_modal}
			action_name={'Manage'}
			action_function={(single_org) => {window.location.href = ("/home/store_dashboard/" + single_org.id + '/7')}}
		/>
        <Navbar className="navbar-top navbar-dark px-4" expand="md" id="navbar-main">
          <Container fluid>
            <div
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            
            >
              {this.props.brandText != 'Brand' ? this.props.brandText : "Manage Org"}
            </div>
			<div className="flex_center ml-lg-auto">
				{/* {
					window.location.pathname === '/home/paid_orgs' ? 
					<Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex" onSubmit={e => e.preventDefault()}>
					<FormGroup className="mb-0" >
						<InputGroup className="input-group-alternative">
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
							<i className="fas fa-search" />
							</InputGroupText>
						</InputGroupAddon>
						<Input placeholder="Search by Org Name / ID" value={this.state.search_text} onChange={e => this.setState({search_text: e.target.value})} onKeyPress={e => (e.key == 'Enter' && this.update_search_text())} type="text" />
						<InputGroupAddon addonType="append" onClick={() => this.clear_search_text()}>
							<InputGroupText>
							<i className="fas fa-times" />
							</InputGroupText>
						</InputGroupAddon>
						</InputGroup>
					</FormGroup>
					</Form>:''
				} */}
				<div className=" navbar-search navbar-search-dark find_orgs_by_email d-none d-md-flex " onClick={() => this.toggle_find_by_email_modal()}>
					Find Orgs by email
				</div>
			</div>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("../../assets/img/icons/avatar.png")}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.user_details ? this.props.user_details.name : null}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/home/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to="/home/meetings" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Meetings</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem style={{cursor:'pointer'}} onClick={this.logout}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = function(state) {
    return {
        user_details: state.user_details
    };
}

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    update_search_text: text => dispatch(update_search_text(text))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);