export const get_user_details = () => dispatch => 
	fetch(global.config.server_path + 'user/get_details', {method: 'POST', headers: {'Content-Type':'application/json'}, 'credentials': 'include'})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => dispatch(user_details_fetched(resp.user.user_details)))
	.catch(err => dispatch(user_details_fetched(null)))


export const get_investor_details = () => dispatch => 
	fetch(global.config.server_path + 'investment/get_investor_details', {method: 'POST', headers: {'Content-Type':'application/json'}, 'credentials': 'include'})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => ((resp && resp.data) ? Promise.resolve(resp) : Promise.reject()))
	.then(resp => dispatch(investor_details_fetched(resp.data)))
	.catch(err => dispatch(investor_details_fetched(null)))


export const get_all_license_plans = () => dispatch => 
	fetch(global.config.server_path + 'license_plan/get_all', {method: 'POST', headers: {'Content-Type':'application/json'}, 'credentials': 'include'})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => ((resp && resp.data) ? Promise.resolve(resp) : Promise.reject()))
	.then(resp => dispatch(set_license_plans(resp.data)))
	.catch(err => dispatch(set_license_plans(null)))


export const get_all_stores = () => dispatch => {
	dispatch(fetching_store_data())
	return fetch(global.config.server_path + 'csp/get_all_of_business_type', {method: 'POST', credentials: 'include'})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => dispatch(set_store_data(resp.data)))
	.catch(err => Promise.reject(err))
} 


export const get_usage = () => dispatch => {
	dispatch(fetching_usage_data())	
	return fetch(global.config.server_path + 'license/get_usage_all', {method: 'POST', credentials: 'include'})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => dispatch(set_usage_data(resp.data)))
	.catch(err => Promise.reject(err))
}


export const logout = () => dispatch => 
	fetch(global.config.server_path + 'user/logout', {method: 'POST', headers: {'Content-Type':'application/json'}, 'credentials': 'include'})


export const user_details_fetched = payload => ({type: 'USER_DETAILS_FETCHED', payload})


export const investor_details_fetched = payload => ({type: 'INVESTOR_DETAILS_FETCHED', payload})


export const set_license_plans = payload => ({type: 'LICENSE_PLANS_FETCHED', payload})


export const fetching_store_data = () => ({type: 'STORE_DATA_FETCHING'})


export const set_store_data = payload => ({type: 'STORE_DATA_FETCHED', payload})


export const fetching_usage_data = () => ({type: 'USAGE_DATA_FETCHING'})


export const set_usage_data = payload => ({type: 'USAGE_DATA_FETCHED', payload})


export const update_search_text = payload => ({type: 'SEARCH_TEXT_UPDATE', payload})


export const clear_search_text = payload => ({type: 'SEARCH_TEXT_CLEAR', payload})


global.validate_response = response => response.response_code == 1 ? Promise.resolve(response) : Promise.reject(response);