const timeSince = function(date_string) {
    var seconds = Math.floor((new Date() - new Date(date_string)) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 0) {
      return interval + (interval==1 ? " year " : " years ")+ "ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 0) {
      return interval + (interval==1 ? " month " : " months ")+ "ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 0) {
      return interval + (interval==1 ? " day " : " days ")+ "ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 0) {
      return interval + (interval==1 ? " hour " : " hours ")+ "ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 0) {
      return interval + (interval==1 ? " minute " : " minutes ")+ "ago";
    }
    return Math.floor(seconds) + (interval==1 ? " second " : " seconds ")+ "ago";
}


export default timeSince