import {get_user_details} from '../../actions.js';
import {sha512} from 'js-sha512';

export const local_login = (email, password) => (dispatch) => {
	var url = global.config.server_path + 'user/login';
	return fetch(url, {method: 'POST', headers: {'Content-Type':'application/json'}, 'credentials': 'include', body: JSON.stringify({username: email, password: sha512(password)})})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => dispatch(get_user_details()))
	.catch(err => Promise.reject(err))
};


export const register_investor = () => dispatch => 
	fetch(global.config.server_path + 'investment/add_investor', {method: 'POST', headers: {'Content-Type':'application/json'}, 'credentials': 'include'})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.catch(err => Promise.reject(err))