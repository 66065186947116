import general_fetch from "./fetch";

const fetch_mix_panel_data = async (start_time, end_time, set_mixpanel_events, set_mixpanel_org_level_events_past, set_fetching_usage) => {
	try{
		const apiUrl = global.config.analytics_path + 'api/org_level_events'; // Replace with your API URL
		// let end_time_2 = new Date(end_time)
		// end_time_2.setDate(end_time.getDate()+1)
		const payload = {
		start_time: start_time.toISOString(), // Replace with your start_time
		end_time: end_time.toISOString(),   // Replace with your end_time
		};

		if(set_fetching_usage) set_fetching_usage(true)
		let resp1 = await general_fetch({full_url:apiUrl, body:payload, req_resp:true})

		if(set_mixpanel_org_level_events_past){
			const timeGap = new Date(end_time).getTime() - new Date(start_time).getTime()
			let new_end_time = new Date(start_time)
			let new_start_time = new Date(new_end_time.getTime() - timeGap)
			let payload = {
				start_time: new_start_time.toISOString(), // Replace with your start_time
				end_time: new_end_time.toISOString(),   // Replace with your end_time
			};

			let resp2 = await general_fetch({full_url:apiUrl, body:payload, req_resp:true})
			set_mixpanel_org_level_events_past(resp2)
		}
		set_mixpanel_events(resp1)
		if(set_fetching_usage) set_fetching_usage(false)

	}catch (err) {
		if(set_fetching_usage) set_fetching_usage(false)
		console.error('start',start_time, 'end', end_time,  err)
	}
}

export default fetch_mix_panel_data