import React, { useState, useEffect, useMemo } from 'react';
import {Button as AntdButton } from 'antd';
import general_fetch from '../../utils/fetch';
import { CardBody, Card, Row, CardHeader, Input, Button, Table, Modal, ModalBody, ModalHeader, FormGroup, Col } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import ReactDatetime from "react-datetime";
import { convert_to_readable } from '../../utils/convert_to_readable';
import ChurnRemarkModal from '../ChurnRemarkModal';


const UpdateModal = ({open, toggle, data_type, field, store_info, fetch_store_info, license_start_date, closing_date, last_billing_date, next_billing_date, first_quarter_payment, renewal_comment, churn_status, billing_mailing_enabled, grace_period_id, grace_period_end_date, fetch_last_paid_cycle_end_date, set_page_loader}) => {

	const [value, set_value] = useState(new Date());

	const submit_fn = async() => {
		try{
			let resp 
			set_page_loader(true, 'Please wait...');
			if(field === 'license_start_date'){
				resp = await general_fetch({url:"csp/update_license_start_date", body:{customer_store_id: store_info.id, license_start_date: value.toDate().toDateString()}})
			}else if(field === 'closing_date'){ 
				resp = await general_fetch({url:"csp/update_closing_date", body:{customer_store_id: store_info.id, closing_date: value}})
			}else if(field === 'last_billing_date'){
				resp = await general_fetch({url:"csp/update_last_billing_date", body:{customer_store_id: store_info.id, last_billing_date: value}})
			}else if(field === 'next_billing_date'){
				resp = await general_fetch({url:"csp/update_next_billing_date", body:{customer_store_id: store_info.id, next_billing_date: value}})
			}else if(field === 'first_quarter_payment' || field === 'renewal_comment'){
				resp = await general_fetch({url:"csp/update_remark", body:{customer_store_id: store_info.id, type: field === 'first_quarter_payment' ? 'payment' : 'cse', text: value}})
			}else if(field === 'churn_status'){
				resp = await general_fetch({url: 'csp/update_churn_status', body:{customer_store_id: store_info.id, churn_status: value}})
			}else if(field === 'billing_mailing_enabled'){
				resp = await general_fetch({url: 'csp/update_billing_mailing_status', body:{customer_store_id: store_info.id, billing_mailing_status: value}})
			}else if(field === 'grace_period_end_date'){
				if(!grace_period_id){
					resp = await general_fetch({url:'csp/grace_period/create', body:{customer_store_id: store_info.id, end_date: value}})
				}else{
					resp = await general_fetch({url:'csp/grace_period/update', body:{id: grace_period_id, end_date: value}})
				}
				let org_id = window.location.pathname.split('/')[3]
				fetch_last_paid_cycle_end_date(org_id)
			}
			fetch_store_info()
			set_page_loader(false, 'Please wait...');
			toggle()
		}catch(err){	
			set_page_loader(false, 'Please wait...');
			toastr.error('Something went wrong, Please try again')
			console.error(err);
		}
	}

	useEffect(() => {
		console.log('enable effect', value, value ? 'boool' : 'string')
	}, [value]);

	useEffect(() => {
		if(field === 'license_start_date'){
			console.log('license_start_date', license_start_date)
			set_value(license_start_date)
		}else if(field === 'closing_date'){ 
			set_value(closing_date)
		}else if(field === 'last_billing_date'){
			set_value(last_billing_date)
		}else if(field === 'next_billing_date'){
			set_value(next_billing_date)
		}else if(field === 'first_quarter_payment'){
			set_value(first_quarter_payment)
		}else if(field === 'renewal_comment'){
			set_value(renewal_comment)
		}else if(field === 'churn_status'){
			set_value(churn_status)
			console.log('churn_status', churn_status)
		}else if(field === 'billing_mailing_enabled'){
			set_value(billing_mailing_enabled)
		}else if(field === 'grace_period_end_date'){
			set_value(grace_period_end_date)
		}
	}, [field]);

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={toggle}>
          <div className="modal-header  bg-gradient-white">
              <h4 className="modal-title" id="exampleModalLabel"> Update {convert_to_readable(field)} </h4>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
                <span aria-hidden={true}>×</span>
              </button>
          </div>
          <div className="modal-body  bg-gradient-secondary" >
              <div className="pl-lg-4">
                <Row>
                  <Col md="12">
                    <FormGroup>
					<label className="form-control-label" htmlFor="input-address" style={{minWidth:'60%', marginBottom:0}}>
						<div className='mb-2'>{convert_to_readable(field)}</div>
						<div>
						{
							data_type === 'date' ?
							<ReactDatetime className="rdt1" closeOnSelect onChange={d => {console.log("set date - ",d); set_value(d)}} value={value ? new Date(value).toString().substring(4,15) : ''} dateFormat="YYYY-MM-DD" timeFormat={false}/>
							:(data_type === 'boolean' ? 
								<Input className='standard_input' type='select' style={{padding:'0px 12px'}} value={value} onChange={(e) => e.target.value === 'true' ? set_value(true) : set_value(false)}>
									<option value={true}>true</option>
									<option value={false}>false</option>
								</Input> 
								: 
								(data_type === 'enable_disable' ? 
									<Input className='standard_input' type='select' style={{padding:'0px 12px'}} value={value}  onChange={(e) => e.target.value === 'true' ? set_value(true) : set_value(false)}>
										<option value={true}>enabled</option>
										<option value={false}>disabled</option>
									</Input>  
									: 
									<Input className='standard_input' value={value} onChange={(e) => set_value(e.target.value)} />))
						}
						</div>
					</label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{justifyContent:'flex-end'}} className='mr-2'>
					<Button color="primary" onClick={submit_fn}>Submit</Button>
				</Row>
              </div>
          </div>
      </Modal>
	)
}

const OrgDates = ({user_details, store_info, fetch_store_info, last_paid_cycle_end_date, fetch_last_paid_cycle_end_date, set_page_loader, is_team_lead}) => {

	const [license_start_date, set_license_start_date] = useState();
	const [onboarding_date, set_onboarding_date] = useState();
	const [closing_date, set_closing_date] = useState();
	const [last_billing_date, set_last_billing_date] = useState();
	const [next_billing_date, set_next_billing_date] = useState();
	const [show_update_modal, set_show_update_modal] = useState(false);
	const [first_quarter_payment, set_first_quarter_payment] = useState('');
	const [renewal_comment, set_renewal_comment] = useState('');
	const [churn_status, set_churn_status] = useState(false);
	const [billing_mailing_enabled, set_billing_mailing_enabled] = useState(false);
	const [grace_period_start_date, set_grace_period_start_date] = useState();
	const [grace_period_end_date, set_grace_period_end_date] = useState();
	const [initialized_grace_period_start_date, set_initialized_grace_period_start_date] = useState(false);
	const [grace_period_id, set_grace_period_id] = useState('');
	const [data_type, set_data_type] = useState();
	const [field, set_field] = useState();
	const [show_churn_remark_modal, set_show_churn_remark_modal] = useState(false);
	const [churn_remark_modal_mode, set_churn_remark_modal_mode] = useState('view');

	const toggle_update_modal = () => { set_show_update_modal(!show_update_modal)}
	const is_cs_admin = useMemo(() => user_details && user_details.role && user_details.role.store && user_details.role.store.length && user_details.role.store.includes('customer_success_admin'), [user_details])

	// const fetch_last_paid_cycle_end_date = async() => {
	// 	try{
	// 		let resp = await general_fetch({url:'csp/fetch_last_paid_cycle_end_date', body:{customer_store_id: store_info.id}})
	// 		if(resp && resp.end_date) set_grace_period_start_date(resp.end_date)
	// 	}catch(err){
	// 		console.error(err)
	// 	}
	// }

	useEffect(() => {
		if(store_info){
			if(store_info.license_start_date) set_license_start_date(new Date(store_info.license_start_date))
			if(store_info.onboarding_date) set_onboarding_date(new Date(store_info.onboarding_date))
			if(store_info.closing_date) set_closing_date(new Date(store_info.closing_date))
			if(store_info.last_billing_date) set_last_billing_date(new Date(store_info.last_billing_date))
			if(store_info.next_billing_date) set_next_billing_date(new Date(store_info.next_billing_date))
			if(store_info.all_payment_remarks && store_info.all_payment_remarks.length) set_first_quarter_payment(store_info.all_payment_remarks[0].text)
			if(store_info.all_cse_remarks && store_info.all_cse_remarks.length) set_renewal_comment(store_info.all_cse_remarks[0].text)
			if(store_info.churned){ 
				set_churn_status(true)
			}else{
				set_churn_status(false)
			}
			if(store_info.billing_mailing_enabled){
				set_billing_mailing_enabled(true)
			}else{
				set_billing_mailing_enabled(false)
			}
		}
	}, [store_info]);

	useEffect(() => {
		if(store_info && store_info.id && !store_info.ongoing_grace_period && !initialized_grace_period_start_date && last_paid_cycle_end_date){
			set_grace_period_start_date(last_paid_cycle_end_date)
			set_initialized_grace_period_start_date(true)
		}else if(store_info && store_info.ongoing_grace_period){
			set_grace_period_end_date(store_info.ongoing_grace_period.end_date)
			set_grace_period_start_date(store_info.ongoing_grace_period.start_date)
			set_initialized_grace_period_start_date(true)
			set_grace_period_id(store_info.ongoing_grace_period.id)
		}
	}, [store_info, last_paid_cycle_end_date]);

	return (
		<div style={{padding:'20px 80px'}}>
			<div className='flex_property mb-2' style={{width:'100%'}}>
                <div style={{flex:5}}>License Start Date</div>
				<Input readOnly className='standard_input' style={{width:'200px', marginRight:(is_cs_admin || is_team_lead) ? '' : '34px'}} value={license_start_date ? new Date(license_start_date).toString().substring(4, 15)  :''} />
				{(is_cs_admin || is_team_lead) ? <i className="fa fa-edit ml-3 cp" style={{color:'#5e72e4', fontSize:'18px'}} onClick={() => {toggle_update_modal(); set_data_type('date'); set_field('license_start_date')}}/> : ''}
            </div>
			{/* <div className='flex_property mb-2' style={{width:'100%'}}>
                <div style={{flex:5}}>Onboarding Date</div>
				<Input readOnly className='standard_input' style={{width:'200px', marginRight:'34px'}} value={onboarding_date ? new Date(onboarding_date).toString().substring(4, 15)  :''} />
            </div> */}
			<div className='flex_property mb-2' style={{width:'100%'}}>
                <div style={{flex:5}}>Last Billing Date</div>
				<Input readOnly className='standard_input' style={{width:'200px', marginRight:'34px'}} value={last_billing_date ? new Date(last_billing_date).toString().substring(4, 15) : ''} />
				{/* <i className="fa fa-edit ml-3 cp" style={{color:'#5e72e4', fontSize:'18px'}} onClick={() => {toggle_update_modal(); set_data_type('date'); set_field('last_billing_date')}}/> */}
            </div>
			<div className='flex_property mb-2' style={{width:'100%'}}>
                <div style={{flex:5}}>Next Billing Date</div>
				<Input readOnly className='standard_input' style={{width:'200px', marginRight:'34px'}} value={next_billing_date ? new Date(next_billing_date).toString().substring(4, 15) : ''} />
				{/* <i className="fa fa-edit ml-3 cp" style={{color:'#5e72e4', fontSize:'18px'}} onClick={() => {toggle_update_modal(); set_data_type('date'); set_field('next_billing_date')}}/> */}
            </div>
			<div className='flex_property mb-2' style={{width:'100%'}}>
                <div style={{flex:5}}>Closing Date</div>
				<Input readOnly className='standard_input' style={{width:'200px', marginRight:'34px'}} value={closing_date ? new Date(closing_date).toString().substring(4, 15) : ''} />
				{/* <i className="fa fa-edit ml-3 cp" style={{color:'#5e72e4', fontSize:'18px'}} onClick={() => {toggle_update_modal(); set_data_type('date'); set_field('closing_date')}}/> */}
            </div>
			<div className='flex_property mb-2' style={{width:'100%'}}>
                <div style={{flex:5}}>First Quarter Payment</div>
				<Input readOnly className='standard_input' style={{width:'200px'}} value={first_quarter_payment} />
				<i className="fa fa-edit ml-3 cp" style={{color:'#5e72e4', fontSize:'18px'}} onClick={() => {toggle_update_modal(); set_data_type('text'); set_field('first_quarter_payment')}}/>
            </div>
			<div className='flex_property mb-2' style={{width:'100%'}}>
                <div style={{flex:5}}>Churned</div>
				<Input readOnly className='standard_input' style={{width:'200px'}} value={churn_status} />
				<i className="fa fa-edit ml-3 cp" style={{color:'#5e72e4', fontSize:'18px'}} onClick={() => {toggle_update_modal(); set_data_type('boolean'); set_field('churn_status')}}/>
            </div>
			<div className='flex_property mb-2' style={{width:'100%'}}>
                <div style={{flex:5}}>Billing Mailing Status</div>
				<Input readOnly className='standard_input' style={{width:'200px'}} value={billing_mailing_enabled ? 'enabled' : 'disabled'} />
				<i className="fa fa-edit ml-3 cp" style={{color:'#5e72e4', fontSize:'18px'}} onClick={() => {toggle_update_modal(); set_data_type('enable_disable'); set_field('billing_mailing_enabled')}}/>
            </div>
			<div className='flex_property mb-2' style={{width:'100%'}}>
                <div style={{flex:5}}>Grace Period</div>
				<Input readOnly className='standard_input' style={{width:'120px'}} value={grace_period_start_date ? new Date(grace_period_start_date).toString().substring(4, 15) : ''} />
				{'\xa0\xa0'}-{'\xa0\xa0'}
				<Input readOnly className='standard_input' style={{width:'120px'}} value={grace_period_end_date ? new Date(grace_period_end_date).toString().substring(4, 15) : 'Not Set'} />
				<i className="fa fa-edit ml-3 cp" style={{color:'#5e72e4', fontSize:'18px'}} onClick={() => {toggle_update_modal(); set_data_type('date'); set_field('grace_period_end_date')}}/>
            </div>

			{/* <div className='flex_property mb-2' style={{width:'100%', flexDirection: 'column', marginTop: '20px'}}>
				<h3>Renewal/Churn Details</h3>
				<div className="flex_property" style={{marginBottom: '10px'}}>
					<Input className='standard_input' readOnly type="textarea" value={renewal_comment} />
					<Input className='standard_input' readOnly value={store_info.all_cse_remarks && store_info.all_cse_remarks.length ? store_info.all_cse_remarks[0].metadata && store_info.all_cse_remarks[0].metadata.reason ? store_info.all_cse_remarks[0].metadata.reason : 'N/A' : 'N/A'} />
					<Input className='standard_input' readOnly value={store_info.all_cse_remarks && store_info.all_cse_remarks.length ? store_info.all_cse_remarks[0].metadata && store_info.all_cse_remarks[0].metadata.sub_reason ? store_info.all_cse_remarks[0].metadata.sub_reason : 'N/A' : 'N/A'} />
					<Input className='standard_input' readOnly value={store_info.all_cse_remarks && store_info.all_cse_remarks.length ? store_info.all_cse_remarks[0].metadata && store_info.all_cse_remarks[0].metadata.reachout_date ? store_info.all_cse_remarks[0].metadata.reachout_date : 'N/A' : 'N/A'} />
				</div>
				<div>
					<Button type="primary" onClick={() => {set_show_churn_remark_modal(true); set_churn_remark_modal_mode('view')}}>
						View Details
					</Button>
					<Button style={{marginLeft: '10px'}} onClick={() => {set_show_churn_remark_modal(true); set_churn_remark_modal_mode('edit')}}>
						Edit Details
					</Button>
				</div>
			</div> */}
			<div className='flex_property mb-2' style={{width:'100%', flexDirection: 'column', marginTop: '20px'}}>
				{/* <div className='flex_property mb-2'>
					<div>
						<h5>Renewal/Churn Details</h5>
					</div>
					<AntdButton type="primary" onClick={() => {set_show_churn_remark_modal(true); set_churn_remark_modal_mode('view')}}>
						View
					</AntdButton>
					<AntdButton type="primary" style={{marginLeft: '10px'}} onClick={() => {set_show_churn_remark_modal(true); set_churn_remark_modal_mode('edit')}}>
						Edit
					</AntdButton>
				</div> */}
				<div className='flex_property mb-2' style={{width:'100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px'}}>
					<div>
						<h4>Renewal/Churn Details</h4>
					</div>
					<div>
						<AntdButton icon={<i className="fa fa-eye"/>} type="primary" onClick={() => {set_show_churn_remark_modal(true); set_churn_remark_modal_mode('view')}}>
							View
						</AntdButton>
						<AntdButton icon={<i className="fa fa-edit"/>} type="primary" style={{marginLeft: '10px'}} onClick={() => {set_show_churn_remark_modal(true); set_churn_remark_modal_mode('edit')}}>
							Edit
						</AntdButton>
					</div>
				</div>
				<div  style={{marginBottom: '10px', width: '100%'}}>
					<div className="flex_property mb-2">
						<div className='mr-5'>Comment</div>
						<Input className='standard_input' readOnly type="textarea" value={renewal_comment} />
					</div>
					<div className="flex_property mb-2">
						<div className='mr-5'>Category</div>
						<Input className='standard_input mr-2' readOnly value={store_info.all_cse_remarks && store_info.all_cse_remarks.length ? store_info.all_cse_remarks[0].metadata && store_info.all_cse_remarks[0].metadata.reason ? store_info.all_cse_remarks[0].metadata.reason : 'N/A' : 'N/A'} />
					    :::  
						<Input className='standard_input ml-2' readOnly value={store_info.all_cse_remarks && store_info.all_cse_remarks.length ? store_info.all_cse_remarks[0].metadata && store_info.all_cse_remarks[0].metadata.sub_reason ? store_info.all_cse_remarks[0].metadata.sub_reason : 'N/A' : 'N/A'} />
					</div>
					<div className="flex_property mb-2">
						<div className='flex_property mr-5'>Date(R/o)</div>
						<Input className='standard_input ml-2' readOnly value={store_info.all_cse_remarks && store_info.all_cse_remarks.length ? store_info.all_cse_remarks[0].metadata && store_info.all_cse_remarks[0].metadata.reachout_date ? store_info.all_cse_remarks[0].metadata.reachout_date : 'N/A' : 'N/A'} />
					</div>
				</div>
			</div>
			<UpdateModal
				open={show_update_modal}
				toggle={toggle_update_modal}
				data_type={data_type}
				field={field}
				store_info = {store_info}
				fetch_store_info = {fetch_store_info}
				license_start_date = {license_start_date}
				closing_date = {closing_date}
				last_billing_date = {last_billing_date}
				next_billing_date = {next_billing_date}
				first_quarter_payment = {first_quarter_payment}
				renewal_comment = {renewal_comment}
				churn_status={churn_status}
				billing_mailing_enabled={billing_mailing_enabled}
				grace_period_id = {grace_period_id}
				grace_period_end_date = {grace_period_end_date}
				fetch_last_paid_cycle_end_date = {fetch_last_paid_cycle_end_date}
				set_page_loader = {set_page_loader}
			/>
			<ChurnRemarkModal
				open={show_churn_remark_modal}
				toggle={() => set_show_churn_remark_modal(!show_churn_remark_modal)}
				org_info={store_info}
				refresh_data={fetch_store_info}
				mode={churn_remark_modal_mode}
			/>

        </div>
	)
}

export default OrgDates