import React from "react";
import { connect } from 'react-redux';
import {toastr} from 'react-redux-toastr'
import {Route, Switch, Redirect} from "react-router-dom";
import {Icon} from 'react-fa';
import NewWindow from 'react-new-window';

import {local_login, register_investor} from './actions';
// reactstrap components
import {Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      redirect: false,
      redirect_url: null,
      show_popup: false
    };
  }

  update_inputs (event) {
      this.setState({[event.target.name]: event.target.value})
  }


  redirect_home = () => {
      var redirect_url = '/home/paid_orgs';
      this.setState({redirect: true, redirect_url});
  } 
  

  signup = () => {
      this.props.register_investor()
      .then(resp => this.redirect_home())
      .catch(() => toastr.error('You are not authorized to access this portal'))
  }


  local_login = event => {
      event.preventDefault();
      var username = this.state.username;
      var password = this.state.password;
      if (username && password) {
          this.props.local_login(username, password)
          .then(data => {
              this.redirect_home();
          })
          .catch(err => {
              toastr.error('Invalid Credentials')
              console.log(err);
          })
      } else {
          toastr.error('please fill all fields')
      }
  }

  render() {
    var {redirect, redirect_url} = this.state;
    if (redirect) {
        return (<Redirect to={redirect_url} />)
    }

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button onClick={() => this.setState({show_popup: true})} className="btn-neutral btn-icon" color="default">
                  <Icon size="lg" name="linkedin-square"/>
                  <span className="btn-inner--text">LinkedIn</span>
                </Button>

                {this.state.show_popup && (<NewWindow url={global.config.server_path + "linkedin"} onUnload={this.signup} />)}
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <Form onSubmit={this.local_login} role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="text" name="username" onChange={event => this.update_inputs(event)} value={this.state.username ? this.state.username : null} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password"  name="password" onChange={event => this.update_inputs(event)} value={this.state.password ? this.state.password : null} />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
                  <label className="custom-control-label" htmlFor=" customCheckLogin">
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-center" xs="12">
              <a className="text-light" onClick={() => this.setState({show_popup: true})}>
                <small>New here? Sign up with LinkedIn</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = function(state) {
    return {
        user_details: state.user_details,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        local_login: (username, password) => dispatch(local_login(username, password)),
        register_investor: () => dispatch(register_investor()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);