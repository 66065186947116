import general_fetch from "../fetch";

export const createReleaseNote = async(jira_version_id, feature_data, highlighted, status='Draft') => {
    try{
        let body = {jira_version_id, feature_data, highlighted, status}
        let response = await general_fetch({ url: 'release_note/create', body});
        return response
    }catch(err){
        console.error(`Error in createReleaseNote `, err)
        return Promise.reject(err);
    }
}

export const updateReleaseNote = async(id, jira_version_id, feature_data, highlighted, status='Draft') => {
    try{
        let body = {id, jira_version_id, feature_data, highlighted, status}
        let response = await general_fetch({ url: 'release_note/update', body});
        return response
    }catch(err){
        console.error(`Error in updateReleaseNote `, err)
        return Promise.reject(err);
    }
}

export const deleteReleaseNote = async(id) => {
    try{
        let body = {id}
        let response = await general_fetch({ url: 'release_note/remove', body});
        return response
    }catch(err){
        console.error(`Error in updateReleaseNote `, err)
        return Promise.reject(err);
    }
}

export const getAllReleaseNotes = async() => {
    try{
        let response = await general_fetch({ url: 'release_note/get_all' });
        return response
    }catch(err){
        console.error(`Error in getAllReleaseNotes `, err)
        return Promise.reject(err);
    }
}

export const getLatestVersions = async() => {
    try{
        let response = await general_fetch({ url: 'release_note/get_ct_20_versions' });
        return response
    }catch(err){
        console.error(`Error in getLatestVersions `, err)
        return Promise.reject(err);
    }
}