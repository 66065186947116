export const customer_tickets_status_options = [
    {value:'cs_review', label:'CS Review'},
    {value:'pm_review', label:'Product Review'},
    {value:'in_progress', label:'In Progress'},
    {value:'jira_added', label:'In Progress - Jira Added'},
    {value:'jira_in_progress', label:'In Progress - Jira in Dev'},
    {value:'jira_done', label:'In Progress - Jira Done'},
    {value:'product_done', label:'Product Done'},
    {value:'done', label:'Done'},
    {value:'future_roadmap', label:'Future Roadmap'},
    {value:'not_in_scope', label:'Not in Scope'},
]