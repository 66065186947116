export const user_details = (state = null, action) => {
	switch (action.type) {
		case 'USER_DETAILS_FETCHED':
	  		return action.payload;
		default:
	  		return state;
	}
};


export const investor_details = (state = null, action) => {
	switch (action.type) {
		case 'INVESTOR_DETAILS_FETCHED':
	  		return action.payload;
		default:
	  		return state;
	}
};


export const license_plans = (state = null, action) => {
	switch (action.type) {
		case 'LICENSE_PLANS_FETCHED':
	  		return action.payload;
		default:
	  		return state;
	}
};


export const stores = (state = null, action) => {
	switch (action.type) {
		case 'STORE_DATA_FETCHED':
	  		return action.payload;
		default:
	  		return state;
	}
};


export const fetching_store_data = (state = false, action) => {
	switch (action.type) {
		case 'STORE_DATA_FETCHED':
	  		return false;
  		case 'STORE_DATA_FETCHING':
	  		return true;
		default:
	  		return state;
	}
};


export const usage = (state = null, action) => {
	switch (action.type) {
		case 'USAGE_DATA_FETCHED':
	  		return action.payload;
		default:
	  		return state;
	}
};


export const fetching_usage_data = (state = false, action) => {
	switch (action.type) {
		case 'USAGE_DATA_FETCHED':
	  		return false;
  		case 'USAGE_DATA_FETCHING':
	  		return true;
		default:
	  		return state;
	}
};


export const search_text = (state = '', action) => {
	// console.log(action)
	switch (action.type) {
		case 'SEARCH_TEXT_UPDATE':
	  		return action.payload;
  		case 'SEARCH_TEXT_CLEAR':
	  		return '';
		default:
	  		return state;
	}
};