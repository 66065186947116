import React, { useState, useEffect } from 'react';
import general_fetch from '../../utils/fetch';
import { CardBody, Card, Row, CardHeader, Input, Button, Table, Modal, ModalBody, ModalHeader, Col, FormGroup } from 'reactstrap';
import QRCode from 'qrcode';
import { toastr } from 'react-redux-toastr';
import DeleteNotification from '../DeleteNotification';
import { confirmAlert } from 'react-confirm-alert';
import { CspModal, CspModalBody, CspModalFooter, CspModalHeader } from '../CspModal';
import { all_infurnia_add_ons, infurnia_add_ons_mes as infurnia_add_ons } from '../../utils/infurnia_add_ons';
import makeAnimated from 'react-select/animated';
import Select, { StylesConfig } from "react-select";
import { colourStyles2 } from "../../utils/react_select_styles";
import {DatePicker} from 'antd'
import dayjs from 'dayjs'

const animatedComponents = makeAnimated();

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
		title: 'Confirm to submit',
		message: message,
		customUI: ({ onClose }) => {
			return (
				Custom_modal ?
					<div>
						<Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
					</div>
					:
					<div className='custom-ui'>
						<DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
					</div>
			);
		}
	});
}

const QRCodeModal = ({ active_item, url, open, toggle }) => {
	const [paymentQrCode, setPaymentQrCode] = useState('');

	const handleDownload = () => {
		const link = document.createElement('a');
		link.href = paymentQrCode;
		link.download = active_item && active_item.name ? active_item.name + ' QRCode.jpg' : 'InfurniaQRCode.jpg';
		link.click();
	};

	useEffect(() => {
		if (url) {
			QRCode.toDataURL(url, (err, qrCode) => {
				if (err) {
					console.error(err);
					// setErrorMessage('An error occurred while generating the QR code.');
				} else {
					setPaymentQrCode(qrCode);
					// setErrorMessage('');
				}
			});
		}
	}, [url]);

	return (
		<Modal isOpen={open} size={'sm'} toggle={toggle} className='modal-dialog-centered'>
			<ModalHeader>{active_item && active_item.name ? active_item.name : ''}</ModalHeader>
			<ModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexFlow: 'column' }}>
				{
					paymentQrCode && (
						<div>
							<img src={paymentQrCode} alt="Payment QR Code" />
						</div>
					)
				}
				<span style={{ cursor: 'pointer' }} onClick={handleDownload}>Download QR Code <i className='fas fa-download'></i></span>
			</ModalBody>
		</Modal>
	)
}

const FilterModal = ({ open, toggle, se_filter, set_se_filter, cs_org_users, set_filter_active, filter_fn }) => {

	const reset_fn = () => {
		set_se_filter('')
	}

	const submit_fn = () => {
		if (se_filter != '') {
			set_filter_active(true)
		} else {
			set_filter_active(false)
		}
		filter_fn(se_filter)
		toggle()
	}

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" id="exampleModalLabel"> Filter </h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{ overflow: 'auto', maxHeight: '600px' }} >
				<div className="pl-lg-4">
					<Row>
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mb-2'>BD Rep</div>
									<Input type='select' value={se_filter} onChange={(e) => set_se_filter(e.target.value)}>
										<option value=''>None</option>
										{
											cs_org_users && cs_org_users.length ? cs_org_users.map((single_user) => (
												<option value={single_user.id}>{single_user.first_name}</option>
											)) : ''
										}
									</Input>
								</label>
							</FormGroup>
						</Col>
					</Row>
				</div>
			</div>
			<div className="modal-footer" style={{ height: '56px' }}>
				<Button className='white_button mr-2' onClick={reset_fn}>Reset</Button>
				<Button color="primary" onClick={submit_fn}>Submit</Button>
			</div>
		</Modal>
	)
}

const EditInvite = ({ open, toggle, pending_invite, fetch_pending_invites, set_page_loader }) => {

	const [edited_org_name, set_edited_org_name] = useState();
	const [edited_additional_users, set_edited_additional_users] = useState(0);
	const [edited_add_ons, set_edited_add_ons] = useState([]);
	const [ edited_payment_gateway, set_edited_payment_gateway ] = useState('phonepe');
	const [ edited_onboarding_date, set_edited_onboarding_date ] = useState('');
	const [ edited_tds_percentage, set_edited_tds_percentage ] = useState(0);

	const onclick_handleclose = () => {
		toggle()
		set_edited_org_name()
		set_edited_additional_users(0)
		set_edited_add_ons([])
		set_edited_payment_gateway('phonepe')
		set_edited_onboarding_date('')
	}

	const update_flow_details = async () => {
		try {
			let line_1 = pending_invite.location ? pending_invite.location.line_1 : ''
			let line_2 = pending_invite.location ? pending_invite.location.line_2 : ''
			let city = pending_invite.location ? pending_invite.location.city : ''
			let state = pending_invite.location ? pending_invite.location.state : ''
			let pin_code = pending_invite.location ? pending_invite.location.pin_code : ''
			let body = { name: pending_invite.name, legal_name: pending_invite.legal_name, line_1, line_2, city, state, pin_code, id: pending_invite.id }

			let changed_something = 0

			if (edited_org_name != pending_invite.name) {
				changed_something = 1
				body['name'] = edited_org_name
			}

			if (edited_additional_users != pending_invite.additional_users) {
				changed_something = 1
				body['additional_users'] = edited_additional_users
			}

			if(edited_onboarding_date && new Date(edited_onboarding_date).toDateString()!='Invalid Date' && ((!pending_invite.onboarding_date && edited_onboarding_date) || new Date(pending_invite.onboarding_date).toDateString() != new Date(edited_onboarding_date).toDateString())){
				changed_something = 1
				body['onboarding_date'] = new Date(edited_onboarding_date).toDateString()
			}

			let selected_add_ons = pending_invite.add_on_ids
			if (JSON.stringify(selected_add_ons.sort()) != JSON.stringify(edited_add_ons.sort())) {
				changed_something = 1
				body['add_on_ids'] = edited_add_ons
			}

			if(pending_invite.payment_gateway != edited_payment_gateway){
				changed_something = 1
				body['payment_gateway'] = edited_payment_gateway
			}

			if(pending_invite.tds_percentage != edited_tds_percentage) changed_something = 1
			body['tds_percentage'] = edited_tds_percentage

			body["apply_onboarding_fees_on_plan"] = true;
			body["add_on_ids_to_apply_onboarding_fees"] = edited_add_ons

			if (!changed_something) return
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ url: 'prepaid_signup_flow/restricted_update', body: body })
			set_page_loader(false)
			fetch_pending_invites()
			onclick_handleclose()
		} catch (e) {
			console.error(e)
			toastr.error(`Could not update flow`)
		}
	}

	useEffect(() => {
		if (open && pending_invite) {
			set_edited_org_name(pending_invite.name || '')
			set_edited_additional_users(pending_invite.additional_users || 0)
			if (pending_invite.add_on_ids && pending_invite.add_on_ids.length) set_edited_add_ons(pending_invite.add_on_ids)
			if(pending_invite.prepaid_order.payment_gateway) set_edited_payment_gateway(pending_invite.prepaid_order.payment_gateway)
			if(pending_invite.onboarding_date) set_edited_onboarding_date(new Date(pending_invite.onboarding_date))
			if(pending_invite.tds_percentage) set_edited_tds_percentage(pending_invite.tds_percentage)
		}
	}, [open, pending_invite]);

	return (
		<CspModal open={open} toggle={onclick_handleclose}>
			<CspModalHeader title='Edit Pending Invite' toggle={onclick_handleclose} />
			<CspModalBody>
				<FormGroup>
					<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
						<div className='mb-2'>Org Name</div>
						<Input style={{ height: '36px' }} className='px-2 py-1' value={edited_org_name} onChange={(e) => set_edited_org_name(e.target.value)} />
					</label>
				</FormGroup>
				<FormGroup>
					<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
						<div className='mb-2'>Additional users</div>
						<Input style={{ height: '36px' }} className='px-2 py-1' value={edited_additional_users} onChange={(e) => set_edited_additional_users(e.target.value)} />
					</label>
				</FormGroup>
				<FormGroup style={{ zIndex: 5, position: 'relative' }}>
					<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
						<div className='mb-2'>Add Ons</div>
						<Select
							closeMenuOnSelect={false}
							components={animatedComponents}
							value={edited_add_ons && edited_add_ons.length ? edited_add_ons.map(x => { return { value: x, label: infurnia_add_ons.find(o => o.value === x).label } }) : []}
							isMulti
							options={infurnia_add_ons}
							styles={colourStyles2}
							onChange={(selectedOptions) => {
								if (!selectedOptions || !selectedOptions.length) {
									set_edited_add_ons([])
								} else {
									if ((edited_add_ons.includes('1') && selectedOptions.find(o => o.value === '4')) || (edited_add_ons.includes('4') && selectedOptions.find(o => o.value === '1'))) {
										toastr.info('You can only select one of the two add-ons among "Panel Cut-List" & "Manufacturing"')
									} else if ((edited_add_ons.includes('6') && selectedOptions.find(o => o.value === '7')) || (edited_add_ons.includes('7') && selectedOptions.find(o => o.value === '6'))) {
										toastr.info('You can only select one of the two add-ons among "MES Lite" & "MES Pro"')
									} else {
										set_edited_add_ons(selectedOptions && selectedOptions.length ? selectedOptions.map(x => x.value) : [])
									}
								}
							}}
						/>
					</label>
				</FormGroup>
				<FormGroup>
					<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
						<div className='mb-2'>Payment Gateway</div>
						<Input className="form-control-alternative" type="select" value={edited_payment_gateway} onChange={(e) => set_edited_payment_gateway(e.target.value)} required>
							<option value={"phonepe"}>Phonepe</option>
							<option value={"razorpay"}>Razorpay</option>
						</Input>
					</label>
				</FormGroup>
				<FormGroup>
					<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
						TDS Percentage:
						<Input className="form-control-alternative" type="select" value={edited_tds_percentage} onChange={(e) => set_edited_tds_percentage(e.target.value)} required>
							<option value={0}>N/A</option>
							<option value={2}>2%</option>
							<option value={10}>10%</option>
						</Input>
					</label>
				</FormGroup>
				{/* <FormGroup>
					<label className="form-control-label" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
						<div className='mb-2'>Onboarding Date</div>
						<div className='form-control-alternative' style={{height:'46px', backgroundColor:'white'}}>
							<DatePicker
								format="YYYY-MM-DD"
								onChange = {(date, dateString) => set_edited_onboarding_date(dateString)}
								value = {dayjs(edited_onboarding_date, new Date(edited_onboarding_date), 'YYYY-MM-DD')}
								// disabledDate={(d) => new Date(d) < new Date(today)}
								style = {{width:'100%', height:'46px', backgroundColor:'white'}}
								bordered = {false}
							/>
						</div>
					</label>
				</FormGroup> */}
			</CspModalBody>
			<CspModalFooter>
				<Button color='primary' onClick={update_flow_details}>Update</Button>
			</CspModalFooter>
		</CspModal>
	)
}

const PendingInvites = ({ set_page_loader }) => {
	const [pending_invites, set_pending_invites] = useState([]);
	const [display_pending_invites, set_display_pending_invites] = useState([]);
	const [active_url, set_active_url] = useState('');
	const [open, set_open] = useState(false);
	const [active_item, set_active_item] = useState('');
	const [filter_active, set_filter_active] = useState(false);
	const [show_filter_modal, set_show_filter_modal] = useState(false);
	const [se_filter, set_se_filter] = useState('');
	const [cs_org_users, set_cs_org_users] = useState();
	const [show_edit_invite_modal, set_show_edit_invite_modal] = useState(false);

	const toggle = () => set_open(!open)
	const toggle_filter_modal = () => set_show_filter_modal(!show_filter_modal)

	const fetch_pending_invites = async () => {
		try {
			set_page_loader(true, 'Fetching invites')
			let resp = await general_fetch({ url: 'prepaid_signup_flow/get_pending' })
			set_page_loader(false, '')
			set_pending_invites(resp)
			if (se_filter != '') {
				let temp_invites = resp && resp.length ? resp.filter(x => x.customer_success_executive && x.customer_success_executive.id === se_filter) : []
				set_display_pending_invites(temp_invites)
			} else {
				set_display_pending_invites(resp)
			}
		} catch (err) {
			console.error(err);
		}
	}

	const filter_fn = (se_filter) => {
		if (se_filter != '') {
			let temp_invites = pending_invites && pending_invites.length ? pending_invites.filter(x => x.customer_success_executive && x.customer_success_executive.id === se_filter) : []
			set_display_pending_invites(temp_invites)
		}
	}

	const fetch_cs_org_users = async () => {
		try {
			let account_details = await general_fetch({ url: 'user/get_all' });
			if (account_details && account_details.length) {
				// account_details = account_details.filter(x => !x.depricated && (x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_admin') || x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_executive')))
				account_details = account_details.filter(x => !x.depricated && (x.role.includes('customer_success_admin') || x.role.includes('customer_success_executive')))
				set_cs_org_users(account_details)
			}
		} catch (err) {
			console.error(err)
		}
	}

	const apply_coupon = async (id) => {
		try {
			let resp = await general_fetch({ url: 'billing/coupon/create_spot', body: { apply_to_prepaid_flow_id: id } })
			toastr.success('Coupon generated and applied successfully')
			fetch_pending_invites()
		} catch (err) {
			console.error(err)
			toastr.error(`Couldn't apply coupon`)
		}
	}

	const unapply_coupon = async (id) => {
		try {
			let resp = await general_fetch({ url: 'prepaid_signup_flow/unapply_coupon', body: { id } })
			toastr.success('Coupon unapplied successfully')
			fetch_pending_invites()
		} catch (err) {
			console.error(err)
			toastr.error(`Couldn't unapply coupon, please try again`)
		}
	}

	const download_zoho_pi = async (id) => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ url: 'prepaid_signup_flow/create_and_get_zoho_pi', body: { id } })
			set_page_loader(false)
			if (resp) {
				const blob = new Blob([new Uint8Array(resp.data)], { type: 'application/pdf' })
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = 'document.pdf';
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(url);
				document.body.removeChild(link);
			}
			toastr.success('Successfully downloaded PI')
		} catch (err) {
			console.error(err)
			toastr.error(`Couldn't download PI, please try again`)
		}
	}

	useEffect(() => {
		fetch_pending_invites()
		fetch_cs_org_users()
	}, []);

	return (
		<React.Fragment>
			<FilterModal
				open={show_filter_modal}
				toggle={toggle_filter_modal}
				se_filter={se_filter}
				set_se_filter={set_se_filter}
				cs_org_users={cs_org_users}
				set_filter_active={set_filter_active}
				filter_fn={filter_fn}
			/>
			<EditInvite
				open={show_edit_invite_modal}
				toggle={() => set_show_edit_invite_modal(false)}
				pending_invite={active_item}
				fetch_pending_invites={fetch_pending_invites}
				set_page_loader={set_page_loader}
			/>
			<QRCodeModal url={active_url} open={open} toggle={toggle} active_item={active_item} />
			<div className='flex_property mb-2' style={{ justifyContent: 'flex-end' }}>
				<div className='flex_center pr-2 pl-2 pt-1 pb-1 cp' style={{ borderRadius: '4px', border: '1px solid gray', color: filter_active ? '#0078ff' : '', borderColor: filter_active ? '#0078ff' : 'gray' }} onClick={() => toggle_filter_modal()}>
					<div className='mr-3' style={{ fontSize: '14px' }}>Filter</div>
					<i className='fa fa-solid fa-filter' style={{}} />
				</div>
			</div>
			<Table className="align-items-center table-flush text-left" responsive>
				<thead className="thead-light">
					<tr>
						<th scope="col" style={{ position: 'sticky', left: 0 }}><span><b>Edit</b></span><br /></th>
						<th scope="col"><span><b>Name</b></span><br /></th>
						<th scope="col"><span><b>Email</b></span><br /></th>
						<th scope="col"><span><b>Country</b></span><br /></th>
						<th scope="col"><span><b>Addl Users</b></span><br /></th>
						<th scope="col"><span><b>Add-ons</b></span><br /></th>
						<th scope="col"><span><b>Discount</b></span><br /></th>
						<th scope="col"><span><b>Total</b></span><br /></th>
						<th scope="col"><span><b>BD Rep</b></span><br /></th>
						<th scope="col"><span><b>Coupon applied</b></span><br /></th>
						<th scope="col"><span><b>Download PI</b></span><br /></th>
						<th scope="col"><span><b></b></span><br /></th>
						<th scope="col"><span><b></b></span><br /></th>
						<th scope="col"><span><b></b></span><br /></th>
					</tr>
				</thead>
				<tbody>
					{
						display_pending_invites && display_pending_invites.length ? display_pending_invites.map((single_invite, idx) => (
							<tr className='display_onhover'>
								<td scope="col" className='cp' style={{ position: 'sticky', left: 0, backgroundColor: 'white' }} onClick={() => { set_show_edit_invite_modal(true); set_active_item(single_invite) }}><span><b><i className='fa fa-edit cp' /></b></span><br /></td>
								<td scope="col"><span><b>{single_invite.name}</b></span><br /></td>
								<td scope="col"><span><b>{single_invite.email}</b></span><br /></td>
								<td scope="col"><span><b>{single_invite.country}</b></span><br /></td>
								<td scope="col"><span><b>{single_invite.additional_users}</b></span><br /></td>
								<td scope="col"><span><b>{single_invite.add_on_ids && single_invite.add_on_ids.length ? single_invite.add_on_ids.map(x => all_infurnia_add_ons.find(o => o.value === x).label).join(', ') : 'N/A'}</b></span><br /></td>
								<td scope="col"><span><b>{Number(single_invite.discount_percentage) + '%'}</b></span><br /></td>
								<td scope="col"><span><b>{single_invite.country === 'IN' ? '\u20B9' : '$'} {single_invite.prepaid_order ? single_invite.prepaid_order.total : '0'}</b></span><br /></td>
								<td scope="col"><span><b>{single_invite.customer_success_executive && single_invite.customer_success_executive.first_name ? single_invite.customer_success_executive.first_name : ''}</b></span><br /></td>
								<td scope="col"><span><b>{single_invite.prepaid_order && single_invite.prepaid_order.discount && single_invite.prepaid_order.discount.coupon ? single_invite.prepaid_order.discount.coupon.code : '-'}</b></span><br /></td>
								<td scope="col"><span><i className='fa fa-download cp' onClick={() => download_zoho_pi(single_invite.id)} /></span><br /></td>
								<td scope="col"><span style={{ color: '#0078ff' }} className='cp' onClick={single_invite.prepaid_order && single_invite.prepaid_order.discount && single_invite.prepaid_order.discount.coupon ? () => submit(() => unapply_coupon(single_invite.id), () => { }, '', '', '') : () => submit(() => apply_coupon(single_invite.id), () => { }, '', '', '')}>{single_invite.prepaid_order && single_invite.prepaid_order.discount && single_invite.prepaid_order.discount.coupon ? 'Unapply Coupon' : 'Apply Coupon'}</span><br /></td>
								<td scope="col"><span><a target="_blank" href={global.config.onboarding_path + `/?form_id=${single_invite.id}`}>Link</a></span><br /></td>
								<td scope="col"><i style={{ cursor: 'pointer' }} onClick={() => { set_active_url(global.config.onboarding_path + `/?form_id=${single_invite.id}`); set_active_item(single_invite); toggle() }} className='fas fa-qrcode'></i></td>
							</tr>
						)) : ''
					}
				</tbody>
			</Table>
		</React.Fragment>
	)
}

export default PendingInvites