import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import ReduxToastr from 'react-redux-toastr';
import './overriden_styles.css'
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import "./assets/css/argon-dashboard-react.css";

import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";
// import { confirmAlert } from 'react-confirm-alert';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './config';
import store from './store';
// import DeleteNotification from "./components/DeleteNotification";

Sentry.init({
	dsn: "https://fc3b93a73762e4cdf849e171ff8f55be@o932499.ingest.sentry.io/4505837868810240",
	environment: global.config.sentry_environment,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 0.5,
	integrations: [new Integrations.BrowserTracing(), 
				  new Sentry.Replay({
					  maskAllText: true,
						blockAllMedia: true,
				  })],
  //   routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
});



ReactDOM.render(
	<Provider store={store}>
	  	<BrowserRouter>
		    <Switch>
		      	<Route path="/home" render={props => <AdminLayout {...props}/>} />
		      	<Route path="/login" render={props => <AuthLayout {...props} page='login' />} />
		      	<Route path="/signup" render={props => <AuthLayout {...props} page='signup' />} />
		      	<Redirect from="/" to={window.location.origin.includes('bdp') ? "/home/onboarding" : "/home/paid_orgs"} />
		    </Switch>    
	  	</BrowserRouter>
      	<ReduxToastr position="bottom-right" timeOut={4000}/>
	</Provider>,
  document.getElementById("root")
);
