import React , { useState, useEffect } from 'react';
import {FormGroup, Button, Form,Modal, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, ModalHeader, } from "reactstrap";

const DeleteNotification = ({yes_del, no_del, text, onClose, button_name, open}) => {

	return(

		  	<div className='custom-ui'>
				<Modal isOpen={true} className='modal-dialog-centered'>
					<div className="modal-header  bg-gradient-white">
						<h4 className="modal-title" id="modal-title-notification">
							<span style={{color:'#627386'}}>Your attention is required</span>
						</h4>
						<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={()=>{no_del(); onClose()}}>
							<span aria-hidden={true}>×</span>
						</button>

					</div>
					<div className="modal-body bg-gradient-secondary">
						<div className="py-3 text-center">
							<h4 style={{color:'#627386', fontSize:'18px'}} className="">{text?text:'Are you sure you want to proceed?'}</h4>
						</div>
					</div>
					<div className="modal-footer" style={{padding:'10px'}}>
						<Button className="white_button" type="button" onClick={()=>{if(no_del) no_del(); onClose()}}>
							Close
						</Button>
						<Button id='delete_notification_button' color='primary' type="button" onClick = {()=>{yes_del(); onClose()}}>
							{button_name?button_name:'Proceed'}
						</Button>
					</div>
				</Modal>
            </div>

	); 
}

export default DeleteNotification ;