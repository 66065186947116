import { useState, useEffect, useRef } from 'react';

export function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // mutable ref to store current callback

    const setStateCallback = (state, cb) => {
        cbRef.current = cb; // store passed callback to ref
        setState(state);
    };

    useEffect(() => {
        if (cbRef.current) {
            var temp = cbRef.current;
            cbRef.current = null; // reset callback after execution
            temp(state);
        }

    }, [state]);

    return [state, setStateCallback];
}

export default useStateCallback;