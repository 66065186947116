import React, {useState, useEffect} from "react";
import {toastr} from 'react-redux-toastr';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, Modal, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardHeader, CardFooter, Col, Table, Row, } from "reactstrap";


const Logs = ({store_id}) => {
    return(
        <div>Logs</div>
    )
}

export default Logs