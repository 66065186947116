import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';

// 3rd party components
import {Modal, FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Stepper from 'react-stepper-horizontal';
import Moment from 'react-moment';
import ReactDatetime from "react-datetime";

// core components
import Header from "../../components/Headers/Header.jsx";
import UsageDisplay from "../../components/UsageDisplay";

import {get_usage, get_user_details} from '../../actions';
import {get_investment_status, create_investment, update_investment, delete_investment} from './actions';

import './style.css';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

class Home extends React.Component {
  
  constructor(props) {
      super(props);
      var now = new Date();
      this.state = {
          orders: null,
          active_store: null,
          show_usage_modal: false,
          selected_date: months[now.getMonth()] + ', ' + now.getFullYear(),
      };
  }
  
  componentWillMount() {
      this.refresh_data();      
  }


  refresh_data = () => { 
      var get_usage_data = Promise.resolve();
      if (!this.props.usage && !this.props.fetching_usage_data) {
          // get_usage_data = this.props.get_usage()
      }
      get_usage_data
      .then(() => {
          this.setState({usage: this.props.usage});
      })
  }

  update_inputs = (event) => {
      console.log(this.state);
      this.setState({[event.target.name]: event.target.value})
  }

  toggle_usage_modal = active_store => {
      var show_usage_modal = true;
      console.log(this.state)
      if (this.state.show_usage_modal) {
          show_usage_modal = false;
      }
      this.setState({show_usage_modal, active_store})
  }

  render() {
    const styles = {
      header_row: {
        marginLeft: 0,
      },
      table_header: {
        fontWeight: "bold",
        fontSize: 'small'
      },
      round_status: {
        fontSize: 'small',
        paddingBottom: 0
      },
      nav_pills_rounds: {
        paddingRight: 60,
        marginRight: "0.7rem",
        marginLeft: "0.2rem",
      },
      action_button: {
        margin: 1       
      }
    }; 
    
    const success_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-success" />
        completed
      </Badge>
    );

    const warning_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-warning" />
        pending
      </Badge>
    );

    const rupee_symbol = <>&#8377; </>

    const investment_table = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>Store ID</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Name</b></span> <br /></th>
            <th scope="col"><span style={styles.table_header}><b>Type</b></span> <br /></th>
            <th scope="col"><span style={styles.table_header}><b>Due</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Status</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Billing</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Details</b></span><br /></th>
          </tr>
        </thead>
        <tbody>
            {this.props.stores && this.state.usage && Object.keys(this.state.usage).length && Object.keys(this.state.usage).map(store => (
                <tr>
                    <td>{store}</td>
                    <td>{this.props.stores.filter(x => x.id == store)[0].name}</td>
                    <td>{this.props.stores.filter(x => x.id == store)[0].type}</td>
                    <td>{this.state.usage[store][this.state.selected_date].licenses.reduce((final, elem) => final + elem.indian_price, 0)}</td>
                    <td>Not Billed</td>
                    <td><Button className="btn-sm" color="primary">Generate Order</Button></td>
                    <td><Button className="btn-sm" color="primary" onClick={() => this.toggle_usage_modal(store)}>View</Button></td>
                </tr>
            ))}
        </tbody>
      </Table>
    )


    const investment_filler = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>Fetching all orders </b></span><br/> Please wait...<br /> &nbsp;</th>
          </tr>
        </thead>
      </Table>
    )


    const usage_details_modal = (
        <Modal style={{maxWidth: "70vw"}} className="modal-dialog-centered" size="lg" isOpen={this.state.show_usage_modal} toggle={() => this.toggle_usage_modal()}>
          <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel"> Postpaid Usage - {this.state.active_store} </h4>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggle_usage_modal()}>
                <span aria-hidden={true}>×</span>
              </button>
          </div>
          <div className="modal-body" style={{minHeight: "50vh"}}>
              <br />
              <UsageDisplay store_id = {this.state.active_store ? this.state.active_store.id :''} usage = {this.state.usage ? this.state.usage[this.state.active_store] : []} market="indian"/>
          </div>
        </Modal>
    )


    return (
      <>
        <Header />
        {/* Page content */}
        
        <Container className="mt--8" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row style={styles.header_row}>
                    <Col xs="8" style={{paddingLeft: 0}}>
                        <b>Month wise postpaid usage</b>
                    </Col>
                    <Col xs="4" className="mt--2 mb--2">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime onChange={d => this.setState({selected_date: months[d.month()] + ', ' + d.year()})} value={this.state.selected_date} inputProps={{placeholder: "Month Wise Usage"}} dateFormat="YYYY-MM" timeFormat={false}/>
                      </InputGroup>
                    </Col>
                  </Row>
                </CardHeader>
                {this.state.usage ? investment_table : investment_filler}           
                {this.state.show_usage_modal && usage_details_modal}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}


const mapStateToProps = (state, own_props) => ({
    usage: state.usage,
    stores: state.stores,
    user_details: state.user_details,
    fetching_usage_details: state.fetching_usage_details,
    fetching_store_details: state.fetching_store_details,
    ...own_props
})


const mapDispatchToProps = dispatch => ({
    get_usage: () =>  dispatch(get_usage()),
    get_user_details: () =>  dispatch(get_user_details()),
    get_investment_status: () =>  dispatch(get_investment_status()),
    create_investment: data =>  dispatch(create_investment(data)),
    update_investment: data =>  dispatch(update_investment(data)),
    delete_investment: data =>  dispatch(delete_investment(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);