import generic_sort_for_orgs from "./generic_sort_for_orgs"

const general_sort_for_orgs = (column_name, last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value) => {
	console.log('last_3_sorted_columns', last_3_sorted_columns)
	let order = 1
	if(last_3_sorted_columns.find(o => o.column_name === column_name)){
		if(last_3_sorted_columns.find(o => o.column_name === column_name).order === 'asc'){
			order = -1
		}else{
			order = 1
		}
	}
	let sorted_stores = generic_sort_for_orgs(column_name, stores, order, sorting_function_value)
	if(last_3_sorted_columns.find(o => o.column_name === column_name)){
		let column = last_3_sorted_columns.find(o => o.column_name === column_name)
		if(column.order === 'asc'){
			column.order = 'desc'
		}else{
			column.order = 'asc'
		}
		let temp_array = last_3_sorted_columns.filter(o => o.column_name != column_name)
		temp_array.push(column)
		set_last_3_sorted_columns(temp_array)
	}else if(last_3_sorted_columns.length<3){
		set_last_3_sorted_columns([...last_3_sorted_columns, {column_name, order:'asc'}])
	}else{
		let temp_last_3_sorted_columns = [...last_3_sorted_columns]
		temp_last_3_sorted_columns.splice(0, 1)
		set_last_3_sorted_columns([...temp_last_3_sorted_columns, {column_name, order:'asc'}])
	}
	sorted_stores = sorted_stores.map((x, idx) => ({...x, idx: idx+1}));
	set_stores(sorted_stores)
}

export default general_sort_for_orgs