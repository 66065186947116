import React, { useState, useEffect, useMemo, useCallback } from "react";
import ISearch from "../../components/ISearch";
import { Button, Col, Input, Row, Nav, NavItem, NavLink } from "reactstrap";
import general_fetch from "../../utils/fetch";
import { date_string } from "../../utils/date_string";
import { convert_to_readable } from "../../utils/convert_to_readable";
import { EditBackOffficeTicket, department_options, check_backoffice_ticket_edit_access } from "./EditBackOffficeTicket";
import AddBackOfficeTicket from "./AddBackOfficeTicket";
import MergeIcon from '@mui/icons-material/Merge';
import { Badge, Checkbox, Button as AntdButton, DatePicker, Radio, ConfigProvider } from 'antd';
import { colourStyles } from "../../utils/react_select_styles";
import Select from 'react-select';
import generic_sort from "../../utils/generic_sort";
import { bo_tickets_status_options } from "../../utils/bo_tickets_status_options";
import { impact_area_options } from "../../utils/cs_ticket_impact_area";
import { toastr } from "react-redux-toastr";
import DeleteNotification from "../../components/DeleteNotification";
import { confirmAlert } from "react-confirm-alert";
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated()

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
		title: 'Confirm to submit',
		message: message,
		customUI: ({ onClose }) => {
			return (
				Custom_modal ?
					<div>
						<Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
					</div>
					:
					<div className='custom-ui'>
						<DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
					</div>
			);
		}
	});
}


const sort_by_options = [{value:'none', label:'None'}, 
						{value:'created_at_low_to_high', label:'Created At (Early first)'},
						{value:'created_at_high_to_low', label:'Created At (Latest first)'}, 
						{value:'priority_low_to_high', label:'Priority (Low to High)'}, 
						{value:'priority_high_to_low', label:'Priority (High to Low)'}, 
						{value:'customer_priority_low_to_high', label:'Customer Priority (Low to High)'}, 
						{value:'customer_priority_high_to_low', label:'Customer Priority (High to Low)'}]

const get_cs_org_user_map = (cs_org_users) => {
    let cs_org_user_map = {};
    for(let user of cs_org_users) {
        cs_org_user_map[user.id] = user;
    }
    return cs_org_user_map;
}

const BackOfficeTickets = ({cs_org_users, set_page_loader, all_stores, link_mode, link_mode_department, back_office_ticket_id_for_linking, set_back_office_ticket_id_for_linking, user_details}) => {

	const [back_office_tickets, set_back_office_tickets] = useState([]);
    const [customer_tickets, set_customer_tickets] = useState([]);
	const [display_back_office_tickets, set_display_back_office_tickets] = useState([]);
	const [search_string, set_search_string] = useState('');
	const [initialized, set_initialized] = useState(false);
	const [show_edit_drawer, set_show_edit_drawer] = useState(false);
	const [show_add_edit_back_office_ticket, set_show_add_edit_back_office_ticket] = useState(false);
	const [selected_ticket, set_selected_ticket] = useState();
	const [description_expand_json, set_description_expand_json] = useState({});
	const [sort_by, set_sort_by] = useState('created_at_low_to_high');
    const [status_filter, set_status_filter] = useState([
        // {value:'todo', label:'No Action Yet'},
        // {value:'jira_added', label:'Jira Added'},
        // {value:'in_progress', label:'Jira in Progress'},
        // {value:'jira_done', label:'Jira Done'},
    ]);
	const [type_filter, set_type_filter] = useState([]);
	const [assignee_filter, set_assignee_filter] = useState([]);
    const [dependent_team_filter, set_dependent_team_filter] = useState(null);
    const [date_filter, set_date_filter] = useState([]);
    const [gchat_post_filter, set_gchat_post_filter] = useState(null);
    const [linked_jira_filter, set_linked_jira_filter] = useState(null);
    const [customer_priority_filter, set_customer_priority_filter] = useState();
    const [priority_filter, set_priority_filter] = useState();
    const [following_ticket_filter, set_following_ticket_filter] = useState([]);
    const [impact_area_filter, set_impact_area_filter] = useState([]);
	const [selected_bo_tickets, set_selected_bo_tickets] = useState([]);
	const [selection_mode, set_selection_mode] = useState(false);
    const [back_office_ticket_assignees, set_back_office_ticket_assignees] = useState([]);
    const [show_archive_only_ticket, set_show_archive_only_ticket] = useState(false);
    const [total_viewing_ticket_count, set_total_viewing_ticket_count] = useState(0);

    const filter_archived = (tickets) => {
        let t = show_archive_only_ticket ? tickets.filter(x => x.archived) : tickets.filter(x => !x.archived);
        return t;
    }

	const update_selected_bo_tickets = (id) => {
		if(selected_bo_tickets.includes(id)) {
			set_selected_bo_tickets(selected_bo_tickets.filter(x => x!=id))
		}else{
			set_selected_bo_tickets([...selected_bo_tickets, id])
		}
	}

	const fetch_back_office_tickets = async() => {
		try{
			let user_department 
			if(user_details && user_details.role && user_details.role.store && user_details.role.store.length){
				if(user_details.role.store.includes('customer_success_admin')){
					user_department = 'all'
				}else if(user_details.role.store.includes('cs_rep')){
					user_department = 'customer_success'
				}else if(user_details.role.store.includes('infurnia_product_manager')){
					user_department = 'product'
				}else if(user_details.role.store.includes('infurnia_catalog_admin')){
					user_department = 'catalogue'
				}
			}
			let body = {department: user_department}
			if(link_mode) body['department'] = link_mode_department
			if(!body.department){
				set_initialized(true)
				return
			}
			let [resp, customer_tickets] = await Promise.all([
                general_fetch({url:'back_office_ticket/get_by_department', body}),
                general_fetch({url:'customer_ticket/get_all', body}),
            ])
			set_back_office_tickets(resp)
            customer_tickets = customer_tickets.filter(x => !x.archived)
            set_customer_tickets(customer_tickets)
			// set_display_back_office_tickets(filter_archived(resp))
			set_initialized(true)
		}catch(err){
			console.error(err)
			toastr.error(`Couldn't get tickets`)
		}
	}

	const update_status = async(back_office_ticket_id, status) => {
		try{
			let resp = await general_fetch({url:'back_office_ticket/update', body:{back_office_ticket_id, status}})			
			toastr.success('Successfully updated ticket status')
			fetch_back_office_tickets()
		}catch(err){
			console.error(err)
			toastr.error(`Couldn't update ticket status`)
		}
	}

    const update_archive = async (back_office_ticket_id, archive_status) => {
        try {
            let resp = await general_fetch({url: 'back_office_ticket/update', body: {back_office_ticket_id, archived: archive_status}});
            fetch_back_office_tickets()
            toastr.success(`Successfully ${archive_status ? '' : 'un-'}archived the ticket`);
        } catch(err) {
            toastr.error(`Couldn't ${archive_status ? '' : 'un-'}archive the ticket`);
            console.error(err);
        }
    }

    const delete_back_office_ticket = async (back_office_ticket_id) => {
        try {
            let resp = await general_fetch({url: 'back_office_ticket/delete_ticket', body: {back_office_ticket_id}});
            fetch_back_office_tickets()
            toastr.success(`Successfully deleted the ticket`);
        } catch(err) {
            toastr.error(`Couldn't delete the ticket`);
            console.error(err);
        }
    }

	const merge_tickets = async() => {
		try{
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({url:'back_office_ticket/merge', body:{back_office_ticket_ids: selected_bo_tickets}})			
			set_page_loader(false)
			toastr.success('Successfully merged tickets')
			fetch_back_office_tickets()
			set_selected_bo_tickets([])
		}catch(err){
			console.error(err)
			toastr.error(`Couldn't merge tickets`)
		}
	}

    const follow_ticket = async ({back_office_ticket_id}) => {
		try{
			let resp = await general_fetch({url:'back_office_ticket/follow_ticket', body:{back_office_ticket_id, follower_id: user_details.id}});
			toastr.success('Successfully followed ticket')
			fetch_back_office_tickets()
		}catch(err){
			console.error(err)
			toastr.error(`Couldn't follow ticket`)
		}
    }

    const unfollow_ticket = async ({back_office_ticket_id}) => {
		try{
			let resp = await general_fetch({url:'back_office_ticket/unfollow_ticket', body:{back_office_ticket_id, follower_id: user_details.id}});
			toastr.success('Successfully unfollowed ticket')
			fetch_back_office_tickets()
		}catch(err){
			console.error(err)
			toastr.error(`Couldn't unfollow ticket`)
		}
    }

	const filter_fn = () => {
		let temp_tickets = JSON.parse(JSON.stringify(back_office_tickets));
        temp_tickets = filter_archived(temp_tickets);
        set_total_viewing_ticket_count(temp_tickets.length);

		if(assignee_filter && assignee_filter.length){
			let assignee_ids = assignee_filter.map(x => x.value)
			temp_tickets = temp_tickets.filter(x => assignee_ids.includes(x.assignee_id))
		}

		if(search_string){
			temp_tickets = temp_tickets.filter(x => (x.title && x.title.toLowerCase().includes(search_string.toLowerCase())))
		}
		
		if(type_filter && type_filter.length){
			let types = type_filter.map(x => x.value)
			temp_tickets = temp_tickets.filter(x => types.includes(x.type))
		}
		
		if(status_filter && status_filter.length){
			let statuses = status_filter.map(x => x.value)
			temp_tickets = temp_tickets.filter(x => statuses.includes(x.status))
		}

        if(dependent_team_filter && dependent_team_filter.length) {
            let teams = dependent_team_filter.map(x => x.value)
            temp_tickets = temp_tickets.filter(x => teams.includes(x.department))
        }

        if(date_filter && date_filter.length && date_filter[0] && date_filter[1]) {
			temp_tickets = temp_tickets.filter(x => 
                new Date(x.created_at) > new Date(date_filter[0]) && new Date(x.created_at) < new Date(date_filter[1])
            )
        }

        if(gchat_post_filter) {
            let value = gchat_post_filter.value;
            if(value == "escalated") {
                temp_tickets = temp_tickets.filter(x => x.customer_tickets.some(y => y.cs_team_escalations_thread));
            } else if(value == "doubt") {
                temp_tickets = temp_tickets.filter(x => x.customer_tickets.some(y => y.cs_team_doubts_thread));
            } else if(value == "none") {
                temp_tickets = temp_tickets.filter(x => !x.customer_tickets.some(y => y.cs_team_escalations_thread)).filter(x => !x.customer_tickets.some(y => y.cs_team_doubts_thread));
            }
        }

        if(linked_jira_filter && linked_jira_filter.length) {
            let jira_status = linked_jira_filter.map(x => x.value)
            temp_tickets = temp_tickets.filter(x => jira_status.includes(x.jira_id != null ? 1 : 0))
        }

        if(customer_priority_filter && customer_priority_filter.length) {
            let values = customer_priority_filter.map(x => x.value)
            temp_tickets = temp_tickets.filter(x => values.includes(x.customer_priority))
        }

        if(priority_filter && priority_filter.length) {
            let values = priority_filter.map(x => x.value)
            temp_tickets = temp_tickets.filter(x => values.includes(x.priority))
        }

        if(following_ticket_filter && following_ticket_filter.length) {
            let values = following_ticket_filter.map(x => x.value)
            temp_tickets = temp_tickets.filter(x => values.includes(x.followers && x.followers.includes(user_details.id) ? 1 : 0))
        }

        if(impact_area_filter && impact_area_filter.length) {
            let values = impact_area_filter.map(x => x.value)
            temp_tickets = temp_tickets.filter(x => values.filter(z => (x.customer_tickets.map(y => y.impact_area)).includes(z)).length != 0)
        }
        
		sort_fn(temp_tickets)
	}

	const sort_fn = (tickets) => {
		let temp_tickets = tickets ? tickets : JSON.parse(JSON.stringify(display_back_office_tickets))
		let null_tickets = []
		let field = null
		let order = 1
		if(sort_by.includes('customer_priority')){
			field = 'customer_priority'
		}else if(sort_by.includes('priority')){
			field = 'priority'
		}else if(sort_by.includes('created_at')){
			field = 'created_at'
		}
        if(field) {
            if(sort_by.includes('low_to_high')) order = -1
            if(field === 'priority' || field === 'customer_priority'){
                null_tickets = temp_tickets.filter(x => !x[field])
                temp_tickets = temp_tickets.filter(x => x[field])
            }
            if(field != "created_at") {
                temp_tickets.sort((a,b) => generic_sort(a, b, field, order, 'number'))
            } else {
                temp_tickets.sort((a,b) => generic_sort(a, b, field, order, 'date'))
            }
            temp_tickets = [...temp_tickets, ...null_tickets]
        }
		set_display_back_office_tickets(temp_tickets)
	}

	const onChangeDate = (date, dateString) => {
        set_date_filter(dateString)
		// document.getElementById('filter_date').click()
	}

	useEffect(() => {
		if((user_details && user_details.id) || link_mode) fetch_back_office_tickets()	
	}, [user_details, link_mode]);

	useEffect(() => {
		if(document.getElementById('back_office_tickets_header') && document.getElementById('back_office_tickets_table')){
			document.getElementById('back_office_tickets_table').style.height = window.innerHeight - document.getElementById('back_office_tickets_header').offsetHeight + 'px'
		}
		let currentURL = new URL(document.URL)
		if(currentURL.searchParams.get('id')){
			set_show_edit_drawer(true)
            let id = currentURL.searchParams.get('id');
            // ideally param id should accurately equal to correct id, but we try to trim it as well to prevent any misinputs
            id = id.trim();
            id = id.split(' ')[0]
            id = id.substr(0, 16);
			set_selected_ticket({id: currentURL.searchParams.get('id')})
		}
	}, []);

	useEffect(() => {
		if(display_back_office_tickets && display_back_office_tickets.length) sort_fn()
	}, [sort_by]);

	useEffect(() => {
		filter_fn()
	}, [back_office_tickets, search_string, status_filter, type_filter, assignee_filter, dependent_team_filter, date_filter, gchat_post_filter, linked_jira_filter, show_archive_only_ticket, customer_priority_filter, priority_filter, following_ticket_filter, impact_area_filter]);

	useEffect(() => {
		console.log('search_string', selected_bo_tickets)
	}, [selected_bo_tickets]);

	useEffect(() => {
		if(link_mode) set_status_filter([])
	}, [link_mode]);

	useEffect(() => {
		let currentURL = new URL(document.URL)
		if(show_edit_drawer && selected_ticket && currentURL.searchParams.get('id')!=selected_ticket.id){
			currentURL.searchParams.set('id', selected_ticket.id)
			let updatedSearchString = currentURL.searchParams.toString();
			let newUrl = window.location.pathname + '?' + updatedSearchString;
			window.history.pushState({ path: newUrl }, '', newUrl);
		}else if(!show_edit_drawer){
			let newUrl = window.location.pathname ;
			window.history.pushState({ path: newUrl }, '', newUrl);
		}
	}, [show_edit_drawer, selected_ticket]);

    useEffect(() => {
        if(cs_org_users && cs_org_users.length && back_office_tickets && back_office_tickets.length) {
            let cs_user_map = get_cs_org_user_map(cs_org_users);
            let assignee_ids = Array.from(new Set(back_office_tickets.map(x => x.assignee_id)));
            let assignees = assignee_ids.map(x => cs_user_map[x]).filter(x => x);
            assignees.push({id: null, first_name: "None"});
            set_back_office_ticket_assignees(assignees);
        }
    }, [cs_org_users, back_office_tickets])

	return(
		<div>
        <ConfigProvider theme={{token: {colorPrimary: '#5e72e4'}}}>
			<div id='back_office_tickets_header'>
				<div className="flex_center px-4 pt-3" style={{justifyContent:'space-between'}}>
					<div>
                        <div className="ml-4 flex_property">
                            <Radio.Group size="medium" options={[{label: 'Active', value: false}, {label: 'Archived', value: true}]} value={show_archive_only_ticket} onChange={({target: {value}}) => {set_show_archive_only_ticket(value)}} optionType="button" buttonStyle="solid" />
                            <div style={{ fontSize: '14px', color: 'purple' }} className="ml-4">Viewing {display_back_office_tickets.length} of {total_viewing_ticket_count} Tickets</div>
                        </div>
                    </div>
					<div className="flex_center">
						<ISearch search_text={search_string} set_search_text={set_search_string}/>
						<Button color="primary" onClick={() => {set_show_add_edit_back_office_ticket(true); }}>Create Ticket</Button>
					</div>
				</div>
			</div>
			<div className="mx-2 px-4 pt-4" id='back_office_tickets_table' style={{overflow:'auto', position:'relative'}}>
				<div className="flex_between mb-3" style={{fontSize:'14px'}}>
					<div className="flex_center">
						<div className="mr-2" style={{fontSize:'13px'}}>Status:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={status_filter}
								isMulti
								options={bo_tickets_status_options}
								styles={colourStyles}
								onChange={(selectedOptions) => set_status_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Type:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={type_filter}
								isMulti
								options={[{value:'bug', label:'Bug'}, {value:'feature', label:'Feature'}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_type_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Assignee:</div>
						<div style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={assignee_filter}
								isMulti
								options={cs_org_users && cs_org_users.length ? back_office_ticket_assignees.map(x => {return {value:x.id, label:x.first_name}}) : []}
								styles={colourStyles}
								onChange={(selectedOptions) => set_assignee_filter(selectedOptions)}
							/>
						</div>
					</div>
					{
						link_mode ? '' :
						<>
							<div className="flex_center">
							{selection_mode ? <i class="fas fa-arrow-alt-circle-left mr-2 cp" style={{fontSize:'20px'}} onClick={() => {set_selection_mode(false); set_selected_bo_tickets([])}}/> : ''}
							{
								selected_bo_tickets && selected_bo_tickets.length>1 ? 
								<AntdButton className="flex_center mr-3 px-2" onClick={() => submit(merge_tickets, () => { }, `This action will merge the selected ${selected_bo_tickets.length} tickets into a single ticket`, '', '')} >
									<MergeIcon className='mr-1' style={{fontSize:'20px'}}/>
									Merge Selected Tickets
								</AntdButton>: (!selection_mode && display_back_office_tickets && display_back_office_tickets.length>1 ?<AntdButton className="flex_center mr-3 px-2" onClick={() => set_selection_mode(true)} >Select</AntdButton>:'')
							}
								<div className="mr-2" style={{fontSize:'13px'}}>Sort by:</div>
								<div style={{fontSize:'12px'}}>
									<Select
										// closeMenuOnSelect={false}
										// components={animatedComponents}
										value={{value:sort_by, label:sort_by_options.find(o => o.value === sort_by) ? sort_by_options.find(o => o.value === sort_by).label : ''}}
										// isMulti
										options={sort_by_options}
										styles={colourStyles}
										onChange={(selectedOptions) => set_sort_by(selectedOptions.value)}
									/>
								</div>
						</div>
						</>
					}
				</div>
				<div className="flex_between mb-3" style={{fontSize:'14px', position: 'relative', zIndex: 2}}>
					<div className="flex_center">
                            {
                                // <>
                                //     <div className="mr-2" style={{fontSize:'13px'}}>Team:</div>
                                //     <div className="mr-2" style={{fontSize:'12px'}}>
                                //         <Select
                                //             // closeMenuOnSelect={false}
                                //             // components={animatedComponents}
                                //             value={dependent_team_filter}
                                //             isMulti
                                //             options={department_options.map(x => {return {value:x.value, label:x.label}})}
                                //             styles={colourStyles}
                                //             onChange={(selectedOptions) => set_dependent_team_filter(selectedOptions)}
                                //         />
                                //     </div>
                                // </>
                            }
                        <div className="mr-2" style={{fontSize:'13px'}}>Date:</div> 
                        <div className="mr-2" style={{fontSize:'12px'}}>
                            <DatePicker.RangePicker
								id = 'filter_date'
                                format="YYYY-MM-DD"
                                onChange = {onChangeDate}
                                // defaultValue = {from_date}
                                // disabledDate={(d) => new Date(d) < new Date(today)}
                            />
                        </div>
						<div className="mr-2" style={{fontSize:'13px'}}>Linked Jira:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={linked_jira_filter}
								isMulti
								options={[{value: 1, label: "Yes"}, {value: 0, label: "No"}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_linked_jira_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Escalation Status:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={gchat_post_filter}
								// isMulti
								options={[{value: 'escalated', label: 'Escalated'}, {value: 'doubt', label: 'Discussed'}, {value: 'none', label: 'None'}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_gchat_post_filter(selectedOptions)}
							/>
						</div>
					</div>
				</div>
				<div className="flex_between mb-3" style={{fontSize:'14px', position: 'relative', zIndex: 1}}>
					<div className="flex_center">
						<div className="mr-2" style={{fontSize:'13px'}}>Customer Priority:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={customer_priority_filter}
								isMulti
								options={[{value: 1, label: 1}, {value: 2, label: 2}, {value: 3, label: 3},{value: 4, label: 4},{value: 5, label: 5}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_customer_priority_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Priority:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={priority_filter}
								isMulti
								options={[{value: 1, label: 1}, {value: 2, label: 2}, {value: 3, label: 3},{value: 4, label: 4},{value: 5, label: 5}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_priority_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Following:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={following_ticket_filter}
								isMulti
								options={[{value: 1, label: "Yes"}, {value: 0, label: 'No'}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_following_ticket_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Impact area:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={impact_area_filter}
								isMulti
								options={impact_area_options.map(x => x.options).flat()}
								styles={colourStyles}
								onChange={(selectedOptions) => set_impact_area_filter(selectedOptions)}
							/>
						</div>
					</div>
				</div>
			{
				display_back_office_tickets && display_back_office_tickets.length ? display_back_office_tickets.map((single_ticket, idx) => (
					<Badge.Ribbon placement="end" text={single_ticket.type === 'bug' ? 'Bug' : (single_ticket.type === 'feature' ? 'Feature' : '')} style={{fontSize:'12px', display:!['bug','feature'].includes(single_ticket.type)?'none':''}} color={single_ticket.type === 'bug' ? 'red' : (single_ticket.type === 'feature' ? 'green' : '')}>
						<div key={single_ticket.id} className="ticket_div activity_box mb-4" style={{padding:'0px'}} >
							<div style={{padding:"18px 18px 10px 18px"}}>
								<div className="flex_between mb-2" style={{fontSize:'16px'}}>
									<div className="flex_property mr-2">
										{
											link_mode?
											<Checkbox className="mr-2" onClick={(e) => {e.stopPropagation(); set_back_office_ticket_id_for_linking(single_ticket.id)}} checked={back_office_ticket_id_for_linking==single_ticket.id} />:
											<div className="flex_property">
											{
												selection_mode ?
												<Checkbox className="mr-2" onClick={(e) => {e.stopPropagation(); update_selected_bo_tickets(single_ticket.id)}} checked={selected_bo_tickets && selected_bo_tickets.length && selected_bo_tickets.includes(single_ticket.id)} />
												:
												<i className="fa fa-ticket mr-2" style={{fontSize:'20px', color:'#A799B7'}} />
											}
											</div>
										}
										<div className="cp tickets_title" onClick={() => {set_show_edit_drawer(true); set_selected_ticket(single_ticket)}}>{single_ticket.title}</div>
									</div>
                                    <div className="flex_property mr-4">
                                        {
                                            single_ticket && !single_ticket.followers.includes(user_details.id) ?
                                                <div>
                                                    <AntdButton type="link" className="flex_property archive" onClick={(e) => {submit(()=> follow_ticket({back_office_ticket_id: single_ticket.id}), ()=>{}, `Follow the ticket to receive updates on Google Chat`, '', '')}}>
                                                        <i className="fas fa-solid fa-eye" style={{ fontSize: '16px', color: '#32325d' }}/>
                                                    </AntdButton>
                                                </div>:''
                                        }
                                        {
                                            single_ticket && single_ticket.followers.includes(user_details.id) ?
                                                <div>
                                                    <AntdButton type="link" className="flex_property archive" onClick={(e) => {submit(()=> unfollow_ticket({back_office_ticket_id: single_ticket.id}), ()=>{}, `Un-Follow the ticket`, '', '')}}>
                                                        <i className="fas fa-solid fa-eye-slash" style={{ fontSize: '16px', color: '#32325d' }}/>
                                                    </AntdButton>
                                                </div>:''
                                        }
                                        {
                                            single_ticket && check_backoffice_ticket_edit_access({user_details, selected_ticket: single_ticket}) && !single_ticket.archived ?
                                                <div>
                                                    <AntdButton type="link" className="flex_property archive" onClick={(e) => {submit(()=> update_archive(single_ticket.id, true), ()=>{}, `This action will archive the Product Ticket`, '', '')}}>
                                                        <i className="fas fa-solid fa-archive" style={{ fontSize: '16px', color: '#32325d' }}/>
                                                    </AntdButton>
                                                </div>:''
                                        }
                                        {
                                            single_ticket && check_backoffice_ticket_edit_access({user_details, selected_ticket: single_ticket}) && single_ticket.archived ?
                                                <div>
                                                    <AntdButton type="link" className="flex_property archive" onClick={(e) => {submit(()=> update_archive(single_ticket.id, false), ()=>{}, `This action will un-archive the Product Ticket`, '', '')}}>
                                                        <i className="fas fa-solid fa-window-restore" style={{ fontSize: '16px', color: '#32325d' }}/>
                                                    </AntdButton>
                                                </div>:''
                                        }
                                        {
                                            single_ticket && check_backoffice_ticket_edit_access({user_details, selected_ticket: single_ticket}) ?
                                                <div>
                                                    <AntdButton type="link" className="flex_property" onClick={(e) => {submit(()=> delete_back_office_ticket(single_ticket.id), ()=>{}, `This action will Delete the Product Ticket`, '', '')}}>
                                                        <i className="fas fa-solid fa-trash" style={{ fontSize: '16px', color: '#f02222' }}/>
                                                    </AntdButton>
                                                </div>:''
                                        }
                                    </div>
								</div>
								<div style={{fontSize:'12px', marginLeft:'28px'}} className="flex_between">
									<div className="flex_property">
										{
											single_ticket.jira_key?
											<div className="mr-2 flex_property" onClick={(e) => e.stopPropagation()}>
												<a href={`https://infurniacoretech.atlassian.net/jira/software/c/projects/PT/issues/${single_ticket.jira_key}`} target="_blank" className="mr-2 flex_property" style={{textDecoration:'underline', color:'#0078ff'}}><img src='/jira.svg' height={'14px'} width={'14px'} className="mr-1"/><div>{single_ticket.jira_key}</div></a>
												|
											</div>:""
										}
										{
											single_ticket.priority?
											<div className="mr-2 flex_property">
												<div className="mr-2">
												Priority - {single_ticket.priority}
												</div>
												|
											</div>:""
										}
										{
											single_ticket.customer_priority?
											<div className="mr-2 flex_property">
												<div className="mr-2">
													Customer Priority - {single_ticket.customer_priority}
												</div>
												|
											</div>:""
										}
                                        <div className="mr-2 flex_property">
                                            <div className="mr-2" style={{opacity:'0.5'}}>
                                                <i style={{color:''}} className="fa fa-history mr-1" />
                                                Created on {date_string(single_ticket.created_at)}
                                            </div>
                                            |
                                        </div>
										<div style={{opacity:'0.5'}}>
											<i style={{color:''}} className="fa fa-history mr-1" />
											Updated on {date_string(single_ticket.updated_at)}
										</div>
									</div>
									
								</div>
							</div>
							<hr className="my-0"/>
							<div className="cp flex_between" style={{fontSize:'13px', borderRadius:'6px', padding:'6px 10px 6px 18px',}} onClick={(e) => {e.stopPropagation(); description_expand_json[single_ticket.id] = !description_expand_json[single_ticket.id]; set_description_expand_json(JSON.parse(JSON.stringify(description_expand_json)))}}>
								<div>
									<i className="far fa-building mr-1" />{convert_to_readable(single_ticket.department)}
								</div>
								{/* <div className="flex_center" style={{color:single_ticket.type==='bug'?'red':(single_ticket.type==='feature'?'green':'')}}>
									{
										single_ticket.type==='bug'?
										<BugOutlined className="mr-1"/>:(
											single_ticket.type==='feature'?
											<StarOutlined className="mr-1" />:
											<RobotOutlined className="mr-1"/>
										)
									}
									{single_ticket.type ? convert_to_readable(single_ticket.type) : 'No type'}
								</div> */}
								<div>
									<i className="far fa-user mr-1" />{cs_org_users && cs_org_users.length && single_ticket.assignee_id ? cs_org_users.find(o => o.id===single_ticket.assignee_id).first_name : 'Not Assigned'}
								</div>
								<div title="Expected resolution time" className="flex_center" style={{opacity:single_ticket.expected_resolution_time?'1':'0.4'}}>
									<i className="far fa-clock mr-1" />{single_ticket.expected_resolution_time ? (single_ticket.expected_resolution_time + ' days') : 'N/A'}
								</div>
								<div>
									<Input disabled={link_mode} onClick={(e) => e.stopPropagation()} onChange={(e) => update_status(single_ticket.id, e.target.value)} value={single_ticket.status} type='select' style={{width:'150px', height:'32px', padding:'4px 12px', }}>
									{
										bo_tickets_status_options.map(x => (
											<option value={x.value}>{x.label}</option>
										))
									}
									</Input>
								</div>
							</div>
						</div>
					</Badge.Ribbon>
				)):<div className="flex_center" style={{fontWeight:700}}>{initialized ? 'No Tickets found' : 'Fetching Product Tickets...'}</div>
			}
			</div>
			<EditBackOffficeTicket
				open={show_edit_drawer}
				toggle={() => {set_show_edit_drawer(false); set_selected_ticket('')}} 
				selected_ticket_id={selected_ticket ? selected_ticket.id : ''}
				fetch_back_off_tickets={fetch_back_office_tickets}
				set_page_loader={set_page_loader}
				cs_org_users={cs_org_users}
				all_stores={all_stores}
				set_view_mode_true={link_mode}
				user_details={user_details}
				update_status = {update_status}
			/>
			<AddBackOfficeTicket
				open={show_add_edit_back_office_ticket}
				toggle={() => set_show_add_edit_back_office_ticket(false)} 
				cs_org_users={cs_org_users}
                customer_tickets={customer_tickets}
				user_details={user_details}
				set_page_loader={set_page_loader}
				fetch_back_office_tickets={fetch_back_office_tickets}
			/>
        </ConfigProvider>
		</div>
	)
}

export default BackOfficeTickets