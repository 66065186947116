import React , { useState , useEffect }from 'react';
import general_fetch from '../../utils/fetch';
import {Modal, CardImg, FormGroup, CardText, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
// import { useAlert } from 'react-alert'
import * as Sentry from "@sentry/react";
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from '../../components/ITable';
import './styles.css';

const BusinessUnitUsers = ({open, handleClose, active_business_unit, set_active_business_unit, user_json}) => {

    // const [active_bu_users, set_active_bu_users] = useState([]);
    // const alert = useAlert()

    const onclick_handleClose = () => {
        
        set_active_business_unit('')
        // set_active_bu_users([])
        handleClose()
    }

    const convert_to_role_name = (role_id) => {
        return role_id.replace('_', ' ')
    }

    useEffect(() => {
        if(open && active_business_unit && user_json){
            // set_active_bu_users(active_business_unit.user_details && active_business_unit.user_details.length ? active_business_unit.user_details.map(user => {return {role_ids: user.role_ids, name: user_json[user.id]}}) : [])
            console.log('user json', user_json)
        }
    }, [open, active_business_unit, user_json]);
    
    try{
        return(
            <Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={onclick_handleClose} >
                <Card>
                    <CardHeader className='global_modal_header'>
                        <div style={{width:'100%', display:'flex'}}>
                            <div>{('Viewing Business Unit -'+'\xa0')}</div>
                            <div className='text_ellipsis' style={{width:'calc(100% - 200px)'}}>{active_business_unit ? active_business_unit.name : ''}</div>
                        </div>
                        <i onClick={onclick_handleClose} style={{cursor: 'pointer'}} className='fa fa-times'/>
                    </CardHeader>
                    <CardBody style={{maxHeight: '600px', overflow: 'auto'}}>
                        <Col>
                            {
                                <ITable rowSeparated={true} coloumnSeparated={true} hover={true} style={{tableLayout: 'fixed'}} style_container={{maxHeight: '500px', overflow: 'auto', border: '1px solid var(--light-border-color)'}}>
                                    <colgroup>
                                        <col style={{ width: "40%" }}></col>
                                        <col style={{ width: "60%" }}></col>
                                    </colgroup>
                                    <ITableHeader style={{background: 'white', position: 'sticky', top: '0px'}}>
                                        <ITH>Name</ITH>
                                        <ITH>Roles</ITH>
                                    </ITableHeader>
                                    <ITableBody>
                                        {
                                            active_business_unit.user_details && active_business_unit.user_details.length ? active_business_unit.user_details.map(user => (
                                                <ITableRow>
                                                    <ITD>
                                                        {user_json[user.user_id]}
                                                    </ITD>
                                                    <ITD style={{overflowX: 'auto'}}>
                                                        <div className='flex_property'>
                                                            {
                                                                user.role_ids && user.role_ids.length ? user.role_ids.map(role => (
                                                                    <div className='mr-2 user_role_badge'>{convert_to_role_name(role)}</div>
                                                                ))
                                                                    : ''
                                                            }
                                                        </div>
                                                    </ITD>
                                                </ITableRow>
                                            ))
                                            :''
                                        }
                                    </ITableBody>
                                </ITable>
                                // active_business_unit.user_details && active_business_unit.user_details.length ? active_business_unit.user_details.map(user => (
                                //     <div className='mb-3 flex_property'>
                                //         <div className='mr-3'>{user_json[user.user_id]}</div>
                                //         <div className='flex_property'>
                                //             {
                                //                 user.role_ids && user.role_ids.length ? user.role_ids.map(role => (
                                //                     <div className='mr-2'>{role}</div>
                                //                 ))
                                //                 :''
                                //             }
                                //         </div>
                                //     </div>
                                // ))
                                // :''
                            }
                        </Col>
                    </CardBody>
                    <CardFooter className='global_modal_footer'>
                        <Button className='white_button' type="button" onClick={onclick_handleClose}>{'Close'}</Button>  
                    </CardFooter>
                </Card>
            </Modal>
        )
    }catch(err){
        Sentry.captureException(err)
    }
}

export default BusinessUnitUsers