import React, {useState, useEffect} from "react";
import {toastr} from 'react-redux-toastr';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, Modal, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardHeader, CardFooter, Col, Table, Row, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";
import Submit from "../../utils/Submit";
import DeleteNotification from "../DeleteNotification";
import { confirmAlert } from "react-confirm-alert";
import FindOrgsByEmail from "../FindOrgsByEmail";
import { Checkbox, DatePicker } from "antd";
import dayjs from 'dayjs';
import { CspModal, CspModalBody, CspModalFooter, CspModalHeader } from "../CspModal";
import { date_string } from "../../utils/date_string";

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
	  title: 'Confirm to submit',
	  message: message,
	  customUI: ({ onClose }) => {
		return (
		  Custom_modal ?
			<div>
			  <Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
			:
			<div className='custom-ui'>
			  <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
		);
	  }
	});
}


const generate_badge = (text, color) => (
	<Badge color={color} className="mb-0" style={{display: "inline-block", marginLeft:"auto", textTransform:'capitalize'}}><h3 style={{fontSize:'15px'}} className="mb-0">{text}</h3></Badge>
)

const RemoveOnboardingFeeModal = ({open, toggle, store_id, store_info, fetch_store_info, dues, fetch_dues, set_page_loader}) => {
	const [ selected_addons, set_selected_addons ] = useState({});
	const [ re_render, set_re_render ] = useState(false);
	const forceReRender = () => set_re_render(!re_render)
	
	const onClickRemoveOnboardingFee = async() => {
		try{
			let body = {};
			body["customer_store_id"] = store_id
			body["add_on_ids"] = Object.keys(selected_addons).filter(x => selected_addons[x])
			set_page_loader(true, "Please Wait")
			let resp = await general_fetch({url: 'csp/remove_onboarding_fees_from_addons', body})
			fetch_dues()
			toggle()
		}catch(err){
			toastr.error("Could not remove the onboarding fee. Please try again in sometime")
		}

		set_page_loader(false, "")
	}

	return(
		<>
			<Modal isOpen={open} size='md' toggle={toggle}>
				<ModalHeader toggle={toggle}>Remove Onboarding Fee</ModalHeader>
				<ModalBody>
				{ 
					dues && dues[0] && dues[0].dues_details && dues[0].dues_details.add_ons && dues[0].dues_details.add_ons.length ? dues[0].dues_details.add_ons.map(addon => (
						<div>
							<Checkbox checked={selected_addons[addon.add_on_id]} onClick={() => {selected_addons[addon.add_on_id] = !selected_addons[addon.add_on_id]; forceReRender()}}>
								{addon.name}
							</Checkbox>
						</div>
					))
					:''
				}
				</ModalBody>
				<ModalFooter>
					<Button className="btn-sm" color="primary" onClick={onClickRemoveOnboardingFee}>Remove Onboarding Fee</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

const UpdateExtraUsers = ({open, toggle, store_info, fetch_store_info, fetch_dues, set_page_loader}) => {

	const [users, set_users] = useState();

	useEffect(() => {
		
	}, [store_info]);

	const onClick_handleClose = () => {
		set_users()
		toggle()
	}

	const onclick_submit = async() => {
		try{
			let customer_store_id = window.location.pathname.split('/')[3]
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({url:'csp/update_future_excess_users_in_dues', body:{customer_store_id, excess_users: Number(users)}})
			set_page_loader(false, 'Please wait...')
			toastr.success('Successfully updated excess users')
			fetch_store_info()
			fetch_dues()
			onClick_handleClose()
		}catch(err){
			set_page_loader(false, 'Please wait...')
			toastr.error('Something went wrong, Please try again')
			console.error(err)
		}
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>Update extra users</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
				<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
				<Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-address" >
							Extra Users
                        </label>
                        <Input name="credits" className="form-control-alternative" type="number" placeholder='Type...' value={users} onChange={(e) => set_users(e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
				<Button color='primary' onClick={() => submit(onclick_submit, ()=>{}, 'This action will re-generate the Org dues, are you sure you want to proceed?', '','')}>Submit</Button>
			</div>
		</Modal>
	)
}

const MakePayment = ({ open, toggle, store_id, store_info, active_order_id, fetch_dues, set_page_loader}) => {
    const [comment, set_comment] = useState('');
    const [credits, set_credits] = useState(0);
    // const [credit_type, set_credit_type] = useState('');
    // const alert = useAlert()
  
    const assign_credits = async () => {
      if(comment.trim()){
        try {
            // var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
            try{ 
				set_page_loader(true, 'Please wait...');
              var resp = await general_fetch({url: 'billing/order/mark_manual_payment', body:{customer_store_id:store_id, amount:credits, comment:comment, order_id:active_order_id, currency: store_info && store_info.market && store_info.market == 'international' ? 'USD' : 'INR'}});
			  set_page_loader(false);
              // console.log(resp);
            //   fetch_store_info()
              toastr.success('Payment Recorded Successfully')
              fetch_dues()
  
            }
            catch(err){
				set_page_loader(false);
              // alert.error("Something went Wrong")
              toastr.error('Something went wrong')
            }
            // var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
            // console.log(resp);
            // fetch_store_info()
            toggle();
        } catch(err) {
            console.log(err);
        }
      }else{
		set_page_loader(false);
        toastr.error('Note is Mandatory')
      }
    }
  
    useEffect(() => {
        set_comment('');
        set_credits(0);
        // set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
    }, [ open ]);
  
    return (
        <Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
            <div className="modal-header  bg-gradient-white">
                <h4 className="modal-title" id="exampleModalLabel" style={{margin: 'auto'}}>Record Manual Payment</h4>
                {/* <span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span> */}
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
                  <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body  bg-gradient-secondary" >
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-address" >
                          Note
                        </label>
                        <Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment"  type="text" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-address" >
                          Amount in {store_info && store_info.market && store_info.market == 'international' ? 'USD' : 'INR'}
                        </label>
                        <Input value={credits} onChange={e => set_credits(Number(e.target.value))} name="credits" className="form-control-alternative" id="credits_free"  type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" onClick={assign_credits}>Record Payment</Button>
                </div>
            </div>
        </Modal>
    );
}

const RecordFirstPayment =({open, toggle, store_info, fetch_store_info, set_show_find_by_email_modal}) => {

	const [option_selected, set_option_selected] = useState(false);
	const [first_payment_option, set_first_payment_option] = useState('transfer_from_org');

	const onClick_handleClose = () => {
		set_option_selected(false)
		set_first_payment_option('transfer_from_org')
	}

	useEffect(() => {
		if(option_selected && first_payment_option === 'transfer_from_org'){
			set_show_find_by_email_modal(true)
			toggle()
			onClick_handleClose()
		}
	}, [option_selected, first_payment_option]);

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
					<h4 className="modal-title" id="exampleModalLabel" style={{margin: 'auto'}}>Transfer Payment from another Org</h4>
					<span className="pull-right">{generate_badge("Store ID - " + (store_info ? store_info.id : ''), "info")}</span>
					<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
					</button>
				</div>
				<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
				{
					option_selected ? 
					<div>

					</div> 
					:
					<div style={{paddingLeft:'20px'}} onChange={(e) => set_first_payment_option(e.target.value)}>
						{/* <div className="flex_property mb-3" style={{justifyContent:'flex-start'}}>
							<Input value='new_entry' checked={first_payment_option === 'new_entry' ? true : false} style={{position:'unset', margin:'0px 10px 0px 0px'}} name='first_payment_option' type='radio'></Input>
							<span>New Entry</span>
						</div> */}
						<div className="flex_property" style={{justifyContent:'flex-start'}}>
							<Input value='transfer_from_org' checked={first_payment_option === 'transfer_from_org' ? true : false} style={{position:'unset', margin:'0px 10px 0px 0px'}} name='first_payment_option' type='radio'></Input>
							<span>Transfer from another Org</span>
						</div>
					</div>
				}
				</div>
				<div className="modal-footer" style={{height:'60px'}}> 
					{option_selected ? <Button className='white_button' onClick={() => set_option_selected(false)}>Back</Button> : ''}
					<Button color='primary' onClick={() => set_option_selected(true)}>Next</Button>
				</div>
		</Modal>
	)
}

const ExtendDueDate = ({open, toggle, due_date, store_id, order_id, fetch_dues}) => {

	const [selected_date, set_selected_date] = useState('');

	const onClick_handleClose = () => {
		set_selected_date('')
		toggle()
	}

	const onChangeDate = (date, dateString) => {
		console.log('dateString', dateString)
		set_selected_date(dateString)
	}

	const handle_submit = async() => {
		try{
			if(selected_date){
				let resp = await general_fetch({url:'csp/extend_due_date', body:{new_due_date: selected_date, customer_store_id: store_id, order_id}})
				onClick_handleClose()
				toastr.success('Due date extended successfully')
				fetch_dues()
			}else{
				toastr.error('Please select a date')
			}
		}catch(err){
			console.error(err)
			toastr.error(`Couldn't extend due date`)
		}
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>Extend Due Date</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
				<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
				<DatePicker
					format="YYYY-MM-DD"
					disabledDate={(d) => new Date(d) <= new Date(due_date)}
					defaultValue = {due_date ? dayjs(due_date, 'YYYY-MM-DD') : null}
					onChange={onChangeDate}
				/>
			</div>
			<div className="modal-footer" style={{height:'60px'}}> 
				<Button className='white_button' onClick={onClick_handleClose}>Close</Button>
				<Button color='primary' onClick={() => {handle_submit()}}>Submit</Button>
			</div>
		</Modal>
	)
}


const CreditNoteModal = ({open, toggle, get_credit_notes, store_id, store_info, set_page_loader, fetch_dues}) => {

	const [credits, set_credits] = useState('');
	const [note, set_note] = useState();
	const [show_grace_selection_ui, set_show_grace_selection_ui] = useState(false);
	const today = new Date()
	const tomorrow = new Date()
	tomorrow.setDate(today.getDate() + 1);
	const [from_date, set_from_date] = useState();
	const [to_date, set_to_date] = useState();
	const [ amount, set_amount ] = useState('');

	const onclick_handleclose = () => {
		toggle()
		set_credits('')
		set_amount('')
	}

	const submit_fn = async() => {
		try {
			let body = {customer_store_id:store_id, type: show_grace_selection_ui?'Grace':'Overage', note }
			if(show_grace_selection_ui){
				body['start_date'] = new Date(from_date).toDateString()
				body['end_date'] = new Date(to_date).toDateString()
			}else{
				body['credits'] = Number(credits)
			}
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({url:'csp/credit_note/add_credit_note', body})
			set_page_loader(false)
			toastr.success('Successfully added credit note')
			get_credit_notes()
			onclick_handleclose()
			fetch_dues()
		}catch (e) {
			set_page_loader(false)
			console.error(e)
			toastr.error(`Couldn't add Credit Note`)
		}
	}

	const get_tentative_credits_for_duration = async(from_date, to_date) => {
		try {
			let body = {start_date: new Date(from_date).toDateString(), end_date: new Date(to_date).toDateString(), customer_store_id:store_id}
			set_page_loader(true, 'Calculating no.of credits...')
			let resp = await general_fetch({url:'csp/credit_note/get_tentative_credits_for_duration', body})
			set_page_loader(false)
			set_credits(resp)
		}catch (e) {
			set_page_loader(false)
			console.error(e)
		}
	}

	const onChangeFromDate = (date, dateString) => {
		set_from_date(dateString)
		document.getElementById('to_date').click()
	}

	const onChangeToDate = (date, dateString) => {
		set_to_date(dateString)
		get_tentative_credits_for_duration(from_date, dateString)
	}

	useEffect(() => {
		if(amount == 0) set_amount('')
		if(credits == 0) set_credits('')
	}, [amount, credits]);

	return(
		<CspModal open={open} toggle={onclick_handleclose} >
			{/* <GraceSelectionModal open={show_grace_selection_ui} toggle={() => set_show_grace_selection_ui(false)} set_credits={set_credits} store_id={store_id} set_page_loader={set_page_loader}/> */}
			<CspModalHeader title={'Add Credit Note'} toggle={onclick_handleclose}/>
			<CspModalBody style={{maxHeight:'600px'}}>
				<div className="flex_property" style={{alignItems:"base"}}>
					<FormGroup>
						<label className="form-control-label" htmlFor="input-address" >
							{show_grace_selection_ui ? 'Select dates for Grace' : `Amount (${store_info && store_info.ccr_details ? store_info.ccr_details.currency : ''}) or Credits`}
						</label>
						<div className="flex_property">
						{
							show_grace_selection_ui ? 
							<Row className=''>
								<Col md="6">
									<FormGroup className='flex_property mb-0'>
										<label className="form-control-label mr-2" htmlFor="input-address" >
											From
										</label>
										<DatePicker
											format="YYYY-MM-DD"
											onChange = {onChangeFromDate}
											// defaultValue = {from_date}
											// disabledDate={(d) => new Date(d) < new Date(today)}
										/>
									</FormGroup>
								</Col>
								<Col md="6">
									<FormGroup className='flex_property mb-0'>
										<label className="form-control-label mr-2" htmlFor="input-address" >
											To
										</label>
										<DatePicker
											id = 'to_date'
											format="YYYY-MM-DD"
											onChange = {onChangeToDate}
											// defaultValue={to_date}
											disabledDate={(d) => new Date(d) < new Date(from_date)}
										/>
									</FormGroup>
								</Col>
							</Row>
							:
							<Input value={amount} onChange={e => {set_credits((Number(e.target.value)/Number(store_info && store_info.ccr_details ? store_info.ccr_details.cost_per_credit : 1)).toFixed(2)); set_amount(e.target.value)}} className="form-control-alternative" type="number" style={{minWidth:'240px'}} placeholder={`Enter Amount (${store_info && store_info.ccr_details ? store_info.ccr_details.currency : ''})`} />
						}
						{
							!show_grace_selection_ui?
							<>
								<div className="mx-2">or</div>
								<div>
									<Input value={credits} onChange={e => {set_credits(e.target.value); set_amount((Number(e.target.value)*Number(store_info && store_info.ccr_details ? store_info.ccr_details.cost_per_credit : 1)).toFixed(2))}} className="form-control-alternative" type="number" style={{minWidth:'240px'}} placeholder='Enter Credits' />
								</div>
							</>:''
						}
							<div className="mx-2">or</div>
							<Button color='primary' onClick={() => {set_credits('');set_amount(''); set_show_grace_selection_ui(!show_grace_selection_ui)}}>{show_grace_selection_ui ? 'Enter No.of credits' : 'Select dates for Grace'}</Button>
						</div>
						<div style={{fontSize:'14px'}} className="mt-2">
							{show_grace_selection_ui?<span className="mr-2" style={{fontWeight:700}}>Equivalent Amount - {(credits*(store_info && store_info.ccr_details ? store_info.ccr_details.cost_per_credit : 1)).toFixed(2)} ({store_info && store_info.ccr_details ? store_info.ccr_details.currency : ''}),</span>:''}
							Equivalent Credits - {credits}
						</div>
					</FormGroup>
				</div>
				<FormGroup>
					<label className="form-control-label" htmlFor="input-address" >
						Note
					</label>
					<textarea style={{height:'100px', width:'100%', borderRadius:'6px', border:'1px solid #cad1d7', fontSize:'14px'}} className='px-2 py-1' value={note} onChange={(e) => set_note(e.target.value)} placeholder='Type...'/>
				</FormGroup>
			</CspModalBody>
			<CspModalFooter>
				<Button color="primary" onClick={submit_fn}>Submit</Button>
			</CspModalFooter>
		</CspModal>
	)
}
  

const Dues = ({store_id, store_info, fetch_store_info, user_details, dues, fetch_dues, set_page_loader}) => {
    // const [dues, set_dues] = useState([]);
    const [is_make_payment_open, set_is_make_payment_open] = useState(false);
    const [active_order_id, set_active_order_id] = useState('');
	const [show_first_payment_modal, set_show_first_payment_modal] = useState(false);
	const [show_extend_due_date_modal, set_show_extend_due_date_modal] = useState(false);
	const [show_find_by_email_modal, set_show_find_by_email_modal] = useState(false);
	const [editing_discount_json, set_editing_discount_json] = useState({});
	const [editing_discount_perc_json, set_editing_discount_perc_json] = useState({});
	const [show_update_extra_users_modal, set_show_update_extra_users_modal] = useState(false);
	const [ show_remove_onboarding_fee_modal, set_show_remove_onboarding_fee_modal ] = useState(false);
	const [active_due, set_active_due] = useState();
	const [show_credit_note_modal, set_show_credit_note_modal] = useState(false);
	const [credit_notes, set_credit_notes] = useState([]);

    const styles = {
        header_row: {
            marginLeft: 0,
        },
        table_header: {
            fontWeight: "bold",
            fontSize: 'small'
        },
        round_status: {
            fontSize: 'small',
            paddingBottom: 0
        },
        nav_pills_rounds: {
            paddingRight: 60,
            marginRight: "0.7rem",
            marginLeft: "0.2rem",
        },
        action_button: {
            margin: 1       
        }
    };

    const toggle = () => {set_is_make_payment_open(!is_make_payment_open)}

	const toggle_record_first_payment = () => {set_show_first_payment_modal(!show_first_payment_modal)}
	const toggle_update_extra_users = () => {set_show_update_extra_users_modal(!show_update_extra_users_modal)}
	const toggle_remove_onboarding_fee = () => {set_show_remove_onboarding_fee_modal(!show_remove_onboarding_fee_modal)}
	const toggle_extend_due_date = () => {set_show_extend_due_date_modal(!show_extend_due_date_modal)}

	const extend_due_date = async(due) => {
		try{
			let number_of_days = 0
			if(!due.extensions || (due.extensions.length == 0)){
				if(store_info.enterprise){
					number_of_days = 7
				}else{
					number_of_days = 3
				}
			}else if(due.extensions.length == 1){
				if(store_info.enterprise){
					number_of_days = 3
				}else{
					number_of_days = 1
				}
			}
			else{
				toastr.error(`You can't extend due date more than twice`)
				return
			}

			let resp = await general_fetch({url:'csp/extend_due_date', body:{order_id: due.id, number_of_days}}) 
			toastr.success('Successfully extended due date')
			fetch_store_info()
			fetch_dues()
		}catch(err){
			console.error("Could not extend due date")
		}
	}

	const transfer_function = async(from_org, to_org_id) => {
		try{
			if(from_org && !from_org.premium_customer){
				toastr.error('Cannot transfer payment from a Non-premium Org')
				return
			}
			let resp = await general_fetch({url:'csp/migrate_first_payment_to_another_org', body:{from_org_id: from_org.id, to_org_id}, req_resp:true})
			if(resp.response_code == -1){
				toastr.error(resp.message)
			}else{				
				toastr.success('Successfully transfered payment')
				set_show_find_by_email_modal(false)
				fetch_store_info()
			}
		}catch(err){
			toastr.error('Tranfer Failed, Please make sure you have Customer Success Admin role')
			console.error(err)
		}
	}
	
	const update_editing_discount_json = (idx, val) => {
		let a = JSON.parse(JSON.stringify(editing_discount_json))
		a[idx] = val
		set_editing_discount_json(a)
	}

	const update_editing_discount_perc_json = (idx, val) => {
		let a = JSON.parse(JSON.stringify(editing_discount_perc_json))
		a[idx] = val
		set_editing_discount_perc_json(a)
	}

	const apply_discount_on_due = async(order_id, discount_input_id, type) => {
		try{
			let body = {order_id}
			if(type === 'perc'){
				body['discount_in_perc'] = Number(document.getElementById(discount_input_id).value)
			}else{
				body['abs_discount_in_money'] = Number(document.getElementById(discount_input_id).value)
			}
			let resp = await general_fetch({url:'csp/apply_discount_to_order ', body})
			fetch_dues()
			set_editing_discount_json({})
			set_editing_discount_perc_json({})
			toastr.success('Discount updated successfully')
		}catch(err){
			toastr.error('Could not update discount')
			console.log(err)
		}
	}

	const sort_by_created_at = (a,b) => {
		if(new Date(a.created_at) < new Date(b.created_at)) return 1
		return -1
	}

	const get_credit_notes = async() => {
		try {
			let resp = await general_fetch({url:'csp/credit_note/get_credit_notes', body:{customer_store_id:store_id}})
			if(resp && resp.length){
				resp.sort(sort_by_created_at)
			}
			set_credit_notes(resp)
		}catch(err){
			console.error(err)
		}
	}

	const convert_to_annual_renewal = async() => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({url:'csp/convert_quarter_to_annual_renewal_payment', body:{customer_org_id:store_id}})
			set_page_loader(false)
			fetch_dues()
			toastr.success('Successfully converted to annual plan')
		}catch(err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't convert to annual plan`)
		}
	}

	const convert_to_quarterly_renewal = async() => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({url:'csp/convert_annual_to_quarter_renewal_payment', body:{customer_org_id:store_id}})
			set_page_loader(false)
			fetch_dues()
			toastr.success('Successfully converted to quarterly plan')
		}catch(err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't convert to quarterly plan`)
		}
	}

	const attach_razorpay_order = async (order_id) => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({url:'billing/order/attach_razorpay_order', body:{order_id}})
			set_page_loader(false)
			fetch_dues()
			toastr.success('Successfully changed the Payment Gateway')
		}catch(err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't change Payment Gateway`)
		}
	}
	const get_zoho_estimate = async (order_id) => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ url: 'billing/order/get_zoho_estimate', body: { order_id } })
			set_page_loader(false)
			if (resp) {
				const blob = new Blob([new Uint8Array(resp.data)], { type: 'application/pdf' })
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = 'document.pdf';
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(url);
				document.body.removeChild(link);
			}
			toastr.success('Successfully downloaded PI')
		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't download PI, please try again`)
		}
	}

    useEffect(() => {
        if(store_id) get_credit_notes()
    }, [store_id]);

    return(
        <>
            <MakePayment open={is_make_payment_open} toggle={toggle} store_id={store_id} store_info={store_info} active_order_id={active_order_id} fetch_dues={fetch_dues} set_page_loader={set_page_loader}/>
			<RecordFirstPayment 
				open={show_first_payment_modal} 
				toggle={toggle_record_first_payment} 
				store_info={store_info} 
				fetch_store_info={fetch_store_info}
				set_show_find_by_email_modal = {set_show_find_by_email_modal}	
			/>
			<UpdateExtraUsers 
				open={show_update_extra_users_modal} 
				toggle={toggle_update_extra_users} 
				store_info={store_info} 
				fetch_store_info={fetch_store_info}
				fetch_dues={fetch_dues}
				set_page_loader={set_page_loader}
			/>
			<RemoveOnboardingFeeModal 
				open={show_remove_onboarding_fee_modal} 
				toggle={toggle_remove_onboarding_fee} 
				store_id={store_id}
				store_info={store_info} 
				fetch_store_info={fetch_store_info}
				dues={dues}
				fetch_dues={fetch_dues}
				set_page_loader={set_page_loader}
			/>
			<FindOrgsByEmail
				open = {show_find_by_email_modal}
				toggle={() => set_show_find_by_email_modal(!show_find_by_email_modal)}
				action_name={'Transfer from this Org'}
				action_function={(single_org) => {transfer_function(single_org, store_id)}}
				show_confirmation={true}
			/>
			<ExtendDueDate 
				open = {show_extend_due_date_modal}
				toggle={toggle_extend_due_date}
				due_date={active_due ? active_due.due_date.substring(0, 10) : ''}
				store_id={store_id}
				order_id={active_due ? active_due.id : ''}
				fetch_dues={fetch_dues}
			/>
			<CreditNoteModal 
				open={show_credit_note_modal}
				toggle={() => set_show_credit_note_modal(false)}
				get_credit_notes = {get_credit_notes}
				store_id={store_id}
				store_info={store_info}
				set_page_loader={set_page_loader}
				fetch_dues={fetch_dues}
			/>
			{
				store_info && !store_info.premium_customer ?
				<div style={{width:'100%', justifyContent:'flex-end'}} className="flex_property">
					<div className="purple_border mb-3" onClick={toggle_record_first_payment}>Transfer Payment from another Org</div>
				</div>:""
			}
			{
				<div className="flex_property" style={{alignItems:"start", flexWrap:'wrap'}}>
					<div className={`order-xl-${store_info.country != 'IN' ? 3 : 1} col-xl-5 p-2 mb-2`}>
						<Card className="p-4 shadow" style={{fontSize:'14px'}}>
							<div className="mb-4 flex_property" style={{justifyContent:"space-between"}}>
								<h3 className="mb-0">Due Details</h3>
								{
									dues && dues.length?
									<div>
										<a href={global.config.onboarding_path+'?due_order_id='+(dues[0].id)} target="_blank"><Button className="btn-sm mr-2" color="primary" >Payment Link <i className="fas fa-external-link-alt ml-1"/></Button></a>
										<Button className="btn-sm" color="primary" onClick={() => {toggle(); set_active_order_id(dues[0].id)}}>Add Payment</Button>
									</div>:""
								}
							</div>
						{
							dues && dues.length?
							<>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Estimated Invoice</div>
									<div style={{flex:3}}><Button className="btn-sm" color='primary' onClick={() => {get_zoho_estimate(dues[0].id)}}>Download Estimated Invoice</Button></div>
								</div>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Total Months </div>
									<div style={{flex:3}}>{dues[0].total_months}{<Button className="btn-sm ml-2" color='primary' onClick={() => submit(dues[0].total_months==3 ? convert_to_annual_renewal : convert_to_quarterly_renewal, ()=>{}, `Are you certain about converting the renewal payment to the ${dues[0].total_months==3 ? 'Annual' : 'Quarterly'} price?`, '', '')}>{dues[0].total_months==3 ? 'Convert to Annual' : 'Convert to Quarterly'}</Button>}</div>
								</div>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Price </div>
									<div style={{flex:3}}>{dues[0].price}</div>
								</div>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Extra Users </div>
									<div style={{flex:3}}>
										<div className="flex_property">
											{dues[0].dues_details ? dues[0].dues_details.excess_users_count : '0'}
											<i className="fa fa-edit ml-3 cp" style={{fontSize:'18px', color:'#0078ff'}} onClick={toggle_update_extra_users}/>
										</div>
									</div>
								</div>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Discount (%) </div>
									<div style={{flex:3}}>
										{
											dues[0].status === 'due' ?(
												editing_discount_perc_json[0]? 
												<div className="flex_property">
													<Input type='number' className='standard_input' id={'discount_input_perc'+0}/>
													<i className='fas fa-check mx-3 cp' style={{color:'#23C16B', fontSize:'18px'}} onClick={() => {apply_discount_on_due(dues[0].id, 'discount_input_perc'+0, 'perc')}}/>
													<i className='fa fa-times cp' style={{color:'#FF5247', fontSize:'18px'}} onClick={() => {update_editing_discount_perc_json(0, false)}}/>
												</div>
												:
												<div className="flex_property">
													{dues[0].discount ? dues[0].discount.percentage : '0'}% 
													<i className="fa fa-edit ml-3 cp" style={{fontSize:'18px', color:'#0078ff'}} onClick={() => {update_editing_discount_perc_json(0, true); update_editing_discount_json(0, false)}}/>
												</div>
											):<div>{dues[0].discount ? dues[0].discount.percentage : '0'}% </div>
										}
									</div>
								</div>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Discount (ABS) </div>
									<div style={{flex:3}}>
										{
											dues[0].status === 'due' ?(
												editing_discount_json[0]? 
												<div className="flex_property">
													<Input type='number' className='standard_input' id={'discount_input'+0}/>
													<i className='fas fa-check mx-3 cp' style={{color:'#23C16B', fontSize:'18px'}} onClick={() => {apply_discount_on_due(dues[0].id, 'discount_input'+0, 'absolute')}}/>
													<i className='fa fa-times cp' style={{color:'#FF5247', fontSize:'18px'}} onClick={() => {update_editing_discount_json(0, false)}}/>
												</div>
												:
												<div className="flex_property">
													{dues[0].discount ? dues[0].discount.absolute : '0'} 
													<i className="fa fa-edit ml-3 cp" style={{fontSize:'18px', color:'#0078ff'}} onClick={() => {update_editing_discount_json(0, true); update_editing_discount_perc_json(0, false)}}/>
												</div>
											):<div>{dues[0].discount ? dues[0].discount.absolute : '0'} </div>
										}
									</div>
								</div>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Due </div>
									<div style={{flex:3}}>
										{dues[0].price_after_discount_before_tax}
									</div>
								</div>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Tax </div>
									<div style={{flex:3}}>
										{dues[0].tax}
									</div>
								</div>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Total </div>
									<div style={{flex:3}}>
										{dues[0].total}
									</div>
								</div>
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Due by </div>
									<div style={{flex:3}}>
										<div className="flex_property">
											<div>{date_string(dues[0].due_date)}</div>
											{
												user_details && user_details.role && user_details.role.store && user_details.role.store.length && user_details.role.store.includes("customer_success_admin")?
												<i className="fa fa-edit cp ml-2" style={{color:'#0078ff', fontSize:'18px'}} onClick={() => {toggle_extend_due_date(); set_active_due(dues[0])}}/>:''
											}
										</div>
									</div>
								</div>
								{
									dues[0].extensions && dues[0].extensions.length > 1 ? '':
									<div className="flex_center mb-4">
										<div style={{flex:2}}>Extend </div>
										<div style={{flex:3}}>
											<Button className="btn-sm" color="primary" onClick={() => {submit(() => extend_due_date(dues[0]), ()=>{}, 'Are you sure you want to extend the due date ?', '','')}}>Extend Due Date ({dues[0].extensions && dues[0].extensions.length == 1 ? (store_info && store_info.enterprise ? '3 days' : '1 day') : (store_info && store_info.enterprise ? '7 days' : '3 days') })</Button>
										</div>
									</div>
								}
								<div className="flex_center mb-4">
									<div style={{flex:2}}>Add-ons considered for the next cycle </div> 
									<div style={{flex:3}}>
										<div>{dues[0].dues_details && dues[0].dues_details.add_ons && dues[0].dues_details.add_ons.length ? dues[0].dues_details.add_ons.reduce((final, x, idx) => final + x.name + (idx!= dues[0].dues_details.add_ons.length-1 ? ', ' : ''), '') : 'N/A'}</div>
										{dues[0].dues_details && dues[0].dues_details.add_ons && dues[0].dues_details.add_ons.length ?<Button className="btn-sm" color="primary" onClick={toggle_remove_onboarding_fee}>Remove Onboarding Fee</Button> : ''}
									</div>
								</div>
								<div className="flex_center">
									<div style={{flex:2}}>Payment Gateway </div>
									<div style={{flex:3}}>{dues[0].payment_gateway}{dues[0].payment_gateway=='razorpay' ? '' : <Button className="btn-sm ml-2" color='primary' onClick={() => submit(() => attach_razorpay_order(dues[0].id), ()=>{}, 'Are you certain about changing the payment gateway?', '', '')}>Change to Razorpay</Button>}</div>
								</div>
							</>:<div style={{width:'100%', fontWeight:'800', fontSize:'16px'}} className="flex_center">There are no dues</div>
						}
						</Card>
					</div>
					<div className="order-xl-2 col-xl-7 p-2 mb-2">
						<Card className="p-4 shadow" style={{fontSize:'14px'}}>
							<div className="mb-4 flex_property" style={{justifyContent:"space-between"}}>
								<h3 className="mb-0">Credit Notes</h3>
								<Button className="btn-sm" color="primary" onClick={() => {set_show_credit_note_modal(true)}}>Add Credit Note</Button>
							</div>
							{
								credit_notes && credit_notes.length ? credit_notes.map((credit_note, idx) => (
									<div style={{display:'flex', position:'relative'}} className="mb-4">
										<div className="activity_type_div mr-4">
										{<i className={`far fa-credit-card`} />}
										</div>
										{(idx != credit_notes.length-1) ? <div className="vertical_line"></div>  : ''}
										<div className="activity_box flex_property" style={{fontSize:'13px'}}>
											<div className="flex_center" style={{justifyContent:'space-between', width:'100%'}}>
												<div>
													<div className="mb-2">Amount : <span style={{fontWeight:700}}>{(credit_note.credits*(store_info && store_info.ccr_details ? store_info.ccr_details.cost_per_credit : 1)).toFixed(2)} ({store_info && store_info.ccr_details ? store_info.ccr_details.currency : ''})</span> , Credits : <span style={{fontWeight:700}}>{credit_note.credits}</span></div>
													<div>Type: {credit_note.type} {credit_note.type==='Grace' ? <span>- from <span style={{fontWeight:700}}>{date_string(credit_note.start_date)}</span> to <span style={{fontWeight:700}}>{date_string(credit_note.end_date)}</span></span> : ''}</div>
													{/* {credit_note.type==='Grace' ? <div>Grace from <span style={{fontWeight:700}}>{date_string(credit_note.start_date)}</span> to <span style={{fontWeight:700}}>{date_string(credit_note.end_date)}</span></div> : <div>Overage Credits : <span style={{fontWeight:700}}>{credit_note.credits}</span></div>} */}
												</div>
												<div style={{opacity:'0.4'}}>
													{date_string(credit_note.updated_at)}
												</div>
											</div>
										</div>
									</div>
								)):""
							}
						</Card>
					</div>
					{
						store_info.country != 'IN' ?
							<div className="order-xl-1 col-xl-5 p-2 mb-2">
								<Card className="p-4 shadow" style={{ fontSize: '14px' }}>
									<div className="mb-4 flex_property" style={{ justifyContent: "space-between" }}>
										<h3 className="mb-0">Stripe Info</h3>
										<img src="/stripe.png" height={'24px'} />
									</div>
									{
										store_info && store_info.stripe_customer_info ?
										<div>
											<div className="mb-2">{`Customer Id: ${store_info.stripe_customer_info.customer ? (store_info.stripe_customer_info.customer) : '-'}`}</div>
											<div className="mb-2">{`Reported Amount: ${store_info.stripe_customer_info.reported_amount !== undefined ? store_info.stripe_customer_info.reported_amount : '-'}`}</div>
											<div className="mb-2">{`Status: ${store_info.stripe_customer_info.status ? store_info.stripe_customer_info.status : '-'}`}</div>
											<div className="mb-2">{`Next Invoice date: ${store_info.stripe_customer_info.next_invoice_date ? date_string(store_info.stripe_customer_info.next_invoice_date) : '-'}`}</div>
										</div>
										:
										<div>Stripe Subsription Info not found</div>
									}
								</Card>
							</div>
							: ''
					}
				</div>
			}
      </>
    )
}

export default Dues