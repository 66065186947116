import React, { useCallback, useEffect, useMemo, useState } from "react";
import ISearch from "../../components/ISearch";
import general_fetch from "../../utils/fetch";
import { ITD, ITable, ITableBody, ITableRow } from "../../components/ITable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Input } from "reactstrap";
import TablePagination from "../../components/TablePagination";
import { Button as AntdButton, Input as AntdInput, DatePicker, Tag } from 'antd';
import { CspModal, CspModalBody, CspModalHeader } from "../../components/CspModal";
import { toastr } from "react-redux-toastr";
import { date_string } from "../../utils/date_string";
import NA from "../../components/NA";
import makeAnimated from 'react-select/animated';
import generic_sort_for_orgs from "../../utils/generic_sort_for_orgs";
import { mkConfig, generateCsv, download } from "export-to-csv";
import fetch_mix_panel_data from "../../utils/fetch_mixpanel_data.js";
import Select from 'react-select';
import { colourStyles, colourStyles1, colourStyles3, colourStyles4 } from "../../utils/react_select_styles.js";
import { Checkbox, Tooltip } from 'antd';

const animatedComponents = makeAnimated();


const NoteModal = ({open, toggle, risk_org, all_stores, fetch_clients_at_risk}) => {
	const [ note, set_note ] = useState('');
	
	const onclick_handleclose = () => {
		set_note('')
		toggle()
	}
 
	const update_note = async () => {
		try {
			let resp = await general_fetch({url:'csp/update_org_at_risk_note', body:{record_id: risk_org.id, note}})
			onclick_handleclose()
			fetch_clients_at_risk()
			toastr.success(`Successfully updated note`)
		}catch (e) {
			console.error(e)
			toastr.error(`Couldn't update note`)
		}
	}

	useEffect(() => {
		if(risk_org){
			set_note(risk_org.note)
		}
	}, [risk_org]);

	return (
		<CspModal open={open} toggle={onclick_handleclose}>
			<CspModalHeader title='Update Note' toggle={onclick_handleclose}/>
			<CspModalBody>
				<div className="mb-2 flex_property">
					<div className="mr-2" style={{fontSize:'14px'}}>Org Name: </div>
					<div className="bold">{all_stores && all_stores.length && risk_org && all_stores.find(o => o.id===risk_org.org_id) ? all_stores.find(o => o.id===risk_org.org_id).name : 'N/A'}</div>
				</div>
				<textarea className="mt-0" style={{padding: '8px 16px', fontSize:'13px', width:'100%', minHeight:'100px', borderRadius:'8px'}} value={note} onChange={(e) => set_note(e.target.value)}/>
				<div className="flex_property mt-2" style={{justifyContent:'flex-end', }}>
					<Button color='primary' onClick={update_note}>Update</Button>
				</div>
			</CspModalBody>
		</CspModal>
	)
}

const ClientsAtRisk = ({all_stores, user_details, set_page_loader, cs_org_users, hide_header_row, permanent_filtration_org_id}) => {

	const [search_string, set_search_string] = useState();
	const [orgs_at_risk, set_orgs_at_risk] = useState([]);
	const [display_orgs_at_risk, set_display_orgs_at_risk] = useState([]);
	const [page_size, set_page_size] = useState(50);
	const [ user_selected_page_size, set_user_selected_page_size ] = useState(0);
	const [no_of_pages, set_no_of_pages] = useState(null);
	const [current_page, set_current_page] = useState(1);
	const [sorted_asc_by, set_sorted_asc_by] = useState({'Week':1});
	const [ show_note_modal, set_show_note_modal ] = useState(false);
	const [ active_risk_org, set_active_risk_org ] = useState();
	const [ org_filter, set_org_filter ] = useState('');
	const [selected_cses, set_selected_cses] = useState([]);
	const [user_select_options, set_user_select_options] = useState([]);
	const [team_members, set_team_members] = useState([]);
	const [last_week_usage, set_last_week_usage] = useState();
	const [ cs_executives, set_cs_executives ] = useState([]);
	const [ tag_filter, set_tag_filter ] = useState([]);
	const [ org_cs_rep_map, set_org_cs_rep_map ] = useState({});
	const [ from_date, set_from_date ] = useState();
	const [ to_date, set_to_date ] = useState();
	const [ week_filter, set_week_filter ] = useState([]);
	const [ week_filter_options, set_week_filter_options ] = useState([]);
	const [payment_cycle_filter, set_payment_cycle_filter] = useState();

    const renewal_probability = (store) => {
        if(store.renewal_probability && store.renewal_probability != "NA") {
            return Number(store.renewal_probability*100).toFixed(2) + ' %';
        } else {
            return "NA"
        }
    }

    const risk_week_percent = (risk_org) => {
        let val = (((risk_org.metric_info[0].current_value - risk_org.metric_info[0].previous_value)/risk_org.metric_info[0].previous_value)*100).toFixed(0) + ' %';
        if(risk_org.metric_info[0].previous_value == 0 && risk_org.metric_info[0].current_value == risk_org.metric_info[0].previous_value) {
            val = '0 %';
        }
        return val
    }

	const headings = useMemo(() => [
		{
			column_name: 'S.No',
			styles:{position:"sticky", left:0, top:0, zIndex:1, backgroundColor:'white'},
			columnWidth: 'minmax(80px, 80px)'
		},
		...(hide_header_row ? [] : [{
				column_name: 'Org',
				styles:{position:"sticky", top:0, left: '80px', zIndex: 1, backgroundColor:'white'},
				columnWidth: 'minmax(200px, 2fr)'
		}])
		,
		{
			column_name: 'Base Week',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth: 'minmax(120px, 1fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('Base Week')
		},
		{
			column_name: 'Risk Week',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(120px, 1fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('Risk Week')
		},
		{
			column_name: 'Risk Week %',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			show_sort: true,
			sort_fn: () => common_sorting_fn('Risk Week %'),
			columnWidth:'minmax(130px, 2fr)'
		},
		{
			column_name: 'Renewal Prob',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(120px, 1fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('Renewal Prob')
		},
		{
			column_name: 'Succeeding Week',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(160px, 1fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('Succeeding Week')
		},
		{
			column_name: 'Succeeding Week %',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(170px, 1fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('Succeeding Week %')
		},
		{
			column_name: 'Last Week',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(120px, 1fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('Last Week')
		},
		{
			column_name: 'Last Week %',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(130px, 1fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('Last Week %')
		},
		{
			column_name: 'Tags',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(360px, 4fr)'
		},
		{
			column_name: 'Week',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(150px, 2fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('Week')
		},
		{
			column_name: 'Note',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(600px, 4fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('Note')
		},
		{
			column_name: 'CS Rep',
			styles:{position:'sticky', top:0, backgroundColor:'white'},
			columnWidth:'minmax(150px, 2fr)',
			show_sort: true,
			sort_fn: () => common_sorting_fn('CS Rep')
		},
	], [orgs_at_risk, display_orgs_at_risk])  

	const [column_visibility_json, set_column_visibility_json] = useState(() => {let json = {}; headings.map(col => {json[col.column_name] = 1}); return json});

	const [show_column_selector, set_show_column_selector] = useState(false);
    const createHeaders = (headings) => {
        return headings.map((item, idx) => ({
            ...item,
            ref: ('resizable-header' + item.column_name),
            }));
    }
    const columns = useMemo(() => headings ? createHeaders(headings) : [], [headings])
	const [searched_columns, set_searched_columns] = useState(columns);
    const [column_search_text, set_column_search_text] = useState('');

	useEffect(() => {
		console.log('column_visibility_json11111', column_visibility_json)
	}, [column_visibility_json]);

	const TableData = useCallback((risk_org, idx) => ({
		'S.No':<ITD style={{position:"sticky", left:0, top:0, zIndex:1, backgroundColor:'white'}}>{risk_org.idx}</ITD>,
		'Org': <ITD style={{textDecoration:'underline', textDecorationColor:'#5e72e4', position:"sticky", left:'80px', zIndex:1, backgroundColor:'white'}}>
					<a target="_blank" href={`/home/store_dashboard/${risk_org.org_id}/7`}>
						{risk_org.name}
					</a>
				</ITD>,
		'Base Week': <ITD className="text-right">{risk_org.metric_info[0].previous_value.toLocaleString()}</ITD>,
		'Risk Week': <ITD className="text-right">{risk_org.metric_info[0].current_value.toLocaleString()}</ITD>,
		'Risk Week %': <ITD className="text-right">{risk_week_percent(risk_org)}</ITD>,
		'Renewal Prob': <ITD className="text-right">{Number(renewal_probability(risk_org).replace('%', '')) == Number.MAX_SAFE_INTEGER ? 'NA' : renewal_probability(risk_org)}</ITD>,
		'Succeeding Week': <ITD className="text-right">{risk_org.metric_info[0].succeeding_week_usage >= 0 ? risk_org.metric_info[0].succeeding_week_usage.toLocaleString() : <NA/>}</ITD>,
		'Succeeding Week %': <ITD className="text-right">{risk_org.metric_info[0].succeeding_week_usage>=0 ? ((((risk_org.metric_info[0].succeeding_week_usage - risk_org.metric_info[0].previous_value)/risk_org.metric_info[0].previous_value)*100).toFixed(0) + '%') : <NA/>}</ITD>,
		'Last Week': <ITD className="text-right">{last_week_usage && last_week_usage[risk_org.org_id] && last_week_usage[risk_org.org_id]['version_save'] ? last_week_usage[risk_org.org_id]['version_save'].toLocaleString() : '0'}</ITD>,
		'Last Week %': <ITD className="text-right">{last_week_usage && last_week_usage[risk_org.org_id] && last_week_usage[risk_org.org_id]['version_save']>=0 ? ((((last_week_usage[risk_org.org_id]['version_save'] - risk_org.metric_info[0].previous_value)/risk_org.metric_info[0].previous_value)*100).toFixed(0) + '%') : '-100%'}</ITD>,
		'Tags': <ITD>{risk_org.risk_metadata && risk_org.risk_metadata.tags && risk_org.risk_metadata.tags.length ? risk_org.risk_metadata.tags.map(x => <Tag color={x==='over_to_under_100' ? 'red' : 'orange'}>{x}</Tag>) : <NA/>}</ITD>,
		'Week': <ITD>{date_string(risk_org.start_date)}</ITD>,
		'Note': <ITD className='cp' onClick={() => {set_active_risk_org(risk_org); set_show_note_modal(true)}}>{risk_org.note}</ITD>,
		'CS Rep': <ITD>{cs_org_users && cs_org_users.length && org_cs_rep_map && org_cs_rep_map[risk_org.org_id] ? cs_org_users.find(o => o.id === org_cs_rep_map[risk_org.org_id]).first_name : ''}</ITD>
	}), [orgs_at_risk, display_orgs_at_risk, last_week_usage, org_cs_rep_map])

	const sorting_function_value = (risk_org, idx) => ({
		'Base Week': {value:risk_org.metric_info[0].previous_value, field_type:'number'},
		'Risk Week': {value:risk_org.metric_info[0].current_value, field_type:'number'},
		'Risk Week %': {value:Number(risk_week_percent(risk_org).replace('%', '')), field_type:'number'},
		'Renewal Prob': {value:Number(renewal_probability(risk_org).replace('%', '')), field_type:'number'},
		'Succeeding Week': {value:risk_org.metric_info[0].succeeding_week_usage, field_type:'number'},
		'Succeeding Week %': {value:risk_org.metric_info[0].succeeding_week_usage>=0 ? (((risk_org.metric_info[0].succeeding_week_usage - risk_org.metric_info[0].previous_value)/risk_org.metric_info[0].previous_value)*100).toFixed(0) : -100, field_type:'number'},
		'Last Week': {value:last_week_usage && last_week_usage[risk_org.org_id] && last_week_usage[risk_org.org_id]['version_save'] ? last_week_usage[risk_org.org_id]['version_save'] : 0, field_type:'number'},
		'Last Week %': {value:last_week_usage && last_week_usage[risk_org.org_id] && last_week_usage[risk_org.org_id]['version_save']>=0 ? (((last_week_usage[risk_org.org_id]['version_save'] - risk_org.metric_info[0].previous_value)/risk_org.metric_info[0].previous_value)*100).toFixed(0) : -100, field_type:'number'},
		'Week': {value:new Date(risk_org.start_date), field_type:'date'},
		'Note': {value:risk_org.note, field_type:'string'},
		'CS Rep': {value:cs_org_users && cs_org_users.length && org_cs_rep_map && org_cs_rep_map[risk_org.org_id] ? cs_org_users.find(o => o.id === org_cs_rep_map[risk_org.org_id]).first_name : '', field_type:'string'}
	})

	const fetch_clients_at_risk = async() => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({url:'csp/get_orgs_at_risk'})
			set_page_loader(false)
			resp = resp && resp.length ? resp.filter(risk_org => new Date(new Date(risk_org.updated_at).toDateString()) > new Date('2024-03-15')) : []
            /* if(!permanent_filtration_org_id) {
                resp = resp.filter(x => !x.end_date);
            } */
            resp = resp.map((risk_org, idx) => {
				let org_found = all_stores.find(o => o.id === risk_org.org_id)
				return {...risk_org, name: org_found.name, payment_cycle: org_found.payment_cycle, renewal_probability: org_found.renewal_probability}
			});
			console.log(resp)
			set_orgs_at_risk(resp)
		}catch (e) {
			set_page_loader(false)
			console.error(e);
		}
	}

	const common_sorting_fn = (column_name) => {
		let temp_orgs_at_risk = generic_sort_for_orgs(column_name, display_orgs_at_risk, sorted_asc_by[column_name] ? 1 : -1, sorting_function_value)
		sorted_asc_by[column_name] = !sorted_asc_by[column_name]
		temp_orgs_at_risk = temp_orgs_at_risk.map((x, idx) => ({...x, idx: idx+1}))
		set_display_orgs_at_risk(temp_orgs_at_risk)
	}

	const download_overview_csv = () => { 
		try {
			let csv_stores = display_orgs_at_risk.map((risk_org, idx) => {
				let org_json = {}
				org_json['S.No'] = idx+1
				org_json['Org'] = risk_org.name
				org_json['Base Week'] = risk_org.metric_info[0].previous_value.toLocaleString()
				org_json['Risk Week'] = risk_org.metric_info[0].current_value.toLocaleString()
				org_json['Risk Week %'] = (((risk_org.metric_info[0].current_value - risk_org.metric_info[0].previous_value)/risk_org.metric_info[0].previous_value)*100).toFixed(0) + '%'
				org_json['Renewal Prob'] = Number(renewal_probability(risk_org).replace('%', '')) == Number.MAX_SAFE_INTEGER ? 'NA' : renewal_probability(risk_org) 
				org_json['Succeeding Week'] = risk_org.metric_info[0].succeeding_week_usage && risk_org.metric_info[0].succeeding_week_usage.toLocaleString()
				org_json['Succeeding Week %'] = risk_org.metric_info[0].succeeding_week_usage>=0 ? ((((risk_org.metric_info[0].succeeding_week_usage - risk_org.metric_info[0].previous_value)/risk_org.metric_info[0].previous_value)*100).toFixed(0) + '%') : ''
				org_json['Last Week'] = last_week_usage && last_week_usage[risk_org.org_id] && last_week_usage[risk_org.org_id]['version_save'] ? last_week_usage[risk_org.org_id]['version_save'].toLocaleString() : '0'
				org_json['Last Week %'] = last_week_usage && last_week_usage[risk_org.org_id] && last_week_usage[risk_org.org_id]['version_save']>=0 ? ((((last_week_usage[risk_org.org_id]['version_save'] - risk_org.metric_info[0].previous_value)/risk_org.metric_info[0].previous_value)*100).toFixed(0) + '%') : '-100%'
				org_json['Tags'] = risk_org.risk_metadata && risk_org.risk_metadata.tags && risk_org.risk_metadata.tags.length ? risk_org.risk_metadata.tags.join(', ') : ''
				org_json['Week'] = date_string(risk_org.start_date)
				org_json['Note'] = risk_org.note ? risk_org.note : ''
				org_json['CS Rep'] = cs_org_users && cs_org_users.length && org_cs_rep_map && org_cs_rep_map[risk_org.org_id] ? cs_org_users.find(o => o.id === org_cs_rep_map[risk_org.org_id]).first_name : ''
				return org_json
			})
			const csvConfig = mkConfig({ useKeysAsHeaders: true });
			const csv = generateCsv(csvConfig)(csv_stores);
			download(csvConfig)(csv)
		} catch (e) {
			console.error(e)
			toastr.error(`Couldn't export to CSV`)
		}
	}

	const fetch_cs_executives = async () => {
		try {
			let account_details = cs_org_users
			let resp = await general_fetch({ url: 'customer_success_executive/get' })

			if (resp && resp.length) {
				for (let i = 0; i < resp.length; i++) {
					let user = account_details.find(o => o.id === resp[i].user_id)
					resp[i].first_name = user ? user.first_name : ''
					resp[i].email = user ? user.email : ''
				}
				if (user_details && resp.find(o => o.user_id === user_details.id)) {
					set_selected_cses([{ value: user_details.id, label: user_details.first_name }])
				}
				set_cs_executives(resp)
			}
		} catch (err) {
			console.error(err)
		}
	}

	const filter_fn = () => {
		let temp_orgs_at_risk = JSON.parse(JSON.stringify(orgs_at_risk));

		if (permanent_filtration_org_id) {
			temp_orgs_at_risk = temp_orgs_at_risk.filter(o => o.org_id === permanent_filtration_org_id);
            temp_orgs_at_risk = temp_orgs_at_risk.map((x, idx) => ({...x, idx: idx+1}));
            set_display_orgs_at_risk(temp_orgs_at_risk)		
            return
		}

		if(org_filter && org_filter.length > 0) {
			let selected_org_ids = org_filter.map(x => x.value)
			temp_orgs_at_risk = temp_orgs_at_risk && temp_orgs_at_risk.length ? temp_orgs_at_risk.filter(o => selected_org_ids.includes(o.org_id)) : []
		}

		if(selected_cses && selected_cses.length > 0) {
			let csrep_ids = selected_cses.map(x => x.value)
			temp_orgs_at_risk = temp_orgs_at_risk && temp_orgs_at_risk.length ? temp_orgs_at_risk.filter(o => csrep_ids.includes(org_cs_rep_map[o.org_id])) : []
		}	

		if(tag_filter && tag_filter.length > 0) {
			let selected_tags = tag_filter.map(x => x.value)
			temp_orgs_at_risk = temp_orgs_at_risk && temp_orgs_at_risk.length ? temp_orgs_at_risk.filter(o => o.risk_metadata && o.risk_metadata.tags && o.risk_metadata.tags.length && o.risk_metadata.tags.some(item => selected_tags.includes(item))) : []
		}
		
		// if(from_date && to_date){
		// 	temp_orgs_at_risk = temp_orgs_at_risk && temp_orgs_at_risk.length ? temp_orgs_at_risk.filter(o => new Date(o.start_date) >= new Date(from_date) && new Date(o.start_date) <= new Date(to_date)) : []
		// }
		if(week_filter && week_filter.length){
			let selected_dates = week_filter.map(o => o.value)
			temp_orgs_at_risk = temp_orgs_at_risk && temp_orgs_at_risk.length ? temp_orgs_at_risk.filter(o => selected_dates.includes(date_string(o.start_date))) : []
		}

		if(payment_cycle_filter){
			temp_orgs_at_risk = temp_orgs_at_risk && temp_orgs_at_risk.length ? temp_orgs_at_risk.filter(o => o.payment_cycle == payment_cycle_filter) : []	
		}

		temp_orgs_at_risk = temp_orgs_at_risk.map((x, idx) => ({...x, idx: idx+1}));
		
		set_display_orgs_at_risk(temp_orgs_at_risk)		
	}

	useEffect(() => {
		if(cs_org_users && cs_org_users.length > 0 && user_details && user_details.id) fetch_cs_executives()
	}, [cs_org_users, user_details]);

	useEffect(() => {
		if(user_details && user_details.id && all_stores && all_stores.length){
			fetch_clients_at_risk()
		}
	}, [user_details, all_stores]);

	useEffect(() => {
		if(document.getElementById('clients_at_risk_header') && document.getElementById('clients_at_risk_table')){
			document.getElementById('clients_at_risk_table').style.height = window.innerHeight - document.getElementById('clients_at_risk_header').offsetHeight + 'px'
		}

		let last_monday = new Date(new Date().toDateString());
		if (last_monday.getDay() === 0) {//special handling if today is sunday
			last_monday.setDate(last_monday.getDate() - 6);
		}
		else {
			last_monday.setDate(last_monday.getDate() - (last_monday.getDay() - 1));
		}
		last_monday.setHours(0); last_monday.setMinutes(0); last_monday.setSeconds(0); last_monday.setMilliseconds(0);

		let _7_days_before_last_monday = new Date(last_monday)
		_7_days_before_last_monday.setDate(last_monday.getDate()-7)

		fetch_mix_panel_data(new Date(_7_days_before_last_monday), new Date(last_monday), set_last_week_usage)

		let last_monday_copy = new Date(last_monday)
		let temp_week_filter_options = []
		for(let i = 0; i < 20; i++){
			temp_week_filter_options.push({value: date_string(last_monday_copy), label:date_string(last_monday_copy)})
			last_monday_copy.setDate(last_monday_copy.getDate() - 7)
		}
		console.log('temp_week_filter_options', temp_week_filter_options)
		set_week_filter_options(temp_week_filter_options)
        set_week_filter([temp_week_filter_options[0]])
	}, []);

	// useEffect(() => {
	// 	if(all_stores && all_stores.length && user_details && user_details.id){
	// 		let temp_orgs_at_risk = JSON.parse(JSON.stringify(orgs_at_risk))
	// 		if(!is_cs_admin){
	// 			let team_members = [user_details.id]
	// 			if(is_team_lead && infurnia_teams && infurnia_teams.length){
	// 				infurnia_teams.map(team => {
	// 					if(team.team_lead_id === user_details.id){
	// 						team_members = team_members.concat(team.users)
	// 					}
	// 				})
	// 			}
	// 			temp_orgs_at_risk = temp_orgs_at_risk && temp_orgs_at_risk.length ? temp_orgs_at_risk.filter(risk_org => all_stores.find(o => o.id === risk_org.org_id) && team_members.includes(all_stores.find(o => o.id === risk_org.org_id).customer_success_executive_id)) : []
	// 		}
	// 		set_display_orgs_at_risk(temp_orgs_at_risk)
	// 	}
	// }, [is_cs_admin, is_team_lead, user_details, all_stores, infurnia_teams, orgs_at_risk]);

	// useEffect(() => {
	// 	if(user_details && cs_org_users && cs_org_users.length){
	// 		if(user_details.role && user_details.role.store && user_details.role.store.length && user_details.role.store.includes('customer_success_admin')){
	// 			set_user_select_options(cs_org_users && cs_org_users.length ? cs_org_users.map(x => {return {value: x.id, label: x.first_name}}) : [])
	// 		}else if(team_members && team_members.length){
	// 			set_user_select_options(team_members.map(x => {return {value: x, label: cs_org_users.find(o => o.id===x).first_name}}))
	// 		}
	// 	}
	// }, [user_details, cs_org_users, team_members]);

	useEffect(() => {
		if(display_orgs_at_risk && display_orgs_at_risk.length && !user_selected_page_size){
			let x1 = document.getElementById('clients_at_risk_header').offsetHeight
			let x2 = 52
			let x3 = 45
			let remaining_height = window.innerHeight - x1 - x2 - x3
			let page_size1 = Math.floor(remaining_height/45)
			set_page_size(Math.floor(page_size1))
			console.log('no of pages', no_of_pages, page_size1, display_orgs_at_risk.length, x1, x2, remaining_height, window.innerHeight)
		}
	}, [display_orgs_at_risk]);

	useEffect(() => {
		if(display_orgs_at_risk && display_orgs_at_risk.length){
			let pages = Math.floor((display_orgs_at_risk.length / page_size)) + ((display_orgs_at_risk.length % page_size) ? 1 : 0)
			set_no_of_pages(pages)
		}else{
			set_no_of_pages(1)
		}
	}, [page_size, display_orgs_at_risk]);

	useEffect(() => {
		if(all_stores && all_stores.length){
			let json = {}
			for(let store of all_stores){
				json[store.id] = store.customer_success_executive_id
			}
			set_org_cs_rep_map(json)
		}
	}, [all_stores]);

	useEffect(() => {
		filter_fn()
	}, [selected_cses, org_filter, tag_filter, orgs_at_risk, from_date, to_date, week_filter, payment_cycle_filter]);

	const update_column_visibility_json = (column_name, value) => {
		let a = JSON.parse(JSON.stringify(column_visibility_json))
		a[column_name] = value
		set_column_visibility_json(a)
	}

	const column_selector = (
		<div className="p-3" style={{ color: '#323338', display:'flex', flexDirection:'column', height:'380px' }}>
			<div>
				<div className="mb-3" style={{ color: '#A7A8B2', color:'black', fontSize:'15px'}}>Cutomize Columns</div>
				<div className="flex_property justify-between mb-3">
					<ISearch style_outer={{width:'300px'}} style_inner={{width:'300px'}} search_text={column_search_text} set_search_text={set_column_search_text} list={columns} set_list={set_searched_columns} search_parameters={['column_name']}/>
				</div>
				<hr className='mb-3 mt-0' />
			</div>
			<div style={{flex:1, overflow:'auto'}}>
			{
				searched_columns.map(column => (
					<div className="flex_property justify-between" style={{marginBottom:'12px'}}>
						<div className="flex_property">
							<Checkbox checked={column_visibility_json[column.column_name]} onClick={() => {update_column_visibility_json(column.column_name, !column_visibility_json[column.column_name])}}>
								<div>{column.column_name}</div>
							</Checkbox>
						</div>
					</div>
				))
			}
			</div>
		</div>
	)

	return (
		<>
			<NoteModal open={show_note_modal} toggle={() => {set_show_note_modal(false); set_active_risk_org('')}} risk_org={active_risk_org} all_stores={all_stores} fetch_clients_at_risk={fetch_clients_at_risk}/>
			<div id='clients_at_risk_header'>
				{hide_header_row ? '' :
				(<>
				<div className="flex_center px-4 pt-3 mb-1" style={{justifyContent:'space-between'}}>
					<div><b>Orgs At Risk {`(${display_orgs_at_risk.length})`}</b></div>
					<div className="flex_center">
						{/* <ISearch search_text={search_string} set_search_text={set_search_string}/> */}
						<AntdButton  onClick={download_overview_csv}>Export to CSV</AntdButton>
                                        <Tooltip
                                            title={column_selector}
                                            color='white'
                                            placement='left'
                                            trigger={'click'}
                                            overlayStyle={{borderRadius:'16px', marginRight:'30px', maxWidth:'unset'}}
                                            overlayInnerStyle={{ maxHeight:'380px', overflow:'hidden', marginBottom:'10px'}}
                                            style={{borderRadius:'16px', width:'400px'}}
                                            fresh={true}
                                            open={show_column_selector}
                                            onOpenChange={() => set_show_column_selector(!show_column_selector)}
                                        >
                                            <div className='flex_center p-2' style={{}}>
                                                <i className='fa fa-cog cp' onClick={() => set_show_column_selector(!show_column_selector)} style={{fontSize:'18px'}} />
                                            </div>  
                                        </Tooltip>
					</div>
				</div>
				<div className="flex_between p-3">
					<div className="flex_center">
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								components={animatedComponents}
								value={{ value: payment_cycle_filter, label: payment_cycle_filter==3 ? 'Quarterly' : (payment_cycle_filter==12 ? 'Annual' : 'All') }}
								options={[{ value: '', label: 'All' }, { value: 3, label: 'Quarterly' }, { value: 12, label: 'Annual' }]}
								styles={colourStyles1}
								onChange={(selectedOptions) => set_payment_cycle_filter(selectedOptions.value)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'12px'}}>CS Rep:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								closeMenuOnSelect={false}
								components={animatedComponents}
								value={selected_cses}
								isMulti
								options={cs_executives && cs_executives.length ? cs_executives.map(x => { return { value: x.user_id, label: x.first_name } }) : []}
								styles={colourStyles}
								onChange={(selectedOptions) => set_selected_cses(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Tag:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								components={animatedComponents}
								value={tag_filter}
								isMulti
								options={[{value:'70_percent_dip', label:'70_percent_dip'}, {value:'over_to_under_100', label:'over_to_under_100'}, {value:'2_weeks_under_100', label:'2_weeks_under_100'}, {value:'first_2_weeks_under_100', label:'first_2_weeks_under_100'}, {value:'4_weeks_under_100', label:'4_weeks_under_100'}, {value:'8_weeks_under_100', label:'8_weeks_under_100'}]}
								styles={colourStyles3}
								onChange={(selectedOptions) => set_tag_filter(selectedOptions)}
							/>
						</div>
					</div>
					<div className="flex_center">
						{/* <DatePicker 
							className="mr-1" 
							format="YYYY-MM-DD"
							onChange={(date, dateString) => set_from_date(dateString)}
						/>
						-
						<DatePicker 
						className="ml-1" 
						format="YYYY-MM-DD"
						onChange={(date, dateString) => set_to_date(dateString)}
					/> */}
						<div className="mr-2" style={{fontSize:'13px'}}>Org:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								components={animatedComponents}
								value={org_filter}
								isMulti
								options={all_stores && all_stores.length ? all_stores.map(x => { return { value: x.id, label: (x.name + ' (' + x.id + ')') } }) : []}
								styles={colourStyles}
								onChange={(selectedOptions) => set_org_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Week:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								components={animatedComponents}
								value={week_filter}
								isMulti
								options={week_filter_options}
								styles={colourStyles}
								onChange={(selectedOptions) => set_week_filter(selectedOptions)}
							/>
						</div>
					</div>
				</div>
				</>)}
			</div>
			<div className="mx-2" id='clients_at_risk_table' style={{overflow:'hidden', backgroundColor:'white', position:'relative'}}>
			{
				display_orgs_at_risk && display_orgs_at_risk.length?
				<ITable style={{fontSize:'13px', whiteSpace:'nowrap'}} style_container={{overflow:'auto', height:'calc(100% - 52px)'}} 
						coloumnSeparated={1} rowSeparated={1} resizable={true} minCellWidth={80} 
						headings={headings} columnWidths={headings && headings.length ? headings.filter(o => column_visibility_json[o.column_name]).map(o => o.columnWidth).join(' ') : 'k'} tableSettings={false}
						column_visibility_json = {column_visibility_json} set_column_visibility_json={set_column_visibility_json} cellHeight={'45px'}
				>
					<ITableBody>
					{
						display_orgs_at_risk && display_orgs_at_risk.length ? display_orgs_at_risk.slice((current_page - 1) * page_size, current_page * page_size).map((risk_org, idx) => {
							let json = TableData(risk_org, idx)
							return(
								Object.keys(json).map((key) => (
									<ITableRow className='display_onhover' >
										{column_visibility_json[key]?json[key]:''}
									</ITableRow>
								))
							)
						}):''
					}
					</ITableBody>
				</ITable>:<div className="" style={{textAlign:'center', fontSize:'14px', paddingTop:'10px'}}> {hide_header_row ? "No Risk history" : "No Orgs at Risk"} <i className="fa fa-search ml-1" /></div>
			}
				<Col xs="12" id='paginator' className="flex_center py-2">
					<TablePagination current_page={current_page} set_current_page={set_current_page} no_of_pages={no_of_pages} page_size={page_size} set_page_size={set_page_size} set_user_selected_page_size={set_user_selected_page_size} />
				</Col>
			</div>
		</>
	)
}

export default ClientsAtRisk