export const impact_area_options = [
    {
        label: 'Design Tools',
        options: [
            {value: 'General Design', label: 'General Design'},
            {value: 'Modular Customization', label: 'Modular Customization'},
            {value: 'User Component', label: 'User Component'},
        ]
    },
    {
        label: 'Render',
        options: [
            {value: 'Render', label: 'Render'}
        ]
    },
    {
        label: 'Presentation',
        options: [
            {value: 'Presentation', label: 'Presentation'}
        ]
    },
    {
        label: 'Sales Channel & Pricing',
        options: [
            {value: 'Sales Channel & Pricing', label: 'Sales Channel & Pricing'}
        ]
    },
    {
        label: 'MES - Manufacturing',
        options: [
            {value: 'Cutlist', label: 'Cutlist'},
            {value: 'Board Layout', label: 'Board Layout'},
            {value: 'CAM Setup', label: 'CAM Setup'},
            {value: 'CAM Rules', label: 'CAM Rules'},
            {value: 'CAM Hardware', label: 'CAM Hardware'},
        ]
    },
    {
        label: 'MES - Others',
        options: [
            {value: 'Work Order', label: 'Work Order'},
            {value: 'Tracking', label: 'Tracking'},
            {value: 'Packaging', label: 'Packaging'},
            {value: 'Shipment', label: 'Shipment'},
        ]
    },
    {
        label: 'Others',
        options: [
            {value: 'Others', label: 'Others'},
        ]
    },
]
