import React, { useEffect, useState } from "react";
import {toastr} from 'react-redux-toastr';
import Select from "react-select";
import { Button, Card, CardHeader, Col, Container, FormGroup, Input, Modal, Row, Table } from "reactstrap";
import general_fetch from "../../utils/fetch";

const styles = {
	header_row: {
		marginLeft: 0,
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	round_status: {
		fontSize: 'small',
		paddingBottom: 0
	},
	nav_pills_rounds: {
		paddingRight: 60,
		marginRight: "0.7rem",
		marginLeft: "0.2rem",
	},
	action_button: {
		margin: 1       
	}
};

const AddEditTeam = ({open, toggle, mode, cs_org_users, active_team, fetch_teams}) => {

	const [team_name, set_team_name] = useState('');
	const [selected_users, set_selected_users] = useState([]);
	const [team_lead, set_team_lead] = useState({});

	const add_team = async() => {
		try{
			let body = {user_ids: selected_users, name: team_name, team_lead_id: team_lead.value, type: window.location.origin.includes('bdp') ? 'business_development' : 'customer_success'}
			let resp = await general_fetch({url:'team/create', body})
			fetch_teams()
			toastr.success('Team created successfully')
			onClick_handleClose()
		}catch(e){
			console.error(e);
			toastr.error(`Couldn't create Team, please try again`)
		}
	}

	const update_team = async() => {
		try{
			let body = {id:active_team.id, }

			if(active_team.name != team_name) body['name'] = team_name
			if(active_team.team_lead_id != team_lead.value) body['team_lead_id'] = team_lead.value
			if(JSON.stringify(selected_users.sort()) != JSON.stringify(active_team.users.sort())) body['user_ids'] = selected_users

			let resp = await general_fetch({url:'team/update', body})
			fetch_teams()
			toastr.success('Team updated successfully')
			onClick_handleClose()
		}catch(e){
			console.error(e);
			toastr.error(`Couldn't update Team, please try again`)
		}
	}

	const onClick_handleClose = () => {
		set_team_name('')
		set_selected_users([])
		set_team_lead()
		toggle()
	}

	useEffect(() => {
		if(active_team && mode==='edit'){
			if(active_team.name) set_team_name(active_team.name)
			if(active_team.users && active_team.users.length){
				set_selected_users(active_team.users)
			}
			if(active_team.team_lead_id){
				set_team_lead({value: active_team.team_lead_id, label: cs_org_users && cs_org_users && cs_org_users.find(o => o.id===active_team.team_lead_id).first_name})
			}
		}
	}, [active_team, mode]);

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>{mode==='add' ? 'Add' : 'Update'} Team</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
				<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
				<Row>
                    <Col md="12">
                      <FormGroup>
					  	<label className="form-control-label" htmlFor="input-address" >
							Name
                        </label>
                        <Input name="credits" className="form-control-alternative mb-3" type="text" placeholder='Enter Team Name' value={team_name} onChange={(e) => set_team_name(e.target.value)} />
						<div className="mb-3" style={{position:'relative', zIndex:'5'}}>
							<label className="form-control-label" htmlFor="input-address" >
								Team Lead
							</label>
							<Select
								// closeMenuOnSelect={false}
								value = {team_lead}
								options={cs_org_users && cs_org_users.length && cs_org_users.map(c => {return {'value': c.id, 'label': c.first_name}})}
								onChange={(selectedOptions) => set_team_lead(selectedOptions)}
							/>
						</div>
                        <div style={{position:'relative', zIndex:'4'}}>
							<label className="form-control-label" htmlFor="input-address" >
								Select Users
							</label>
							<Select
								closeMenuOnSelect={false}
								// components={animatedComponents}
								value = {selected_users && selected_users.length ? selected_users.map(x => {return {value:x, label:cs_org_users && cs_org_users.length ? cs_org_users.find(o => o.id===x).first_name : ''}}) : []}
								isMulti
								options={cs_org_users && cs_org_users.length && cs_org_users.map(c => {return {'value': c.id, 'label': c.first_name}})}
								// styles={colourStyles}
								onChange={(selectedOptions) => set_selected_users(selectedOptions && selectedOptions.length ? selectedOptions.map(x => x.value) : [])}
							/>
						</div>
                      </FormGroup>
                    </Col>
                  </Row>
			</div>
			<div className="modal-footer" style={{height:'60px'}}>
				<Button color='primary' disabled={(team_name && team_lead && team_lead.value && selected_users && selected_users.length ? false : true)} onClick={mode==='add' ? add_team : update_team}>Submit</Button>
			</div>
		</Modal>
	)
}

const Teams = ({user_details, cs_org_users, set_infurnia_teams}) => {

	const [add_modal_open, set_add_modal_open] = useState(false);
	const [display_teams, set_display_teams] = useState([]);
	const [mode, set_mode] = useState('');
	const [active_team, set_active_team] = useState();

	const toggle_add_modal = () => set_add_modal_open(!add_modal_open)

	const fetch_teams = async() => {
		try{
            var resp = await general_fetch({url: 'team/get'});
			if(window.location.origin.includes('bdp')){
				resp = resp.filter(o => o.type === 'business_development')
			}else if(window.location.origin.includes('csp')){
				resp = resp.filter(o => o.type === 'customer_success')
			} 
			set_display_teams(resp)
			set_infurnia_teams(resp)//this is to refetch in admin.jsx
		}catch(err){
			console.error(err)
		}
	}

	useEffect(() => {
		fetch_teams()
	}, []);

	return(
		<>
			<Container fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-4 bg-gradient-secondary">
								<Row className='mb-3 mr-3' style={{ display: 'flex', justifyContent: 'end' }}><Button color='primary' onClick={() => {toggle_add_modal();set_mode('add')}}>Add Team</Button></Row>
							</CardHeader>
							<div style={{minHeight:'200px'}}>
								<Table className="align-items-center table-flush text-center" responsive>
									<thead className="thead-light text-center">
										<tr style={{ textAlign: 'left' }}>
											<th scope="col"><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Team Name</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Team Lead</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Members</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Actions</b></span>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{
											display_teams && display_teams.length ? display_teams.map((single_team, idx) => (
												<tr style={{ textAlign: 'left' }}>
													<td scope="col"><span>{idx + 1}</span>&nbsp;</td>
													<td scope="col"><span>{single_team.name ? single_team.name : 'N/A'}</span>&nbsp;</td>
													<td scope="col"><span>{single_team.team_lead_id && cs_org_users && cs_org_users.length ? cs_org_users.find(o => o.id===single_team.team_lead_id).first_name : 'N/A'}</span>&nbsp;</td>
													<td scope="col">
														<div title={single_team.users && single_team.users.length ? single_team.users.map((c, idx) => cs_org_users && cs_org_users.length && cs_org_users.find(o => o.id===c).first_name).join(', ') : 'N/A'} className=" lines1_elipsis" style={{maxWidth:'300px',}}>
															{single_team.users && single_team.users.length ? single_team.users.map((c, idx) => cs_org_users && cs_org_users.length && cs_org_users.find(o => o.id===c).first_name).join(', ') : 'N/A'}
														</div>
													</td>
													<td scope="col">
														<i onClick={() => { set_active_team(single_team); toggle_add_modal(); set_mode('edit')}} className="fa fa-edit p-2 cp"></i>
													</td>
												</tr>
											)) : ''
										}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
				<AddEditTeam open={add_modal_open} toggle={() => {toggle_add_modal(); set_mode(''); set_active_team()}} user_details={user_details} fetch_teams={fetch_teams} active_team={active_team} mode={mode} cs_org_users={cs_org_users}/>
			</Container>
		</>
	)
}

export default Teams