import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {Link} from "react-router-dom";

// 3rd party components
import {Modal, FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Stepper from 'react-stepper-horizontal';
import Moment from 'react-moment';

// core components
import Header from "../../components/Headers/Header.jsx";

import {get_user_details} from '../../actions';
import {get_all_orders, get_investment_status, create_investment, update_investment, delete_investment} from './actions';

import './style.css';

const rupee_symbol = <>&#8377; </>

class Home extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          orders: this.props.orders,
          active_order: null,
          show_details_modal: null
      };
  }

  componentWillReceiveProps(next_props) {
      this.setState({ orders: next_props.orders });  
  }

  componenetDidUpdate(prev_props) {
      console.log('pp', this.props.orders)
      if (prev_props.orders !== this.props.orders) {
          this.setState({orders: this.props.orders});
      }
  }

  toggle_details_modal = active_order => {
      var show_details_modal = true;
      console.log(this.state)
      console.log(active_order)
      
      if (active_order) {
          var price = 'indian_price';
          var licenses = active_order.licenses;
          var order_items = active_order.order_details
          // var order_items = licenses.reduce((final, elem) => ({...final, [elem.license_plan_id]: final[elem.license_plan_id] ? {quantity: final[elem.license_plan_id].quantity + 1, amount: final[elem.license_plan_id].amount + Number(elem[price])} : {quantity: 1, amount: Number(elem[price])}}), {});
          this.setState({order_items});
      }

      if (this.state.show_details_modal) {
          show_details_modal = false;
      }
      this.setState({show_details_modal, active_order})
  }

  toggle_payments_modal = active_order => {
      var show_payments_modal = true;
      // console.log(this.state)
      // console.log(active_order)
      
      if (active_order) {
          var price = 'indian_price';
          var amount = 'indian_amount';
          var currency = "₹";
          if (active_order.international_price) {
              price = "international_price";
              amount = "international_amount";
              currency = "$";
          }
          var payments = active_order.license_payment;
          var amount_paid = payments.reduce((sum, x) => sum + x[amount], 0);
          this.setState({payments, price, amount, currency, amount_paid});
      }

      if (this.state.show_payments_modal) {
          show_payments_modal = false;
      }
      this.setState({show_payments_modal, active_order});
  }


  badge = (text, color, style = {}) => (
      <Badge color={color} className="mb-0" style={{display: "inline-block", marginLeft:"auto", ...style}}><h5 className="mb-0">{text}</h5></Badge>
  )


  order_details_modal = () => (
      <Modal className="modal-dialog-centered" size="lg" isOpen={this.state.show_details_modal} toggle={() => this.toggle_details_modal()}>
        <div className="modal-header  bg-gradient-secondary">
            <h4 className="modal-title" style={{margin: 'auto'}} id="exampleModalLabel"> Order Details </h4>
            <span className="pull-right">{this.badge("Order ID - " + this.state.active_order.id, "primary")}</span>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggle_details_modal()}>
              <span aria-hidden={true}>×</span>
            </button>
        </div>
        <div className="modal-body  bg-gradient-secondary" >
            <br />
            <Table className="align-items-center table-flush text-center" responsive>
                <thead className="thead-light text-center">
                  <tr>
                    <th scope="col"><span><b>Product</b></span><br /></th>
                    <th scope="col"><span><b>Unit Price</b></span><br /></th>
                    <th scope="col"><span><b>Quantity</b></span> <br /></th>
                    <th scope="col"><span><b>Total Cost</b></span><br /></th>
                  </tr>
                </thead>
                <tbody>
                    {this.state.order_items && this.state.order_items.map(item => (
                        <tr>
                            <td>{item.product_id}</td>
                            <td>1</td>
                            <td>{item.quantity}</td>
                            <td>{item.amount}</td>
                        </tr>
                    ))}
                </tbody>
                <thead className="thead-light text-center">
                  <tr>
                    <th scope="col" className="text-right" colSpan="3"><span><b>GST</b></span><br /></th>
                    <th scope="col"><span><b>{this.state.active_order.indian_taxes}</b></span><br /></th>
                  </tr>
                  <tr>
                    <th scope="col" className="text-right" colSpan="3"><span><b>Total</b></span><br /></th>
                    <th scope="col"><span><b>{this.state.active_order.indian_price}</b></span><br /></th>
                  </tr>
                </thead>
              </Table>
        </div>
      </Modal>
  )

  payments_modal = () => (
      <Modal className="modal-dialog-centered" size="lg" isOpen={this.state.show_payments_modal} toggle={() => this.toggle_payments_modal()}>
        <div className="modal-header  bg-gradient-secondary">
            <h4 className="modal-title" id="exampleModalLabel" style={{margin: 'auto'}}> Payment Details </h4>
            <span className="pull-right">{this.badge("Order ID - " + this.state.active_order.id, "info")}</span>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggle_payments_modal()}>
              <span aria-hidden={true}>×</span>
            </button>
        </div>
        <div className="modal-body  bg-gradient-secondary" >
            <Row>
                <Col sm={4}>
                    <div style={{width: '100%'}}>{this.badge("Billed - " + this.state.currency + " " + this.state.active_order[this.state.price], "info", {width: '100%'})}</div>
                </Col>
                <Col sm={4}>
                    <div style={{width: '100%'}}>{this.badge("Paid - " + this.state.currency + " " + this.state.amount_paid, "info", {width: '100%'})}</div>
                </Col>
                <Col sm={4}>
                    <div style={{width: '100%'}}>{this.badge("Due - " + this.state.currency + " " + (this.state.active_order[this.state.price] - this.state.amount_paid), "info", {width: '100%'})}</div>
                </Col>
            </Row>
            <br />
            <Table className="align-items-center table-flush text-center" responsive>
                <thead className="thead-light text-center">
                  <tr>
                    <th scope="col"><span><b>ID</b></span><br /></th>
                    <th scope="col"><span><b>Date</b></span><br /></th>
                    <th scope="col"><span><b>Mode</b></span> <br /></th>
                    <th scope="col"><span><b>Amount</b></span><br /></th>
                  </tr>
                </thead>
                <tbody>
                    {this.state.payments && this.state.payments.map(payment => (
                        <tr>
                            <td>{payment.id}</td>
                            <td>{payment.created_at}</td>
                            <td><Badge color="primary">{payment.payment_mode}</Badge></td>
                            <td>{payment.international_amount ? "$ " : rupee_symbol} {payment.international_amount ? payment.international_amount : payment.indian_amount}</td>
                        </tr>
                    ))}
                </tbody>
              </Table>
        </div>
      </Modal>
  )

  render() {
    const styles = {
      header_row: {
        marginLeft: 0,
        overflowX: "auto",
      },
      table_header: {
        fontWeight: "bold",
        fontSize: 'small'
      },
      round_status: {
        fontSize: 'small',
        paddingBottom: 0
      },
      nav_pills_rounds: {
        paddingRight: 60,
        marginRight: "0.7rem",
        marginLeft: "0.2rem",
      },
      action_button: {
        margin: 1       
      }
    }; 
    

    const success_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-success" />
        completed
      </Badge>
    );

    const warning_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-warning" />
        pending
      </Badge>
    );

    const orders_table = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>ID</b></span><br /></th>
            {this.props.show_store_id && (<th scope="col"><span style={styles.table_header}><b>Store ID</b></span><br /></th>)}
            <th scope="col"><span style={styles.table_header}><b>Type</b></span> <br /></th>
            <th scope="col"><span style={styles.table_header}><b>Date</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Status</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Details</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Payments</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Amount</b></span><br /></th>
          </tr>
        </thead>
        <tbody>
            {this.state.orders && this.state.orders.sort((a, b) => a.created_at < b.created_at ? 1 : -1).map(order => (
                <tr>
                    <td>{order.id}</td>
                    {this.props.show_store_id && (<td><Link to={"/home/store_dashboard/" + order.store_id}>{order.store_id}</Link></td>)}
                    <td>{order.type}</td>
                    <td><Moment format="DD/MM/YYYY">{order.created_at}</Moment></td>
                    <td><Badge color={order.status == 'confirmed' ? "success" : "danger"}>{order.status}</Badge></td>
                    <td><Button className="btn-sm" color="primary" onClick={() => this.toggle_details_modal(order)}>View</Button></td>
                    <td><Button className="btn-sm" color="primary" onClick={() => this.toggle_payments_modal(order)}>View</Button></td>
                    <td>{order.international_price ? "$ " : rupee_symbol} {order.international_price ? order.international_price : order.indian_price}</td>
                </tr>
            ))}
        </tbody>
      </Table>
    )


    const investment_filler = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>Fetching all orders </b></span><br/> Please wait...<br /> &nbsp;</th>
          </tr>
        </thead>
      </Table>
    )


    return (
      <>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow bg-secondary">
                <CardHeader className="border-0">
                  <Row style={styles.header_row}>
                    <b>All Orders</b>
                  </Row>
                </CardHeader>
                {this.state.orders ? orders_table : investment_filler}           
                {this.state.active_order && (<this.order_details_modal />)}
                {this.state.active_order && (<this.payments_modal />)}
              </Card>
            </div>
          </Row>
      </>
    );
  }
}


const mapStateToProps = (state, own_props) => ({
    user_details: state.user_details,
    license_plans: state.license_plans,
    ...own_props
})


const mapDispatchToProps = dispatch => ({
    get_all_orders: () =>  dispatch(get_all_orders()),
    get_user_details: () =>  dispatch(get_user_details()),
    get_investment_status: () =>  dispatch(get_investment_status()),
    create_investment: data =>  dispatch(create_investment(data)),
    update_investment: data =>  dispatch(update_investment(data)),
    delete_investment: data =>  dispatch(delete_investment(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);