import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {Link} from 'react-router-dom';
import {Icon} from 'react-fa';

// reactstrap components
import {Badge, Card, CardBody, Button, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
// core components
import Header from "../../components/Headers/Header.jsx";
import {get_user_details} from '../../actions';

import './style.css';

class Working extends React.Component {
  
  constructor(props) {
      super(props);
  }

  componentDidMount() {
  }

  render() {

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Complete your investment in 4 simple steps</h3>
                </CardHeader>
                <CardBody>
                  <br />
                  <Row className="mb-4 text-center">
                    <Col xs="4" sm="2" className="text-center">
                      <Button className="btn-link "> STEP 1</Button>
                      <div className="bg-gradient-info mt-2" style={{width: 4, height: "calc(100% - 46px)", marginLeft:"calc(50% - 4px)"}}></div>
                    </Col> 
                    <Col xs="8" sm="10"> 
                      <Card className="border-0 bg-gradient-info" style={{textColor: "white"}}>
                        <CardHeader className="bg-gradient-info text-left border-0">
                          <h3 className="text-white">
                            <Icon className="mr-2" name="warning"/> Express Interest 
                          </h3>
                        </CardHeader>
                        <CardBody >
                          <div className="text-left">
                            <div className="h4 font-weight-400 text-white">
                               As a first step, you can go through termsheet on the left tab and express your interest in investing in Infurnia by selecting the investment amount. 
                               On expressing your interest, you can book meeting with the co-founder through the meetings tab.
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-4 text-center">
                    <Col xs="4" sm="2">
                      <Button className="btn-link btn-lg"> STEP 2</Button>
                      <div className="bg-gradient-info mt-2" style={{width: 4, height: "calc(100% - 46px)", marginLeft:"calc(50% - 4px)"}}></div>
                    </Col> 
                    <Col xs="8" sm="10"> 
                      <Card className="border-0 bg-gradient-info" style={{color: "white"}}>
                        <CardHeader className="bg-gradient-info text-left border-0">
                          <h3 className="text-white">
                            <Icon className="mr-2" name="thumbs-up"/> Commit Investment 
                          </h3>
                        </CardHeader>
                        <CardBody >
                          <div className="text-left">
                            <div className="h4 font-weight-400 text-white">
                              Once you are satisfied about the investment prospects, 
                              you can commit the investment amount, which will get you an exclusive 15 day period to sign the Share Subscription Agreement (SSA). 
                              The investment amount is blocked for you and it gets released for others at the end of the 15th day, if you do not sign SSA by then.
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-4 text-center">
                    <Col xs="4" sm="2">
                      <Button className="btn-link bg-info-gradient btn-lg"> STEP 3</Button>
                      <div className="bg-gradient-info mt-2" style={{width: 4, height: "calc(100% - 46px)", marginLeft:"calc(50% - 4px)"}}></div>
                    </Col> 
                    <Col xs="8" sm="10"> 
                      <Card className="border-0 bg-gradient-info" style={{color: "white"}}>
                        <CardHeader className="bg-gradient-info text-left border-0">
                          <h3 className="text-white">
                            <Icon className="mr-2" name="edit"/> Sign SSA 
                          </h3>
                        </CardHeader>
                        <CardBody >
                          <div className="text-left">
                            <div className="h4 font-weight-400 text-white">
                              Within the 15 day exclusive commitment period, you can sign the Share Subscription Agreement (SSA). 
                              The SSA along with Share Holder’s Agreement (SHA) contains all relevant information regarding the investment. 
                              Once you fill out all the personal details on my profile tab, Infurnia will reach out with physical copy of SSA to be signed.
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-4 text-center">
                    <Col xs="4" sm="2">
                      <Button className="btn-link btn-lg"> STEP 4</Button>
                    </Col> 
                    <Col xs="8" sm="10"> 
                      <Card className="border-0 bg-gradient-info" style={{color: "white"}}>
                        <CardHeader className="bg-gradient-info text-left border-0">
                          <h3 className="text-white">
                            <Icon className="mr-2" name="check-circle"/> Investment Complete 
                          </h3>
                        </CardHeader>
                        <CardBody >
                          <div className="text-left">
                            <div className="h4 font-weight-400 text-white">
                              Within 10 days of signing SSA, you can transfer subscription money to Infurnia bank account as set in Schedule C of SSA. 
                              On transfer of money, Infurnia will carry out the rest of paperwork to close the investment.
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    user_details: state.user_details
})

const mapDispatchToProps = dispatch => ({
    get_user_details: () => dispatch(get_user_details())
})

export default connect(mapStateToProps, mapDispatchToProps)(Working);