
import React, {useState, useEffect, useRef} from "react";
import { Input, Pagination, PaginationItem, PaginationLink, } from "reactstrap";
import { LoadingOutlined } from '@ant-design/icons';

const TablePagination = ({current_page, set_current_page, no_of_pages, page_size, set_page_size, set_user_selected_page_size, fetching_usage}) => (
	<div style={{minWidth:'300px'}}>
	  <Pagination className='flex_center' style={{ width:'100%'}}>
	  <PaginationItem >
		  <PaginationLink aria-label="Previous" onClick={() => set_current_page(1)}>
			<i className="fa fa-angle-double-left" /><span className="sr-only">Previous</span>
		  </PaginationLink>
		</PaginationItem>
		<PaginationItem >
		  <PaginationLink aria-label="Previous" onClick={() => set_current_page((current_page > 1) ? (current_page - 1) : current_page)}>
			<i className="fa fa-angle-left" /><span className="sr-only">Previous</span>
		  </PaginationLink>
		</PaginationItem>
		<div className="flex_center" style={{flex:1}}>
			{'Page ' + current_page + ' of ' + (no_of_pages)}
		</div>
		<Input value={page_size} onChange={(e) => {set_page_size(e.target.value); if(set_user_selected_page_size)set_user_selected_page_size(e.target.value); set_current_page(1);}} type='select' className='p-1 ml-2' style={{width:'110px', height:'30px', position:'absolute', left:'8px', bottom:'10px'}}>	
			<option value={page_size}>{page_size} per page</option>
			{page_size != 10 ? <option value={10}>10 per page</option> : ''}
			{page_size != 20 ? <option value={20}>20 per page</option> : ''}
			{page_size != 30 ? <option value={30}>30 per page</option> : ''}
			{page_size != 40 ? <option value={40}>40 per page</option> : ''}
		</Input>
		{
			fetching_usage ?
			<div className="flex_property" style={{position:'absolute', right:'8px', bottom:'14px', fontSize:'14px'}}>
				<LoadingOutlined className="mr-2" style={{fontSize:'18px'}} />
				<div>Fetching Usage...</div>
			</div>:""
		}
		<PaginationItem >
		  <PaginationLink aria-label="Next" onClick={() => set_current_page((current_page < no_of_pages) ? (current_page + 1) : current_page)}>
			<i className="fa fa-angle-right" /> <span className="sr-only">Next</span>
		  </PaginationLink>
		</PaginationItem>
		<PaginationItem >
		  <PaginationLink aria-label="Next" onClick={() => set_current_page(no_of_pages)}>
			<i className="fa fa-angle-double-right" /> <span className="sr-only">Next</span>
		  </PaginationLink>
		</PaginationItem>
	  </Pagination>
	</div>
);
export default TablePagination