import React, { useState, useEffect, isValidElement } from 'react';
import './styles.css'

const ISearch = ({classNameOuter, classnameInner, search_text, set_search_text, placeholder, style_outer, style_inner, list, set_list, search_parameters}) => {
	const [search_string, set_search_string] = useState('');
    const clear_search = () => {
        set_search_text('')
    }

	useEffect(() => {
		if(list && list.length){
            if (search_text) {
                if (search_parameters && search_parameters.length) {
                    // console.log()
                    set_list(
                        list.filter(x => {
                            let result = 0
                            search_parameters.map(search_value => {
                                if (x && x[search_value] && x[search_value].toLowerCase().includes(search_text.toLowerCase())) {
                                    result = 1
                                }
                            })
                            return result
                        })
                    )
                }
                else {
                    set_list(list.filter(x => x && x.toLowerCase().includes(search_text.toLowerCase())))
                }
            } else {
                set_list(list)
            }
        }else if(set_list){
            set_list([])
        }
	}, [search_text, list]);

    return (
        <div className={classNameOuter? classNameOuter : 'infurnia_search_component_container_ideal'} style={{...style_outer}}>
            <i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/>
            <input className={classnameInner? classnameInner :'infurnia_search_component_inner_container'} style={{...style_inner}} placeholder= {placeholder ? placeholder :'Type to Search'} type='text' value={search_text} onChange={(e) => set_search_text(e.target.value)}></input>
            <div className='hover_blue' style={{paddingRight:'12px', cursor:'pointer', fontSize:'10px', color:'#9aa5b5', visibility:search_text ? 'visible':'hidden', fontStyle:'italic'}} onClick={clear_search}><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-times'/></div>
        </div>
    )
}

export default ISearch