import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { toastr } from 'react-redux-toastr';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Modal, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, Dropdown, } from "reactstrap";
import Header from "../../components/Headers/Header.jsx";
import generic_sort from "../../utils/generic_sort.js";
import general_fetch from "../../utils/fetch.js";
import { mkConfig, generateCsv, download } from "export-to-csv";
import Select from "react-select";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import makeAnimated from 'react-select/animated';
import { convert_to_readable } from "../../utils/convert_to_readable.js";
import OrgFilterModal from "../../components/OrgFilterModal/index.jsx";
import DateRangeModal from "../../components/DateRangeModal/index.jsx";
import { addDays, set } from "date-fns";
import mixpanel_event_types from "../../utils/mixpanel_event_types.js";
import NA from "../../components/NA/index.js";
import fetch_mix_panel_data from "../../utils/fetch_mixpanel_data.js";
import filter_fn from "../../utils/org_filter_func.js";
import { colourStyles, colourStyles1, colourStyles2 } from "../../utils/react_select_styles.js";
import ISearch from "../../components/ISearch/index.jsx";
import { set_csp_filter } from "../../utils/set_csp_filter.js";
import { get_csp_filter } from "../../utils/get_csp_filter.js";
import TablePagination from "../../components/TablePagination/index.jsx";
import { ITD, ITable, ITableBody, ITableRow } from "../../components/ITable/index.jsx";
import { date_string } from "../../utils/date_string.js";
import { infurnia_add_ons } from "../../utils/infurnia_add_ons.js";
import general_sort_for_orgs from "../../utils/general_sort_for_orgs.js";
import generic_sort_for_orgs from "../../utils/generic_sort_for_orgs.js";
import { Checkbox, Tooltip } from 'antd';


const styles = {
	header_row: {
		marginLeft: 0,
		overflowX: "auto",
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	round_status: {
		fontSize: 'small',
		paddingBottom: 0
	},
	nav_pills_rounds: {
		paddingRight: 60,
		marginRight: "0.7rem",
		marginLeft: "0.2rem",
	},
	action_button: {
		margin: 1
	}
};

const animatedComponents = makeAnimated();

const PaidOrgs = ({ all_stores, cs_org_users, user_details, activity_metadata, fetch_activity_metadata }) => {

	const [search_text, set_search_text] = useState('');
	const [stores, set_stores] = useState(null);
	const [original_stores, set_original_stores] = useState(null);
	const [current_page, set_current_page] = useState(1);
	const [page_size, set_page_size] = useState(50);
	const [no_of_pages, set_no_of_pages] = useState(null);
	const [premium_filter, set_premium_filter] = useState(true);
	const [enterprise_filter, set_enterprise_filter] = useState('');
	const [suspended_filter, set_suspended_filter] = useState('');
	const [internal_filter, set_internal_filter] = useState('');
	const [se_filter, set_se_filter] = useState('');
	const [onboarding_filter, set_onboarding_filter] = useState('');
	const [ addon_onboarding_filter, set_addon_onboarding_filter ] = useState('');
	const [ addon_onboarding_date_from, set_addon_onboarding_date_from ] = useState('');
	const [ addon_onboarding_date_to, set_addon_onboarding_date_to ] = useState('');
	const [ country_filter, set_country_filter ] = useState([]);
	const [churn_date_filter, set_churn_date_filter] = useState('');
	const [churn_date_from, set_churn_date_from] = useState('');
	const [churn_date_to, set_churn_date_to] = useState('');

	const [suspension_date_filter, set_suspension_date_filter] = useState('');
	const [suspension_date_from, set_suspension_date_from] = useState('');
	const [suspension_date_to, set_suspension_date_to] = useState('');

	const [due_date_filter, set_due_date_filter] = useState('');
	const [due_date_from, set_due_date_from] = useState('');
	const [due_date_to, set_due_date_to] = useState('');

	const [last_billed_date_filter, set_last_billed_date_filter] = useState('');
	const [last_billed_date_from, set_last_billed_date_from] = useState('');
	const [last_billed_date_to, set_last_billed_date_to] = useState('');

	const [next_billing_date_filter, set_next_billing_date_filter] = useState('');
	const [next_billing_date_from, set_next_billing_date_from] = useState('');
	const [next_billing_date_to, set_next_billing_date_to] = useState('');

	const [license_start_date_filter, set_license_start_date_filter] = useState('');
	const [license_start_date_from, set_license_start_date_from] = useState('');
	const [license_start_date_to, set_license_start_date_to] = useState('');

	const [filter_active, set_filter_active] = useState(false);
	const [cs_executives, set_cs_executives] = useState([]);
	const [usage_start_time, set_usage_start_time] = useState(addDays(new Date(new Date().toDateString()), -31));
	const [usage_end_time, set_usage_end_time] = useState(new Date(new Date().toDateString()));
	const [usage_duration_filter, set_usage_duration_filter] = useState('custom');
	const [mixpanel_org_level_events, set_mixpanel_org_level_events] = useState({});
	const [mixpanel_org_level_events_past, set_mixpanel_org_level_events_past] = useState({});
	const [selectedNavItem, set_selectedNavItem] = useState(1);
	const [show_date_range, set_show_date_range] = useState(false);
	const [show_usage_date_range, set_show_usage_date_range] = useState(false);
	const [selected_cses, set_selected_cses] = useState([]);
	const [selected_usage_events, set_selected_usage_events] = useState([{ value: 'version_save', label: 'Version Save' }]);
	const [all_new_renewed_filter, set_all_new_renewed_filter] = useState('all');
	const [show_filter_modal, set_show_filter_modal] = useState(false);
	const [edited_cs_org_users, set_edited_cs_org_users] = useState();
	const initializedStateCount = useRef(0);
	const [filter_json_from_backend, set_filter_json_from_backend] = useState();
	const [last_or_next, set_last_or_next] = useState('last');
	const [responsiveness_filter, set_responsiveness_filter] = useState('');
	const [selected_add_ons, set_selected_add_ons] = useState('');
	const [selected_domains, set_selected_domains] = useState('');
	const [headings, set_headings] = useState()
	const [user_selected_page_size, set_user_selected_page_size] = useState(0);
	const [last_3_sorted_columns, set_last_3_sorted_columns] = useState([]);
	const [saved_last_3_sorted_columns, set_saved_last_3_sorted_columns] = useState([]);
	const [fetching_usage, set_fetching_usage] = useState(false);
	const [ payment_cycle_filter, set_payment_cycle_filter ] = useState('');

	const [show_column_selector, set_show_column_selector] = useState(false);
    const createHeaders = (headings) => {
        return headings.map((item, idx) => ({
            ...item,
            ref: ('resizable-header' + item.column_name),
            }));
    }
    const columns = useMemo(() => headings ? createHeaders(headings) : [], [headings])
	const [searched_columns, set_searched_columns] = useState(columns);
    const [column_search_text, set_column_search_text] = useState('');
	
	const get_sort_order = (column_name) => {
		return last_3_sorted_columns && last_3_sorted_columns.length && last_3_sorted_columns[last_3_sorted_columns.length-1].column_name === column_name ? last_3_sorted_columns[last_3_sorted_columns.length-1].order : false
	}

	const main_headings = useMemo(() => ([
		{
			column_name: 'Manage',
			styles:{position:"sticky", top:0, left:0, zIndex:3, backgroundColor:'white'},
			sort_fn: () => {},
			columnWidth: 'minmax(60px, 60px)'
		},
		{
			column_name: 'No.',
			styles:{position:'sticky', top:0, left: '60px', zIndex: 3, backgroundColor:'white'},
			sort_fn: () => {},
			columnWidth: 'minmax(60px, 60px)'
		},
		{
			column_name: 'Org Name',
			styles:{position:'sticky', top:0, left: '120px', zIndex: 3, backgroundColor:'white'},
			show_sort:true,
			show_sort_order: get_sort_order('Org Name'),
			sort_fn: () => general_sort_for_orgs('Org Name', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			columnWidth: 'minmax(200px, 2fr)'
		},
		{
			column_name: 'Org ID',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort: true,
			show_sort_order: get_sort_order('Org ID'),
			sort_fn: () => general_sort_for_orgs('Org ID', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Add-ons',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Add-ons'),
			sort_fn: () => general_sort_for_orgs( 'Add-ons', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort:true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Users',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Users'),
			sort_fn: () => general_sort_for_orgs( 'Users', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort:true,
			columnWidth:'minmax(100px, 2fr)'
		},
		{
			column_name: 'Versions',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order('Versions'),
			sort_fn: () => general_sort_for_orgs( 'Versions', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(120px, 2fr)'
		},
		{
			column_name: 'Usage',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Usage'),
			sort_fn: () => general_sort_for_orgs( 'Usage', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(120px, 2fr)'
		},
		{
			column_name: 'Usage Change',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Usage Change'),
			sort_fn: () => general_sort_for_orgs( 'Usage Change', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(140px, 2fr)'
		},
		{
			column_name: 'Renewal Prob',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order('Renewal Prob'),
			sort_fn: () => general_sort_for_orgs('Renewal Prob', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(140px, 2fr)'
		},
		{
			column_name: 'Next Billing',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Next Billing'),
			sort_fn: () => general_sort_for_orgs( 'Next Billing', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Last Billing',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Last Billing'),
			sort_fn: () => general_sort_for_orgs( 'Last Billing', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Start Date',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Start Date'),
			sort_fn: () => general_sort_for_orgs( 'Start Date', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort:true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Closing Date',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Closing Date'),
			sort_fn: () => general_sort_for_orgs( 'Closing Date', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Last Activity',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Last Activity'),
			sort_fn: () => general_sort_for_orgs( 'Last Activity', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Next Activity',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Next Activity'),
			sort_fn: () => general_sort_for_orgs( 'Next Activity', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Cutlist Machine',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Cutlist Machine'),
			sort_fn: () => general_sort_for_orgs( 'Cutlist Machine', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort:true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'CAM Machine',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'CAM Machine'),
			sort_fn: () => general_sort_for_orgs( 'CAM Machine', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort:true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Due by Date',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Due by Date'),
			sort_fn: () => general_sort_for_orgs( 'Due by Date', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Suspended',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Suspended'),
			sort_fn: () => general_sort_for_orgs( 'Suspended', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Onboarding',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Onboarding'),
			sort_fn: () => general_sort_for_orgs( 'Onboarding', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Domain',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Domain'),
			sort_fn: () => general_sort_for_orgs( 'Domain', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort:true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Enterprise',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Enterprise'),
			sort_fn: () => general_sort_for_orgs( 'Enterprise', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(120px, 2fr)'
		},
		{
			column_name: 'Billing Mails',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Billing Mails'),
			sort_fn: () => general_sort_for_orgs( 'Billing Mails', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Important',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Important'),
			sort_fn: () => general_sort_for_orgs( 'Important', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Activity Count',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Activity Count'),
			sort_fn: () => general_sort_for_orgs( 'Activity Count', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
		{
			column_name: 'Adv Pricing Onboarding',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Adv Pricing Onboarding'),
			sort_fn: () => general_sort_for_orgs( 'Adv Pricing Onboarding', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Adv Pricing Onboarding Date',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Adv Pricing Onboarding Date'),
			sort_fn: () => general_sort_for_orgs( 'Adv Pricing Onboarding Date', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(250px, 2fr)'
		},
		{
			column_name: 'Cutlist Onboarding',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Cutlist Onboarding'),
			sort_fn: () => general_sort_for_orgs( 'Cutlist Onboarding', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(200px, 2fr)'
		},
		{
			column_name: 'Cutlist Onboarding Date',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Cutlist Onboarding Date'),
			sort_fn: () => general_sort_for_orgs( 'Cutlist Onboarding Date', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(250px, 2fr)'
		},
		{
			column_name: 'Manufacturing Onboarding',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Manufacturing Onboarding'),
			sort_fn: () => general_sort_for_orgs( 'Manufacturing Onboarding', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(220px, 2fr)'
		},
		{
			column_name: 'Manufacturing Onboarding Date',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'Manufacturing Onboarding Date'),
			sort_fn: () => general_sort_for_orgs( 'Manufacturing Onboarding Date', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(270px, 2fr)'
		},
		{
			column_name: 'CS Rep',
			styles:{position:'sticky', top:0, backgroundColor:'white', zIndex:1},
			show_sort_order: get_sort_order( 'CS Rep'),
			sort_fn: () => general_sort_for_orgs( 'CS Rep', last_3_sorted_columns, set_last_3_sorted_columns, stores, set_stores, sorting_function_value),
			show_sort: true,
			columnWidth:'minmax(150px, 2fr)'
		},
	]), [stores]) 
	const [column_visibility_json, set_column_visibility_json] = useState(() => {
        let json = {}; 
        main_headings.map(col => {
            if(col.column_name != 'Org ID') {
                json[col.column_name] = 1
            } else {
                json[col.column_name] = 0
            }
        });
        return json
    });

	useEffect(() => {
		let temp_headings = [...main_headings]
		if(selectedNavItem==2){
			temp_headings.splice(19, 1)
		}else if(selectedNavItem==3){
			temp_headings.splice(18, 1)
		}else{
			temp_headings.splice(18, 2)
		}
		set_headings(temp_headings)
	}, [main_headings, selectedNavItem, stores, ])

	const TableData = useCallback((store, idx, current_page, page_size) => ({
		'Manage': <ITD style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex:2}} className='text-center' ><a href={"/home/store_dashboard/" + store.id + '/7'} target="_blank"><i className="fa fa-cog" color="primary" style={{ fontSize: '18px' }} /></a></ITD>,
		'No.': <ITD style={{ position: 'sticky', left: '60px', backgroundColor: 'white', zIndex:2}} title={store.idx ? store.idx : ''} className="ITD_style">{((current_page - 1) * page_size) + idx + 1}</ITD>,
		'Org Name': <ITD style={{ position: 'sticky', left: '120px', backgroundColor: 'white', zIndex:2}} title={store.name ? store.name : ''} className="ITD_style">{store.name ? store.name : <NA />}</ITD>,
		'Org ID': <ITD style={{textTransform: 'lowercase', }}>{store.id ? store.id : <NA />}</ITD>,
		'Add-ons': <ITD>{store.active_add_on_ids && store.active_add_on_ids.length ? return_add_ons_string(store.active_add_on_ids) : <NA/>}</ITD>,
		'Users': <ITD className="text-right">{store.non_internal_users}</ITD>,
		'Versions': <ITD className="text-right">{mixpanel_org_level_events && mixpanel_org_level_events[store.id] ? [{value: 'version_save', label: 'Version Save'}].reduce((final, event) => mixpanel_org_level_events[store.id][event.value] ? final + mixpanel_org_level_events[store.id][event.value] : final, 0).toLocaleString() : "0"}</ITD>,
		'Usage': <ITD className="text-right">{mixpanel_org_level_events && mixpanel_org_level_events[store.id] && selected_usage_events.length ? selected_usage_events.reduce((final, event) => mixpanel_org_level_events[store.id][event.value] ? final + mixpanel_org_level_events[store.id][event.value] : final, 0).toLocaleString() : "0"}</ITD>,
		'Usage Change': <ITD className="text-right">{Number(usage_change(store.id).replace('%', '')) == Number.MAX_SAFE_INTEGER ? '+ Infinity %' : usage_change(store.id)}</ITD>,
		'Renewal Prob': <ITD className="text-right">{Number(renewal_probability(store).replace('%', '')) == Number.MAX_SAFE_INTEGER ? 'NA' : renewal_probability(store)}</ITD>,
		'Next Billing': <ITD>{store.next_billing_date ? new Date(store.next_billing_date).toString().substring(4, 15) : <NA />}</ITD>,
		'Last Billing': <ITD>{store.last_billing_date ? new Date(store.last_billing_date).toString().substring(4, 15) : <NA />}</ITD>,
		'Start Date': <ITD>{store.license_start_date ? new Date(store.license_start_date).toString().substring(4, 15) : <NA />}</ITD>,
        'Closing Date': <ITD>{store.closing_date ? new Date(store.closing_date).toString().substring(4, 15) : <NA />}</ITD>,
		'Last Activity': <ITD>{activity_metadata && activity_metadata[store.id] && activity_metadata[store.id].last_activity ? date_string(activity_metadata[store.id].last_activity.due_date) : <NA />}</ITD>,
		'Next Activity': <ITD>{activity_metadata && activity_metadata[store.id] && activity_metadata[store.id].next_activity ? date_string(activity_metadata[store.id].next_activity.due_date) : <NA />}</ITD>,
		'Cutlist Machine': <ITD className="text-right">{store.add_on_details && Array.isArray(store.add_on_details.cutlist_machine) && store.add_on_details.cutlist_machine.length  ? store.add_on_details.cutlist_machine.map(x => `${x.type} - ${x.brand_name} - ${x.model_number}`).join(' | ') : <NA/>}</ITD>,
		'CAM Machine': <ITD className="text-right">{store.add_on_details && store.add_on_details.cam_machine && store.add_on_details.cam_machine.length  ? store.add_on_details.cam_machine.map(x => `${x.type} - ${x.brand_name} - ${x.model_number}`).join(' | ') : <NA/>}</ITD>,
		'Due by Date': <ITD>{store.due_date ? new Date(store.due_date).toString().substring(4, 15) : <NA />}</ITD>,
		'Suspended': <ITD>{store.last_suspended_date ? new Date(store.last_suspended_date).toString().substring(4, 15) : <NA />}</ITD>,
		'Onboarding': <ITD>{store.onboarding_status ? store.onboarding_status : <NA />}</ITD>,
		'Domain': <ITD>{store.domain ? store.domain : <NA/>}</ITD>,
		'Enterprise': <ITD>{store.enterprise ? 'Enterprise' : 'SMB'}</ITD>,
		'Billing Mails': <ITD>{store.billing_mailing_enabled ? 'enabled' : 'disabled'}</ITD>,
		'Important': <ITD>{store.csp_important ? 'Yes' : 'No'}</ITD>,
		'Activity Count': <ITD>{activity_metadata && activity_metadata[store.id] ? activity_metadata[store.id].activity_count : <NA />}</ITD>,
		'Adv Pricing Onboarding': <ITD>{store.onboarding_metadata && store.onboarding_metadata.adv_pric_onboarding_status ? store.onboarding_metadata.adv_pric_onboarding_status : <NA/>}</ITD>,
		'Adv Pricing Onboarding Date': <ITD>{store.onboarding_metadata && store.onboarding_metadata.adv_pric_onboarding_date ? date_string(new Date(store.onboarding_metadata.adv_pric_onboarding_date)) : <NA/>}</ITD>,
		'Cutlist Onboarding': <ITD>{store.onboarding_metadata && store.onboarding_metadata.cutlist_onboarding_status ? store.onboarding_metadata.cutlist_onboarding_status : <NA/>}</ITD>,
		'Cutlist Onboarding Date': <ITD>{store.onboarding_metadata && store.onboarding_metadata.cutlist_onboarding_date ? date_string(new Date(store.onboarding_metadata.cutlist_onboarding_date)) : <NA/>}</ITD>,
		'Manufacturing Onboarding': <ITD>{store.onboarding_metadata && store.onboarding_metadata.manuf_onboarding_status ? store.onboarding_metadata.manuf_onboarding_status : <NA/>}</ITD>,
		'Manufacturing Onboarding Date': <ITD>{store.onboarding_metadata && store.onboarding_metadata.manuf_onboarding_date ? date_string(new Date(store.onboarding_metadata.manuf_onboarding_date)) : <NA/>}</ITD>,
		'CS Rep': <ITD>{store.customer_success_executive_name ? store.customer_success_executive_name : <NA />}</ITD>,
	}), [stores, mixpanel_org_level_events, mixpanel_org_level_events_past, selected_usage_events, activity_metadata, last_3_sorted_columns])

	const sorting_function_value = (store) => ({
		'Org Name': {value:store.name ? store.name.toLowerCase() : '', field_type:'string'},
		'Org ID': {value:store.id ? store.id.toLowerCase() : '', field_type:'string'},
		'Versions': {value:mixpanel_org_level_events && mixpanel_org_level_events[store.id] ? [{value: 'version_save', label: 'Version Save'}].reduce((final, event) => mixpanel_org_level_events[store.id][event.value] ? final + mixpanel_org_level_events[store.id][event.value] : final, 0) : 0, field_type:'number'},
		'Usage': {value:mixpanel_org_level_events && mixpanel_org_level_events[store.id] && selected_usage_events.length ? selected_usage_events.reduce((final, event) => mixpanel_org_level_events[store.id][event.value] ? final + mixpanel_org_level_events[store.id][event.value] : final, 0) : 0, field_type:'number'},
		'Usage Change': {value:Number(usage_change(store.id).replace('%', '')), field_type:'number'},
		'Renewal Prob': {value:Number(renewal_probability(store).replace('%', '')), field_type:'number'},
		'Onboarding': {value:store.onboarding_status ? store.onboarding_status :'', field_type:'string'},
		'Domain': {value:store.domain ? store.domain :'', field_type:'string'},
		'Add-ons': {value:store.active_add_on_ids && store.active_add_on_ids.length ? return_add_ons_string(store.active_add_on_ids) :'', field_type:'string'},
		'Users': {value:store.non_internal_users, field_type:'number'},
		'Cutlist Machine': {value:store.add_on_details && Array.isArray(store.add_on_details.cutlist_machine) && store.add_on_details.cutlist_machine.length  ? store.add_on_details.cutlist_machine.map(x => `${x.type} - ${x.brand_name} - ${x.model_number}`).join(' | ') :'', field_type:'string'},
		'CAM Machine': {value:store.add_on_details && store.add_on_details.cam_machine && store.add_on_details.cam_machine.length  ? store.add_on_details.cam_machine.map(x => `${x.type} - ${x.brand_name} - ${x.model_number}`).join(' | ') :'', field_type:'string'},
		'Billing Mails': {value:store.billing_mailing_enabled ? 'enabled' : 'disabled', field_type:'string'},
		'Important': {value:store.csp_important ? 'Yes' : 'No', field_type:'string'},
		'Due by Date': {value:store.due_date ? new Date(store.due_date) : '', field_type:'date'},
		'Suspended': {value:store.last_suspended_date ? new Date(store.last_suspended_date):'', field_type:'date'},
		'Last Activity': {value:activity_metadata && activity_metadata[store.id] && activity_metadata[store.id].last_activity ? date_string(activity_metadata[store.id].last_activity.due_date) :'', field_type:'date'},
		'Next Activity': {value:activity_metadata && activity_metadata[store.id] && activity_metadata[store.id].next_activity ? date_string(activity_metadata[store.id].next_activity.due_date) :'', field_type:'date'},
		'Next Billing': {value:store.next_billing_date ? new Date(store.next_billing_date):'', field_type:'date'},
		'Last Billing': {value:store.last_billing_date ? new Date(store.last_billing_date):'', field_type:'date'},
		'Start Date': {value:store.license_start_date ? new Date(store.license_start_date):'', field_type:'date'},
		'Closing Date': {value:store.closing_date ? new Date(store.closing_date):'', field_type:'date'},
		'Enterprise': {value:store.enterprise ? 'Enterprise' : 'SMB', field_type:'string'},
		'Activity Count': {value:activity_metadata && activity_metadata[store.id] ? activity_metadata[store.id].activity_count :0, field_type:'number'},
		'Adv Pricing Onboarding':{value:store.onboarding_metadata && store.onboarding_metadata.adv_pric_onboarding_status ? store.onboarding_metadata.adv_pric_onboarding_status : '', field_type:'string'},
		'Adv Pricing Onboarding Date':{value:store.onboarding_metadata && store.onboarding_metadata.adv_pric_onboarding_date ? date_string(new Date(store.onboarding_metadata.adv_pric_onboarding_date)) : '', field_type:'date'},
		'Cutlist Onboarding':{value:store.onboarding_metadata && store.onboarding_metadata.cutlist_onboarding_status ? store.onboarding_metadata.cutlist_onboarding_status : '', field_type:'string'},
		'Cutlist Onboarding Date':{value:store.onboarding_metadata && store.onboarding_metadata.cutlist_onboarding_date ? date_string(new Date(store.onboarding_metadata.cutlist_onboarding_date)) : '', field_type:'date'},
		'Manufacturing Onboarding':{value:store.onboarding_metadata && store.onboarding_metadata.manuf_onboarding_status ? store.onboarding_metadata.manuf_onboarding_status : '', field_type:'string'},
		'Manufacturing Onboarding Date':{value:store.onboarding_metadata && store.onboarding_metadata.manuf_onboarding_date ? date_string(new Date(store.onboarding_metadata.manuf_onboarding_date)) : '', field_type:'date'},
		'CS Rep': {value:store.customer_success_executive_name ? store.customer_success_executive_name:'', field_type:'string'},
	})

	const toggle_filter_modal = () => set_show_filter_modal(!show_filter_modal)

	const refresh_data = async () => {
		try {
			var stores = all_stores
			console.log('stores => ', stores);
			var pages = Math.floor((stores.length / page_size).toFixed(0)) + ((stores.length % page_size) ? 1 : 0);
			set_stores(stores)
			set_no_of_pages(pages)
			set_original_stores(stores)
		} catch (err) {
			console.log(err)
		}
	}

	const fetch_cs_org_users = async () => {
		try {
			let account_details = cs_org_users
			if (account_details && account_details.length) {
				// account_details = account_details.filter(x => !x.depricated && (x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_admin') || x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_executive')))
				account_details = account_details.filter(x => !x.depricated && (x.role.includes('customer_success_admin') || x.role.includes('customer_success_executive')))
				set_edited_cs_org_users(account_details)
			}

			let resp = await general_fetch({ url: 'customer_success_executive/get' })

			if (resp && resp.length) {
				for (let i = 0; i < resp.length; i++) {
					let user = account_details.find(o => o.id === resp[i].user_id)
					resp[i].first_name = user ? user.first_name : ''
					resp[i].email = user ? user.email : ''
				}
				if (user_details && resp.find(o => o.user_id === user_details.id)) {
					set_selected_cses([{ value: user_details.id, label: user_details.first_name }])
					filter_fn(search_text, original_stores, set_stores, selectedNavItem, all_new_renewed_filter, enterprise_filter, suspended_filter, internal_filter, [{ value: user_details.id, label: user_details.first_name }], se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, due_date_filter, due_date_from, due_date_to, suspension_date_filter, suspension_date_from, suspension_date_to, churn_date_filter, churn_date_from, churn_date_to, last_billed_date_filter, last_billed_date_from, last_billed_date_to, license_start_date_filter, license_start_date_from, license_start_date_to, undefined, undefined, undefined, next_billing_date_filter, next_billing_date_from, next_billing_date_to, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter)
				}
				set_cs_executives(resp)
			}
		} catch (err) {
			console.error(err)
		}
	}

	const download_overview_csv = () => {
		try {
			let csv_stores = stores.map(store => {
				let store_json = {}
				if(column_visibility_json['Org ID']) store_json['Org ID'] = store.id
				if(column_visibility_json['Org Name']) store_json['Org Name'] = store.name
				if(column_visibility_json['Versions']) store_json['Versions'] = mixpanel_org_level_events && mixpanel_org_level_events[store.id] ? [{value: 'version_save', label: 'Version Save'}].reduce((final, event) => mixpanel_org_level_events[store.id][event.value] ? final + mixpanel_org_level_events[store.id][event.value] : final, 0).toLocaleString() : "0"
				if(column_visibility_json['Usage']) store_json['Usage'] = mixpanel_org_level_events && mixpanel_org_level_events[store.id] && selected_usage_events.length ? selected_usage_events.reduce((final, event) => mixpanel_org_level_events[store.id][event.value] ? final + mixpanel_org_level_events[store.id][event.value] : final, 0).toLocaleString() : "0"
				if(column_visibility_json['Usage Change']) store_json['Usage Change'] = Number(usage_change(store.id).replace('%', '')) == Number.MAX_SAFE_INTEGER ? '+ Infinity %' : usage_change(store.id)
				if(column_visibility_json['Renewal Prob']) store_json['Renewal Prob'] = Number(renewal_probability(store).replace('%', '')) == Number.MAX_SAFE_INTEGER ? 'NA' : renewal_probability(store)
				if(column_visibility_json['Onboarding']) store_json['Onboarding'] = store.onboarding_status ? store.onboarding_status : ''
				if(column_visibility_json['Domain']) store_json['Domain'] = store.domain ? store.domain : ''
				if(column_visibility_json['Add-ons']) store_json['Add-ons'] = store.active_add_on_ids && store.active_add_on_ids.length ? return_add_ons_string(store.active_add_on_ids) : ''
				if(column_visibility_json['Users']) store_json['Users']  =  store.non_internal_users
				if(column_visibility_json['Cutlist Machine']) store_json['Cutlist Machine']  =  store.add_on_details && Array.isArray(store.add_on_details.cutlist_machine) && store.add_on_details.cutlist_machine.length  ? store.add_on_details.cutlist_machine.map(x => `${x.type} - ${x.brand_name} - ${x.model_number}`).join(' | ') : ''
				if(column_visibility_json['CAM Machine']) store_json['CAM Machine']  =  store.add_on_details && store.add_on_details.cam_machine && store.add_on_details.cam_machine.length  ? store.add_on_details.cam_machine.map(x => `${x.type} - ${x.brand_name} - ${x.model_number}`).join(' | ') : ''
				if(column_visibility_json['Billing Mails']) store_json['Billing Mails'] = store.billing_mailing_enabled ? 'enabled' : 'disabled'
				if(column_visibility_json['Important']) store_json['Important'] = store.csp_important ? 'Yes' : 'No'
				if(column_visibility_json['Due by Date']) store_json['Due by Date'] = store.due_date ? new Date(store.due_date).toString().substring(4, 15) : ''
				if(column_visibility_json['Suspended']) store_json['Suspended'] = store.last_suspended_date ? new Date(store.last_suspended_date).toString().substring(4, 15) : ''
				if(column_visibility_json['Last Activity']) store_json['Last Activity'] = activity_metadata && activity_metadata[store.id] && activity_metadata[store.id].last_activity ? date_string(activity_metadata[store.id].last_activity.due_date) : ''
				if(column_visibility_json['Next Activity']) store_json['Next Activity'] = activity_metadata && activity_metadata[store.id] && activity_metadata[store.id].next_activity ? date_string(activity_metadata[store.id].next_activity.due_date) : ''
				if(column_visibility_json['Next Billing']) store_json['Next Billing'] = store.next_billing_date ? new Date(store.next_billing_date).toString().substring(4, 15) : ''
				if(column_visibility_json['Last Billing']) store_json['Last Billing'] = store.last_billing_date ? new Date(store.last_billing_date).toString().substring(4, 15) : ''
				if(column_visibility_json['Start Date']) store_json['Start Date'] = store.license_start_date ? new Date(store.license_start_date).toString().substring(4, 15) : ''
				if(column_visibility_json['Closing Date']) store_json['Closing Date'] = store.closing_date ? new Date(store.closing_date).toString().substring(4, 15) : ''
				if(column_visibility_json['Enteprise']) store_json['Enteprise'] = store.enterprise ? 'Enterprise' : 'SMB'
				if(column_visibility_json['Activity Count']) store_json['Activity Count'] = activity_metadata && activity_metadata[store.id] ? activity_metadata[store.id].activity_count : '0'
				if(column_visibility_json['Adv Pricing Onboarding']) store_json['Adv Pricing Onboarding'] = store.onboarding_metadata && store.onboarding_metadata.adv_pric_onboarding_status ? store.onboarding_metadata.adv_pric_onboarding_status : ''
				if(column_visibility_json['Adv Pricing Onboarding Date']) store_json['Adv Pricing Onboarding Date'] = store.onboarding_metadata && store.onboarding_metadata.adv_pric_onboarding_date ? date_string(new Date(store.onboarding_metadata.adv_pric_onboarding_date)) : ''
				if(column_visibility_json['Cutlist Onboarding']) store_json['Cutlist Onboarding'] = store.onboarding_metadata && store.onboarding_metadata.cutlist_onboarding_status ? store.onboarding_metadata.cutlist_onboarding_status : ''
				if(column_visibility_json['Cutlist Onboarding Date']) store_json['Cutlist Onboarding Date'] = store.onboarding_metadata && store.onboarding_metadata.cutlist_onboarding_date ? date_string(new Date(store.onboarding_metadata.cutlist_onboarding_date)) : ''
				if(column_visibility_json['Manufacturing Onboarding']) store_json['Manufacturing Onboarding'] = store.onboarding_metadata && store.onboarding_metadata.manuf_onboarding_status ? store.onboarding_metadata.manuf_onboarding_status : ''
				if(column_visibility_json['Manufacturing Onboarding Date']) store_json['Manufacturing Onboarding Date'] = store.onboarding_metadata && store.onboarding_metadata.manuf_onboarding_date ? date_string(new Date(store.onboarding_metadata.manuf_onboarding_date)) : ''
				if(column_visibility_json['CS Rep']) store_json['CS Rep'] = store.customer_success_executive_name ? store.customer_success_executive_name : ''
				return store_json
			})
			const csvConfig = mkConfig({ useKeysAsHeaders: true });
			const csv = generateCsv(csvConfig)(csv_stores);
			download(csvConfig)(csv)
		} catch (e) {
			console.error(e)
			toastr.error(`Couldn't download overview CSV`)
		}
	}
	const return_add_ons_string = (add_on_ids) => {
		let str = ''
		if(add_on_ids && add_on_ids.length){
			add_on_ids = add_on_ids.map((x, idx) => infurnia_add_ons.find(o => o.value === x)?infurnia_add_ons.find(o => o.value === x).label:'').sort()
			add_on_ids.map((x, idx) => str = str + (idx!=0 ? ', ' : '') + x, '')
		}
		return str
	}

	const usage_change = (org_id) => {
		if(mixpanel_org_level_events && mixpanel_org_level_events_past && selected_usage_events.length){
			let current_number = selected_usage_events.reduce((final, event) => (mixpanel_org_level_events[org_id] && mixpanel_org_level_events[org_id][event.value]) ? (final + mixpanel_org_level_events[org_id][event.value]) : final, 0)
			let past_number = selected_usage_events.reduce((final, event) => (mixpanel_org_level_events_past[org_id] && mixpanel_org_level_events_past[org_id][event.value]) ? (final + mixpanel_org_level_events_past[org_id][event.value]) : final, 0)
			if(!Number(past_number) && !Number(current_number)) return '0 %'
			if(!Number(past_number)) return Number.MAX_SAFE_INTEGER + ' %'
			let percentage_change = (((Number(current_number)-Number(past_number))/Number(past_number))*100).toFixed(0)
			return (percentage_change>0 ? '+' : '') + (String(percentage_change).replace('.0', '')) + ' %'
		}
		return "0 %"
	}

    const renewal_probability = (store) => {
        if(store.renewal_probability && store.renewal_probability != "NA") {
            return Number(store.renewal_probability*100).toFixed(2) + ' %';
        } else {
            return '100 %'
        }
    }

	useEffect(() => {
		if (all_stores && all_stores.length) {
			refresh_data();
		}
	}, [all_stores]);

	useEffect(() => {
		if (cs_org_users && cs_org_users.length && user_details) {
			fetch_cs_org_users()
		}
	}, [cs_org_users, user_details]);

	useEffect(() => {
		filter_fn(search_text, original_stores, set_stores, selectedNavItem, all_new_renewed_filter, enterprise_filter, suspended_filter, internal_filter, selected_cses, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, due_date_filter, due_date_from, due_date_to, suspension_date_filter, suspension_date_from, suspension_date_to, churn_date_filter, churn_date_from, churn_date_to, last_billed_date_filter, last_billed_date_from, last_billed_date_to, license_start_date_filter, license_start_date_from, license_start_date_to, undefined, undefined, undefined, next_billing_date_filter, next_billing_date_from, next_billing_date_to, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter)
	}, [search_text, original_stores, selectedNavItem, all_new_renewed_filter, due_date_filter, suspension_date_filter, churn_date_filter, last_billed_date_filter, license_start_date_filter, next_billing_date_filter, payment_cycle_filter]);

	useEffect(() => {
		console.log('all uef', initializedStateCount.current)
		if (initializedStateCount.current < 2) {
			initializedStateCount.current += 1
		} else if (user_details && user_details.id) {
			let filter_json = { selectedNavItem, search_text, internal_filter, enterprise_filter, selected_cses, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, all_new_renewed_filter, selected_usage_events, license_start_date_filter, license_start_date_from, license_start_date_to, last_billed_date_filter, last_billed_date_from, last_billed_date_to, next_billing_date_filter, next_billing_date_from, next_billing_date_to, due_date_filter, due_date_from, due_date_to, suspension_date_filter, suspension_date_from, suspension_date_to, usage_duration_filter, usage_start_time, usage_end_time, last_or_next, column_visibility_json, user_selected_page_size, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter}
			if(last_3_sorted_columns && last_3_sorted_columns.length){
				filter_json['last_3_sorted_columns'] = last_3_sorted_columns
			}else{
				filter_json['last_3_sorted_columns'] = saved_last_3_sorted_columns
			}
			set_csp_filter(user_details.id, '/home/paid_orgs', '0', filter_json)
		}
	}, [user_details, selectedNavItem, search_text, internal_filter, enterprise_filter, selected_cses, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, all_new_renewed_filter, selected_usage_events, license_start_date_filter, license_start_date_from, license_start_date_to, last_billed_date_filter, last_billed_date_from, last_billed_date_to, due_date_filter, due_date_from, due_date_to, suspension_date_filter, suspension_date_from, suspension_date_to, usage_duration_filter, usage_start_time, usage_end_time, next_billing_date_filter, next_billing_date_from, next_billing_date_to, last_or_next, column_visibility_json, user_selected_page_size, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter, last_3_sorted_columns]);

	useEffect(() => {
		if (user_details && user_details.id) get_csp_filter(user_details.id, '/home/paid_orgs', set_filter_json_from_backend)
	}, [user_details]);

	useEffect(() => {
		if (stores && stores.length && !user_selected_page_size) {
			let x1 = document.getElementById('orgs_page_header').offsetHeight
			let x2 = 52
			let x3 = 45
			let remaining_height = window.innerHeight - x1 - x2 - x3
			let page_size1 = Math.floor(remaining_height/45)
			set_page_size(page_size1)
			console.log('no of pages', remaining_height, no_of_pages, stores.length)
			console.log('STORES ----_>>>> ', stores)
		}
	}, [stores]);

	useEffect(() => {
		if(stores && stores.length){
			var pages = Math.floor((stores.length / page_size)) + ((stores.length % page_size) ? 1 : 0)
			set_no_of_pages(pages)
		}else{
			set_no_of_pages(1)
		}
		set_current_page(1)
	}, [page_size, stores]);

	useEffect(() => {
		if (filter_json_from_backend && filter_json_from_backend['/home/paid_orgs'] && original_stores && original_stores.length) {
			let final_json = JSON.parse(filter_json_from_backend['/home/paid_orgs'][0])
			console.log('Response data cassandra1234 all uef paid orgs', final_json)
			if (final_json) {
				set_selectedNavItem(final_json.selectedNavItem)
				set_search_text(final_json.search_text ? final_json.search_text : '')
				set_internal_filter(final_json.internal_filter ? final_json.internal_filter : '')
				set_enterprise_filter(final_json.enterprise_filter ? final_json.enterprise_filter : '')
				set_selected_cses(final_json.selected_cses ? final_json.selected_cses : '')
				set_se_filter(final_json.se_filter ? final_json.se_filter : '')
				set_onboarding_filter(final_json.onboarding_filter ? final_json.onboarding_filter : '')
				set_addon_onboarding_filter(final_json.addon_onboarding_filter ? final_json.addon_onboarding_filter : '')
				set_addon_onboarding_date_from(final_json.addon_onboarding_date_from ? new Date(final_json.addon_onboarding_date_from) : '')
				set_addon_onboarding_date_to(final_json.addon_onboarding_date_to ? new Date(final_json.addon_onboarding_date_to) : '')
				set_responsiveness_filter(final_json.responsiveness_filter ? final_json.responsiveness_filter : '')
				set_country_filter(final_json.country_filter ? final_json.country_filter : '')
				set_selected_add_ons(final_json.selected_add_ons)
				set_selected_domains(final_json.selected_domains)
				set_all_new_renewed_filter(final_json.all_new_renewed_filter)
				set_selected_usage_events(final_json.selected_usage_events)
				set_license_start_date_filter(final_json.license_start_date_filter)
				set_license_start_date_from(new Date(final_json.license_start_date_from))
				set_license_start_date_to(new Date(final_json.license_start_date_to))
				set_last_billed_date_filter(final_json.last_billed_date_filter)
				set_last_billed_date_from(new Date(final_json.last_billed_date_from))
				set_last_billed_date_to(new Date(final_json.last_billed_date_to))
				set_next_billing_date_filter(final_json.next_billing_date_filter)
				set_next_billing_date_from(new Date(final_json.next_billing_date_from))
				set_next_billing_date_to(new Date(final_json.next_billing_date_to))
				set_due_date_filter(final_json.due_date_filter)
				set_due_date_from(new Date(final_json.due_date_from))
				set_due_date_to(new Date(final_json.due_date_to))
				set_suspension_date_filter(final_json.suspension_date_filter)
				set_suspension_date_from(new Date(final_json.suspension_date_from))
				set_suspension_date_to(new Date(final_json.suspension_date_to))
				set_usage_duration_filter(final_json.usage_duration_filter)
				set_usage_start_time(new Date(final_json.usage_start_time))
				set_usage_end_time(new Date(final_json.usage_end_time))
				set_last_or_next(final_json.last_or_next)
				set_payment_cycle_filter(final_json.payment_cycle_filter)
				if(Number(final_json.user_selected_page_size)){
					set_user_selected_page_size(Number(final_json.user_selected_page_size))
					set_page_size(Number(final_json.user_selected_page_size))
				}
				// set_column_visibility_json(final_json.column_visibility_json ? final_json.column_visibility_json : column_visibility_json)
				if(final_json.column_visibility_json){
					main_headings.map((key) => {
						console.log(key.column_name, final_json.column_visibility_json[key.column_name])
						if(final_json.column_visibility_json[key.column_name] || final_json.column_visibility_json[key.column_name]==undefined){
							column_visibility_json[key.column_name] = true
						}else{
							console.log(key.column_name, final_json.column_visibility_json[key.column_name])
							column_visibility_json[key.column_name] = false
						}
					})
				}
				if (final_json.usage_duration_filter && final_json.usage_duration_filter === 'custom') {
					fetch_mix_panel_data(new Date(final_json.usage_start_time), new Date(final_json.usage_end_time), set_mixpanel_org_level_events, set_mixpanel_org_level_events_past, set_fetching_usage)
				} else if (!final_json.usage_duration_filter) {
					fetch_mix_panel_data(new Date(usage_start_time), new Date(usage_end_time), set_mixpanel_org_level_events, set_mixpanel_org_level_events_past, set_fetching_usage)
				}

				if(final_json.last_3_sorted_columns && final_json.last_3_sorted_columns.length){
					set_last_3_sorted_columns(final_json.last_3_sorted_columns)
					set_saved_last_3_sorted_columns(final_json.last_3_sorted_columns)
				}

				filter_fn(final_json.search_text, original_stores, set_stores, final_json.selectedNavItem, final_json.all_new_renewed_filter, final_json.enterprise_filter, suspended_filter, final_json.internal_filter, final_json.selected_cses, final_json.se_filter, final_json.onboarding_filter, final_json.responsiveness_filter, final_json.selected_add_ons, final_json.selected_domains, final_json.due_date_filter, final_json.due_date_from, final_json.due_date_to, final_json.suspension_date_filter, final_json.suspension_date_from, final_json.suspension_date_to, churn_date_filter, churn_date_from, churn_date_to, final_json.last_billed_date_filter, final_json.last_billed_date_from, final_json.last_billed_date_to, final_json.license_start_date_filter, final_json.license_start_date_from, final_json.license_start_date_to, undefined, undefined, undefined, final_json.next_billing_date_filter, final_json.next_billing_date_from, final_json.next_billing_date_to, final_json.addon_onboarding_filter, final_json.addon_onboarding_date_from, final_json.addon_onboarding_date_to, final_json.country_filter, final_json.payment_cycle_filter)
			}
		}
	}, [filter_json_from_backend, original_stores]);

	useEffect(() => {
		if(saved_last_3_sorted_columns && saved_last_3_sorted_columns.length && original_stores && original_stores.length && mixpanel_org_level_events && mixpanel_org_level_events_past && cs_org_users && cs_org_users.length){
			let temp_stores = JSON.parse(JSON.stringify(stores));
			last_3_sorted_columns.map(column => {
				let order = column.order==='asc' ? 1 : -1
				temp_stores = generic_sort_for_orgs(column.column_name, temp_stores, order, sorting_function_value)
			})
			temp_stores = temp_stores.map((x, idx) => ({ ...x, idx }))
			set_stores(temp_stores)
		}
	}, [saved_last_3_sorted_columns, original_stores, mixpanel_org_level_events, mixpanel_org_level_events_past, cs_org_users]);

	useEffect(() => {
		if (usage_duration_filter && usage_duration_filter != 'custom') {
			let from_date = new Date(), to_date = new Date()
			if (usage_duration_filter == 'yesterday') {
				from_date = new Date(new Date().toDateString())
				to_date = new Date(new Date().toDateString())
				from_date.setDate(from_date.getDate() - 1)
			} else if (usage_duration_filter == 'last_7_days') {
				from_date.setDate(from_date.getDate() - 7)
			} else if (usage_duration_filter == 'last_30_days') {
				from_date.setDate(from_date.getDate() - 30)
			}else if(usage_duration_filter == 'today') {
				from_date = new Date(new Date().toDateString())
			}
			fetch_mix_panel_data(from_date, to_date, set_mixpanel_org_level_events, set_mixpanel_org_level_events_past, set_fetching_usage)
		}
	}, [usage_duration_filter]);

	useEffect(() => {
		if (enterprise_filter != '' || internal_filter != '' || (selected_cses && selected_cses.length) || se_filter != '' || onboarding_filter != '' || addon_onboarding_filter != '' || addon_onboarding_date_from != '' || addon_onboarding_date_to != '' || responsiveness_filter != '' || payment_cycle_filter || (selected_add_ons && selected_add_ons.length) || (selected_domains && selected_domains.length) || (country_filter && country_filter.length)) {
			set_filter_active(true)
		} else {
			set_filter_active(false)
		}
	}, [enterprise_filter, internal_filter, selected_cses, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter]);

	useEffect(() => {
		column_visibility_json['Due by Date'] = selectedNavItem === 2 ? 1 : 0
		column_visibility_json['Suspended'] = selectedNavItem === 3 ? 1 : 0
		set_column_visibility_json(JSON.parse(JSON.stringify(column_visibility_json)))
	}, [selectedNavItem]);

	useEffect(() => {
		if(all_stores && all_stores.length) fetch_activity_metadata()
	}, []);

	const store_table1 = (
		<ITable 
			style={{fontSize:'13px', whiteSpace:'nowrap'}} 
			style_container={{overflow:'auto', height:'calc(100% - 52px)'}} 
			coloumnSeparated={1} 
			rowSeparated={1} 
			resizable={true} 
			minCellWidth={80} 
			headings={headings} 
			columnWidths={headings && headings.length ? headings.filter(o => column_visibility_json[o.column_name]).map(o => o.columnWidth).join(' ') : 'k'} 
			tableSettings={false}
			column_visibility_json = {column_visibility_json} 
			set_column_visibility_json={set_column_visibility_json} 
			cellHeight={'45px'}
		>
			<ITableBody>
				{
					stores && stores.length ? stores.slice((current_page - 1) * page_size, current_page * page_size).map((org, idx) => {
						let json = TableData(org, idx, current_page, page_size)
						return(
							Object.keys(json).map((key) => (
								<ITableRow className='display_onhover'>
									{column_visibility_json[key]?json[key]:''}
								</ITableRow>
							))
						)
					}):''
				}
			</ITableBody>
		</ITable>
	)

	const store_filler = (
		<Table className="align-items-center table-flush text-center" responsive>
			<thead className="thead-light text-center">
				<tr>
					<th scope="col"><span style={styles.table_header}><b>Fetching all stores </b></span><br /> Please wait...<br /> &nbsp;</th>
				</tr>
			</thead>
		</Table>
	)

	const update_column_visibility_json = (column_name, value) => {
		let a = JSON.parse(JSON.stringify(column_visibility_json))
		a[column_name] = value
		set_column_visibility_json(a)
	}

	const column_selector = (
		<div className="p-3" style={{ color: '#323338', display:'flex', flexDirection:'column', height:'380px' }}>
			<div>
				<div className="mb-3" style={{ color: '#A7A8B2', color:'black', fontSize:'15px'}}>Cutomize Columns</div>
				<div className="flex_property justify-between mb-3">
					<ISearch style_outer={{width:'300px'}} style_inner={{width:'300px'}} search_text={column_search_text} set_search_text={set_column_search_text} list={columns} set_list={set_searched_columns} search_parameters={['column_name']}/>
				</div>
				<hr className='mb-3 mt-0' />
			</div>
			<div style={{flex:1, overflow:'auto'}}>
			{
				searched_columns.map(column => (
					<div className="flex_property justify-between" style={{marginBottom:'12px'}}>
						<div className="flex_property">
							<Checkbox checked={column_visibility_json[column.column_name]} onClick={() => {update_column_visibility_json(column.column_name, !column_visibility_json[column.column_name])}}>
								<div>{column.column_name}</div>
							</Checkbox>
						</div>
					</div>
				))
			}
			</div>
		</div>
	)

	return (
		<>
			<OrgFilterModal
				open={show_filter_modal}
				toggle={toggle_filter_modal}
				premium_filter={premium_filter}
				set_premium_filter={set_premium_filter}
				enterprise_filter={enterprise_filter}
				set_enterprise_filter={set_enterprise_filter}
				suspended_filter={suspended_filter}
				set_suspended_filter={set_suspended_filter}
				internal_filter={internal_filter}
				set_internal_filter={set_internal_filter}
				selected_cses={selected_cses}
				set_selected_cses={set_selected_cses}
				se_filter={se_filter}
				set_se_filter={set_se_filter}
				onboarding_filter={onboarding_filter}
				set_onboarding_filter={set_onboarding_filter}
				addon_onboarding_filter={addon_onboarding_filter}
				set_addon_onboarding_filter={set_addon_onboarding_filter}
				addon_onboarding_date_from = {addon_onboarding_date_from} 
				set_addon_onboarding_date_from = {set_addon_onboarding_date_from} 
				addon_onboarding_date_to = {addon_onboarding_date_to}
				set_addon_onboarding_date_to = {set_addon_onboarding_date_to}
				churn_date_filter={churn_date_filter}
				set_churn_date_filter={set_churn_date_filter}
				churn_date_from={churn_date_from}
				set_churn_date_from={set_churn_date_from}
				churn_date_to={churn_date_to}
				set_churn_date_to={set_churn_date_to}
				cs_org_users={edited_cs_org_users}
				cs_executives={cs_executives}
				set_filter_active={set_filter_active}
				responsiveness_filter={responsiveness_filter}
				set_responsiveness_filter={set_responsiveness_filter}
				selected_add_ons={selected_add_ons}
				set_selected_add_ons={set_selected_add_ons}
				selected_domains={selected_domains}
				set_selected_domains={set_selected_domains}
				country_filter = {country_filter}
				set_country_filter = {set_country_filter}
				payment_cycle_filter = {payment_cycle_filter}
				set_payment_cycle_filter = {set_payment_cycle_filter}
				all_stores = {all_stores.filter(o => o.premium_customer && !o.churned)}
				filter_fn={(a, b, c, d, e, f, g, h, i, j, k, l, m, n) => filter_fn(search_text, original_stores, set_stores, selectedNavItem, all_new_renewed_filter, a, b, c, d, e, f, g, h, i, due_date_filter, due_date_from, due_date_to, suspension_date_filter, suspension_date_from, suspension_date_to, churn_date_filter, churn_date_from, churn_date_to, last_billed_date_filter, last_billed_date_from, last_billed_date_to, license_start_date_filter, license_start_date_from, license_start_date_to, undefined, undefined, undefined, next_billing_date_filter, next_billing_date_from, next_billing_date_to, j, k, l, m, n)}
			/>
			<DateRangeModal
				open={show_date_range}
				toggle={() => set_show_date_range(false)}
				dateRange={[{ startDate: selectedNavItem === 2 ? due_date_from : (selectedNavItem === 3 ? suspension_date_from : (selectedNavItem === 1 ? (last_or_next === 'last' ? last_billed_date_from : next_billing_date_from) : license_start_date_from)), endDate: selectedNavItem === 2 ? due_date_to : (selectedNavItem === 3 ? suspension_date_to : (selectedNavItem === 1 ? (last_or_next === 'last' ? last_billed_date_to : next_billing_date_to) : license_start_date_to)), key: 'selection' }]}
				setRange={(x, y) => selectedNavItem === 2 ? (set_due_date_from(x), set_due_date_to(y), set_due_date_filter('custom')) : (selectedNavItem === 3 ? (set_suspension_date_from(x), set_suspension_date_to(y), set_suspension_date_filter('custom')) : (selectedNavItem === 1 ? (last_or_next === 'last' ? (set_last_billed_date_from(x), set_last_billed_date_to(y), set_last_billed_date_filter('custom')) : (set_next_billing_date_from(x), set_next_billing_date_to(y), set_next_billing_date_filter('custom'))) : (set_license_start_date_from(x), set_license_start_date_to(y), set_license_start_date_filter('custom'))))}
				filter_fn={(x, y) => selectedNavItem === 2 ? filter_fn(search_text, original_stores, set_stores, selectedNavItem, all_new_renewed_filter, enterprise_filter, suspended_filter, internal_filter, selected_cses, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, 'custom', x, y, suspension_date_filter, suspension_date_from, suspension_date_to, churn_date_filter, churn_date_from, churn_date_to, last_billed_date_filter, last_billed_date_from, last_billed_date_to, license_start_date_filter, license_start_date_from, license_start_date_to, undefined, undefined, undefined, next_billing_date_filter, next_billing_date_from, next_billing_date_to, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter) : (
					selectedNavItem === 3 ? filter_fn(search_text, original_stores, set_stores, selectedNavItem, all_new_renewed_filter, enterprise_filter, suspended_filter, internal_filter, selected_cses, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, due_date_filter, due_date_from, due_date_to, 'custom', x, y, churn_date_filter, churn_date_from, churn_date_to, last_billed_date_filter, last_billed_date_from, last_billed_date_to, license_start_date_filter, license_start_date_from, license_start_date_to, undefined, undefined, undefined, next_billing_date_filter, next_billing_date_from, next_billing_date_to, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter) : (
						selectedNavItem === 1 ? filter_fn(search_text, original_stores, set_stores, selectedNavItem, all_new_renewed_filter, enterprise_filter, suspended_filter, internal_filter, selected_cses, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, due_date_filter, due_date_from, due_date_to, suspension_date_filter, suspension_date_from, suspension_date_to, churn_date_filter, churn_date_from, churn_date_to, last_or_next === 'last' ? 'custom' : last_billed_date_filter, last_or_next === 'last' ? x : last_billed_date_from, last_or_next === 'last' ? y : last_billed_date_to, license_start_date_filter, license_start_date_from, license_start_date_to, undefined, undefined, undefined, last_or_next === 'last' ? next_billing_date_filter : 'custom', last_or_next === 'last' ? next_billing_date_from : x, last_or_next === 'last' ? next_billing_date_to : y, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter) :
							filter_fn(search_text, original_stores, set_stores, selectedNavItem, all_new_renewed_filter, enterprise_filter, suspended_filter, internal_filter, selected_cses, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, due_date_filter, due_date_from, due_date_to, suspension_date_filter, suspension_date_from, suspension_date_to, churn_date_filter, churn_date_from, churn_date_to, last_billed_date_filter, last_billed_date_from, last_billed_date_to, 'custom', x, y, undefined, undefined, undefined, next_billing_date_filter, next_billing_date_from, next_billing_date_to, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter)))}
			/>
			<DateRangeModal
				open={show_usage_date_range}
				toggle={() => set_show_usage_date_range(false)}
				dateRange={[{ startDate: usage_start_time, endDate: usage_end_time, key: 'selection' }]}
				setRange={(x, y) => { set_usage_start_time(x); set_usage_end_time(y); set_usage_duration_filter('custom') }}
				filter_fn={(x, y) => fetch_mix_panel_data(x, y, set_mixpanel_org_level_events, set_mixpanel_org_level_events_past, set_fetching_usage)}
			/>
			{/* Page content */}

			<Container fluid style={{overflow:'hidden'}}>
				{/* Table */}
				<Row style={{overflow:'hidden'}}>
					<div className="col px-2" >
						<Card className="shadow" style={{ height: '100vh', borderRadius: '0px' }}>
							<CardHeader id='orgs_page_header' className="border-0 p-0 px-1 pb-2">
								<Row style={{ ...styles.header_row, justifyContent: 'space-between' }} className='pl-2 pr-4 mt-4'>
									<Nav pills style={{ paddingBottom: 5, marginTop: 4 }}>
										<NavItem>
											<NavLink href='#' active={selectedNavItem === 0} onClick={() => { set_selectedNavItem(0); set_current_page(1) }}><b>All Orgs</b> </NavLink>
										</NavItem>
										<NavItem>
											<NavLink href='#' active={selectedNavItem === 1} onClick={() => { set_selectedNavItem(1); set_current_page(1) }}><b>Active</b> </NavLink>
										</NavItem>
										<NavItem>
											<NavLink href='#' active={selectedNavItem === 7} onClick={() => { set_selectedNavItem(7); set_current_page(1) }}><b>Last Week</b> </NavLink>
										</NavItem>
										<NavItem>
											<NavLink href='#' active={selectedNavItem === 2} onClick={() => { set_selectedNavItem(2); set_current_page(1) }}><b>Due for Renewal</b> </NavLink>
										</NavItem>
										<NavItem>
											<NavLink href='#' active={selectedNavItem === 3} onClick={() => { set_selectedNavItem(3); set_current_page(1) }}><b>Suspended</b> </NavLink>
										</NavItem>
									</Nav>
									<div className="flex_center">
										<ISearch search_text={search_text} set_search_text={set_search_text} placeholder={'Search by Name, Id, Address'} style_outer={{ width: '250px' }} />
										<div className='flex_center pr-2 pl-2 pt-1 pb-1 cp mr-2' style={{ borderRadius: '6px', border: '1px solid gray', color: filter_active ? '#0078ff' : '', borderColor: filter_active ? '#0078ff' : 'gray', backgroundColor: filter_active ? '#EFF6FF' : "" }} onClick={() => toggle_filter_modal()}>
											<div className='mr-3' style={{ fontSize: '14px' }}>Filter</div>
											{filter_active ? <i className='fa fa-filter' style={{}} /> : <img src="/filter_icon_outline.svg" />}
										</div>
										<div className="flex_center cp py-1 px-2" style={{ border: '1px solid #0078ff', borderRadius: '6px', color: '#0078ff', fontSize: '14px' }} onClick={download_overview_csv}>Export Results</div>
                                        <Tooltip
                                            title={column_selector}
                                            color='white'
                                            placement='left'
                                            trigger={'click'}
                                            overlayStyle={{borderRadius:'16px', marginRight:'30px', maxWidth:'unset'}}
                                            overlayInnerStyle={{ maxHeight:'380px', overflow:'hidden', marginBottom:'10px'}}
                                            style={{borderRadius:'16px', width:'400px'}}
                                            fresh={true}
                                            open={show_column_selector}
                                            onOpenChange={() => set_show_column_selector(!show_column_selector)}
                                        >
                                            <div className='flex_center p-2' style={{}}>
                                                <i className='fa fa-cog cp' onClick={() => set_show_column_selector(!show_column_selector)} style={{fontSize:'18px'}} />
                                            </div>  
                                        </Tooltip>
									</div>
								</Row>
								<Row className='mt-4 flex_center px-4' style={{ justifyContent: 'space-between', fontSize: '14px', position: 'relative', zIndex: '20' }}>
									<div className="flex_center" style={{ fontSize: '12px' }}>
										<Select
											components={animatedComponents}
											value={{ value: all_new_renewed_filter, label: convert_to_readable(all_new_renewed_filter) }}
											options={[{ value: 'all', label: 'All' }, { value: 'new', label: 'New' }, { value: 'renewed', label: 'Renewed' }]}
											styles={colourStyles1}
											onChange={(selectedOptions) => set_all_new_renewed_filter(selectedOptions.value)}
										/>
										<div className="ml-2">
											<Select
												components={animatedComponents}
												value={{ value: payment_cycle_filter, label: payment_cycle_filter==3 ? 'Quarterly' : (payment_cycle_filter==12 ? 'Annual' : 'All') }}
												options={[{ value: '', label: 'All' }, { value: 3, label: 'Quarterly' }, { value: 12, label: 'Annual' }]}
												styles={colourStyles1}
												onChange={(selectedOptions) => set_payment_cycle_filter(selectedOptions.value)}
											/>
										</div>
										<div className="mr-2 ml-2">CS Rep:</div>
										<Select
											// closeMenuOnSelect={false}
											components={animatedComponents}
											value={selected_cses}
											isMulti
											options={cs_executives && cs_executives.length ? cs_executives.map(x => { return { value: x.user_id, label: x.first_name } }) : []}
											styles={colourStyles}
											onChange={(selectedOptions) => { set_selected_cses(selectedOptions); filter_fn(search_text, original_stores, set_stores, selectedNavItem, all_new_renewed_filter, enterprise_filter, suspended_filter, internal_filter, selectedOptions, se_filter, onboarding_filter, responsiveness_filter, selected_add_ons, selected_domains, due_date_filter, due_date_from, due_date_to, suspension_date_filter, suspension_date_from, suspension_date_to, churn_date_filter, churn_date_from, churn_date_to, last_billed_date_filter, last_billed_date_from, last_billed_date_to, license_start_date_filter, license_start_date_from, license_start_date_to, undefined, undefined, undefined, next_billing_date_filter, next_billing_date_from, next_billing_date_to, addon_onboarding_filter, addon_onboarding_date_from, addon_onboarding_date_to, country_filter, payment_cycle_filter) }}
										/>
										<div style={{ fontSize: '14px', color: 'purple' }} className="ml-4">Viewing {((current_page - 1) * page_size)+1 +' - '+ Math.min((((current_page - 1) * page_size)+page_size), stores&&stores.length)} of {stores ? stores.length : 0} Orgs</div>
									</div>
									<div className="flex_center" style={{ fontSize: '12px' }}>
										<div className="mr-2">Usage Events:</div>
										<Select
											// closeMenuOnSelect={false}
											components={animatedComponents}
											value={selected_usage_events}
											isMulti
											options={mixpanel_event_types}
											styles={colourStyles}
											onChange={(selectedOptions) => set_selected_usage_events(selectedOptions)}
										/>
									</div>
								</Row>

								<Row className='mt-4 flex_center px-4' style={{ justifyContent: 'space-between', fontSize: '14px', padding: '0px 0px 0px 10px', position: 'relative', zIndex: '11' }}>
									{
										selectedNavItem === 0 ?
											<div className="custom_time_filter">
												<div
													className={`nav-item ${license_start_date_filter === '' && 'selected'}`}
													onClick={() => { set_license_start_date_filter(''); set_current_page(1) }}>
													All
												</div>
												<div
													className={`nav-item ${license_start_date_filter === 'today' && 'selected'}`}
													onClick={() => { set_license_start_date_filter('today'); set_current_page(1) }}
												>
													Started Today
												</div>
												<div
													className={`nav-item ${license_start_date_filter === 'yesterday' && 'selected'}`}
													onClick={() => { set_license_start_date_filter('yesterday'); set_current_page(1) }}
												>
													Started Yesterday
												</div>
												<div
													className={`nav-item ${license_start_date_filter === 'last_7_days' && 'selected'}`}
													onClick={() => { set_license_start_date_filter('last_7_days'); set_current_page(1) }}
												>
													Last 7 days
												</div>
												<div
													className={`nav-item ${license_start_date_filter === 'last_30_days' && 'selected'}`}
													onClick={() => { set_license_start_date_filter('last_30_days'); set_current_page(1) }}
												>
													Last 30 days
												</div>
												<div
													className={`nav-item ${license_start_date_filter === 'custom' && 'selected'}`}
													onClick={() => { set_current_page(1); set_show_date_range(true) }}
												>
													{license_start_date_filter === 'custom' ? license_start_date_from.toDateString().substring(4, 10) + ' - ' + license_start_date_to.toDateString().substring(4, 10) : 'Select Period'}
													<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: '10', boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)' }}>
													</div>
												</div>
											</div>
											: (
												selectedNavItem === 2 ?
													<div className="custom_time_filter">
														<div
															className={`nav-item ${due_date_filter === '' && 'selected'}`}
															onClick={() => { set_due_date_filter(''); set_current_page(1) }}
														>
															All
														</div>
														<div
															className={`nav-item ${due_date_filter === 'today' && 'selected'}`}
															onClick={() => { set_due_date_filter('today'); set_current_page(1) }}
														>
															Due Today
														</div>
														<div
															className={`nav-item ${due_date_filter === 'tomorrow' && 'selected'}`}
															onClick={() => { set_due_date_filter('tomorrow'); set_current_page(1) }}
														>
															Due Tomorrow
														</div>
														<div
															className={`nav-item ${due_date_filter === 'next_7_days' && 'selected'}`}
															onClick={() => { set_due_date_filter('next_7_days'); set_current_page(1) }}
														>
															Next 7 days
														</div>
														<div
															className={`nav-item ${due_date_filter === 'next_30_days' && 'selected'}`}
															onClick={() => { set_due_date_filter('next_30_days'); set_current_page(1) }}
														>
															Next 30 days
														</div>
														<div
															className={`nav-item ${due_date_filter === 'custom' && 'selected'}`}
															onClick={() => { set_current_page(1); set_show_date_range(true) }}
														>
															{due_date_filter === 'custom' ? due_date_from.toDateString().substring(4, 10) + ' - ' + due_date_to.toDateString().substring(4, 10) : 'Select Period'}
															<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: '10', boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)' }}>
															</div>
														</div>
													</div> : (
														selectedNavItem === 3 ?
															<div className="custom_time_filter">
																<div
																	className={`nav-item ${suspension_date_filter === '' && 'selected'}`}
																	onClick={() => { set_suspension_date_filter(''); set_current_page(1) }}>
																	All
																</div>
																<div
																	className={`nav-item ${suspension_date_filter === 'today' && 'selected'}`}
																	onClick={() => { set_suspension_date_filter('today'); set_current_page(1) }}
																>
																	Suspended Today
																</div>
																<div
																	className={`nav-item ${suspension_date_filter === 'yesterday' && 'selected'}`}
																	onClick={() => { set_suspension_date_filter('yesterday'); set_current_page(1) }}
																>
																	Suspended Yesterday
																</div>
																<div
																	className={`nav-item ${suspension_date_filter === 'last_7_days' && 'selected'}`}
																	onClick={() => { set_suspension_date_filter('last_7_days'); set_current_page(1) }}
																>
																	Last 7 days
																</div>
																<div
																	className={`nav-item ${suspension_date_filter === 'last_30_days' && 'selected'}`}
																	onClick={() => { set_suspension_date_filter('last_30_days'); set_current_page(1) }}
																>
																	Last 30 days
																</div>
																<div
																	className={`nav-item ${suspension_date_filter === 'custom' && 'selected'}`}
																	onClick={() => { set_current_page(1); set_show_date_range(true) }}
																>
																	{suspension_date_filter === 'custom' ? suspension_date_from.toDateString().substring(4, 10) + ' - ' + suspension_date_to.toDateString().substring(4, 10) : 'Select Period'}
																	<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: '10', boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)' }}>
																	</div>
																</div>
															</div> :
															<>
																<div className="custom_time_filter">
																	<Select
																		components={animatedComponents}
																		value={{ value: last_or_next, label: last_or_next === 'last' ? 'Last Billed' : 'Next Billing' }}
																		options={[{ value: 'last', label: 'Last Billed' }, { value: 'next', label: 'Next Billing' }]}
																		styles={colourStyles2}
																		onChange={(selectedOptions) => { set_last_or_next(selectedOptions.value); selectedOptions.value === 'last' ? set_next_billing_date_filter('') : set_last_billed_date_filter('') }}
																	/>
																	<div
																		className={`ml-2 nav-item ${((last_or_next === 'last' && last_billed_date_filter === '') || (last_or_next === 'next' && next_billing_date_filter === '')) && 'selected'}`}
																		onClick={() => { last_or_next === 'last' ? set_last_billed_date_filter('') : set_next_billing_date_filter(''); set_current_page(1) }}>
																		All
																	</div>
																	<div
																		className={`nav-item ${((last_or_next === 'last' && last_billed_date_filter === 'today') || (last_or_next === 'next' && next_billing_date_filter === 'today')) && 'selected'}`}
																		onClick={() => { last_or_next === 'last' ? set_last_billed_date_filter('today') : set_next_billing_date_filter('today'); set_current_page(1) }}
																	>
																		Today
																	</div>
																	<div
																		className={`nav-item ${((last_or_next === 'last' && last_billed_date_filter === 'yesterday') || (last_or_next === 'next' && next_billing_date_filter === 'tomorrow')) && 'selected'}`}
																		onClick={() => { last_or_next === 'last' ? set_last_billed_date_filter('yesterday') : set_next_billing_date_filter('tomorrow'); set_current_page(1) }}
																	>
																		{last_or_next === 'last' ? 'Yesterday' : 'Tomorrow'}
																	</div>
																	<div
																		className={`nav-item ${((last_or_next === 'last' && last_billed_date_filter === 'last_7_days') || (last_or_next === 'next' && next_billing_date_filter === 'next_7_days')) && 'selected'}`}
																		onClick={() => { last_or_next === 'last' ? set_last_billed_date_filter('last_7_days') : set_next_billing_date_filter('next_7_days'); set_current_page(1) }}
																	>
																		{last_or_next === 'last' ? 'Last 7 days' : 'Next 7 days'}
																	</div>
																	<div
																		className={`nav-item ${((last_or_next === 'last' && last_billed_date_filter === 'last_30_days') || (last_or_next === 'next' && next_billing_date_filter === 'next_30_days')) && 'selected'}`}
																		onClick={() => { last_or_next === 'last' ? set_last_billed_date_filter('last_30_days') : set_next_billing_date_filter('next_30_days'); set_current_page(1) }}
																	>
																		{last_or_next === 'last' ? 'Last 30 days' : 'Next 30 days'}
																	</div>
																	<div
																		className={`nav-item ${((last_or_next === 'last' && last_billed_date_filter === 'custom') || (last_or_next === 'next' && next_billing_date_filter === 'custom')) && 'selected'}`}
																		onClick={() => { set_current_page(1); set_show_date_range(true) }}
																	>
																		{(last_or_next === 'last' && last_billed_date_filter === 'custom') ? last_billed_date_from.toDateString().substring(4, 10) + ' - ' + last_billed_date_to.toDateString().substring(4, 10) : ((last_or_next === 'next' && next_billing_date_filter === 'custom') ? next_billing_date_from.toDateString().substring(4, 10) + ' - ' + next_billing_date_to.toDateString().substring(4, 10) : 'Select Period')}
																		<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: '10', boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)' }}>
																		</div>
																	</div>
																</div>
															</>
													)
											)
									}
									<div className="custom_time_filter">
										<div
											className={`nav-item ${usage_duration_filter === 'today' && 'selected'}`}
											onClick={() => { set_usage_duration_filter('today'); }}
										>
											Usage Today
										</div>
										<div
											className={`nav-item ${usage_duration_filter === 'yesterday' && 'selected'}`}
											onClick={() => { set_usage_duration_filter('yesterday'); }}
										>
											Usage Yesterday
										</div>
										<div
											className={`nav-item ${usage_duration_filter === 'last_7_days' && 'selected'}`}
											onClick={() => { set_usage_duration_filter('last_7_days'); }}
										>
											Last 7 days
										</div>
										<div
											className={`nav-item ${usage_duration_filter === 'last_30_days' && 'selected'}`}
											onClick={() => { set_usage_duration_filter('last_30_days'); }}
										>
											Last 30 days
										</div>
										<div
											className={`nav-item ${usage_duration_filter === 'custom' && 'selected'}`}
											onClick={() => { set_show_usage_date_range(true) }}
										>
											{usage_duration_filter === 'custom' ? usage_start_time.toDateString().substring(4, 10) + ' - ' + usage_end_time.toDateString().substring(4, 10) : 'Select Period'}
											<div style={{ position: 'absolute', backgroundColor: 'white', zIndex: '10', boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)' }}>
											</div>
										</div>
									</div>
								</Row>
							</CardHeader>
							{stores ? store_table1 : store_filler}
							<Col xs="12" id='paginator' className="flex_center py-2" style={{ maxHeight: '52px' }}>
								<TablePagination current_page={current_page} set_current_page={set_current_page} no_of_pages={no_of_pages} page_size={page_size} set_page_size={set_page_size} set_user_selected_page_size={set_user_selected_page_size}fetching_usage={fetching_usage}/>
							</Col>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	)

}

export default PaidOrgs