import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import general_fetch from '../../utils/fetch';
import { CardBody, Card, Row, CardHeader, Input, Button, Table } from 'reactstrap';
import DeleteNotification from "../../components/DeleteNotification/index.js";
import { confirmAlert } from 'react-confirm-alert';
import date_sort from '../../utils/date_sort';
import generic_sort from '../../utils/generic_sort';
import { isWeekend } from 'date-fns';

const CouponDetails = ({ coupon }) => {
    const [expanded, setExpanded] = useState(true);

    const toggleDetails = () => {
        setExpanded(!expanded);
    };

    const copyToClipboard = async (couponCode) => {
        try {
            await navigator.clipboard.writeText(couponCode);
            toastr.success('Copied to Clipboard')
        } catch (error) {
            console.error('Failed to copy:', error);
            toastr.error('Unable to Copy to Clipboard')
        }

    }

    return (
        <div className="coupon-details">
            <div className="coupon-info">
                <div className="coupon-code"><span style={{ cursor: 'pointer' }} onClick={() => { copyToClipboard(coupon.code) }}><b>{coupon.code}</b><i className='far fa-copy ml-2'></i></span></div>
                <div className="edit-status">
                    {/* <button className="edit-button">Edit</button> */}
                    <i className='far fa-edit mr-2 ml-2'></i>
                    <span className="status">{coupon.status}</span>
                    <i className={(expanded ? 'fa fa-caret-up' : 'fa fa-caret-down') + ' mr-2 ml-2'} onClick={toggleDetails}></i>
                </div>
            </div>

            {/* {!expanded && (
                <div className="summary-info">
                    <div>Discount Percentage: {coupon.discount_percentage}%</div>
                    <div>Maximum Discount: {coupon.max_discount}</div>
                    <div>Minimum Billable Amount: {coupon.min_bill}</div>
                    <div>Validity: {coupon.validity}</div>
                    <div>Validity Start Date: {coupon.validity_start_date}</div>
                    <div>Expiry: {coupon.expiry}</div>
                </div>
            )}

            <button className="expand-button" onClick={toggleDetails}>
                {expanded ? '[-] Less Details' : '[+] More Details'}
            </button>

            {expanded && (
                <div className="expanded-details">
                    <div>Discount Percentage: 20%</div>
                    <div>Maximum Discount: $50</div>
                    <div>Minimum Billable Amount: $100</div>
                    <div>Validity: 30 days</div>
                    <div>Validity Start Date: 2023-05-01</div>
                    <div>Expiry: 2023-05-31</div>
                </div>
            )} */}
            {
                expanded ?
                    <div className="summary-info">
                        <div>Discount Percentage: <b>{coupon.discount_percentage}</b>%</div>
                        <div>Maximum Discount: <b>{coupon.max_discount}</b></div>
                        <div>Minimum Billable Amount: <b>{coupon.min_bill}</b></div>
                        {/* <div>Validity: <b>{coupon.validity}</b></div> */}
                        <div>Validity Start Date: <b>{coupon.validity_start_date}</b></div>
                        <div>Expiry: <b>{coupon.expiry}</b></div>
                        <div>Usage: <b>{coupon.use_count ? coupon.use_count : '0'}/{coupon.max_count ? coupon.max_count : '-'}</b></div>
                    </div>
                    : ''
            }
        </div>
    );
};

function SpotCoupons({ isMobile, user_details }) {

    const [coupons, set_coupons] = useState([]);

    const [couponCodeAsc, set_couponCodeAsc] = useState(true);
    const [discountPercentageAsc, set_discountPercentageAsc] = useState(true);
    const [maximumDiscountAsc, set_maximumDiscountAsc] = useState(true);
    const [minimumBillableAmountAsc, set_minimumBillableAmountAsc] = useState(true);

    const get_all_spot_coupons = async() => {
        try{
            let coupons_resp = await general_fetch({url: 'billing/coupon/get_for_cs_user'})
            // console.log("couponss", coupons_resp)
            set_coupons(coupons_resp && coupons_resp.coupons && coupons_resp.coupons.length ? coupons_resp.coupons.filter(x => x.type == 'SPOT').sort((a,b) => date_sort(a, b, 'created_at')) : [])

        }catch(err){
            toastr.error("Could not create coupon")
        }
    }

    const copyToClipboard = async (couponCode) => {
        try {
            await navigator.clipboard.writeText(couponCode);
            toastr.success('Copied to Clipboard')
        } catch (error) {
            console.error('Failed to copy:', error);
            toastr.error('Unable to Copy to Clipboard')
        }

    }

    const create_new_spot_coupon = async() => {
        try{
            let resp = await general_fetch({url: 'billing/coupon/create_spot'})
            get_all_spot_coupons()

        }catch(err){
            toastr.error("Could not create coupon")
        }
    }

    const sort_table_fields = (field, asc, set_asc, type) => {
        let coupons_copy = JSON.parse(JSON.stringify(coupons))
        coupons_copy = coupons_copy.sort((a, b) => generic_sort(a, b, field, asc ? 1 : -1, type))
        // this.setState({stores: all_stores, sorted_asc_by_premium: asc})
        set_coupons(coupons_copy)
        set_asc(!asc)
    }


    useEffect(() => {
        get_all_spot_coupons()
    }, []);

    // useEffect(() => {
    //     console.log(coupons, "coupons")
    // }, [coupons]);

    return (
        <>
            <Row className='mb-3 mr-3' style={{ display: isWeekend ? 'flex' : 'none', justifyContent: 'end' }}><Button color='primary' onClick={create_new_spot_coupon}>Add</Button></Row>
            <div>
                {
                    isMobile ?
                        <>
                            {
                                coupons && coupons.length ? coupons.map((coupon, idx) => (
                                    <CouponDetails coupon={coupon} />
                                ))
                                    : ''
                            }
                        </>
                        :
                        <Table className="align-items-center table-flush text-center" responsive>
                            <thead className="thead-light text-center">
                                <tr>
                                    {/* <th scope="col"><span><b></b></span><br /></th> */}
                                    {/* <th scope="col"><span><b>Coupon Code</b></span><br /></th>
                                    <th scope="col"><span><b>Status</b></span><br /></th>
                                    <th scope="col"><span><b>Discount Percentage</b></span><br /></th>
                                    <th scope="col"><span><b>Maximum Discount</b></span><br /></th>
                                    <th scope="col"><span><b>Minimum Billable Amount</b></span><br /></th> */}
                                    {/* <th scope="col"><span><b>Validity</b></span><br /></th> */}
                                    {/* <th scope="col"><span><b>Validity Start Date</b></span><br /></th>
                                    <th scope="col"><span><b>Expiry</b></span><br /></th>
                                    <th scope="col"><span><b>Usage</b></span><br /></th> */}
                                    {/* <th scope="col"><span><b>Usage Limit</b></span><br /></th> */}
                                    {/* <th scope="col"><span><b></b></span><br /></th> */}
                                    <th scope="col">
                                        <span><b>Coupon Code</b> <i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('code', couponCodeAsc, set_couponCodeAsc)}} /></span><br />
                                        {/* <span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
                                            <i title='Sort by Coupon Code' onClick={() => sort_table_fields('code', 1)} style={{height:'10px'}} className='fa fa-caret-up'/>
                                            <i title='Sort by Coupon Code' onClick={() => sort_table_fields('code', 0)} className='fa fa-caret-down'/>
                                        </span>  */}
                                    </th>
                                    <th scope="col"><span><b>Status</b>{/*<i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('code', couponCodeAsc, set_couponCodeAsc)}} />*/}</span><br /></th>
                                    <th scope="col"><span><b>Discount Percentage</b><i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('discount_percentage', discountPercentageAsc, set_discountPercentageAsc, 'number')}} /></span><br /></th>
                                    <th scope="col"><span><b>Maximum Discount</b><i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('max_absolute_discount', maximumDiscountAsc, set_maximumDiscountAsc, 'number')}} /></span><br /></th>
                                    <th scope="col"><span><b>Minimum Billable Amount</b><i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('min_billable_amount', minimumBillableAmountAsc, set_minimumBillableAmountAsc, 'number')}} /></span><br /></th>
                                    {/* <th scope="col"><span><b>Validity</b><i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('validity', validityAsc, set_validityAsc)}} /></span><br /></th> */}
                                    <th scope="col"><span><b>Validity Start Date</b></span><br /></th>
                                    <th scope="col"><span><b>Expiry</b></span><br /></th>
                                    <th scope="col"><span><b>Usage</b></span><br /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    coupons && coupons.length ? coupons.map((coupon, idx) => (
                                        <tr key={idx}>
                                            {/* <td scope="col"><span><i className='far fa-edit'></i></span><br /></td> */}
                                            <td scope="col"><span style={{ cursor: 'pointer' }} onClick={() => { copyToClipboard(coupon.code) }}><b>{coupon.code}</b><i className='far fa-copy ml-2'></i></span><br /></td>
                                            <td scope="col"><span><b>{coupon.active ? 'Active' : 'Inactive'}</b></span><br /></td>
                                            <td scope="col"><span><b>{coupon.discount_percentage + '%'}</b></span><br /></td>
                                            <td scope="col"><span><b>{(coupon.max_absolute_discount ? coupon.max_absolute_discount : '-')}</b></span><br /></td>
                                            <td scope="col"><span><b>{coupon.min_billable_amount ? coupon.min_billable_amount : '-'}</b></span><br /></td>
                                            {/* <td scope="col"><span><b>{coupon.validity}</b></span><br /></td> */}
                                            <td scope="col"><span><b>{(new Date(coupon.start_datetime)).toLocaleString()}</b></span><br /></td>
                                            <td scope="col"><span><b>{coupon.end_datetime ? (new Date(coupon.end_datetime)).toLocaleString() : '-'}</b></span><br /></td>
                                            {/* <td scope="col"><span><i onClick={() => {copyToClipboard(coupon.code)}} className='far fa-copy'></i></span><br /></td> */}
                                            <td scope="col"><span><b>{coupon.use_count ? coupon.use_count : '0'}/{coupon.max_count ? coupon.max_count : '-'}</b></span><br /></td>
                                            {/* <td scope="col"><span><b>{coupon.max_count ? coupon.max_count : '-'}</b></span><br /></td> */}
                                        </tr>
                                    )) : ''
                                }
                            </tbody>
                        </Table>
                }
            </div>
        </>
    );
}

export default SpotCoupons;