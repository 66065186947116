import React, {useState, useEffect} from "react";
import {toastr} from 'react-redux-toastr';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, Modal, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardHeader, CardFooter, Col, Table, Row, Container, Nav, NavItem, NavLink } from "reactstrap";
import Header from "../../components/Headers/Header";
import RegularCoupons from "../../components/RegularCoupons";
import SpotCoupons from "../../components/SpotCoupons";

const Coupons = (user_details) => {
    const styles = {
        header_row: {
          marginLeft: 0,
          marginRight: 0,
          overflowX: "auto",
        },
    }
    const [active_tab, set_active_tab] = useState(0);
    const [isMobile, setIsMobile] = useState(false);

    const regularCoupons = <RegularCoupons isMobile={isMobile} user_details={user_details}/>
    const spotCoupons = <SpotCoupons isMobile={isMobile} user_details={user_details}/>


    const tabs = [regularCoupons, spotCoupons]

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return(
        <>
            <Container  fluid>
                <Row>
                    <div className="col">
                    <Card className="shadow">
                        <CardHeader className="border-4 bg-gradient-secondary">
                        <Row style={styles.header_row}>
                            <Nav pills style={{paddingBottom: 5, marginTop: 4}}>
                                <NavItem>
                                    <NavLink href='#' active={active_tab == 0} onClick={() => set_active_tab(0)}><b>Regular Coupons</b> </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink href='#' active={active_tab == 1} onClick={() => set_active_tab(1)}><b>Spot Coupons</b> </NavLink>
                                </NavItem> */}
                            </Nav>
                        </Row>
                        </CardHeader>
                        <CardBody>
                            {tabs[active_tab]}           
                        </CardBody>
                    </Card>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default Coupons