import Header from "../../components/Headers/Header";
import React, { useEffect, useState } from "react";
import {toastr} from 'react-redux-toastr';
import Select from "react-select";
import { Button, Card, CardHeader, Col, Container, FormGroup, Input, Modal, Row, Table } from "reactstrap";
import country_list from "../../utils/country_list";
import general_fetch from "../../utils/fetch";

const styles = {
	header_row: {
		marginLeft: 0,
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	round_status: {
		fontSize: 'small',
		paddingBottom: 0
	},
	nav_pills_rounds: {
		paddingRight: 60,
		marginRight: "0.7rem",
		marginLeft: "0.2rem",
	},
	action_button: {
		margin: 1       
	}
};

const AddGeography = ({open, toggle, fetch_geography, active_geo, mode}) => {

	const [selected_countries, set_selected_countries] = useState([]);
	const [geography_name, set_geography_name] = useState('');

	const add_geography = async() => {
		try{
			let body = {countries: selected_countries, name: geography_name}
			let resp = await general_fetch({url:'geography/create', body})
			fetch_geography()
			toastr.success('Geography created successfully')
			onClick_handleClose()
		}catch(e){
			console.error(e);
			toastr.error(`Couldn't create geography, please try again`)
		}
	}

	const update_geography = async() => {
		try{
			let body = {id:active_geo.id, countries: selected_countries, name: geography_name}
			let resp = await general_fetch({url:'geography/update', body})
			fetch_geography()
			toastr.success('Geography updated successfully')
			onClick_handleClose()
		}catch(e){
			console.error(e);
			toastr.error(`Couldn't update geography, please try again`)
		}
	}

	const onClick_handleClose = () => {
		set_geography_name('')
		set_selected_countries([])
		toggle()
	}
	
	useEffect(() => {
		if(active_geo){
			set_geography_name(active_geo.name)
			set_selected_countries(active_geo.countries)
		}
	}, [active_geo]);

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>{mode==='add' ? 'Add' : 'Update'} Geography</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
				<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
				<Row>
                    <Col md="12">
                      <FormGroup>
					  	<label className="form-control-label" htmlFor="input-address" >
							Name
                        </label>
                        <Input name="credits" className="form-control-alternative mb-3" type="text" placeholder='Enter Geography Name' value={geography_name} onChange={(e) => set_geography_name(e.target.value)} />
                        <label className="form-control-label" htmlFor="input-address" >
							Select Country
                        </label>
                        {/* <Input name="credits" className="form-control-alternative" type="select" placeholder='Type...' value={selected_countries} onChange={(e) => set_selected_countries(e.target.value)} /> */}
						<Select
                            closeMenuOnSelect={false}
                            // components={animatedComponents}
                            value = {selected_countries}
                            isMulti
                            options={country_list && country_list.map(c => {return {'value': c.code, 'label': c.name}})}
                            // styles={colourStyles}
                            onChange={(selectedOptions) => set_selected_countries(selectedOptions)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
			</div>
			<div className="modal-footer" style={{height:'60px'}}>
				<Button color='primary' disabled={(geography_name && selected_countries && selected_countries.length ? false : true)} onClick={mode==='add' ? add_geography : update_geography}>Submit</Button>
			</div>
		</Modal>
	)
}

const Geography = ({user_details}) => {

	const [add_modal_open, set_add_modal_open] = useState(false);
	const [display_geography, set_display_geography] = useState([]);
	const [mode, set_mode] = useState('');
	const [active_geo, set_active_geo] = useState();

	const toggle_add_modal = () => set_add_modal_open(!add_modal_open)

	const fetch_geography = async() => {
		try{
            var resp = await general_fetch({url: 'geography/get'});
			set_display_geography(resp)
		}catch(err){
            console.log(err);
			toastr.error(`Couldn't get geography, please try again`)
		}
	}

	useEffect(() => {
		fetch_geography()
	}, []);

	return(
		<>
			<Container fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-4 bg-gradient-secondary">
								<Row className='mb-3 mr-3' style={{ display: 'flex', justifyContent: 'end' }}><Button color='primary' onClick={() => {toggle_add_modal();set_mode('add')}}>Add Geography</Button></Row>
							</CardHeader>
							<div style={{minHeight:'200px'}}>
								<Table className="align-items-center table-flush text-center" responsive>
									<thead className="thead-light text-center">
										<tr style={{ textAlign: 'left' }}>
											<th scope="col"><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Geography Name</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Countries</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Actions</b></span>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{
											display_geography && display_geography.length ? display_geography.map((single_geography, idx) => (
												<tr style={{ textAlign: 'left' }}>
													<td scope="col"><span>{idx + 1}</span>&nbsp;</td>
													<td scope="col"><span>{single_geography.name ? single_geography.name : 'N/A'}</span>&nbsp;</td>
													<td scope="col">
														<div title={single_geography.countries ? single_geography.countries.map((c, idx) => c.label + (idx != single_geography.countries.length-1 ? ', ' : '')).join(' ') : 'N/A'} className=" lines1_elipsis" style={{maxWidth:'300px',}}>
															{single_geography.countries ? single_geography.countries.map((c, idx) => c.label + (idx != single_geography.countries.length-1 ? ', ' : '')).join(' ') : 'N/A'}
														</div>
													</td>
													<td scope="col">
														<i onClick={() => { set_active_geo(single_geography); toggle_add_modal(); set_mode('edit')}} className="fa fa-edit p-2 cp"></i>
													</td>
												</tr>
											)) : ''
										}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
				<AddGeography open={add_modal_open} toggle={() => {toggle_add_modal(); set_mode(''); set_active_geo()}} user_details={user_details} fetch_geography={fetch_geography} active_geo={active_geo} mode={mode}/>
			</Container>
		</>
	)
}

export default Geography