import React, { useState, useEffect } from 'react';
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from '../ITable';
import includes_search_string from '../../utils/includes_search_string';
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import general_fetch from '../../utils/fetch';
import { Checkbox } from 'antd';

const rule_type_struct = [
    {
        name: 'Automation Rules',
        id: 'design_automation'
    },
    {
        name: 'Manufacturing Rules',
        id: 'manufacturing_rule'
    },
    {
        name: 'Hardware Rules',
        id: 'hardware_rule'
    },
    {
        name: 'Design Constraint Rules',
        id: 'design_constraint'
    }
]


const RulesSelectionTableSingleRow = ({ rule, search_string}) => {

    return (
        <ITableRow style={{ cursor: 'pointer', display: includes_search_string(rule.name, search_string) || includes_search_string(rule.description, search_string) ? '' : 'none' }}>
            <ITD />
            <ITD>{rule.name}</ITD>
            <ITD>{rule.description}</ITD>
        </ITableRow>
    )
}

const RulesSelectionTable = ({ rules, visibility, search_string }) => {
    return (
        <ITable rowSeparated={true} hover={true} bordered={true} style_container={{ display: visibility ? 'block' : 'none' }}>
            <ITableHeader>
                <ITH></ITH>
                <ITH style={{ whiteSpace: 'nowrap' }}>Rule Name</ITH>
                <ITH>Rule Description</ITH>
            </ITableHeader>
            <ITableBody>
                {
                    rules && Array.isArray(rules) ? rules.map(rule => (
                        <RulesSelectionTableSingleRow key={rule.id} rule={rule} search_string={search_string} />
                    ))
                        : ''
                }
            </ITableBody>
        </ITable>
    )
}

const ViewTemplateModal = ({ rules, open, handle_close}) => {
    const [active_rule_type, set_active_rule_type] = useState('design_automation');
    const [search_string, set_search_string] = useState('');

    // const alert = useAlert();
    // ['am, hw, dc, mf]

    const on_click_handle_close = () => {
        set_active_rule_type('design_automation')
        handle_close()
    }

    return (
        <Modal centered size='xl' isOpen={open} toggle={on_click_handle_close}>
            <ModalHeader toggle={on_click_handle_close}>Create New Inventory Template</ModalHeader>
            <ModalBody style={{ height: 'calc(100vh - 160px)', overflow: 'auto' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }} className='nav nav-pills mb-3'>
                    {
                        rule_type_struct.map(rule => (
                            <div key={rule.id} onClick={() => { set_active_rule_type(rule.id) }} className='nav-item' style={{ cursor: 'pointer' }}><a className={active_rule_type === rule.id ? 'nav-link active' : 'nav-link'} style={{ height: 'unset' }}> {rule.name} </a></div>
                        ))
                    }
                </div>
                <RulesSelectionTable rules={rules && rules.filter(x => x.type === 'design_automation')} visibility={active_rule_type === 'design_automation'} search_string={search_string} />
                <RulesSelectionTable rules={rules && rules.filter(x => x.type === 'manufacturing_rule')} visibility={active_rule_type === 'manufacturing_rule'} search_string={search_string} />
                <RulesSelectionTable rules={rules && rules.filter(x => x.type === 'hardware_rule')} visibility={active_rule_type === 'hardware_rule'} search_string={search_string} />
                <RulesSelectionTable rules={rules && rules.filter(x => x.type === 'design_constraint')} visibility={active_rule_type === 'design_constraint'} search_string={search_string} />
            </ModalBody>

            <ModalFooter>
                <Button className='white_button' onClick={on_click_handle_close}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}

const InventoryTemplatesTableSingleRow = ({ template, template_selection_map, search_string='', onClickViewRule}) => {
    const [checked, set_checked] = useState(false);

    const onCheckChange = (e) => {
            e.stopPropagation();
            set_checked(!checked);
    }

    useEffect(() => {
        template_selection_map[template.id] = checked;
    }, [checked]);
    return (
        <ITableRow style={{ cursor: 'pointer', display: includes_search_string(template.name, search_string) ? '' : 'none' }} onClick={onCheckChange}>
            <ITD><Checkbox checked={checked} onChange={onCheckChange} /></ITD>
            {/* <ITD>{idx + 1}</ITD> */}
            <ITD>{template.name}</ITD>
            <ITD onClick={(e) => { e.stopPropagation(); onClickViewRule(template.id) }}>Click to view</ITD>
            <ITD></ITD>
        </ITableRow>
    )
}

const InventoryTemplatesTable = ({ templates, search_string, template_selection_map }) => {
    const [active_template_id, set_active_template_id] = useState('');

    const onClickViewRule = (template_id) => {
        set_active_template_id(template_id)
    }

    const closeViewRuleModal = () => {
        set_active_template_id('')
    }

    return (
        <React.Fragment>
            {/* all_rules && Array.isArray(all_rules) && active_template_id && templates[active_template_id] && templates[active_template_id].automation_rule_ids ? all_rules.filter(x => templates[active_template_id].automation_rule_ids.includes(x.id)) : [] */}
            <ViewTemplateModal rules={active_template_id && templates[active_template_id] ? templates[active_template_id].automation_rules : []} open={active_template_id} handle_close={closeViewRuleModal} />
            <ITable rowSeparated={true} hover={true} style_container={{borderTop: '1px solid  var(--light-border-color', borderBottom: '1px solid  var(--light-border-color', flex: 1, minHeight: '40px', overflow: 'auto'}}>
                <ITableHeader>
                    <ITH></ITH>
                    {/* <ITH>S.No</ITH> */}
                    <ITH style={{ whiteSpace: 'nowrap' }}>Template Name</ITH>
                    <ITH></ITH>
                </ITableHeader>
                <ITableBody>
                    {
                        templates && Object.keys(templates) ? Object.keys(templates).map((template_id, idx) => (
                            <InventoryTemplatesTableSingleRow key={template_id} template={templates[template_id]} template_selection_map={template_selection_map} onClickViewRule={onClickViewRule}/>
                        ))
                            : ''
                    }
                </ITableBody>
            </ITable>
        </React.Fragment>

    )
}

const InventoryTemplates = ({store_info, user_details, set_page_loader}) => {
    const [all_inventory_templates, set_all_inventory_templates] = useState({});
    const [template_selection_map, set_template_selection_map] = useState({});

    const get_all_inventory_templates = async () => {
        try {
            let response = await general_fetch({ url: 'inventory_template/get' });
            return response;
    
        } catch (err) {
            console.log("Error getting inventory templates", err);
            return Promise.reject({ ...err, info: 'Error getting inventory templates' })
        }
    }

    const apply_inventory_templates = async (body) => {
        try {
            let response = await general_fetch({ url: 'inventory_template/apply', body });
            return response;
    
        } catch (err) {
            console.log("Error applying inventory templates", err);
            return Promise.reject({ ...err, info: 'Error applying inventory templates' })
        }
    }

    const fetch_all_inventory_templates = async () => {
        try {
            let inventory_templates = await get_all_inventory_templates();
            // debug_log("Inventory Templates - ", inventory_templates);
            set_all_inventory_templates(inventory_templates)
        } catch (err) {
            toastr.error("Error fetching automation rules. Try again in sometime")
        }
    }

    const applyInventoryTemplates = async () => {
        set_page_loader(true, "Applying Template")
        // if (dataValidation()) {
            try {
                let inventory_template_ids = Object.keys(template_selection_map).filter(id => template_selection_map[id])
                let data = { inventory_template_ids }
                // todo - Select a BU for applying the templates
                data["customer_business_unit_id"] = store_info.default_business_unit_id;
                data["cs_user_id"] = user_details.id;
                console.log("Rule Data on Inventory Template Apply ", data)
                await apply_inventory_templates(data);
                toastr.success("Inventory Template applied successfully")
                // on_click_handle_close()
                // fetch_all_inventory_templates()
            } catch (err) {
                toastr.error("Could not apply inventory template. Please try again later.")
            }
        set_page_loader(false)
        // }
    }

    useEffect(() => {
        fetch_all_inventory_templates()
    }, []);



    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className='mb-3 px-2' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>Rule Wizard</div>
                <Button color='primary' onClick={applyInventoryTemplates}>Apply Templates</Button>
            </div>
            <InventoryTemplatesTable templates={all_inventory_templates} search_string={''} template_selection_map={template_selection_map}/>
        </div>
    )
}

export default InventoryTemplates