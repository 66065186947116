import Header from "../../components/Headers/Header";
import React, { useEffect, useState } from "react";
import {toastr} from 'react-redux-toastr';
import { Button, Card, CardHeader, Col, Container, FormGroup, Input, Modal, Row, Table } from "reactstrap";
import Select from "react-select";
import general_fetch from "../../utils/fetch";
import { DatePicker, Tooltip } from "antd";
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { confirmAlert } from "react-confirm-alert";
import DeleteNotification from "../../components/DeleteNotification";

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
	  title: 'Confirm to submit',
	  message: message,
	  customUI: ({ onClose }) => {
		return (
		  Custom_modal ?
			<div>
			  <Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
			:
			<div className='custom-ui'>
			  <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
		);
	  }
	});
}

const months = [
	'January', 'February', 'March', 'April',
	'May', 'June', 'July', 'August',
	'September', 'October', 'November', 'December'
  ];

const week_days = [
	'Su', 'Mo', 'Tu', 'We',
	'Th', 'Fr', 'Sa'
  ];

  const convert_to_readable = (str) => {
	//snake case
	if (!str) return '';
	if (str.includes('_')) {
		return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
	}
	//camel case
	let words = str.match(/[A-Za-z][a-z]*/g) || [];
	return words.map(word => (word.charAt(0).toUpperCase() + word.substring(1))).join(" ");
}

function getDaysInMonth(year, month) {
	const date = new Date(year, month, 1);
	const days = [];
  
	while (date.getMonth() === month) {
	  days.push({ date: String(date.getDate()), day: week_days[date.getDay()] });
	  date.setDate(date.getDate() + 1);
	}
  
	return days;
}

const styles = {
	header_row: {
		marginLeft: 0,
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	round_status: {
		fontSize: 'small',
		paddingBottom: 0
	},
	nav_pills_rounds: {
		paddingRight: 60,
		marginRight: "0.7rem",
		marginLeft: "0.2rem",
	},
	action_button: {
		margin: 1       
	}
};

const ShowPipedriveActivities = ({open, toggle, close_modal, cs_user_id, prospecting_call_activity, onclick_submit}) => {
	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>Pipedrive Activities</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
				<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
				<Table className="align-items-center table-flush text-center" responsive>
					<thead className="thead-light text-center">
						<tr style={{ textAlign: 'left' }}>
							<th scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th>
							<th scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span style={styles.table_header}><b>Subject</b></span>&nbsp;</th>
							<th scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span style={styles.table_header}><b>Lead Name</b></span>&nbsp;</th>
							<th scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span style={styles.table_header}><b>Org Name</b></span>&nbsp;</th>
							<th scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span style={styles.table_header}><b>Add date</b></span>&nbsp;</th>
							<th scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span style={styles.table_header}><b>Due date</b></span>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{
							prospecting_call_activity && prospecting_call_activity.length ? prospecting_call_activity.map((activity, idx) => (
								<tr className="display_onhover" style={{ textAlign: 'left' }}>
									<td scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span>{idx + 1}</span>&nbsp;</td>
									<td scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span>{activity.subject ? activity.subject : 'N/A'}</span>&nbsp;</td>
									<td scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span>{activity.person_name ? activity.person_name : 'N/A'}</span>&nbsp;</td>
									<td scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span>{activity.org_name ? activity.org_name : 'N/A'}</span>&nbsp;</td>
									<td scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span>{activity.add_time ? activity.add_time.split(' ')[0] : 'N/A'}</span>&nbsp;</td>
									<td scope="col" style={{maxWidth:'150px', overflow:'scroll'}}><span>{activity.due_date ? activity.due_date : 'N/A'}</span>&nbsp;</td>
								</tr>
							)) : <div className="flex_center">Fetching Activities...</div>
						}
					</tbody>
				</Table>
			</div>
			<div className="modal-footer" style={{height:'60px'}}>
				<Button color='primary' onClick={() => {onclick_submit(true); toggle(); close_modal()}}>Transfer</Button>
			</div>
		</Modal>
	)
}

const ApplyLeave = ({open, toggle, cs_user_id, cs_user_email, fetch_cs_org_leaves, set_page_loader}) => {

	const today = new Date()
	const tomorrow = new Date()
	tomorrow.setDate(today.getDate() + 1);
	const yesterday = new Date()
	yesterday.setDate(today.getDate() - 1);

	const [from_date, set_from_date] = useState();
	const [to_date, set_to_date] = useState();
	const [type, set_type] = useState('active');
	const [show_activities_modal, set_show_activities_modal] = useState(false);
	const [prospecting_call_activity, set_prospecting_call_activity] = useState([]);

	const onChangeFromDate = (date, dateString) => {
		set_from_date(dateString)
		// set_to_date(dateString)
		document.getElementById('to_date').click()
	}

	const onChangeToDate = (date, dateString) => {
		set_to_date(dateString)
	}

	const onClick_handleClose = () => {
		set_from_date(today.toDateString())
		set_to_date(tomorrow.toDateString())
		toggle()
	}

	const fetch_prospecting_call_activity = async() => {
		try{
			// set_page_loader(true, 'Fetching activities...')
			let resp = await general_fetch({url:'unavailability/get_pending_activities_for_email', body:{email: cs_user_email, start_time: new Date(from_date).toDateString(), end_time: new Date(to_date).toDateString()}})
			// set_page_loader(false)
			set_prospecting_call_activity(resp)
		}catch(err){	
			console.error(err)
		}
	}
	
	const onclick_submit = async(transfer) => {
		try{
			if(!transfer && type === 'inactive_transfer_pc'){
				fetch_prospecting_call_activity()
				set_show_activities_modal(true)
			}else{
				let body = {cs_user_id, start_time: new Date(from_date), end_time: new Date(to_date), type:type==='active' ? 'active' : 'inactive', restriction_type: type}
				let resp = await general_fetch({url:'unavailability/create', body})
				fetch_cs_org_leaves()
				onClick_handleClose()
			}
		}catch(e){
			console.error(e)
			toastr.error(`Couldn't mark Unavailability`)
		}
	}

	useEffect(() => {
		console.log('from_date', from_date, to_date)
	}, [from_date, to_date]);

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>Mark Unavailability</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
				<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
			    <Row>
                    <Col md="6">
						<FormGroup>
							<label className="form-control-label mr-2" htmlFor="input-address" >
								From
							</label>
							{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateTimePicker
								value={from_date}
								onChange={(newValue) => set_from_date(newValue)}
							/>
							</LocalizationProvider> */}
							<DatePicker
								format="YYYY-MM-DD"
								onChange = {onChangeFromDate}
								// defaultValue = {from_date}
								// disabledDate={(d) => new Date(d) < new Date(today)}
							/>
						</FormGroup>
					</Col>
					<Col md="6">
						<FormGroup>
							<label className="form-control-label mr-2" htmlFor="input-address" >
								To
							</label>
							{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateTimePicker
								value={to_date}
								onChange={(newValue) => set_to_date(newValue)}
							/>
							</LocalizationProvider> */}
							<DatePicker
								id = 'to_date'
								format="YYYY-MM-DD"
								onChange = {onChangeToDate}
								// defaultValue={to_date}
								// disabledDate={(d) => new Date(d) < new Date(from_date)}
							/>
						</FormGroup>
					</Col>
				</Row>
				{/* <Row className='mt-3'>
                    <Col md="12">
						<FormGroup>
							<label className="form-control-label mr-2" htmlFor="input-address" >
								Select type of leave you want to apply
							</label>
							<Input type='select' className='standard_input' />
						</FormGroup>
					</Col>
				</Row> */}
				{
					to_date < from_date ?
					<Row className='mb-4'>
						<Col md="12" style={{color:'red'}}>
							** From date should be less than To date
						</Col>
					</Row>:""
				}
				<Row>
                    <Col md="12">
						<FormGroup>
							<label className="form-control-label mb-3" htmlFor="input-address" >
								Unavailability Type
							</label>
							<div className="mb-2" onClick={() => set_type('active')} >
								<input type="radio" checked = {type == 'active' ? true : false} />
								<span style={{padding : '6px 12px', marginLeft:'16px', cursor: 'pointer'}}>Active, unavailable for new leads</span>
							</div>

							<div className="mb-2" onClick={() => set_type('inactive_transfer_pc')} >
								<input type="radio" checked = {type == 'inactive_transfer_pc' ? true : false} />
								<span style={{padding : '6px 12px', marginLeft:'16px', cursor:'pointer'}}>Inactive, transfer prospecting leads</span>
							</div>

							<div onClick={() => set_type('inactive_dont_transfer_pc')} >
								<input type="radio" checked = {type == 'inactive_dont_transfer_pc' ? true : false} />
								<span style={{padding : '6px 12px', marginLeft:'16px', cursor:'pointer'}}>Inactive, don't transfer any leads</span>
							</div>
						</FormGroup>
					</Col>
				</Row>
			</div>
			<div className="modal-footer" style={{height:'60px'}}>
				<Button color='primary' disabled={(!to_date || !from_date || to_date < from_date) ? true : false} onClick={() => onclick_submit(false)}>Submit</Button>
			</div>
			<ShowPipedriveActivities open={show_activities_modal} toggle={() => set_show_activities_modal(false)} close_modal={onClick_handleClose} cs_user_id={cs_user_id} prospecting_call_activity={prospecting_call_activity} onclick_submit={onclick_submit}/>
		</Modal>
	)
}

const OutofOffice = ({user_details, cs_org_users, set_page_loader, infurnia_cs_reps, infurnia_bd_reps}) => {

	const [cs_users, set_cs_users] = useState([]);
	const [non_cs_users, set_non_cs_users] = useState([]);
	const [active_cs_id, set_active_cs_id] = useState('');
	const [selected_month, set_selected_month] = useState(new Date().getMonth());
	const [selected_year, set_selected_year] = useState(new Date().getFullYear());
	const [show_apply_leave_modal, set_show_apply_leave_modal] = useState();
	const [cs_org_leaves, set_cs_org_leaves] = useState();
	const [display_cs_users, set_display_cs_users] = useState([]);

	const toggle_apply_leave_modal = () => set_show_apply_leave_modal(!show_apply_leave_modal)

	function getStartAndEndOfMonth(year, month) {
		const startDate = new Date(year, month, 1)
		const endDate = new Date(year, month+1, 0) ; // 0 day of next month is the last day of the current month
	  console.log(`getStartAndEndOfMonth`, year, month, startDate, endDate)
		return {
		  startDate: startDate.toDateString(),
		  endDate: endDate.toDateString(),
		};
	}

	const fetch_cs_org_leaves = async (month, year) => {
		try {
			// let account_details = cs_org_users
			// if (account_details && account_details.length) {
			// 	// account_details = account_details.filter(x => !x.depricated && (x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_admin') || x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_executive')))
			// 	let account_details_cs = account_details.filter(x => !x.depricated && (x.role.includes('customer_success_admin') || x.role.includes('customer_success_executive')))
			// 	set_cs_users(account_details_cs)
			// 	let account_details_non_cs = account_details.filter(x => !x.depricated && !(x.role.includes('customer_success_admin') || x.role.includes('customer_success_executive')))
			// 	set_non_cs_users(account_details_non_cs)
			// }
			let a = getStartAndEndOfMonth(year, month)
			let body = {start_time: a.startDate, end_time: a.endDate}
			console.log('getStartAndEndOfMonth1111', a.startDate, a.endDate, body)
			set_page_loader(true, "Please wait...")
			let resp = await general_fetch({url:"unavailability/get_in_duration", body})
			set_page_loader(false)
			let temp_cs_org_users
			if(window.location.origin.includes('bdp')){
				temp_cs_org_users = cs_org_users.filter(o => infurnia_bd_reps && infurnia_bd_reps.length && infurnia_bd_reps.find(x => x.user_id === o.id))
			}else{
				temp_cs_org_users = cs_org_users.filter(o => infurnia_cs_reps && infurnia_cs_reps.length && infurnia_cs_reps.find(x => x.user_id === o.id))
			}
			console.log('temp_cs_org_users', temp_cs_org_users)
			if(resp && resp.length){
				resp.map((single_leave) => {
					let found_user = temp_cs_org_users.find(o => o.id === single_leave.user_id)
					if(found_user) found_user[single_leave.date] = single_leave.type
				})
			}
			
			set_display_cs_users(temp_cs_org_users)
			set_cs_org_leaves(resp)
		} catch (err) {
			set_page_loader(false)
			console.error(err)
		}
	}

	const go_to_next_month = () => {
		if(selected_month == 11){
			set_selected_month(0)
			set_selected_year(selected_year+1)
			fetch_cs_org_leaves(0, selected_year+1)
		}else{
			set_selected_month(selected_month+1)
			fetch_cs_org_leaves(selected_month+1, selected_year)
		}
	}

	const go_to_prev_month = () => {
		if(selected_month == 0){
			set_selected_month(11)
			set_selected_year(selected_year-1)
			fetch_cs_org_leaves(11, selected_year-1)
		}else{
			set_selected_month(selected_month-1)
			fetch_cs_org_leaves(selected_month-1, selected_year)
		}
	}

	const calculate_date_str = (obj) => {
		return selected_year +'-' + (String(selected_month+1).length==1 ? ('0'+String(selected_month+1)) : (selected_month+1)) + '-' + (obj.date.length ==1 ? ('0'+obj.date) : obj.date)
	}

	const unmark_unavailability = async(date) => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({url:'unavailability/remove', body:{cs_user_id: user_details.id, date}})
			set_page_loader(false)
			fetch_cs_org_leaves(selected_month, selected_year)
		}catch(err) {
			console.error(err)
			toastr.error(`Couldn't unmark unavailability`)
		}
	}

	useEffect(() => {
	if(cs_org_users && cs_org_users.length && infurnia_bd_reps && infurnia_bd_reps.length && infurnia_cs_reps && infurnia_cs_reps.length) fetch_cs_org_leaves(selected_month, selected_year)
	}, [cs_org_users, infurnia_cs_reps, infurnia_cs_reps]);

	return(
		<>
		<ApplyLeave open={show_apply_leave_modal} toggle={toggle_apply_leave_modal} cs_user_id={user_details && user_details.id} cs_user_email={user_details && user_details.email} fetch_cs_org_leaves={() => fetch_cs_org_leaves(selected_month, selected_year)} set_page_loader={set_page_loader}/>
			<Container  fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-4 bg-gradient-secondary">
								<Row className='mb-3 mr-3' style={{ display: 'flex', justifyContent: 'end' }}><Button color='primary' onClick={toggle_apply_leave_modal}>Mark Unavailablity</Button></Row>
							</CardHeader>
							<div style={{minHeight:'200px'}}>
								<Table className="align-items-center table-flush text-center" responsive>
									<thead className="thead-light text-center">
										<tr style={{ textAlign: 'left' }}>
											{/* <th scope="col"><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th> */}
											<th scope="col"><span style={{textTransform:'initial', fontSize:'12px'}}>
												<i style={{color:'#2986ce', fontSize:'14px'}} className="fas fa-chevron-circle-left mr-2 cp" onClick={go_to_prev_month}/>
												<b>{months[selected_month] + ' ' + selected_year}</b> 
												<i className="fas fa-chevron-circle-right ml-2 cp" style={{color:'#2986ce', fontSize:'14px'}} onClick={go_to_next_month}/></span>&nbsp;</th>
											{
												getDaysInMonth(selected_year, selected_month).map((obj, i) => (
													<th className="p-1" style={{textTransform:'initial', fontSize:'11px'}}>
														<div className="flex_center">{obj.day}</div></th>
												))
											}
										</tr>
									</thead>
									<tbody>
										{
											display_cs_users && display_cs_users.length ? display_cs_users.map((user) => (
												cs_org_leaves && cs_org_leaves.length && cs_org_leaves.find(o => o.user_id == user.id) ?	
													<tr style={{ textAlign: 'left' }}>
														<td scope="col"><span>{user.first_name ? user.first_name : 'N/A'}</span>&nbsp;</td>
														{
															getDaysInMonth(selected_year, selected_month).map((obj, i) => (
																	<td className={`p-1 ${(user.id===(user_details&&user_details.id) && user[calculate_date_str(obj)] && user[calculate_date_str(obj)].includes('active')) ? 'cp' : ''}`} style={{textTransform:'initial', fontSize:'11px',}} onClick={() => (user.id===(user_details&&user_details.id) && user[calculate_date_str(obj)] && user[calculate_date_str(obj)].includes('active')) ? submit(() => {unmark_unavailability(obj.date + ' ' + months[selected_month].substring(0,3) + ' ' + selected_year)}, ()=>{}, `Unmark Unavailability on ${obj.date} ${months[selected_month].substring(0,3)} ${selected_year} ?`, '','') : ''}>
																		<Tooltip title={user[calculate_date_str(obj)] ? convert_to_readable(user[calculate_date_str(obj)]) : ''}>
																			<div className="flex_center" style={{backgroundColor: user[calculate_date_str(obj)] == 'weekend' ? '#fbc02d' : (user[calculate_date_str(obj)] ? (user[calculate_date_str(obj)].includes('holiday') ? '#98b433' : (user[calculate_date_str(obj)].includes('active') && !user[calculate_date_str(obj)].includes('inactive')) ? '#9785c2' : '#64c3d1') : '' ), color: user[calculate_date_str(obj)] ? 'white' : '', borderRadius:'100px', height:'24px', width:'24px'}}>{obj.date}</div>
																		</Tooltip>
																	</td>
															))
														}
													</tr>:''
												
											)):""
										}
									</tbody>
								</Table>
							</div>
							<div className="flex_property mx-4" style={{height:'60px', justifyContent:'flex-end'}}>
								<div className="flex_center mr-4" style={{fontSize:'12px'}}>
									<div className="mr-2 calender_dot" style={{backgroundColor:'#fbc02d'}}></div>
									Weekly Off
								</div>
								<div className="flex_center mr-4" style={{fontSize:'12px'}}>
									<div className="mr-2 calender_dot" style={{backgroundColor:'#9785c2'}}></div>
									Active
								</div>
								<div className="flex_center mr-4" style={{fontSize:'12px'}}>
									<div className="mr-2 calender_dot" style={{backgroundColor:'#64c3d1'}}></div>
									Inactive
								</div>
								<div className="flex_center mr-4" style={{fontSize:'12px'}}>
									<div className="mr-2 calender_dot" style={{backgroundColor:'#98b433'}}></div>
									Holiday
								</div>
							</div>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	)
}

export default OutofOffice