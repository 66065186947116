import React, {useState, useEffect} from "react";
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Modal, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, Dropdown, } from "reactstrap";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';

const DateRangeModal = ({open, toggle, dateRange, setRange, filter_fn}) => {
	const [tempRange, setTempRange] = useState([{
													startDate: new Date(),
													endDate: addDays(new Date(), 7),
													key: 'selection'
												}]);

												
	const onclick_submit = () => {
		if(filter_fn) filter_fn(tempRange[0].startDate, tempRange[0].endDate)
		setRange(tempRange[0].startDate, tempRange[0].endDate)
		onClick_handleClose()
	}					
	
	const onClick_handleClose = () => {
		toggle()
	}

	useEffect(() => {
		if(open && dateRange) {
			setTempRange(dateRange)
		}
	}, [open, dateRange]);
	
	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px', width:'938px'}}>
				<DateRangePicker
					onChange={item => {setTempRange([item.selection])}}
					showSelectionPreview={true}
					moveRangeOnFirstSelection={false}
					months={2}
					ranges={tempRange}
					direction="horizontal"
				/>
			</div>
			<div className="modal-footer" style={{height:'60px', width:'938px', backgroundColor:'white'}}>
				<Button color='primary' onClick={onclick_submit}>Submit</Button>
			</div>
		</Modal>
	)
}

export default DateRangeModal