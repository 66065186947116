const mixpanel_event_types = [{value: 'version_save', label: 'Version Save'}, 
								{value: 'draw_usage', label: 'Draw Tool'}, 
								{value: 'sketch_usage', label: 'Sketch Tool'},
								{value: 'bim_usage', label: 'Architecture'},
								{value: 'presentation_usage', label: 'Presentation'},
								{value: 'sku_property_edited', label: 'SKU Property Edited'}, 
								{value:'bulk_edit_sku', label: 'Bulk Edit SKU'}, 
								{value:'sku_structure_edited', label: 'SKU Structure Edited'}, 
								{value: "bulk_create_sku", label:'Bulk Create SKU'}, 
								{value: "core_on_backend", label:'Core on Backend'}, 
								{value: "render", label:'Render'}, 
								{value: "seller_sku_publish", label:'Seller SKU Publish'},
								{value: "cam_output_download", label:'CAM Output Download'},
								{value: "cutlist_output_download", label:'Cutlist Output Download'},
								{value: "pricing_quotation_download", label:'Pricing Quotation Download'},
								{value: "boq_download", label:'BOQ Download'},
								{value: "api_request", label:'API Requests'},
								{value:"component_edited", label:'Component Edited'},
								{value: "Work Order Created", label:'Work Order Created'},
								{value: "Work Order Completed", label:'Work Order Completed'},
								{value: "Modular Unit Edited", label:'MES Unit Edited'},
								{value: "MES Panel Listed", label:"MES Panel Listed"},
								{value: "Panel Cut Tracked", label:'Panel Cut Tracked'},
								{value: "Panel EB Tracked", label:"Panel EB Tracked"},
								{value: "Panel CNC Tracked", label:"Panel CNC Tracked"},
								{value: "Panel Packaged", label:"Panel Packaged"},
								{value: "Panel Shipped", label:"Panel Shipped"},
							]
export default mixpanel_event_types