import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import {toastr} from 'react-redux-toastr';
// import { loadStripe } from '@stripe/stripe-js';
import general_fetch from '../../utils/fetch';
import { CardBody, Card, Row, CardHeader, Input, Button, Table, Modal } from 'reactstrap';
import country_list from '../../utils/country_list';
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import DeleteNotification from "../../components/DeleteNotification/index.js";
// import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import {DatePicker, Tooltip} from 'antd'
import language_list from '../../utils/language_list';
import stateList from '../../utils/states';
import { infurnia_add_ons_mes as infurnia_add_ons } from '../../utils/infurnia_add_ons';

const animatedComponents = makeAnimated();

// const validate_email = (email) => {
// 	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
//   	return emailRegex.test(email);
// }

const validate_email = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', height:'46px', boxShadow:'0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)', border:0 }),
}

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
  confirmAlert({
    title: 'Confirm to submit',
    message: message,
    customUI: ({ onClose }) => {
      return (
        Custom_modal ?
          <div>
            <Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
          </div>
          :
          <div className='custom-ui'>
            <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
          </div>
      );
    }
  });
}


const possible_plans = [
    {
        id: 'studio',
        name: 'Studio (2 Free Users)'
    },
    {
        id: 'business',
        name: 'Business (5 Free Users)'
    },
    {
        id: 'enterprise',
        name: 'Enterprise (40 Free Users)'
    }
] 

const ShowExistingOrgs = ({open, toggle, OrgList, email, country, submit_fn}) => {

	const handle_action = (single_org, action_type) => {
		submit(() => {submit_fn(action_type, single_org.id);toggle()}, '', '')
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
              <h4 className="modal-title" id="exampleModalLabel"> Orgs found by Email - {email}</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className='mb-3' style={{fontSize:'14px'}}>
					Some organizations already exist for the provided email address.
				</div>
				<Table className="align-items-center table-flush text-center" responsive>
				<thead className="thead-light text-center">
					<tr style={{textAlign:'left'}}>
						<th scope="col"><span style={{fontSize:'12px'}}><b>Org Name</b></span><br /></th>
						<th scope="col"><span style={{fontSize:'12px'}}><b>Org ID</b></span><br /></th>
						<th scope="col" style={{textAlign:'center'}}><span style={{fontSize:'12px'}}><b>If Payment received</b></span><br /></th>
						<th scope="col" style={{textAlign:'center'}}><span style={{fontSize:'12px'}}><b>If Payment not received</b></span><br /></th>
					</tr>
				</thead>
				<tbody>
				{
					OrgList && OrgList.length ? OrgList.map((single_org, idx) => (
						<Tooltip title={single_org.country != country ? `The country of the existing organization does not match the country you have selected` : ''} placement='right' mouseEnterDelay={0}>
							<tr style={{textAlign:'left', color: single_org.country != country ? 'red' : ''}}>
								<td scope="col"><span>{single_org.name ?  single_org.name : 'N/A'}</span><br /></td>
								<td scope="col"><span>{single_org.id}</span><br /></td>
								<td scope="col" style={{textAlign:'center'}}><span><Button disabled = {single_org.country != country} onClick={() => {handle_action(single_org, 'register_closure')}} className="btn-sm" color="primary" >Record Payment</Button></span><br /></td>
								<td scope="col" style={{textAlign:'center'}}><span><Button disabled = {single_org.country != country} onClick={() => {handle_action(single_org, 'create_invite')}} className="btn-sm" color="primary" >Create Invite</Button></span><br /></td>
							</tr>	
						</Tooltip>
					)):''
				}
				</tbody>
				</Table>
			</div>
		</Modal>
	)
}

function PaymentForm({register_closure_tab, set_page_loader}) {
	const today = new Date().toDateString();
    const [organizationName, setOrganizationName] = useState('');
	const [legal_name, set_legal_name] = useState('');
    const [country, setCountry] = useState('IN');
    const [planType, setPlanType] = useState('studio');
    const [email, setEmail] = useState('');
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [discount, setDiscount] = useState(0);
	const [ coupon_code, set_coupon_code ] = useState();
    const [additionalUsers, setAdditionalUsers] = useState(0);
    const [paymentUrl, setPaymentUrl] = useState('');
    const [paymentQrCode, setPaymentQrCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [line_1, set_line_1] = useState('');
    const [line_2, set_line_2] = useState('');
    const [city, set_city] = useState('');
    const [state, set_state] = useState('');
    const [pin_code, set_pin_code] = useState('');
	const [gst_number, set_gst_number] = useState('');
	const [comments, set_comments] = useState('');
    const [selected_add_ons, set_selected_add_ons] = useState([]);
	const [show_existing_orgs_modal, set_show_existing_orgs_modal] = useState(false);
	const [OrgList, setOrgList] = useState([]);
	const [action_name, set_action_name] = useState('Manage');
	const [domain, set_domain] = useState('');
	const [language, set_language] = useState('');
	const [onboarding_date, set_onboarding_date] = useState();
	const [time_out_output, set_time_out_output] = useState();
	const [created_store_id, set_created_store_id] = useState();
	const [payment_cycle, set_payment_cycle] = useState(3);
	const [ payment_gateway, set_payment_gateway ] = useState("phonepe");
	const [tds_percentage, set_tds_percentage] = useState(0);
	let first_time = 1
	let toast

	const get_order_status = async (id) => {
		if (id) {
		  try {
			let body = {
			  id
			}
			let resp = await general_fetch({ url: 'prepaid_signup_flow/get_by_id', body })
			if(resp.payment_status === 'success' && first_time){
				toast = toastr.info('You will receive a mail once the Org is created, you can wait or close the tab', {timeOut: 3600000})
				set_page_loader(true, 'Payment recorded, creating Org...')
				first_time = 0
			}
			if (resp.store_id) {
			  set_created_store_id(resp.store_id)
			  set_page_loader(false)
			}
			return resp && resp.store_id
		  } catch (err) {
			console.log(err)
		  }
		}
	  }
	
	  const start_pinging_status = async (id) => {
		let poll_interval = setInterval(async () => {
		  let temp_store_id = await get_order_status(id)
		  if (temp_store_id) {
			clearInterval(poll_interval);
			toastr.remove(toast)
			toastr.success('Payment recorded & Org created successfully')
		  }
		}, 2000)
	
		// let time_out = setTimeout(function () {
		//   clearInterval(poll_interval);
		// }, 5 * 60 * 1000)
	
		// set_time_out_output(time_out)
	
	}

	// useEffect(() => {
	// 	if (created_store_id) { clearTimeout(time_out_output)}
	//   }, [created_store_id]);
  
    const processPayment = async (action_type, org_id) => {
      try {
        // generate payment id
        // const paymentId = await generatePaymentId(country, paymentAmount);
        let add_on_ids = selected_add_ons.map(x => x.value)

        let body = {
            name:organizationName,
			legal_name,
            country:country,
            plan_type: planType,
            email:email.toLowerCase(),
            discount_percentage: discount,
            additional_users: additionalUsers,
            line_1,
            line_2,
            city,
            state,
            pin_code,
            add_on_ids,
			gst_number: gst_number ? gst_number.trim() : null,
			comments,
			domain,
			languages: [language],
			onboarding_date,
			payment_cycle: payment_cycle,
			coupon_code,
			payment_gateway,
			tds_percentage,
            apply_onboarding_fees_on_plan: true,
            add_on_ids_to_apply_onboarding_fees: add_on_ids,
        }

		if(action_type != 'register_closure' && !register_closure_tab){
			body['send_invite_link'] = true
		}else{
			body['send_invite_link'] = false
		}
		if(org_id) body['existing_store_id'] = org_id
		
		set_page_loader(true, 'Please wait ...')
        let resp = await general_fetch({url: 'prepaid_signup_flow/create', body, req_resp:true});
		set_page_loader(false)
        if(resp.action === 'TOAST_ERROR' && resp.code === 'SIGNUP_LIMIT_REACHED'){
            toastr.error('Email already exists');
            return
        }

		const form_id = resp.data.id

		if(form_id && register_closure_tab && !org_id){
			set_page_loader(true, 'Recording payment...')
			start_pinging_status(form_id)
		}

        setPaymentAmount(0)
        setOrganizationName('')
        setCountry('IN')
        setEmail('')
        setDiscount(0)
        setAdditionalUsers(0)
        set_line_1('')
        set_line_2('')
        set_city('')
        set_state('')
        set_pin_code('')
        setPlanType('studio')
        set_selected_add_ons([])
		set_coupon_code('')
		set_domain('')
		set_language('')
		set_payment_gateway('phonepe')
		set_tds_percentage(0)
        // generate hash
        // const hash = generateHash(organizationName, country, country = planType, country = email, paymentAmount, paymentId);
  
        // make payment url
		console.log('create_invite', action_type, org_id)
        if(action_type != 'register_closure' && !register_closure_tab){
			const url = global.config.onboarding_path+`/?form_id=${form_id}`;
			navigator.clipboard.writeText(url)
			toastr.success('Invite link copied to clipboard');
			// const url = `https://example.com/payment?hash=${hash}`;
			setPaymentUrl(url);
	
			// generate qr code
			QRCode.toDataURL(url, (err, qrCode) => {
			if (err) {
				console.error(err);
				setErrorMessage('An error occurred while generating the QR code.');
			} else {
				setPaymentQrCode(qrCode);
				setErrorMessage('');
			}
			});
		}else{
			// toastr.success('Payment recorded successfully');
		}

		
  
        // send email with payment details
        // sendPaymentDetails(email, url, paymentQrCode );


        // Call an api to send the payment details

      } catch (error) {
        console.error(error);
        setErrorMessage('An error occurred while processing the payment.');
		toastr.error('Something went wrong, please try again')
      }
    };

    const recalculate_tentative_payment = async() => {

        let add_on_ids = selected_add_ons.map(x => x.value)
		// if(add_on_ids.includes('1') && add_on_ids.includes('4')){
		// 	add_on_ids = add_on_ids.filter(x => x!='4')
		// 	let a = JSON.parse(JSON.stringify(selected_add_ons))
		// 	a = a.filter(x => x.value != '4')
		// 	set_selected_add_ons(a)
		// 	toastr.info('You can only select one of the two add-ons among "Panel Cut-List" & "Manufacturing"')
		// }

        if(add_on_ids.includes('6') && add_on_ids.includes('7')){
			add_on_ids = add_on_ids.filter(x => x!='4')
			let a = JSON.parse(JSON.stringify(selected_add_ons))
			a = a.filter(x => x.value != '6')
			set_selected_add_ons(a)
			toastr.info('You can only select one of the two add-ons among "MES Lite" & "MES Pro"')
		}

        let body = {
            country:country,
            plan_type: planType,
            discount_percentage: discount,
            additional_users: additionalUsers,
            add_on_ids,
			payment_cycle:payment_cycle,
			tds_percentage,
            apply_onboarding_fees_on_plan: true,
            add_on_ids_to_apply_onboarding_fees: add_on_ids,

        }

        let resp = null;
        try {
            resp = await general_fetch({url: 'prepaid_signup_flow/get_tentative_cost', body})
        } catch(err) {
            toastr.error('Something went wrong, please try again')
            console.log(err);
        }
        if(resp)setPaymentAmount(resp.final_price)
    }
	
	const handle_submit = async() => {
		try{
			if(!organizationName || !email || !country){
				toastr.error('Please fill all mandatory fields')
				return
			}
			
			if(!domain){
				toastr.error('Please select domain')
				return
			}
			
			if(!language){
				toastr.error('Please select language')
				return
			}


			if(!validate_email(email)){
				toastr.error('Please enter a valid email')
				return
			}
			
			let resp = await general_fetch({url:'csp/get_attached_orgs_from_email', body: {email, filter_on_domain: false}})
			if(resp && resp.orgs && Object.values(resp.orgs).length){
				setOrgList(Object.values(resp.orgs))
				set_show_existing_orgs_modal(true)
			}else{
				submit(processPayment, ()=>{}, (register_closure_tab ? `Are you sure that we have received the payment ?` : `You've added ${Number(additionalUsers)} additional users and ${selected_add_ons.length} add-ons.`), '','')
			}
		}catch(error) {
			console.error(error);
		}
	}

	const create_spot_coupon = async() => {
		try{
			let resp =  await general_fetch({url:'billing/coupon/create_spot'})
			if(resp && resp.code) set_coupon_code(resp.code)
		}catch(error) {
			console.error(error)
			toastr.error(`Couldn't create spot coupon`)
		}
	}

	const get_coupon_by_code = async() => {
		try{
			let resp =  await general_fetch({url:'billing/coupon/get_by_code', body:{coupon_code}})
			if(resp && resp.coupons){
				let coupon = resp.coupons[Object.keys(resp.coupons)[0]]
				setDiscount(coupon.discount_percentage)
			}else{
				setDiscount(0)
			}
		}catch(error) {
			console.error(error)
			setDiscount(0)
		}
	}

    // Will be done from backend
    // const generatePaymentId = async (country, amount) => {
    //   if (country === 'india') {
    //     // generate Razorpay payment id
    //     const response = await fetch('https://api.razorpay.com/v1/payment_links', {
    //       method: 'POST',
    //       headers: { 'Content-Type': 'application/json' },
    //       body: JSON.stringify({ amount: amount }),
    //     });
    //     const data = await response.json();
    //     return data.id;
    //   } else {
    //     // generate Stripe payment id
    //     const stripe = await loadStripe('YOUR_STRIPE_PUBLISHABLE_KEY');
    //     const response = await fetch('/create-stripe-payment-intent', {
    //       method: 'POST',
    //       headers: { 'Content-Type': 'application/json' },
    //       body: JSON.stringify({ amount: amount }),
    //     });
    //     const data = await response.json();
    //     const paymentIntent = await stripe.confirmCardPayment(data.client_secret);
    //     return paymentIntent.id;
    //   }
    // };
  

//   function generateHash(organizationName, country, planType, email, paymentAmount, paymentId) {
//     const data = {
//       organizationName,
//       country,
//       planType,
//       email,
//       paymentAmount,
//       paymentId,
//     };
//     const jsonString = JSON.stringify(data);
//     const encodedString = btoa(jsonString);
//     const hash = sha256(encodedString);
//     return hash;
//   }

//   const sendPaymentDetails = (email, url, qrCode) => {
//     // implement your email sending logic here
//     // ...
//     // for example, you could use the following code to send an email using the Gmail API
//     const accessToken = 'YOUR_GMAIL_API_ACCESS_TOKEN';
//     const message = createMessage(email, url, qrCode);
//     const encodedMessage = btoa(message);
//     const headers = new Headers();
//     headers.append('Authorization', `Bearer ${accessToken}`);
//     headers.append('Content-Type', 'application/json');
//     const body = JSON.stringify({ raw: encodedMessage });
//     fetch('https://gmail.googleapis.com/gmail/v1/users/me/messages/send', {
//       method: 'POST',
//       headers: headers,
//       body: body,
//     })
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Failed to send email.');
//         }
//         setSuccessMessage('Payment details have been sent to your email.');
//       })
//       .catch(error => {
//         console.error(error);
//         setErrorMessage('An error occurred while sending the email.');
//       });
//   };

//   const createMessage = (to, url, qrCode) => {
//     const subject = 'Payment Details';
//     const message = `Payment URL: ${url}\n\nQR Code:\n${qrCode}`;
//     const headers = [
//       'Content-Type: text/plain; charset=UTF-8',
//       `To: ${to}`,
//       `Subject: ${subject}`,
//       'MIME-Version: 1.0',
//       'Content-Transfer-Encoding: 7bit',
//     ].join('\r\n');
//     const body = message.replace(/\n/g, '\r\n');
//     return `${headers}\r\n\r\n${body}`;
//   };


useEffect(() => {
    // let plan_credits = {
    //     'studio': 125,
    //     'business': 500,
    //     'enterprise': 2500
    // }
    // let additional_designer_cost = 50
    // let amount = 0
    // amount = amount + plan_credits[planType]
    // amount = amount + (additionalUsers * additional_designer_cost)
    // amount = ((100 - discount) * amount)/100
    // let tax = 0
    // if(country == 'IN'){
    //     tax = 0.18 * amount
    // }
    // amount = amount + tax
    // if(country == 'IN'){
    //     amount = amount * 40
    // }

    recalculate_tentative_payment()
    
}, [planType, country, discount, additionalUsers, selected_add_ons, payment_cycle, tds_percentage]);

useEffect(() => {
	let timeout = setTimeout(get_coupon_by_code, 2000)
	
    return () => clearTimeout(timeout)
}, [coupon_code]);

useEffect(() => {
	if(country === 'IN') setDiscount(0)
}, [country]);

  return (
    <div>
		<ShowExistingOrgs 
			open={show_existing_orgs_modal}
			toggle={() => set_show_existing_orgs_modal(false)}
			OrgList={OrgList}
			action_name={action_name}
			email={email}
			country={country}
			submit_fn={processPayment}
		/>
      <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0" >
                <Row className="align-items-center">
                    <h3 className="mb-0">Payment Form</h3>
                </Row>
            </CardHeader>
            <CardBody>
                <form style={{display:'flex', flexWrap:'wrap'}}>
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-orgname" >
                    Organization Name*:
                    <Input className="form-control-alternative" type="text" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} required />
                    </label>
					<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-orgname" >
                    Legal Name:
                    <Input className="form-control-alternative" type="text" value={legal_name} onChange={(e) => set_legal_name(e.target.value)} required />
                    </label>
                    <br />
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-email" >
                    Email*:
                    <Input className="form-control-alternative" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </label>
                    <br />
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-country" >
                    Country*:
                    <Input className="form-control-alternative" type="select" value={country} onChange={(e) => {setCountry(e.target.value); set_state('')}} required>
                        {
                            country_list && country_list.map((country, idx) => (
                                <option key={idx} value={country.code}>{country.name}</option>
                            ))
                        }
                    </Input>
                    </label>
                    <br />
                    {/* <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-plan" >
                    Plan Type*:
                    <Input className="form-control-alternative" type="select" value={planType} onChange={(e) => setPlanType(e.target.value)} required>
                        {
                            possible_plans && possible_plans.map((plan, idx) => (
                                <option key={idx} value={plan.id}>{plan.name}</option>
                            ))
                        }
                    </Input>
                    </label> */}
                    {/* <br /> */}
                    {/* <label className="form-control-label mb-3 col-12" htmlFor="input-amount" >
                    Payment Amount:
                    <Input className="form-control-alternative" type="number" value={paymentAmount} onChange={(e) => setPaymentAmount(e.target.value)} required readOnly/>
                    </label>
                    <br /> */}
                    <br />
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Additional Users:
                    <Input className="form-control-alternative" type="number" value={additionalUsers} onChange={(e) => Number(e.target.value)>=0 ? setAdditionalUsers(e.target.value) : toastr.error('Please enter a non-negative value')} required/>
                    </label>
                    <br />
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Add-ons:
                    <div>
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value = {selected_add_ons}
                            isMulti
                            options={infurnia_add_ons}
                            styles={colourStyles}
                            onChange={(selectedOptions) => set_selected_add_ons(selectedOptions)}
                        />
                    </div>
                    </label>
                    {
						register_closure_tab ?
						<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
						Coupon Code :
						<div className='flex_center'>
							<Input className="form-control-alternative mr-2"  value={coupon_code} onChange={(e) => set_coupon_code(e.target.value)} required/>
							<div className='mr-2'>or</div>
							<Button onClick={create_spot_coupon}>Create Spot Coupon</Button>
						</div>
						</label>:""
					}
                    <br />
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Address line 1:
                    <Input className="form-control-alternative" type="text" value={line_1} onChange={(e) => set_line_1(e.target.value)} required/>
                    </label>
                    <br />
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Address line 2:
                    <Input className="form-control-alternative" type="text" value={line_2} onChange={(e) => set_line_2(e.target.value)} required/>
                    </label>
                    <br />
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    City:
                    <Input className="form-control-alternative" type="text" value={city} onChange={(e) => set_city(e.target.value)} required/>
                    </label>
                    <br />
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    State:
                    <Input className="form-control-alternative" type={country==='IN' ? 'select' : 'text'} value={state} onChange={(e) => set_state(e.target.value)} required>
						<option value={''}>Select</option>
					{
						stateList.map((state, i) => (
							<option value={state.code}>{state.name}</option>
						))
					}
					</Input>
                    </label>
                    <br />
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Zipcode:
                    <Input className="form-control-alternative" type="text" value={pin_code} onChange={(e) => set_pin_code(e.target.value)} required/>
                    </label>
                    <br />
					{
						register_closure_tab && country === 'IN'?
						<>
							<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
							GST Number:
							<Input className="form-control-alternative" type="text" value={gst_number} onChange={(e) => set_gst_number(e.target.value)} required/>
							</label>
							<br />
						</>:""
					}
					{
						register_closure_tab?
						<>
							<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
							Comments:
							<Input className="form-control-alternative" type="text" value={comments} onChange={(e) => set_comments(e.target.value)} required/>
							</label>
							<br />
						</>:""
					}
					<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Domain*:
                    <Input className="form-control-alternative" type="select" value={domain} onChange={(e) => set_domain(e.target.value)} required>
					<option value={''}>Select</option>
						<option value={'Interior Design'}>Interior Design</option>
						<option value={'Modular Interior'}>Modular Interior</option>
						<option value={'Building Design'}>Building Design</option>
					</Input>
                    </label>
                    <br />
					<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Language*:
                    <Input className="form-control-alternative" type="select" value={language} onChange={(e) => set_language(e.target.value)} required>
                        <option value=''>Select</option>
                        {
                            language_list && language_list.map(l => (
                                <option value={l.code}>{l.name}</option>
                            ))
                        }
					</Input>
                    </label>
					<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Onboarding Date:
                    <div className='form-control-alternative' style={{height:'46px', backgroundColor:'white'}}>
						<DatePicker
							format="YYYY-MM-DD"
							onChange = {(date, dateString) => set_onboarding_date(dateString)}
							// defaultValue = {from_date}
							disabledDate={(d) => new Date(d) < new Date(today)}
							style = {{width:'100%', height:'46px', backgroundColor:'white'}}
							bordered = {false}
						/>
					</div>
                    </label>
					<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Quarterly/Annual*:
                    <Input className="form-control-alternative" type="select" value={payment_cycle} onChange={(e) => set_payment_cycle(e.target.value)} required>
						<option value={3}>Quarterly</option>
						{planType === "Single User" ? '' : <option value={12}>Annual</option>}
					</Input>
                    </label> 
                    <label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Plan Type*:
                    <Input className="form-control-alternative" type="select" value={planType} onChange={(e) => {setPlanType(e.target.value); set_payment_cycle(3)}} required>
						<option value={"studio"}>Studio</option>
						<option value={"Single User"}>Single User</option>
					</Input>
                    </label> 
					<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    Payment Gateway:
                    <Input className="form-control-alternative" type="select" value={payment_gateway} onChange={(e) => set_payment_gateway(e.target.value)} required>
						<option value={"phonepe"}>Phonepe</option>
						<option value={"razorpay"}>Razorpay</option>
					</Input>
                    </label> 
					<label className="form-control-label mb-3 col-12 col-lg-6" htmlFor="input-amount" >
                    TDS Percentage:
                    <Input className="form-control-alternative" type="select" value={tds_percentage} onChange={(e) => set_tds_percentage(e.target.value)} required>
						<option value={0}>N/A</option>
						<option value={2}>2%</option>
						<option value={10}>10%</option>
					</Input>
                    </label> 
                    <br />
                </form>
                <div className='col-12 mb-4' style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <div style={{padding:'20px', borderRadius:'10px', backgroundColor:'#F5F6F8'}}>
                        Total ({payment_cycle } Months): {(country == 'IN' ? '\u20B9' : '$') + paymentAmount + ` (${(country == 'IN' ? '\u20B9' : '$') + (Number.isInteger(paymentAmount/(payment_cycle)) ? paymentAmount/(payment_cycle) : (paymentAmount/(payment_cycle)).toFixed(2))}/month)`}
                    </div>
                </div>
                <div className='col-12' style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Button /*disabled={country=='IN' && payment_cycle==3 && !(selected_add_ons && selected_add_ons.length)}*/ color="primary" onClick={() => (register_closure_tab && !coupon_code) ? submit(handle_submit, ()=>{}, `Do you want to proceed without Coupon ?`, '','') : handle_submit()}>{!register_closure_tab ? 'Send Invite (With payment link)' : 'Create Store & Record Payment'}</Button>
                </div>
            </CardBody>
            {/* {paymentUrl && (
                <div>
                Payment URL: {paymentUrl}
                </div>
            )}
            {paymentQrCode && (
                <div>
                <img src={paymentQrCode} alt="Payment QR Code" />
                </div>
            )} */}
      </Card>
    </div>
  );
}

export default PaymentForm;