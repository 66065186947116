import React, { useState } from "react";
import { CspModal, CspModalBody, CspModalHeader } from "../CspModal";
import { Button as AntdButton, message, Steps, theme, Input as AntdInput } from 'antd';
import { toastr } from "react-redux-toastr";
import general_fetch from "../../utils/fetch";

const CreatePersonalSpace = () => (
	<div className="flex_center" style={{flexDirection:"column"}}>
		<div className="flex_center my-2 bold">Visit <a className="ml-2" href="https://mail.google.com/chat" target="_blank">https://mail.google.com/chat</a><i class="fas fa-external-link-alt ml-2" style={{fontSize:'14px'}}></i></div>
		<img className="mt-3" src="/create_a_space.png" width={'500px'} height={'400px'}/>
		<img className="mt-3" src="/save_space.png" width={'500px'} height={'550px'}/>
		<img className="mt-3" src="/open_space.png" width={'500px'} height={'500px'}/>
	</div>
)

const AddWebhook = () => (
	<div className="flex_center" style={{flexDirection:"column"}}>
		<img className="mt-3" src="/apps&integrations.png" width={'400px'} height={'350px'}/>
		<img className="mt-3" src="/add_webhook.png" width={'750px'} height={'400px'}/>
		<img className="mt-3" src="/save_webhook.png" width={'500px'} height={'250px'}/>
		<img className="mt-3" src="/copy_webhook_link.png" width={'900px'} height={'250px'}/>
	</div>
)


const GChatPersonalSpaceModal = ({ open, toggle, user_details }) => {
	const { token } = theme.useToken();
	const [ webhook_link, set_webhook_link ] = useState();
	const [current, setCurrent] = useState(0);
	const next = () => {
		document.getElementById('personal-notifs-modal-top').scrollIntoView({behavior: 'smooth'})
		setCurrent(current + 1);
	};
	const prev = () => {
		document.getElementById('personal-notifs-modal-top').scrollIntoView({behavior: 'smooth'})
		setCurrent(current - 1);
	};

	const PasteLink = () => (
		<div className="p-3">
			<div className="mb-2">Paste the link here</div>
			<AntdInput value={webhook_link} onChange={(e) => set_webhook_link(e.target.value)}/>
		</div>
	)

	const steps = [
		{
			title: 'Create Personal Space',
			content: <CreatePersonalSpace/>,
		},
		{
			title: 'Add Webhook',
			content: <AddWebhook/>,
		},
		{
			title: 'Paste Link and Proceed',
			content: PasteLink(),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));
	const contentStyle = {
		overflow:'auto',
		color: token.colorTextTertiary,
		backgroundColor: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: `1px dashed ${token.colorBorder}`,
		marginTop: 16,
		padding: 8
	};

	const update_gchat_webhook_url = () => {
		try {
			if(!webhook_link || !webhook_link.includes('chat.googleapis.com')){
				toastr.error('Please paste the link')
				return
			}
			let resp = general_fetch({url:'csp/update_gchat_webhook_url', body:{webhook_url: webhook_link}})
			message.success('Successfully added your personal space link!')
			toggle()
		}catch(err){
			console.error(err);
			toastr.error(`Could not add personal chat link`)
		}
	}

	return (
		<CspModal size={'xl'} open={open}>
			<CspModalHeader title={'Enable Personal Notifications on Google Chat!'} toggle={toggle} />
			<CspModalBody style={{ maxHeight: '600px' }}>
				<div id='personal-notifs-modal-top' style={{fontSize:'14px'}} className="mb-4">Please follow the following steps in order to receive Google Chat notifications related to CSP</div>
				<Steps current={current} items={items} />
				<div style={contentStyle}>{steps[current].content}</div>
				<div
					style={{
						marginTop: 24,
					}}
				>
					{current < steps.length - 1 && (
						<AntdButton type="primary" onClick={() => next()}>
							Next
						</AntdButton>
					)}
					{current === steps.length - 1 && (
						<AntdButton type="primary" onClick={() => update_gchat_webhook_url()}>
							Done
						</AntdButton>
					)}
					{current > 0 && (
						<AntdButton
							style={{
								margin: '0 8px',
							}}
							onClick={() => prev()}
						>
							Previous
						</AntdButton>
					)}
				</div>
			</CspModalBody>
		</CspModal>
	)
}

export default GChatPersonalSpaceModal