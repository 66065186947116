export const time_string = (date) => {
	let new_date = new Date(date)
	const months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	  ];
	const hours = new_date.getHours();
	const minutes = new_date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';

	const formattedTime = `${(hours % 12) || 12}:${String(minutes).padStart(2, '0')} ${ampm}`;

	return formattedTime
}