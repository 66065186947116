import React, { useEffect, useState } from "react";
import { Button, Input, Row, Col } from "reactstrap";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import { colourStyles4 } from "../../utils/react_select_styles";
import { Switch, Drawer, message, Upload, Button as AntdButton, Form, Input as AntdInput } from 'antd';
import general_fetch from "../../utils/fetch";

const AddBackOfficeTicket = ({ open, toggle, cs_org_users, fetch_back_office_tickets, customer_tickets, set_page_loader }) => {

	const [title, set_title] = useState();
	const [priority, set_priority] = useState();
    const department = 'product';
	const [assignee_id, set_assignee_id] = useState();
	const [type, set_type] = useState();
	const [use_case, set_use_case] = useState();
	const [reproduction, set_reproduction] = useState();
    const [customer_ticket_ids, set_customer_ticket_ids] = useState([]);

	const onclick_handleclose = () => {
		toggle()
		set_title('')
		set_priority('')
		set_assignee_id()
		set_type()
		set_use_case()
		set_reproduction()
        set_customer_ticket_ids([])
	}
    
	const submit_fn = async () => {
		try {
            if(!title) { toastr.error('Title is required'); return }
            if(!customer_ticket_ids || (customer_ticket_ids.length == 0))  {toastr.error('Atleast 1 customer ticket should be linked'); return}
            if(!type || type == '') {toastr.error('Type is required'); return}
            if(!assignee_id || assignee_id == '') {toastr.error('Assignee is required'); return}
			let body = {}
			body.title = title
			if(type) body.type = type
            if(priority) body.priority = priority;
			let additional_info = { use_case, reproduction }
			body.additional_info = JSON.stringify(additional_info);
            if(assignee_id) body.assignee_id = assignee_id;
            // customer ticket ids
            let customer_ticket_link_ids = customer_ticket_ids.map(x => x.value);
            body.customer_ticket_ids = JSON.stringify(customer_ticket_link_ids);;
			set_page_loader(true, 'Please wait...')
			await general_fetch({ url: 'back_office_ticket/create', body: body })
			toastr.success('Successfully created back office ticket')
			set_page_loader(false)
			fetch_back_office_tickets()
			onclick_handleclose()
		} catch (e) {
			set_page_loader(false)
			console.error(e)
			toastr.error(`Couldn't Create Ticket`)
		}
	}

	const get_assignee_list = () => {
		if(cs_org_users && cs_org_users.length){
			let temp_cs_org_users = JSON.parse(JSON.stringify(cs_org_users))
			if(department === 'customer_success'){
				temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('cs_rep'))
			}else if(department === 'product'){
				temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('infurnia_product_manager'))
			}else if(department === 'catalogue'){
				temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('infurnia_catalog_admin'))
			}
			return temp_cs_org_users.map(o => { return { value: o.id, label: o.first_name } }) 
		}
		return []
	}

	useEffect(() => {
		if (department === 'catalogue') {
			set_type('org')
		} else {
			set_type('')
		}
	}, [department]);

	const AssignedTo = () => (
		<div className="mb-4" style={{position: 'relative', zIndex: 999999}}>
			<div className='mb-2 bold'>Assigned to*</div>
			<Select
				value={{ value: assignee_id, label: cs_org_users && cs_org_users.length && assignee_id ? cs_org_users.find(o => o.id === assignee_id).first_name : '' }}
				options={get_assignee_list()}
				// styles={colourStyles4}
				onChange={(selectedOptions) => set_assignee_id(selectedOptions.value)}
			/>
		</div>
	)

    const CustomerTicketsPicker = () => (
		<div className="mb-4">
            <div className="mb-2 bold">Customer Tickets*:</div>
            <Select
                value={customer_ticket_ids}
                isMulti
                options={customer_tickets && customer_tickets.length ? customer_tickets.map(x => { return { value: x.id, label: x.title } }) : []}
                // styles={colourStyles4}
                onChange={(selectedOptions) => set_customer_ticket_ids(selectedOptions)}
            />
		</div>
    )

	return (
		<Drawer open={open} onClose={onclick_handleclose} size="large" title='Create Product Ticket' >
			{
					<>
                        <div className='mb-2 bold'>Title*</div>
                        <Input type='text' style={{ height: '36px' }} className='px-2 py-1 mb-4' placeholder={'Type...'} value={title} onChange={(e) => set_title(e.target.value)} />
                        {

                            <>
                                {
                                    AssignedTo()
                                }
                                <label className="form-control-label mt-2 mb-4 flex_property" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
                                    <div className='mr-2'>Type*</div>
                                    <div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('feature') }} >
                                        <input type="radio" className="mr-2" checked={type === 'feature' ? true : false} />
                                        <div className="mr-4">Feature</div>
                                    </div>
                                    <div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('bug') }} >
                                        <input type="radio" className="mr-2" checked={type === 'bug' ? true : false} />
                                        <div>Bug</div>
                                    </div>
                                </label>
                                {
                                    type === 'feature' ?
                                        <>
                                            <div className='mb-2 bold'>Use Case</div>
                                            <textarea style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={use_case} onChange={(e) => set_use_case(e.target.value)} placeholder='e.g. The client is trying to create shutters with a design that is shown in this image link <url>' />
                                        </> : (
                                            type === 'bug' ?
                                                <>
                                                    <div className='mb-2 bold'>Reproduction</div>
                                                    <textarea style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={reproduction} onChange={(e) => set_reproduction(e.target.value)} placeholder='e.g. Add a Cabinet, Change the shutter and external finish and then change shutter design from normal to profile' />
                                            </> : ""
                                    )
                                }
                            </>
                        }
                        {
                            CustomerTicketsPicker()
                        }
						<div className='mb-4 bold'>Priority. 1 is highest priority, 5 is lowest</div>
						<div className="flex_property mb-4" style={{ fontWeight: 400 }}>
							<div>
								<div style={{ minHeight: '32px' }}></div>
							</div>
							{[1, 2, 3, 4, 5].map(x => (
								<div>
									<div style={{ minHeight: '32px' }} className="ml-2 mr-2">{x}</div>
									<div style={{ minHeight: '32px' }} className="ml-2 mr-2" onClick={() => set_priority(x)} >
										<input type="radio" checked={priority === x ? true : false} />
									</div>
								</div>
							))}
							<div>
								<div style={{ minHeight: '32px' }}></div>
							</div>
						</div>
					</>
			}
			{
                <Button color='primary' onClick={submit_fn}>Submit</Button>
            }
		</Drawer>
	)
}

export default AddBackOfficeTicket;
