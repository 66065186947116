import Maps from "./views/examples/Maps.jsx";
import Register from "./views/examples/Register.jsx";
import Login from "./views/examples/Login.jsx";
import Home from "./views/Home";
import Traction from "./views/Traction";
import Icons from "./views/examples/Icons.jsx";
import Meetings from "./views/Meetings";
import Documents from "./views/Documents";
import Profile from "./views/Profile";
import Working from "./views/Working";
import Payments from "./views/Payments";
import Orders from "./views/Orders";
import Billing from "./views/Billing";
import Coupons from "./views/Coupons/index.js";
import Geography from "./views/Geography/index.jsx";
import Languages from "./views/Languages/index.jsx";
import CsExecutives from "./views/CsExecutives/index.jsx";
import OutofOffice from "./views/OutofOffice/index.jsx";
import CsAssignments from "./views/CsAssignments/index.jsx";
import Bdreps from "./views/BdReps/index.jsx";
import BdGroups from "./views/BdGroups/index.js";
import PaidOrgs from "./views/PaidOrgs/index.jsx";
import UnpaidOrgs from "./views/UnpaidOrgs/index.jsx";
import ChurnedOrgs from "./views/ChurnedOrgs/index.jsx";
import Activities from "./views/Activities/index.jsx";
import Teams from "./views/Teams/index.jsx";
import ActivityReports from "./views/ActivityReports/index.jsx";
import CustomerTickets from "./views/CustomerTickets/index.jsx";
import BackOfficeTickets from "./views/BackOfficeTickets/index.jsx";
import CohortAnalysis from "./views/CohortAnalysis/index.jsx";
import ClientsAtRisk from "./views/ClientsAtRisk/index.js";
import ReleaseNotes from "./views/ReleaseNotes/index.js";

var routes = [
  {
    path: "/paid_orgs",
    name: "Paid Orgs",
    icon: "briefcase",
    component: PaidOrgs,
    admin_only: false,
	cs_only: true,
    layout: "/home"
  },
  {
    path: "/churned_orgs",
    name: "Churned Orgs",
    icon: "briefcase",
    component: ChurnedOrgs,
    admin_only: false,
	cs_only: true,
    layout: "/home"
  },
  {
    path: "/unpaid_orgs",
    name: "Unpaid Orgs",
    icon: "briefcase",
    component: UnpaidOrgs,
    admin_only: false,
	cs_only: true,
    layout: "/home"
  },
  {
    path: "/orgs_at_risk",
    name: "Orgs at Risk",
    icon: "exclamation-triangle",
    component: ClientsAtRisk,
    admin_only: false,
	cs_only: true,
    layout: "/home"
  },
  {
    path: "/cohort_analysis",
    name: "Cohort Analysis",
    icon: "chart-line",
    component: CohortAnalysis,
    admin_only: false,
	cs_only: true,
    layout: "/home"
  },
  {
    path: "/activities",
    name: "Activities",
    icon: "tasks",
    component: Activities,
    admin_only: false,
	cs_only: true,
    layout: "/home"
  },
  {
    path: "/activity_reports",
    name: "Activity Reports",
    icon: "chart-bar",
    component: ActivityReports,
    admin_only: false,
	team_lead_only: true,
	cs_only: true,
    layout: "/home"
  },
  {
	path: "/customer_tickets",
    name: "Tickets",
    icon: "ticket",
    component: CustomerTickets,
    admin_only: false,
	cs_only: true,
    layout: "/home"
  },
  /* {
	path: "/back_office_tickets",
    name: "Product Tickets",
    icon: "ticket",
    component: BackOfficeTickets,
    admin_only: false,
	cs_only: true,
    layout: "/home"
  }, */
  {
    path: "/onboarding",
    name: "OnBoarding",
    icon: "user-plus",
    component: Payments,
    admin_only: false,
	bd_only: true,
    layout: "/home"
  },
  {
    path: "/coupons",
    name: "Coupons",
    icon: "tags",
    component: Coupons,
    admin_only: false,
	bd_only: true,
    layout: "/home"
  },
  {
    path: "/out_of_office",
    name: "Availability",
    icon: "calendar-alt",
    component: OutofOffice,
    admin_only: false,
    layout: "/home"
  },
  {
    path: "/geography",
    name: "Geography",
    icon: "globe",
    component: Geography,
    admin_only: true,
    layout: "/home"
  },
  {
    path: "/teams",
    name: "Teams",
    icon: "sitemap",
    component: Teams,
    admin_only: true,
    layout: "/home"
  },
  {
    path: "/cs_executives",
    name: "CS Reps",
    icon: "users",
    component: CsExecutives,
    admin_only: false,
	team_lead_only: true,
	cs_only: true,
    layout: "/home"
  },
  {
    path: "/cs_assignments",
    name: "CS Assignments",
    icon: "users",
    component: CsAssignments,
    admin_only: true,
	cs_only: true,
    layout: "/home"
  },
  {
    path: "/bd_reps",
    name: "BD Reps",
    icon: "users",
    component: Bdreps,
    admin_only: false,
	team_lead_only: true,
	bd_only: true,
    layout: "/home"
  },
  {
    path: "/bd_groups",
    name: "BD Groups",
    icon: "sitemap",
    component: BdGroups,
    admin_only: true,
	bd_only: true,
    layout: "/home"
  },
  {
    path: "/release",
    name: "Release Notes",
    icon: "sitemap",
    component: ReleaseNotes,
    // admin_only: true,
	  cs_only: true,
    layout: "/home"
  },
  {
    path: "/feature_guide",
    name: "Feature Guide",
    icon: "lightbulb",
    component: ReleaseNotes,
    // admin_only: true,
	  cs_only: true,
    layout: "/home"
  }
];
export default routes;
