export const get_investment_status = () => dispatch => 
	fetch(global.config.server_path + 'investment/get_status', {method: 'POST', credentials: 'include'})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const create_investment = data => dispatch => 
	fetch(global.config.server_path + 'investment/add', {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify(data)})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const update_investment = data => dispatch => 
	fetch(global.config.server_path + 'investment/update', {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify(data)})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))


export const delete_investment = data => dispatch => 
	fetch(global.config.server_path + 'investment/delete', {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify(data)})
	.then(resp => resp.json())
	.then(resp => global.validate_response(resp))
	.then(resp => resp.data)
	.catch(err => Promise.reject(err))