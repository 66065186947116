import React, { useEffect, useState, version } from "react";
import { Button, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from "../../components/ITable";
import { createFeatureGuide, deleteFeatureGuide, getAllFeatureGuides, updateFeatureGuide, upload_gif } from "../../utils/api/feature_guide";
import { getLatestVersions } from "../../utils/api/release_note"
import useStateCallback from "../../utils/custom_hooks";
import general_fetch from "../../utils/fetch";
import { toastr } from "react-redux-toastr";

const AddOrUpdateFeatureGuides = ({open, handle_close, getLatestFeatureGuides, all_versions, active_feature_guide, set_page_loader}) => {
    const [guide_name, set_guide_name] = useState('');
    const [type, set_type] = useState('Blink');
    const [ versions, set_versions ] = useState([]);

    const [ release, set_release ] = useState('');
    const [ feature_guide_steps, set_feature_guide_steps ] = useState([]);
    const [ re_render, set_re_render ] = useState(false);

    const forceRerender = () => {set_re_render(!re_render)}

    const onclick_handle_close = () => {
        handle_close();
        set_guide_name('');
        set_type('Blink');
        set_release('');
        set_versions([]);
        set_feature_guide_steps([]);
    }
    const handleGuideNameChange = (e) =>{
        set_guide_name(e.target.value);
    }

    const handleTypeChange = (e) =>{
        set_type(e.target.value);
    }

    const createFeatureGuidefn = async() => {
        try{
            console.log(release, feature_guide_steps, guide_name, type);
            set_page_loader(true, "Adding Feature Guide ...");
            await createFeatureGuide(release, feature_guide_steps, "Draft", guide_name, type)
            set_page_loader(false, "Successfully Adding Feature Guide ...");
            onclick_handle_close()
            getLatestFeatureGuides()
        }catch(err){
            set_page_loader(false, "Failed to add  Feature Guide ...");
            toastr.error('Please enter mandatory fields')
            console.error(`Error in createFeatureGuidefn `, err)
            // handleError(err);
        }
    }

    const updateFeatureGuidefn = async() => {
        try{
            set_page_loader(true, "Updating Feature Guide ...");
            await updateFeatureGuide(active_feature_guide.id, release, feature_guide_steps, active_feature_guide.status, guide_name, type)
            set_page_loader(false, "Successfully Updated Feature Guide ...");
            onclick_handle_close()
            getLatestFeatureGuides()
        }catch(err){
            set_page_loader(false, "Failed to update Updated Feature Guide ...");
            toastr.error('Failed to update Feature Guide')
            console.error(`Error in updateFeatureGuidefn `, err)
            // handleError(err);
        }
    }
    const uploadGif = async(e, idx) => {
        try{
            
            let formData = new FormData();
            formData.append('upl', e.target.files[0], e.target.files[0].name);
            // set loader
            console.log({target : e.target.files, input : e.target.files[0]});
            set_page_loader(true, "Uploading gif...");
            let uploaded_image = await general_fetch({url : 'image/add', body : formData, is_form_data: true});
            set_page_loader(false, "Successfully uploaded gif");
            feature_guide_steps[idx].gif_pic_id = uploaded_image.id;
            //unset loader
            e.stopPropagation();
            forceRerender();
        }catch(err) {
            set_page_loader(false, "Failed to upload gif");
            console.log(`error in uploadGif`, err);
        }
    }

    useEffect(() => {
        if(open){
            (async () => {
                if(active_feature_guide && active_feature_guide.guide_name) {
                    set_guide_name(active_feature_guide.guide_name);
                }
                if(active_feature_guide && active_feature_guide.type) {
                    set_type(active_feature_guide.type);
                }
                if(!(all_versions && all_versions.length)){
                    let versions = await getLatestVersions()
                    set_versions(versions)
                    if(active_feature_guide && active_feature_guide.jira_version_id){
                        set_release(active_feature_guide.jira_version_id)
                    }else{
                        set_release(versions[0].id)
                    }
                }else{
                    set_versions(all_versions)
                    if(active_feature_guide && active_feature_guide.jira_version_id){
                        set_release(active_feature_guide.jira_version_id)
                    }else{
                        set_release(all_versions[0].id)
                    }
                }

                if(active_feature_guide && active_feature_guide.feature_guide_steps){
                    set_feature_guide_steps(active_feature_guide.feature_guide_steps)
                }
            })()
        }
    }, [open]);
    return(
        <>
            <Modal centered size={"xl"} isOpen={open} toggle={onclick_handle_close}>
                <ModalHeader toggle={onclick_handle_close}> Add/Update Feature Guide</ModalHeader>
                <ModalBody>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                        <div className="flex_property" style={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{minWidth: "20%"}}>
                                    <div className="mr-3" style={{margin : "8px 0"}}>Release*</div>
                                    <Input type='select' value={release} onChange={(e) => {set_release(e.target.value)}} >
                                        {
                                            versions && versions.length ? versions.map((version, idx) => (
                                                <option value={version.id} key={idx}>{version.name}</option>
                                            ))
                                            :''
                                        }
                                    </Input>
                                </div>
                                <div style={{minWidth: "40%"}}>
                                    <div className="mr-3" style={{margin : "8px 0"}}>Guide Name*</div>
                                    <Input type='text' value = {guide_name} onChange={handleGuideNameChange} style={{width: "100%"}}></Input>
                                </div>
                                <div style={{minWidth: "20%"}}>
                                    <div className="mr-3" style={{margin : "8px 0"}}>Type*</div>
                                    <Input value={type} type={"select"} onChange={handleTypeChange}>
                                            <option value={"Blink"}>Blink</option>
                                            <option value={"Forced"}>Forced</option>
                                    </Input>
                                </div>
                        </div>
                        <ITable coloumnSeparated={true} rowSeparated={true} style={{width : "100%"}} style_container={{border: '1px solid var(--light-border-color, #E6E9EE)', maxHeight: '400px', overflow: 'auto'}}>
                            <ITableHeader style={{background: "white", position: 'sticky', top: '0'}}>
                                <ITH>Element ID/ Div ID</ITH>
                                <ITH>Gif</ITH>
                                <ITH>Tooltip Text</ITH>
                                <ITD>Actions</ITD>
                            </ITableHeader>
                            <ITableBody /* key={key_table_body} */ >
                                {
                                    feature_guide_steps && feature_guide_steps.length ? feature_guide_steps.map((feature_guide_step, idx) => (
                                        <ITableRow key={feature_guide_step.key}>
                                            <ITD style={{padding: '0px'}}><Input style={{border: 'none'}} value={feature_guide_step.element_id} onChange={(e) => {feature_guide_step.element_id = e.target.value; forceRerender()}}></Input></ITD>
                                            <ITD style={{padding: '0px'}}><div style={{display: 'flex', gap: '8px', 'justify-content': 'space-between',  padding: "0 10px"}}>{feature_guide_step && feature_guide_step.gif_image && feature_guide_step.gif_image.image ? <a href = {global.config.static_path + feature_guide_step.gif_image.image} target = "_blank">View Existing Gif</a> : <></>}<Input style={{border: 'none'}} filename = "filename" type="file" name = "file" accept = ".gif" onChange={(e) => {uploadGif(e, idx)}}> </Input></div></ITD>
                                            <ITD style={{padding: '0px'}}><Input style={{border: 'none'}} value={feature_guide_step.tooltip_text} onChange={(e) => {feature_guide_step.tooltip_text = e.target.value; forceRerender()}}></Input></ITD>
                                            <ITD style={{color: 'red'}}><div style={{cursor: 'pointer'}} onClick={() => {feature_guide_steps.splice(idx, 1); /* set_key_table_body(Math.random() + Date.now()); */ forceRerender()}}>Delete</div></ITD>
                                        </ITableRow>
                                    ))
                                    :''
                                }
                            </ITableBody>
                        </ITable>
                        <div>
                            <Button color='primary' onClick={() => {feature_guide_steps.push({element_id: '', tooltip_text: '', key: Math.random() + Date.now()}); forceRerender()}}>Add Row</Button>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onclick_handle_close}>Discard</Button>
                    <Button color='primary' onClick={active_feature_guide && active_feature_guide.id ? updateFeatureGuidefn : createFeatureGuidefn}>Apply</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const FeatureGuides = ({set_page_loader}) => {
    const [ is_feature_guides_modal_open, set_is_feature_guides_modal_open ] = useState(false);
    const [ feature_guides, set_feature_guides ] = useState([]);
    const [ re_render, set_re_render ] = useState(false);
    const [ all_release_versions, set_all_release_versions ] = useState([]);
    const [ active_feature_guide, set_active_feature_guide ] = useStateCallback({});

    const forceRerender = () => {set_re_render(!re_render)}
    
    const getLatestFeatureGuides = async() => {
        try{
            let featureGuides = await getAllFeatureGuides();
            set_feature_guides(featureGuides);
            console.log("latest release notes => ", featureGuides)
        }catch(err){
            console.error(`Error in getLatestFeatureGuides `, err)
            // handleError(err);
        }
    }
    
    const getLatestReleases = async() => {
        try{
            let features = await getLatestVersions()
            let temp_all_release_versions = {};
            for(let i in features){
                let release = features[i]
                temp_all_release_versions[release.id] = release
            }
            console.log("all features ", temp_all_release_versions)
            set_all_release_versions(temp_all_release_versions)
        }catch(err){
            console.error(`Error in getLatestReleases `, err)
            // handleError(err);
        }
    }

    const onChangeStatus = async(feature_guide, status) => {
        try{
            console.log(feature_guide, status);
            await updateFeatureGuide(feature_guide.id, feature_guide.jira_version_id, feature_guide.feature_guide_steps, status, feature_guide.guide_name, feature_guide.type)
            feature_guide.status = status;
            forceRerender()
        }catch(err){
            console.error(`Error in onChangeStatus `, err)
            // handleError(err);
        }
    }

    const onDeleteFeatureGuide = async(id, idx) => {
        try{
            await deleteFeatureGuide(id);
            feature_guides.splice(idx, 1);
            forceRerender()
        }catch(err){
            console.error(`Error in onDeleteFeatureGuide `, err)
            // handleError(err);
        }
    }

    useEffect(() => {
        if(!is_feature_guides_modal_open){
            set_active_feature_guide({})
        }
    }, [is_feature_guides_modal_open]);

    useEffect(() => {
        (async() => {
            getLatestFeatureGuides()
            getLatestReleases()
        })()

    }, []);

    return(
        <>
            <AddOrUpdateFeatureGuides open={is_feature_guides_modal_open} handle_close={() => set_is_feature_guides_modal_open(false)} getLatestFeatureGuides={getLatestFeatureGuides} active_feature_guide={active_feature_guide} set_page_loader = {set_page_loader}/>
            <div style={{height: '100vh', width: '100%', padding: '36px'}}>
                <div className="flex_property justify-content-between mb-3">
                    <h4 style={{margin: 0}}>Feature Guides</h4>
                    <Button color='primary' onClick={() => set_is_feature_guides_modal_open(true)}>Add New</Button>
                </div>
                {/* Already added release notes */}
                <ITable rowSeparated={true} coloumnSeparated={true} style={{width : "100%"}} style_container={{maxHeight: '40vh', overflow: 'auto', border: '1px solid #e6e9ee', background: 'white'}}>
                    <ITableHeader>
                        <ITH>Type</ITH>
                        <ITH>Release Date</ITH>
                        <ITH>Guide Name</ITH>
                        <ITH>Status</ITH>
                        <ITH>Actions</ITH>
                    </ITableHeader>
                    <ITableBody style={{position: "sticky", top: '0px', background: 'white'}}>
                        {
                            feature_guides && feature_guides.length ? feature_guides.map((feature_guide, idx) => (
                                <ITableRow key={idx}>
                                    <ITD> {feature_guide.type}</ITD>
                                    <ITD>{all_release_versions[feature_guide.jira_version_id] && all_release_versions[feature_guide.jira_version_id].name}</ITD>
                                    <ITD> {feature_guide.guide_name ? feature_guide.guide_name : ""}</ITD>
                                    <ITD>
                                        <Input style={{border: 'none', padding: '0px', height: 'unset'}} value={feature_guide.status ? feature_guide.status : "Draft"} type={"select"} onChange={(e) => onChangeStatus(feature_guide, e.target.value)}>
                                            <option value={"Draft"}>Save as Draft</option>
                                            <option value={"Live"}>Live</option>
                                        </Input>
                                    </ITD>
                                    <ITD>
                                        <div style={{display: 'flex', gap: '12px'}}>
                                            <div style={{cursor: 'pointer', color: '#0078ff'}} onClick={() => {set_active_feature_guide(feature_guide, () => set_is_feature_guides_modal_open(true))}}>Edit</div>
                                            <div style={{cursor: 'pointer', color: 'red'}} onClick={() => {onDeleteFeatureGuide(feature_guide.id, idx)}}>Delete</div>
                                        </div>
                                    </ITD>
                                </ITableRow>
                            ))
                            :''
                        }
                    </ITableBody>
                </ITable>
            </div>
        </>
    )
}

export default FeatureGuides
