import React, {useState, useEffect} from "react";
import {toastr} from 'react-redux-toastr';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, Modal, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardHeader, CardFooter, Col, Table, Row} from "reactstrap";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
import '../../index.css'
import { confirmAlert } from "react-confirm-alert";
import DeleteNotification from "../DeleteNotification";

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
	  title: 'Confirm to submit',
	  message: message,
	  customUI: ({ onClose }) => {
		return (
		  Custom_modal ?
			<div>
			  <Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
			:
			<div className='custom-ui'>
			  <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
		);
	  }
	});
  }

const FindOrgsByEmail = ({open, toggle, modal_heading, action_name, action_function, show_confirmation, confirmation_msg}) => {
	const [email, set_email] = useState('');
	const [searched_for_once, set_searched_for_once] = useState(false);
	const [OrgList, setOrgList] = useState([]);
	const history = useHistory()

	const handleSubmit = async(filter_on_domain) => {
		if(email){
			if(!searched_for_once) set_searched_for_once(true)
			try{
				let resp = await general_fetch({url:'csp/get_attached_orgs_from_email', body: {email, filter_on_domain}})
				if(resp && resp.orgs){
					setOrgList(Object.values(resp.orgs))
					if(Object.values(resp.orgs).length == 0) toastr.error('No orgs found')
				}
			}catch(err){
				console.log(err);
			}
		}else{
			toastr.error('Please enter an email')
		}
	}

	const handle_action = (single_org) => {
		if(show_confirmation){
			submit(() => {action_function(single_org)}, '', confirmation_msg ? confirmation_msg : '')
		}else{
			action_function(single_org)
		}
	}

	const onclick_handleclose = () => {
		set_email('')
		setOrgList([])
		toggle()
	}

	useEffect(() => {
		console.log('searched_for_once', searched_for_once)
	}, [searched_for_once]);

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
              <h4 className="modal-title" id="exampleModalLabel"> Find Orgs by Email </h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" >
				<div className={"pl-lg-4 " + (searched_for_once ? 'flex_property mb-3' : '') }>
					<Row>
					<Col md="12">
						<FormGroup className={searched_for_once ? 'flex_center mb-0' : ''}>
						<label className={"form-control-label mr-3 " + [searched_for_once ? 'mb-0' : '']} htmlFor="input-address">
							Email
						</label>
						<Input value={email} onChange={e => set_email(e.target.value)} name="email" className="form-control-alternative mr-3"  type="text" style={{padding:'4px 12px', minWidth:'300px'}}/>
						</FormGroup>
					</Col>
					</Row>
					<Button color="primary mr-2" onClick={() => handleSubmit()}>Search by Email</Button>
					<Button color="primary" onClick={() => handleSubmit(true)}>Search by Domain</Button>
				</div>
				{
					searched_for_once ? 
					<div>
						<Table className="align-items-center table-flush text-center" responsive>
						<thead className="thead-light text-center">
							<tr style={{textAlign:'left'}}>
								<th scope="col"><span><b>Org Name</b></span><br /></th>
								<th scope="col"><span><b>Org ID</b></span><br /></th>
								<th scope="col"><span><b>Actions</b></span><br /></th>
							</tr>
						</thead>
						<tbody>
						{
							OrgList && OrgList.length ? OrgList.map((single_org, idx) => (
								<tr style={{textAlign:'left'}}>
									<td scope="col"><span>{single_org.name ?  single_org.name : 'N/A'}</span><br /></td>
									<td scope="col"><span>{single_org.id}</span><br /></td>
									<td scope="col"><span><Button onClick={() => {handle_action(single_org)}} className="btn-sm" color="primary" >{action_name}</Button></span><br /></td>
								</tr>
							)):''
						}
						</tbody>
						</Table>
					</div>:''
				}
			</div>
		</Modal>
	)
}

export default FindOrgsByEmail