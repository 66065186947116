import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {Link} from "react-router-dom";

// reactstrap components
import {Button, Input, Form, FormGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
// core components
import UserHeader from "../../components/Headers/UserHeader.jsx";
import {get_user_details} from '../../actions';
import {update_investor_details} from './actions';
import routes from "../../routes.js";

class Documents extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          name: this.props.investor_details.name,
          pan: this.props.investor_details.pan,
          phone: this.props.investor_details.phone,
          address: this.props.investor_details.address ? JSON.parse(this.props.investor_details.address) : {}
      }
      var address = this.state.address
      if (!address.country) {
          address.country = "India"
      }
      if (!address.city) {
          address.city = "Bangalore"
      }
      this.state.address = address;
  }

  update_inputs = (event) => {
      this.setState({[event.target.name]: event.target.value})
  }

  update_address = (event) => {
      var address = this.state.address;
      address[event.target.name] = event.target.value;
      this.setState({address})
  }

  update_profile = e => {
      e.preventDefault();
      console.log(this.state);
      var fields = ['name', 'phone', 'pan'];
      var data = {investor_id: this.props.investor_details.id, ...fields.reduce((final, elem) => ({...final, [elem]: this.state[elem]}), {}), address: JSON.stringify(this.state.address)};
      console.log(data)
      this.props.update_investor_details(data)
      .then(() => toastr.success('Details Updated'))
      .catch(() => toastr.error('Somehting went wrong'))
  }

  componentWillMount() {
      
  }

  render() {
    const styles = {
      bg_white: {
        background: "white"
      }
    }

    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("../../assets/img/icons/avatar.png")}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5" />
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {this.props.user_details.name}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {this.props.investor_details.designation}
                    </div>
                    <hr className="my-4" />
                    <p>
                    </p>
                    <Link to="/home/dashboard">
                      Investment Dashboard
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Personal details</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      
                    </Col>
                  </Row>
                </CardHeader>
                <Form onSubmit={this.update_profile}>
                  <CardBody>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-username" >
                              Name
                            </label>
                            <Input style={styles.bg_white} className="form-control-alternative" id="input-username" name="name" placeholder="Name" type="text" value={this.state.name} onChange={this.update_inputs} />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-email" >
                              Email address
                            </label>
                            <Input style={styles.bg_white} className="form-control-alternative" id="input-email" placeholder="example@infurnia.com" type="email" value={this.props.user_details.email} disabled/>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-first-name">
                              Phone
                            </label>
                            <Input onChange={this.update_inputs} value={this.state.phone} className="form-control-alternative" name="phone" id="input-first-name" placeholder="Mobile Number" type="tel" pattern="[0-9]{10}" maxLength="10"/>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-last-name" >
                              PAN
                            </label>
                            <Input onChange={this.update_inputs} value={this.state.pan} className="form-control-alternative" name="pan" id="input-last-name" placeholder="PAN" type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-address" >
                              Address
                            </label>
                            <Input onChange={this.update_address} value={this.state.address.street} name="street" className="form-control-alternative" id="input-address" placeholder="Address" type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-city" >
                              City
                            </label>
                            <Input onChange={this.update_address} value={this.state.address.city} name="city" className="form-control-alternative" id="input-city" placeholder="City" type="text" />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-country" >
                              Country
                            </label>
                            <Input onChange={this.update_address} value={this.state.address.country} name="country" className="form-control-alternative"  id="input-country" placeholder="Country" type="text" />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-country">
                              PIN code
                            </label>
                            <Input onChange={this.update_address} value={this.state.address.pin_code} name="pin_code" className="form-control-alternative" id="input-postal-code" placeholder="Postal code" type="number" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter className="text-center">
                      <Button color="primary" type="submit" >
                        Update Details
                      </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state, own_props) => ({
    user_details: state.user_details,
    investor_details: state.investor_details,
    ...own_props
})

const mapDispatchToProps = dispatch => ({
    get_user_details: () => dispatch(get_user_details()),
    update_investor_details: data => dispatch(update_investor_details(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Documents);