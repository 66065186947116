import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Radio, Button, DatePicker } from 'antd';
import { toastr } from "react-redux-toastr";
import Select from 'react-select';
import general_fetch from "../../utils/fetch";
import { CspModal, CspModalBody, CspModalHeader } from "../CspModal";
import dayjs from 'dayjs';


// const ChurnRemarkModal = ({open, toggle, org_info, all_stores, fetch_clients_at_risk}) => {
// 	const [ note, set_note ] = useState('');
//     const [ metadata, set_metadata ] = useState({});
    
	
// 	const onclick_handleclose = () => {
// 		set_note('')
// 		toggle()
// 	}
 
// 	const update_note = async () => {
// 		try {
// 			let resp = await general_fetch({url:'csp/update_org_at_risk_note', body:{record_id: org_info.id, note}})
// 			onclick_handleclose()
// 			fetch_clients_at_risk()
// 			toastr.success(`Successfully updated note`)
// 		}catch (e) {
// 			console.error(e)
// 			toastr.error(`Couldn't update note`)
// 		}
// 	}

// 	useEffect(() => {
// 		if(org_info){
// 			set_note(org_info.note)
// 		}
// 	}, [org_info]);

// 	return (
// 		<CspModal open={open} toggle={onclick_handleclose}>
// 			<CspModalHeader title='Update Note' toggle={onclick_handleclose}/>
// 			<CspModalBody>
// 				<div className="mb-2 flex_property">
// 					<div className="mr-2" style={{fontSize:'14px'}}>Org Name: </div>
// 					<div className="bold">{all_stores && all_stores.length && org_info && all_stores.find(o => o.id===org_info.org_id) ? all_stores.find(o => o.id===org_info.org_id).name : 'N/A'}</div>
// 				</div>
// 				<textarea className="mt-0" style={{padding: '8px 16px', fontSize:'13px', width:'100%', minHeight:'100px', borderRadius:'8px'}} value={note} onChange={(e) => set_note(e.target.value)}/>
// 				<div className="flex_property mt-2" style={{justifyContent:'flex-end', }}>
// 					<Button color='primary' onClick={update_note}>Update</Button>
// 				</div>
// 			</CspModalBody>
// 		</CspModal>
// 	)
// }


const ChurnRemarkModal = ({open, toggle, org_info, refresh_data, mode, set_page_loader, post_ops}) => {
    const [note, set_note] = useState('');
    const [metadata, set_metadata] = useState({});
    const [reason, set_reason] = useState('');
    const [sub_reason, set_sub_reason] = useState('');
    const [sub_reason_options, set_sub_reason_options] = useState([]);
    const [ modal_mode, set_modal_mode ] = useState("view");
    const [ reachout_date, set_reachout_date ] = useState(null);

    const reason_options = [
        { value: 'Adoption Issues', label: 'Adoption Issues' },
        { value: 'Design Limitation', label: 'Design Limitation' },
        { value: 'High Price', label: 'High Price' },
        { value: 'Software Issues', label: 'Software Issues' },
        { value: 'User Side Issues', label: 'User Side Issues' },
        { value: 'Unclear/DNP', label: 'Unclear/DNP' }
    ];

    const all_sub_reasons = {
        'Adoption Issues': ['Low Intent', 'Setup Issues', 'Too Complex', 'Others'],
        'Design Limitation': ['General Design', 'Modular Customization', 'Others'],
        'High Price': ['Subscription Cost', 'Success Fee', 'Onboarding', 'Others'],
        'Software Issues': ['Wrong Modular Output', 'Render Quality', 'Software Glitch and Bugs', 'Catalogue', 'Others'],
        'User Side Issues': ['Project Unavailable', 'Designer Unavailable', 'not in target group'],
        'Unclear/DNP': []
    };

    useEffect(() => {
        if (reason) {
            set_sub_reason_options(all_sub_reasons[reason].map(subReason => ({ value: subReason, label: subReason })));
        }
    }, [reason]);


    useEffect(() => {
        set_metadata(prevMetadata => ({ ...prevMetadata, reason, sub_reason, reachout_date }));
    }, [reason, sub_reason, reachout_date]);

    const onclick_handleclose = () => {
        set_note('');
        toggle();
    };

    const update_note = async () => {
        try {
            //remove keys from metadata which are null or undefined or empty string
            let mtd = { ...metadata};
            Object.keys(mtd).forEach(key => {
                if (mtd[key] === null || mtd[key] === undefined || mtd[key] === '') {
                    delete mtd[key];
                }
            });
            let resp = await general_fetch({
                url: 'csp/update_remark',
                body: { type: 'cse', text: note, metadata: mtd, customer_store_id: org_info.id }
            });
            onclick_handleclose();
            if (refresh_data) {
                if (set_page_loader) {
                    console.log("SETTING PAGE LOADER", set_page_loader);
                    set_page_loader(true, "Re-syncing org data");
                }
                await refresh_data();
                if (post_ops) {
                    post_ops();
                }
                if (set_page_loader) {
                    console.log("SETTING PAGE LOADER OFF");
                    set_page_loader(false);
                }
            }
            toastr.success(`Successfully updated Churn Details`);
        } catch (e) {
            console.error(e);
            toastr.error(`Couldn't update note`);
        }
    };
    useEffect(() => {
        if (mode == "edit") {
            set_modal_mode(mode);
        } else {
            set_modal_mode("view");
        }
    }, [mode]);

    useEffect(() => {
        if (!open) {
            set_modal_mode("view");
        }
    }, [open]);

    useEffect(() => {
        if (org_info) {
            console.log("ORG INFO ____ ", org_info)
            if (modal_mode == "view") {
                if (org_info.all_cse_remarks[0]) {
                    set_note(org_info.all_cse_remarks[0].text);
                    if (org_info.all_cse_remarks[0].metadata) {
                        set_reason(org_info.all_cse_remarks[0].metadata.reason);
                        set_sub_reason(org_info.all_cse_remarks[0].metadata.sub_reason);
                        console.log("REACHOUT DATE ", org_info.all_cse_remarks[0].metadata, new Date(org_info.all_cse_remarks[0].metadata.reachout_date));
                        set_reachout_date(org_info.all_cse_remarks[0].metadata.reachout_date ? org_info.all_cse_remarks[0].metadata.reachout_date : null);
                    }
                }
            } else {
                set_note('');
                set_reason(null);
                set_sub_reason(null);
                set_reachout_date(null);
            }
        }
    }, [org_info, open, modal_mode]);

    return (
        <CspModal open={open} toggle={onclick_handleclose}>
            <CspModalHeader title={' Churn Details'} toggle={onclick_handleclose}/>
            <CspModalBody style={{ overflow: 'visible !important' }} overflow="off">
                <div className="mb-4 flex_property">
                    <div className="mr-2">Org Name: </div>
                    <div className="bold">{(org_info && org_info.name) ? org_info.name : 'N/A'}</div>
                </div>
                
                <Radio.Group onChange={e => set_modal_mode(e.target.value)} value={modal_mode} style={{ fontSize: 18, marginBottom: 16 }}>
                    <Radio.Button value="view">View Existing Churn Details</Radio.Button>
                    <Radio.Button value="edit">Edit Churn Details</Radio.Button>
                </Radio.Group>
                <div className="mb-2 mr-2 ml-2">
                    <div className="mb-1">Churn Comment</div>
                    <textarea disabled={modal_mode=="view"} className="mt-0" style={{padding: '8px 16px', fontSize:'13px', width:'100%', minHeight:'100px', borderRadius:'8px'}} value={note} onChange={(e) => set_note(e.target.value)}/>
                </div>
                <div className="mb-2 mr-2 ml-2">
                    <div className="mb-1">Churn Category</div>            
                    <div className="flex_property mt-2" style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Select
                            isDisabled={modal_mode=="view"}
                            options={reason_options}
                            value={reason_options.find(option => option.value === reason) ? reason_options.find(option => option.value === reason) : null}
                            onChange={(option) => set_reason(option.value)}
                            placeholder="Select Reason"
                        />
                        {reason && (
                            <Select
                                isDisabled={modal_mode=="view"}
                                options={sub_reason_options}
                                value={sub_reason_options.find(option => option.value === sub_reason) ? sub_reason_options.find(option => option.value === sub_reason) : null}
                                onChange={(option) => set_sub_reason(option.value)}
                                placeholder="Select Sub Reason"
                            />
                        )}
                    </div>
                </div>
                <div className="mb-4 mr-2 ml-2 mt-4">
                    <div className="mb-1">Reachout Date</div>            
                    <DatePicker
                        format="YYYY-MM-DD"
                        disabled={modal_mode=="view"}
                        value={reachout_date? dayjs(reachout_date) : null}
                        onChange={(date, dateString) => set_reachout_date(dateString)}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className="flex_property mt-2" style={{justifyContent:'flex-end', }}>
                    <Button type='primary' onClick={update_note} disabled={modal_mode == 'view' || !note || !reason || (!sub_reason && sub_reason_options.length)}>Update</Button>
                </div>
            </CspModalBody>
        </CspModal>
    );
};

export default ChurnRemarkModal;