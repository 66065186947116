import thunk from 'redux-thunk';
import {createStore, applyMiddleware, combineReducers} from "redux";
import {reducer as toastrReducer} from 'react-redux-toastr';
import {stores, user_details, search_text, fetching_store_data, fetching_usage_data, usage, investor_details, license_plans} from './reducer';

const reducer = combineReducers({
	usage,
	stores,
	search_text,
	user_details,
	license_plans,
	investor_details,
	fetching_store_data,
	fetching_usage_data,
	toastr: toastrReducer,
});

const store = createStore(reducer, {}, applyMiddleware(thunk));
export default store;