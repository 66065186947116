import React, { useCallback, useEffect, useState } from "react";
import { Switch, Drawer, Tabs, Upload, Tooltip, Button as AntdButton, Input as AntdInput } from 'antd';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from "reactstrap";
import Select from 'react-select';
import { InboxOutlined, PlusCircleOutlined, ArrowLeftOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { toastr } from "react-redux-toastr";
import general_fetch from "../../utils/fetch";
import { colourStyles4 } from "../../utils/react_select_styles";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {EditCustomerTicket} from "../CustomerTickets/EditCustomerTicket";
import timeSince from "../../utils/timeSince";
import { bo_tickets_status_options } from "../../utils/bo_tickets_status_options";
import { confirmAlert } from "react-confirm-alert";
import DeleteNotification from "../../components/DeleteNotification";
const { Dragger } = Upload;

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
	  title: 'Confirm to submit',
	  message: message,
	  customUI: ({ onClose }) => {
		return (
		  Custom_modal ?
			<div>
			  <Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
			:
			<div className='custom-ui'>
			  <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
		);
	  }
	});
}

const department_options = [{ value: 'none', label: 'None' }, { value: 'customer_success', label: 'Customer Success Team' }, { value: 'product', label: 'Product Team' }, { value: 'catalogue', label: 'Catalogue Team' }]

const check_backoffice_ticket_edit_access = async ({user_details, selected_ticket}) => {
    let dept = selected_ticket.department
    let roles = user_details.role.store
    if(!roles.includes('customer_success_admin') && ((dept === 'product' && !roles.includes('infurnia_product_manager')) || dept === 'catalogue' && !roles.includes('infurnia_catalog_admin') || dept === 'customer_success' && !roles.includes('cs_rep'))){
        return false
    }
    return true
}

const EditBackOffficeTicket = ({ open, toggle, selected_ticket_id, fetch_back_off_tickets, set_page_loader, cs_org_users, all_stores, set_view_mode_true, user_details, update_status }) => {

	const [title, set_title] = useState();
	const [priority, set_priority] = useState();
	const [customer_priority, set_customer_priority] = useState();
	const [department, set_department] = useState();
	const [assignee_id, set_assignee_id] = useState();
	const [type, set_type] = useState();
	const [use_case, set_use_case] = useState();
	const [reproduction, set_reproduction] = useState();
	// const [comments, set_comments] = useState();
	const [status, set_status] = useState();
	const [selected_customer_ticket, set_selected_customer_ticket] = useState();
	const [description_expand_json, set_description_expand_json] = useState({});
	const [show_customer_ticket_drawer, set_show_customer_ticket_drawer] = useState();
	const [bo_ticket_comments, set_bo_ticket_comments] = useState([]);
	const [new_comment, set_new_comment] = useState();
	const [expected_resolution_time, set_expected_resolution_time] = useState();
	const [jira_summary, set_jira_summary] = useState();
	const [jira_description, set_jira_description] = useState();
	const [show_jira_creation_flow, set_show_jira_creation_flow] = useState(false);
	const [show_jira_linking_flow, set_show_jira_linking_flow] = useState(false);
	const [jira_details, set_jira_details] = useState();
	const [active_key, set_active_key] = useState("1");
	const [selected_ticket, set_selected_ticket] = useState();
	const [jira_key, set_jira_key] = useState();
	const [ view_mode, set_view_mode ] = useState(false);
	const [existing_files, set_existing_files] = useState();
	const [new_files, set_new_files] = useState();
	// const Description = () => (
	// 	<div className="mb-4">
	// 		<div className='mb-2 bold'>Description</div>
	// 		<textarea style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1' value={description} onChange={(e) => set_description(e.target.value)} placeholder='Type...' />
	// 	</div>
	// )

	const update_ticket_details = async () => {
		try {
			let body = {}
			if (title != selected_ticket.title) body.title = title
			if (priority != selected_ticket.priority) body.priority = priority
			if (customer_priority != selected_ticket.customer_priority) body.customer_priority = customer_priority
			if (department != selected_ticket.department) body.department = department
			if (assignee_id != selected_ticket.assignee_id) body.assignee_id = assignee_id
			if (type != selected_ticket.type) body.type = type
			if (status != selected_ticket.status) body.status = status
			if (expected_resolution_time != selected_ticket.expected_resolution_time) body.expected_resolution_time = expected_resolution_time
			let additional_info = { use_case, reproduction }
			if (JSON.stringify(additional_info) != selected_ticket.additional_info) body.additional_info = JSON.stringify(additional_info)
			if (JSON.stringify(body) == JSON.stringify({})) return
			body.back_office_ticket_id = selected_ticket.id

			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ url: 'back_office_ticket/update', body })
			set_page_loader(false)
			fetch_back_off_tickets()
			onclick_handleclose()
			toastr.success('Successfully updated Product Ticket')

		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't update ticket details`)
		}
	}

	const get_comments = async (back_office_ticket_id) => {
		try {
			let resp = await general_fetch({ 'url': 'back_office_ticket/get_comments', body: { back_office_ticket_id: back_office_ticket_id ? back_office_ticket_id : selected_ticket.id } })
			set_bo_ticket_comments(resp)
		} catch (err) {
			console.error(err)
			toastr.error(`Couldn't get comments`)
		}
	}

	const add_comment = async () => {
		try {
			let resp = await general_fetch({ 'url': 'back_office_ticket/add_comment', body: { back_office_ticket_id: selected_ticket.id, comment: new_comment } })
			get_comments()
			set_new_comment('')
		} catch (err) {
			console.error(err)
			toastr.error(`Couldn't add comment`)
		}
	}

	const create_jira = async () => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ 'url': 'back_office_ticket/create_jira_ticket', body: { back_office_ticket_id: selected_ticket.id, summary: jira_summary, description: jira_description } })
			set_page_loader(false)
			fetch_back_off_tickets()
			set_show_jira_creation_flow(false)
			set_show_jira_linking_flow(false)
			set_jira_summary('')
			set_jira_description('')
			get_back_office_ticket_by_id(selected_ticket_id)
			let jira_details = await get_jira_details()
			toastr.success(`Successfully created Jira issue`)
            if(jira_details) {
                if(jira_details.status != "Done") {
                    if(selected_ticket.status != 'in_progress' && selected_ticket.status != 'jira_done' && selected_ticket.status != 'done') update_status(selected_ticket.id, 'jira_added')
                } else {
                    if(selected_ticket.status != 'done') update_status(selected_ticket.id, 'jira_done')
                }
            } else {
                if(selected_ticket.status != 'in_progress' && selected_ticket.status != 'jira_done' && selected_ticket.status != 'done') update_status(selected_ticket.id, 'jira_added')
            }
		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't create Jira issue`)
		}
	}

	const link_existing_jira = async () => {
		try {
			if(!jira_key || jira_key.includes('infurniacoretech.atlassian.net')){
				toastr.error(`Please provide a jira issue key`)
				return
			}

			let resp = await general_fetch({url:'back_office_ticket/link_existing_jira', body:{back_office_ticket_id: selected_ticket.id, jira_key: jira_key.toUpperCase()}})
			fetch_back_off_tickets()
			set_jira_key('')
			set_show_jira_creation_flow(false)
			set_show_jira_linking_flow(false)
			set_jira_details('')
			let jira_details = await get_jira_details()
			get_back_office_ticket_by_id(selected_ticket_id)
			toastr.success(`Successfully linked Jira issue`)
            if(jira_details) {
                if(jira_details.status != "Done") {
                    if(selected_ticket.status != 'in_progress' && selected_ticket.status != 'jira_done' && selected_ticket.status != 'done') update_status(selected_ticket.id, 'jira_added')
                } else {
                    if(selected_ticket.status != 'done') update_status(selected_ticket.id, 'jira_done')
                }
            } else {
                if(selected_ticket.status != 'in_progress' && selected_ticket.status != 'jira_done' && selected_ticket.status != 'done') update_status(selected_ticket.id, 'jira_added')
            }
		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't link Jira issue`)
		}
	}

	const get_jira_details = async (back_office_ticket_id) => {
		try {
			let resp = await general_fetch({ 'url': 'back_office_ticket/get_jira_details', body: { back_office_ticket_id: back_office_ticket_id ? back_office_ticket_id : selected_ticket.id } })
			if(resp){
				set_jira_details(resp)
			}
            return resp;
		} catch (err) {
			console.error(err)
		}
	}

	const get_back_office_ticket_by_id = async (back_office_ticket_id) => {
		try {
			let resp = await general_fetch({'url':'back_office_ticket/get_by_id',body:{back_office_ticket_id}})
			set_selected_ticket(resp)
		}catch (err) {
			console.error(err)
		}
	}

	const get_assignee_list = () => {
		if(cs_org_users && cs_org_users.length){
			let temp_cs_org_users = JSON.parse(JSON.stringify(cs_org_users))
			if(department === 'customer_success'){
				temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('cs_rep'))
			}else if(department === 'product'){
				temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('infurnia_product_manager'))
			}else if(department === 'catalogue'){
				temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('infurnia_catalog_admin'))
			}
			return temp_cs_org_users.map(o => { return { value: o.id, label: o.first_name } }) 
		}
		return []
	}

	const delink_jira_issue = async() => {
		try {
			let resp = await general_fetch({'url':'back_office_ticket/delink_jira_issue',body:{back_office_ticket_id: selected_ticket_id}})
			fetch_back_off_tickets()
			set_jira_key('')
			set_show_jira_creation_flow(false)
			set_show_jira_linking_flow(false)
			get_back_office_ticket_by_id(selected_ticket_id)
			toastr.success(`Successfully delinked Jira issue`)
		}catch (err) {
			console.error(err)
		}
	}

	const delink_customer_ticket = async(customer_ticket_id) => {
		try {
			let resp = await general_fetch({'url':'back_office_ticket/delink_customer_ticket',body:{back_office_ticket_id: selected_ticket_id, customer_ticket_id}})
			get_back_office_ticket_by_id(selected_ticket_id)
			toastr.success(`Successfully delinked Customer Ticket`)
		}catch (err) {
			console.error(err)
		}
	}

	const add_ticket_files = async () => {
		try {
			let fd = new FormData();

			if (new_files && new_files.length) {
				for (let file of new_files) {
					fd.append('files', file.originFileObj)
				}
			}
			fd.append('back_office_ticket_id', selected_ticket.id)
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ url: 'back_office_ticket/update', body: fd, is_form_data: true })
			set_page_loader(false)
			get_back_office_ticket_by_id(selected_ticket_id)
			set_new_files([])
			toastr.success('Successfully added Files')

		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't add Files`)
		}
	}

	const delete_file = async (file_id) => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({'url':'back_office_ticket/delete_file',body:{back_office_ticket_id: selected_ticket_id, file_id}})
			set_page_loader(false)
			get_back_office_ticket_by_id(selected_ticket_id)
		}catch (err) {
			console.error(err)
		}
	}

	const onclick_handleclose = () => {
		toggle()
		set_title('')
		set_priority()
		set_customer_priority()
		set_department('')
		set_assignee_id('') 
		set_type('')
		set_use_case('')
		set_reproduction('')
		set_status('')
		set_show_jira_creation_flow(false)
		set_show_jira_linking_flow(false)
		set_jira_summary('')
		set_jira_description('')
		set_active_key("1")
		set_jira_details('')
		set_view_mode(false)
		set_existing_files([])
        set_new_files([])
	}

	useEffect(() => {
		if(set_view_mode_true){
			set_view_mode(true)
		}else if(user_details && user_details.id && selected_ticket && selected_ticket.id){
            if(!check_backoffice_ticket_edit_access({user_details, selected_ticket})) {
                set_view_mode(true)
            }
		}
	}, [set_view_mode_true, user_details, selected_ticket]);

	useEffect(() => {
		if (selected_ticket) {
			set_title(selected_ticket.title);
			set_jira_summary(selected_ticket.title);
			set_priority(selected_ticket.priority);
			set_customer_priority(selected_ticket.customer_priority);
			set_department(selected_ticket.department);
			set_assignee_id(selected_ticket.assignee_id);
			set_type(selected_ticket.type);
			set_status(selected_ticket.status);
			set_expected_resolution_time(selected_ticket.expected_resolution_time)
			set_existing_files(selected_ticket.files)
			if (selected_ticket.additional_info) {
				let additional_info = JSON.parse(selected_ticket.additional_info)
				set_use_case(additional_info.use_case);
				set_reproduction(additional_info.reproduction);
				// set_comments(additional_info.comments);
				set_jira_description(additional_info.use_case + '                ' + additional_info.reproduction); 
			}
			// if(selected_ticket.jira_key) get_jira_details()
			get_comments()
		}
	}, [selected_ticket]);

	useEffect(() => {
		if(open && selected_ticket_id) get_back_office_ticket_by_id(selected_ticket_id)
	}, [open, selected_ticket_id]);

	const AssignedTo = () => (
		<div className="mb-4" style={{ position: 'relative', zIndex: '4' }}>
			<div className='mb-2 bold'>Assigned to</div>
			<Select
				// closeMenuOnSelect={false}
				// components={animatedComponents}
				value={{ value: assignee_id, label: cs_org_users && cs_org_users.length && assignee_id ? cs_org_users.find(o => o.id === assignee_id).first_name : '' }}
				// isMulti
				options={get_assignee_list()}
				styles={colourStyles4}
				onChange={(selectedOptions) => set_assignee_id(selectedOptions.value)}
				isDisabled={view_mode}
			/>
		</div>
	)

	const TicketDetails = () => (
		<>
			<div className='mb-2 bold'>Title*</div>
			<Input readOnly={view_mode} type='text' style={{ height: '36px' }} className='px-2 py-1 mb-4' placeholder={'Type'} value={title} onChange={(e) => set_title(e.target.value)} />

			<div className='mb-4 bold'>Priority (1 is highest priority, 5 is lowest)</div>
			<div className="flex_property mb-4" style={{ fontWeight: 400 }}>
				<div>
					<div style={{ minHeight: '32px' }}></div>
					{/* <div style={{ minHeight: '32px', fontSize: '12px' }}>Highest Priority</div> */}
				</div>
				{[1, 2, 3, 4, 5].map(x => (
					<div>
						<div style={{ minHeight: '32px' }} className="ml-2 mr-2">{x}</div>
						<div style={{ minHeight: '32px' }} className="ml-2 mr-2" onClick={() => set_priority(x)} >
							<input disabled={view_mode} type="radio" checked={priority === x ? true : false} />
						</div>
					</div>
				))}
				<div>
					<div style={{ minHeight: '32px' }}></div>
					{/* <div style={{ minHeight: '32px', fontSize: '12px' }}>Lowset Priority</div> */}
				</div>
			</div>
            {
                // <>
                //     <div className='mb-4 bold'>Customer priority or Client misery level (1 is highest priority, 5 is lowest)</div>
                //     <div className="flex_property mb-4" style={{ fontWeight: 400 }}>
                //         <div>
                //             <div style={{ minHeight: '32px' }}></div>
                //             {/* <div style={{ minHeight: '32px', fontSize: '12px' }}>High priority for customer</div> */}
                //         </div>
                //         {[1, 2, 3, 4, 5].map(x => (
                //             <div>
                //                 <div style={{ minHeight: '32px' }} className="ml-2 mr-2">{x}</div>
                //                 <div style={{ minHeight: '32px' }} className="ml-2 mr-2" onClick={() => set_customer_priority(x)} >
                //                     <input disabled={view_mode} type="radio" checked={customer_priority === x ? true : false} />
                //                 </div>
                //             </div>
                //         ))}
                //         <div>
                //             <div style={{ minHeight: '32px' }}></div>
                //             {/* <div style={{ minHeight: '32px', fontSize: '12px' }}>Low priority for customer</div> */}
                //         </div>
                //     </div>
                // </>
            }
            {
                // <div className='mb-2 bold'>Dependency</div>
                // <div className="mb-4" style={{ position: 'relative', zIndex: '5' }}>
                //     <Select
                //         // closeMenuOnSelect={false}
                //         // components={animatedComponents}
                //         value={department ? { value: department, label: department_options.find(o => o.value === department).label } : {}}
                //         // isMulti
                //         options={department_options}
                //         onChange={(selectedOptions) => set_department(selectedOptions.value)}
                //         isDisabled={view_mode}
                //     />
                // </div>
            }
			{
				department === 'customer_success' ?
					<>
						{AssignedTo()}
						{/* {Description()} */}
					</> : (
						department === 'product' ?
							<>
								<label className="form-control-label mt-2 mb-4 flex_property" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mr-2'>Type</div>
									<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('feature') }} >
										<input disabled={view_mode} type="radio" className="mr-2" checked={type === 'feature' ? true : false} />
										<div className="mr-4">Feature</div>
									</div>
									<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('bug') }} >
										<input disabled={view_mode} type="radio" className="mr-2" checked={type === 'bug' ? true : false} />
										<div>Bug</div>
									</div>
								</label>
								{
									type === 'feature' || type === 'bug' ? AssignedTo() : ""
								}
								{
									type === 'feature' ?
										<>
											<div className='mb-2 bold'>Use Case</div>
											<textarea readOnly={view_mode} style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={use_case} onChange={(e) => set_use_case(e.target.value)} placeholder='e.g. The client is trying to create shutters with a design that is shown in this image link <url>' />
											{/* {Description()} */}
										</> : (
											type === 'bug' ?
												<>
													<div className='mb-2 bold'>Reproduction</div>
													<textarea readOnly={view_mode} style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={reproduction} onChange={(e) => set_reproduction(e.target.value)} placeholder='e.g. Add a Cabinet, Change the shutter and external finish and then change shutter design from normal to profile' />
													{/* <div className='mb-2'>Design Link</div>
													<div className="flex_property mb-4" style={{ width: '100%' }}>
														<Input type='text' style={{ height: '36px', width: '60%' }} className='px-2 py-1 mr-2' placeholder={'Type...'} value={design_link} onChange={(e) => set_design_link(e.target.value)} />
														{org_id ? <Button color='secondary'>Add "pm1" to the Org</Button> : ''}
													</div> */}
													{/* <div className='mb-2 bold'>Comments</div>
													<textarea style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={comments} onChange={(e) => set_comments(e.target.value)} placeholder='e.g. the same shutter related bug was also noticed at partition level also' /> */}
												</> : ""
										)
								}
							</> :
							<>
								{AssignedTo()}
								<label className="form-control-label mt-2 " htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
									<div className='mr-2 bold'>Global/Org</div>
									<div className="flex_property mb-4">
										<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('org') }} >
											<input disabled={view_mode} type="radio" className="mr-2" checked={type === 'org' ? true : false} />
											<div className="mr-4">Org</div>
										</div>
										<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('global') }} >
											<input disabled={view_mode} type="radio" className="mr-2" checked={type === 'global' ? true : false} />
											<div className="mr-4">Global</div>
										</div>
										<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('org_and_global') }} >
											<input disabled={view_mode} type="radio" className="mr-2" checked={type === 'org_and_global' ? true : false} />
											<div>Both</div>
										</div>
									</div>
								</label>
								{/* {Description()} */}
							</>
					)
			}
			<div className='mb-2 bold'>Status</div>
			<div className="flex_property mb-4" style={{ width: '100%', position:'relative', zIndex:3 }}>
				<div style={{ flex: 3 }}>
					<Select
						// closeMenuOnSelect={false}
						// components={animatedComponents}
						value={{ value:status, label:bo_tickets_status_options.find(o => o.value===status)?bo_tickets_status_options.find(o => o.value===status).label:'' }}
						// isMulti
						options={bo_tickets_status_options}
						onChange={(selectedOptions) => {set_status(selectedOptions.value)}}
						isDisabled={view_mode}
					/>
				</div>
			</div>
			<div className='mb-2 bold'>Expected Resolution Time (Days)</div>
			<div className="flex_property mb-4" style={{ width: '60%', }}>
				<Input type='number' readOnly={view_mode} value={expected_resolution_time} onChange={(e) => set_expected_resolution_time(e.target.value)} />
			</div>
			{view_mode ? '' : <Button color="primary" onClick={update_ticket_details}>Update Ticket Details</Button>}
		</>
	)

	const CustomerTicketsTab = () => (
		selected_ticket && selected_ticket.customer_tickets && selected_ticket.customer_tickets.length ? selected_ticket.customer_tickets.map(customer_ticket => (
			<div className="ticket_div activity_box mb-4" style={{ padding: '0px' }} >
				<div style={{ padding: "18px 18px 10px 18px" }}>
					<div className="flex_between mb-2" style={{ fontSize: '16px' }}>
						<div className="flex_property">
							<i className="fa fa-ticket mr-2" style={{ fontSize: '20px', color: '#A799B7' }} />
							<div className="cp tickets_title" onClick={() => { set_show_customer_ticket_drawer(true); set_selected_customer_ticket(customer_ticket) }}>{customer_ticket.title}</div>
						</div>
						<div className="flex_property">
							<UncontrolledDropdown >
								<DropdownToggle  style={{padding:'4px 0px 4px 4px', background:'unset', boxShadow:'unset', border:'unset'}}>
									<i className="fas fa-ellipsis-h cp" ></i>
								</DropdownToggle>
								<DropdownMenu>
									{
										view_mode?'':<DropdownItem onClick={(e) => {submit(() => delink_customer_ticket(customer_ticket.id), ()=>{}, 'This action will unlink the Customer Ticket from this Product Ticket.', '', '')}}>Delink</DropdownItem>
									}
									{
										customer_ticket.cs_team_doubts_thread?
										<DropdownItem><a href={customer_ticket.cs_team_doubts_thread.replace(/spaces\/([^/]+)\/threads\/([^/]+)/, "https://chat.google.com/room/$1/$2/$2")} target="_blank">View 'CS Team doubts' thread</a></DropdownItem>
										:''
									}
									{
										customer_ticket.cs_team_escalations_thread ?
										<DropdownItem><a href={customer_ticket.cs_team_escalations_thread.replace(/spaces\/([^/]+)\/threads\/([^/]+)/, "https://chat.google.com/room/$1/$2/$2")} target="_blank">View 'CS Team escalations' thread</a></DropdownItem>
										:''
									}
								</DropdownMenu>
							</UncontrolledDropdown>
							
						</div>
					</div>
					<div style={{ fontSize: '12px', marginLeft: '28px' }} className="flex_between">
						<div className="flex_property">
							{
								customer_ticket.org_id ?
									<div className="mr-2 flex_property">
										<Link to={`/home/store_dashboard/${customer_ticket.org_id}/7`} onClick={(e) => e.stopPropagation()} style={{ textDecoration: 'underline' }} className="mr-2">{customer_ticket.org_id && all_stores && all_stores.length && all_stores.find(o => o.id === customer_ticket.org_id) ? all_stores.find(o => o.id === customer_ticket.org_id).name : ''}</Link>
										|
									</div> : ''
							}
							{
								customer_ticket.priority ?
									<div className="mr-2 flex_property">
										<div className="mr-2">
											Priority - {customer_ticket.priority}
										</div>
										|
									</div> : ""
							}
							{
								/* customer_ticket.customer_priority ?
									<div className="mr-2 flex_property">
										<div className="mr-2">
											Customer Priority - {customer_ticket.customer_priority}
										</div>
										|
									</div> : "" */
							}
							{
								customer_ticket.creator_id ?
									<div className="mr-2 flex_property">
										<div className="mr-2">
											{customer_ticket.creator_id && cs_org_users && cs_org_users.length && cs_org_users.find(o => o.id===customer_ticket.creator_id) ? cs_org_users.find(o => o.id===customer_ticket.creator_id).first_name : ''}
										</div>
									</div> : ""
							}
						</div>
					</div>
				</div>
				{/* <hr className="mt-2 mb-3"/> */}
				<div className="cp" style={{ fontSize: '13px', borderRadius: '6px', backgroundColor:/*'#F4F3EE'*/'#FFF8F0', padding: '6px 10px 6px 18px', display: 'flex', justifyContent: 'space-between' }} onClick={(e) => { e.stopPropagation(); description_expand_json[customer_ticket.id] = !description_expand_json[customer_ticket.id]; set_description_expand_json(JSON.parse(JSON.stringify(description_expand_json))) }}>
					<div style={{ borderRadius: '6px', flex: 1, overflow: description_expand_json[customer_ticket.id] ? '' : 'hidden', whiteSpace: description_expand_json[customer_ticket.id] ? '' : 'nowrap', textOverflow: 'ellipsis' }} >{customer_ticket.description ? customer_ticket.description : 'No description'}</div>
					<img src={`/svgs/${description_expand_json[customer_ticket.id] ? 'arrows_converging.svg' : 'arrows_up_down.svg'}`} style={{ height: '18px', width: '18px' }} className="cp mr-1" />
				</div>
			</div>
		)) : ""
	)

	const CommentsTab = () => (
		<>
			<div className="mb-3 bold">Comments <Tooltip title='Refresh comments'><AntdButton className="ml-1 px-2 py-1"><span className="cp" onClick={() => { console.log('refreshhhh'); get_comments() }} style={{ opacity: '0.5' }}><i className="fa fa-refresh" /></span></AntdButton></Tooltip></div>
			<div className="mb-4" style={{ display: 'flex' }}>
				<div className="avatar_div mr-2">{user_details && user_details.first_name ? user_details.first_name[0] : ':)'}</div>
				<div style={{ width: "100%" }}>
					<textarea value={new_comment} onChange={(e) => set_new_comment(e.target.value)} style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1' placeholder="Add a comment..." />
					{
						new_comment ?
							<div>
								<AntdButton onClick={() => set_new_comment('')} className="mr-2">Cancel</AntdButton>
								<AntdButton onClick={add_comment} type="primary">Save</AntdButton>
							</div> : ""
					}
				</div>
			</div>
		{
			bo_ticket_comments && bo_ticket_comments.length ? bo_ticket_comments.map((single_comment, idx) => (
				<div className="mb-4" style={{ display: 'flex' }}>
					<div className="avatar_div mr-2">{single_comment.commented_by && cs_org_users && cs_org_users.length ? cs_org_users.find(o => o.id === single_comment.commented_by).first_name[0] : ':)'}</div>
					<div style={{ width: "100%" }}>
						<div className="flex_property mb-2">
							<div className="mr-2" style={{ color: '#44546f', fontWeight: 500 }}>{single_comment.commented_by && cs_org_users && cs_org_users.length ? cs_org_users.find(o => o.id === single_comment.commented_by).first_name : 'Anonymous User'}</div>
							<div style={{ opacity: '0.5' }}>{timeSince(single_comment.updated_at)}</div>
						</div>
						<div>{single_comment.comment}</div>
						{user_details && single_comment.commented_by === user_details.id ?
							<div>
	
							</div> : ''
						}
					</div>
				</div>
			)):''
		}
		</>
	)

	const JiraTab = () => (
			(
				selected_ticket && selected_ticket.jira_key && !show_jira_creation_flow && !show_jira_linking_flow?
				<>
					<div className="flex_property mb-3">
						<div className="mr-2">Jira Issue Link:</div>
						<a href={`https://infurniacoretech.atlassian.net/jira/software/c/projects/PT/issues/${selected_ticket.jira_key}`} target="_blank" className="mr-2 flex_property" style={{textDecoration:'underline', color:'#0078ff'}}><img src='/jira.svg' height={'18px'} width={'18px'} className="mr-1"/><div>{selected_ticket.jira_key}</div></a>
					</div>
					<div className="mb-3 flex_property">
						<div className="mr-2">Status:</div>
						<div className="bold">{jira_details ? jira_details.status : ''}</div>
					</div>
					<div className="mb-3 flex_property">
						<div className="mr-2">Sprint:</div>
						<div className="bold">{jira_details ? jira_details.sprint : ''}</div>
					</div>
					<div className="mb-3 flex_property">
						<div className="mr-2">Due Date:</div>
						<div className="bold">{jira_details ? jira_details.due_date : ''}</div>
					</div>
					<div className="mb-3 flex_property">
						<div className="mr-2">Fix Version:</div>
						<div className="bold">{jira_details ? jira_details.fix_version : ''}</div>
					</div>
					<div className="mb-2">Summary</div>
					<AntdInput className="mb-3" value={jira_details ? jira_details.summary : ''} readOnly/>
					<div className="mb-2">Description</div>
					<textarea readOnly style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={jira_details ? jira_details.description : ''}  placeholder='Type...' />
					<AntdButton type="primary" className="mr-2" disabled={view_mode} onClick={() => set_show_jira_linking_flow(true)} >Link another Jira issue</AntdButton>	
					<AntdButton type="primary" danger className="mr-2" disabled={view_mode} onClick={() => submit(delink_jira_issue, ()=>{}, 'This action will delink the jira issue of this Product Ticket', '','')} >Delink Jira issue</AntdButton>	
				</>:
				<>
				{
					show_jira_creation_flow?
					<div>
						<div className="mb-2">Summary</div>
						<AntdInput className="mb-4" value={jira_summary} onChange={(e) => set_jira_summary(e.target.value)}/>
						<div className="mb-2">Description</div>
						<textarea style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={jira_description} onChange={(e) => set_jira_description(e.target.value)} placeholder='Type...' />
						<div className="flex_property">
							<AntdButton type="primary" className="mr-2" onClick={create_jira} >Submit</AntdButton>	
							<div className="mr-2">or</div>
							<AntdButton type="primary" onClick={() => {set_show_jira_creation_flow(false); set_show_jira_linking_flow(true)}} >Link Existing Issue</AntdButton>	
						</div>
					</div>:(
						show_jira_linking_flow?
						<div>
							<div className="mb-2">Jira Issue Key</div>
							<AntdInput className="mb-4" value={jira_key} onChange={(e) => set_jira_key(e.target.value)}/>
							<div className="flex_property">
								<AntdButton type="primary" className="mr-2" onClick={link_existing_jira} >Link this Issue</AntdButton>	
								<div className="mr-2">or</div>
								<AntdButton type="primary" onClick={() => {set_show_jira_creation_flow(true); set_show_jira_linking_flow(false)}} >Create New Issue</AntdButton>	
							</div>
						</div>:
						<div className="flex_center mt-4" style={{}}>
							<AntdButton type="primary" icon={<PlusCircleOutlined style={{fontWeight:700}} />} disabled={view_mode} onClick={() => set_show_jira_creation_flow(true)}>Create Jira Issue</AntdButton>
							{'\xa0\xa0\xa0or\xa0\xa0\xa0'}
							<AntdButton type="primary" icon={<PlusCircleOutlined style={{fontWeight:700}} />} disabled={view_mode} onClick={() => set_show_jira_linking_flow(true)}>Link Existing Issue</AntdButton>
						</div>
					)
				}
				{
					selected_ticket && selected_ticket.jira_key?
					<AntdButton className="mt-4" type="dashed" onClick={() => {set_show_jira_creation_flow(false); set_show_jira_linking_flow(false)}} icon={<ArrowLeftOutlined style={{fontWeight:700}}/>}>Go Back</AntdButton>:""
				}
				</>
			)
		)

	const props = {
		name: 'file',
		multiple: true,
		action: '',
		customRequest: ({ fileList, onSuccess }) => { onSuccess() },
		defaultFiles: new_files && new_files.length ? new_files.map((x, idx) => { return { uid: x.id, name: ('file_' + (idx + 1) + " (click to view)"), status: 'done'} }) : [],
		onChange(info) {
			const { status } = info.file;
			set_new_files(info.fileList)

			console.log(info.fileList)
			//   if (status !== 'uploading') {
			// 	console.log(info.file, info.fileList);
			//   }
			//   if (status === 'done') {
			// 	message.success(`${info.file.name} file uploaded successfully.`);
			//   } else if (status === 'error') {
			// 	message.error(`${info.file.name} file upload failed.`);
			//   }
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	const FilesTab = useCallback(() => (
		<div>
			{/* <Dragger {...props} style={{ display: 'block' }}>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">Click or drag file to this area to upload</p>
				<p className="ant-upload-hint">
					Supports a single or bulk upload.
				</p>
			</Dragger>
			{new_files && new_files.length && !view_mode ? <Button className="mt-4" color='primary' onClick={add_ticket_files}>Update Files</Button> : ''} */}
			<div className="mb-2 bold">Files</div>
			<div className="mb-4">
			{
				existing_files && existing_files.length && existing_files.filter(file => !file.global_path).length ? existing_files.filter(file => !file.global_path).map((file, idx) => (
					<div className="flex_property mb-1">
						<PaperClipOutlined className='mr-2' style={{ opacity: '0.5' }} />
						<a style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:"nowrap"}} href={'https://storage.googleapis.com/' + global.config.ticketing_bucket_name + file.file_path} target="_blank">file{'_' + (idx + 1)} (click to view)</a>
						{view_mode ? '' : <DeleteOutlined className="ml-2 cp" style={{color:'red'}} onClick={() => submit(() => delete_file(file.id), ()=>{}, 'This action will delete the file, are you sure you want to proceed?', '','')} />}
					</div>
				)) : <div style={{opacity:'0.4'}}>No files were uploaded</div>
			}
			</div>
			{
				view_mode ? '':
				<>
					<div className="mb-4">
						<div className="mb-2 bold">Add new Files</div>
						<Dragger {...props} fileList={new_files} style={{ display: 'block' }}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">Click or drag file to this area to upload</p>
							<p className="ant-upload-hint">
								Supports a single or bulk upload.
							</p>
						</Dragger>
						{
							new_files && new_files.length ? <AntdButton type="primary" onClick={add_ticket_files}>Submit Files</AntdButton> : ''
						}
					</div>
				</>
			}
		</div>
	), [existing_files, new_files, selected_ticket, view_mode])

	const tab_items = [
		{
			key: '1',
			label: 'Details',
			children: TicketDetails(),
		},
		{
			key: '2',
			label: 'Files',
			children: FilesTab(),
		},
		{
			key: '3',
			label: 'Linked Customer Tickets',
			children: CustomerTicketsTab(),
		},
		{
			key: '4',
			label: 'Comments',
			children: CommentsTab(),
		},
		{
			key: '5',
			label: 'Jira Ticket',
			children: JiraTab(),
		},
	];

	const callbackTabClicked = (key, event) => {
		set_active_key(key)
		if(key === '4'){
			if(selected_ticket && selected_ticket.jira_key && !jira_details) get_jira_details()
		}
	}

	return (
		<Drawer title={(view_mode ? "Viewing" : "Edit") + " Product Ticket"} onClose={onclick_handleclose} open={open} size="large" bodyStyle={{ paddingTop: '0px' }}>
			<EditCustomerTicket set_view_mode_true={true} open={show_customer_ticket_drawer} toggle={() => set_show_customer_ticket_drawer()} all_orgs={all_stores} selected_ticket_id={selected_customer_ticket ? selected_customer_ticket.id : ''} cs_org_users={cs_org_users} user_details={user_details} />
			<Tabs activeKey={active_key} items={tab_items} onTabClick={callbackTabClicked} />
		</Drawer>
	)
}

export { EditBackOffficeTicket, department_options, check_backoffice_ticket_edit_access};