import Header from "../../components/Headers/Header";
import React, { useState } from "react";
import {toastr} from 'react-redux-toastr';
import { Button, Card, CardHeader, Col, Container, FormGroup, Input, Modal, Row, Table } from "reactstrap";
import language_list from "../../utils/language_list";

const styles = {
	header_row: {
		marginLeft: 0,
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	round_status: {
		fontSize: 'small',
		paddingBottom: 0
	},
	nav_pills_rounds: {
		paddingRight: 60,
		marginRight: "0.7rem",
		marginLeft: "0.2rem",
	},
	action_button: {
		margin: 1       
	}
};

const AddLanguage = ({open, toggle}) => {

	const [language, set_language] = useState();

	const onClick_handleClose = () => {
		toggle()
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>Add Language</h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
				<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
				<Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-address" >
							Select Language
                        </label>
                        <Input name="credits" className="form-control-alternative" type="select" placeholder='Type...' value={language} onChange={(e) => set_language(e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
				<Button color='primary'>Submit</Button>
			</div>
		</Modal>
	)
}

const Languages = ({user_details}) => {

	const [add_modal_open, set_add_modal_open] = useState(false);

	const toggle_add_modal = () => set_add_modal_open(!add_modal_open)

	return(
		<>
			<Container fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-4 bg-gradient-secondary">
								{/* <Row className='mb-3 mr-3' style={{ display: 'flex', justifyContent: 'end' }}><Button color='primary' onClick={toggle_add_modal}>Add</Button></Row> */}
								{/* <Row className='mb-3 mr-3' style={{ display: 'flex', justifyContent: 'end' }}><Button color='primary' onClick={toggle_add_modal}>Add</Button></Row> */}
							</CardHeader>
							<div style={{minHeight:'200px'}}>
								<Table className="align-items-center table-flush text-center" responsive>
								<thead className="thead-light text-center">
										<tr style={{ textAlign: 'left' }}>
											<th scope="col"><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Language Name</b></span>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{
											language_list && language_list.length ? language_list.map((language, idx) => (
												<tr style={{ textAlign: 'left' }}>
													<td scope="col"><span>{idx + 1}</span>&nbsp;</td>
													<td scope="col"><span>{language.name ? language.name : 'N/A'}</span>&nbsp;</td>
												</tr>
											)) : ''
										}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
				{/* <AddLanguage open={add_modal_open} toggle={toggle_add_modal} user_details={user_details}/> */}
			</Container>
		</>
	)
}

export default Languages