import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';

// reactstrap components
import {Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
// core components
import Header from "../../components/Headers/Header.jsx";
import {get_user_details} from '../../actions';
import routes from "../../routes.js";

class Documents extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          current_document: this.get_document_name(this.props.location.pathname)
      }
  }

  componentWillMount() {
      
  }

  get_document_name = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return null;
  };

  render() {
    const styles = {
        iframes: {
            height: 620,
            width: "100%"
        }
    }

    const ssa = (<iframe style={styles.iframes} src="https://docs.google.com/document/d/e/2PACX-1vRFF2cEHMxPcatlMUdW-7J_0e40j8vVQYug9hwI5wsOu0rUrgECSORCh8vYcy3J01O0jp7blO6fY7Vn/pub?embedded=true"></iframe>);

    const term_sheet = (<iframe style={styles.iframes} src="https://docs.google.com/document/d/e/2PACX-1vTKPEWN7b54r2c9Qs1qFui6T8GWKBoU6LBAA3-5MiN0LGyc9_mr5syg54yeLGi8aNJu4EXUQZaEku_6/pub?embedded=true"></iframe>);

    const sha = (<iframe style={styles.iframes} src="https://docs.google.com/document/d/e/2PACX-1vRFP96UQl4MQuObH7LNXAYPx7DmUA39sh4-3SUbsekSEdFegyNNYv0OwoRZ-POntCG3FLQjda9QIcaH/pub?embedded=true"></iframe>);

    const pitchdeck = (<iframe style={styles.iframes} src="https://docs.google.com/presentation/d/e/2PACX-1vQMwHDuP6y6RR3HceOPhyAdolRoSWH-aXqG3jkcPWWlSSERFOe2g1ykb2omdk-tL5pz34KAQ464ELFn/embed?start=false&loop=false&delayms=60000" frameborder="0" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>);

    const doc_map = {SHA: sha, Termsheet: term_sheet, Pitchdeck: pitchdeck, SSA: ssa}

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7 text-center" style={{overflowX: "auto"}} fluid>
            <div class="text-center" style={{position: "relative", width: 800, margin: "auto"}}>
                {doc_map[this.state.current_document]}
            </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = function(state, own_props) {
    return {
        user_details: state.user_details,
        ...own_props
    };
}

const mapDispatchToProps = dispatch => {
    return {
        get_user_details: () => {
            return dispatch(get_user_details())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents);