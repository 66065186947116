import general_fetch from "./fetch";

export const set_csp_filter = async(user_id, pathname, tab_id, filter_json) => {
	try{
		const apiUrl = global.config.analytics_path + 'api/user_filter/create'; 
		const payload = {user_id, pathname, tab_id, filter_json: JSON.stringify(filter_json), token:'73d6745e-f627-4aaa-aeef-2d6d14682e1c'}
		console.log('writing filter json all uef', filter_json)
		// fetch(apiUrl, {
		// method: 'POST', 
		// headers: {
		// 	'Content-Type': 'application/json',
		// },
		// body: JSON.stringify(payload),
		// })
		// .then(response => {
		// 	if (!response.ok) {
		// 	throw new Error(`HTTP error! Status: ${response.status}`);
		// 	}
		// 	return response.json();
		// })
		// .then(data => {
		// 	console.log('Response data cassandra:', data);
		// })
		// .catch(error => {
		// 	console.error('Error:', error);
		// });
		let response = await general_fetch({full_url:apiUrl, body:payload, req_resp:1})
	}catch (err) {
		console.error(err)
		// throw new Error
	}
}