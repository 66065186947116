import React, {useState, useEffect} from "react";
import {toastr} from 'react-redux-toastr';
import general_fetch from '../../utils/fetch';
import {FormGroup, Button, Form, Input, Modal, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardHeader, CardFooter, Col, Table, Row, Container, Nav, NavItem, NavLink } from "reactstrap";
import PaymentForm from "../../components/PaymentForm";
import Header from "../../components/Headers/Header";
import PendingInvites from "../../components/PendingInvites";

const Payments = ({set_page_loader}) => {
    const styles = {
        header_row: {
          marginLeft: 0,
          marginRight: 0,
          overflowX: "auto",
        },
    }
    const [active_tab, set_active_tab] = useState(0);

	useEffect(() => {
		console.log("set_active_tab", active_tab);
	}, [active_tab]);

    const new_payment = <PaymentForm set_page_loader={set_page_loader}/>
    const payment_history = <PendingInvites set_page_loader={set_page_loader}/>
	const register_payment = <PaymentForm register_closure_tab={true} set_page_loader={set_page_loader}/>

    const tabs = [new_payment, payment_history, register_payment]

    return(
        <>
            <Container fluid>
                <Row>
                    <div className="col">
                    <Card className="shadow">
                        <CardHeader className="border-4 bg-gradient-secondary">
                        <Row style={styles.header_row}>
                            <Nav pills style={{paddingBottom: 5, marginTop: 4}}>
                                <NavItem>
                                    <NavLink href='#' active={active_tab == 0} onClick={() => set_active_tab(0)}><b>Create Invite</b> </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href='#' active={active_tab == 1} onClick={() => set_active_tab(1)}><b>Pending Invites</b> </NavLink>
                                </NavItem>
								<NavItem>
                                    <NavLink href='#' active={active_tab == 2} onClick={() => set_active_tab(2)}><b>Register Closure</b> </NavLink>
                                </NavItem>
                            </Nav>
                        </Row>
                        </CardHeader>
                        <CardBody>
                            {tabs[active_tab]}           
                        </CardBody>
                    </Card>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default Payments