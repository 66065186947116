import React, { useEffect, useMemo, useState } from "react";
import makeAnimated from 'react-select/animated';
import Select, { StylesConfig } from "react-select";
import { colourStyles, colourStyles1, colourStyles2 } from "../../utils/react_select_styles";
import general_fetch from "../../utils/fetch";
import { date_string } from "../../utils/date_string";
import mixpanel_event_types from "../../utils/mixpanel_event_types";
import { Input } from "reactstrap";
import Gradient from 'javascript-color-gradient';
import { infurnia_add_ons } from "../../utils/infurnia_add_ons";
import { toastr } from "react-redux-toastr";
import country_list from "../../utils/country_list";
import {Tooltip, Switch} from "antd";

const animatedComponents = makeAnimated();
const domain_options = [{ value: 'Interior Design', label: 'Interior Design' }, { value: 'Modular Interior', label: 'Modular Interior' }, { value: 'Building Design', label: 'Building Design' }]
const gradentParams = ['#D21404', '#FFA500', '#fdfa72', '#90EE90', '#228B22'];
const colorCellFromValue = (params, value, midpoint = 31) => {
	const getColorIndex = Math.round(midpoint * value);
	return new Gradient()
		.setColorGradient(...params)
		.setMidpoint(midpoint).getColor(getColorIndex === 0 ? 0.01 : getColorIndex);
};

const get_last_monday = function ({ now = new Date(), offset_in_min = 0 } = { now: new Date(), offset_in_min: 0 }) {
	try {
		let last_monday = new Date(now);
		if (last_monday.getDay() === 0) {//special handling if today is sunday
			last_monday.setDate(last_monday.getDate() - 6);
		}
		else {
			last_monday.setDate(last_monday.getDate() - (last_monday.getDay() - 1));
		}

		last_monday.setHours(0); last_monday.setMinutes(0); last_monday.setSeconds(0); last_monday.setMilliseconds(0);
		
		let timezone_offset = new Date().getTimezoneOffset()
		const hoursToAdjust = Math.floor(Math.abs(timezone_offset) / 60)
		const minutesToAdjust = Math.abs(timezone_offset) % 60;
		last_monday.setHours(last_monday.getHours() + hoursToAdjust * (timezone_offset>0 ? -1 : 1))
		last_monday.setMinutes(last_monday.getMinutes() + minutesToAdjust * (timezone_offset>0 ? -1 : 1))

		last_monday.setTime(last_monday.getTime() + offset_in_min * 60 * 1000);
		return last_monday;
	}
	catch (err) {
		console.error(`error in get_last_monday`, err);
	}

}

const CohortAnalysis = ({all_stores, set_page_loader, fetched_cohort_analysis_data, set_fetched_cohort_analysis_data, cs_org_users, user_details}) => {

	const [selected_domains, set_selected_domains] = useState([{ value: 'Interior Design', label: 'Interior Design' }, { value: 'Modular Interior', label: 'Modular Interior' }]);
	const [final_report, set_final_report] = useState();
	const [final_dates, set_final_dates] = useState();
	const [specified_threshold, set_specified_threshold] = useState(100);
	const [selected_usage_events, set_selected_usage_events] = useState([{value:'version_save', label:'Version Save'}]);
	const [ selected_add_ons, set_selected_add_ons ] = useState([]);
	const [ selected_cses, set_selected_cses ] = useState([]);
	const [ cs_executives, set_cs_executives ] = useState([]);
	const [ selected_countries, set_selected_countries ] = useState([]);
	const [ weekly_orgs_json, set_weekly_orgs_json ] = useState({});
	const [view_percentage, set_view_percentage] = useState(true);
	const [org_filter, set_org_filter] = useState([]);
	const [cohort_analysis_orgs, set_cohort_analysis_orgs] = useState([]);
	const [payment_cycle_filter, set_payment_cycle_filter] = useState();
	const countries_array = useMemo(() => {return cohort_analysis_orgs && cohort_analysis_orgs.length ? cohort_analysis_orgs.map(x => x.country) : []}, [cohort_analysis_orgs])

	const find_weekly_usage = async ({ start_dt, end_dt, org_ids } = { start_dt, end_dt, org_ids }) => {
		try {
			if (!end_dt) end_dt = new Date();
			if (!start_dt) start_dt = new Date(end_dt.getTime() - 7 * 24 * 60 * 60 * 1000);
			const apiUrl = global.config.analytics_path + 'api/org_level_events'
			const payload = {
				start_time: start_dt,
				end_time: end_dt,
			};
			let org_level_events = await general_fetch({ full_url: apiUrl, body: payload, req_resp: true })

			let all_orgs = all_stores && all_stores.length ? all_stores.filter(x => x.premium_customer && org_ids.includes(x.id)) : []
			all_orgs = all_orgs && all_orgs.length ? all_orgs.map(o => ({...o, usage:org_level_events[o.id]})) : []
			return all_orgs
		} catch (err) {
			console.error('error:', err);
		}
	}

	// const generate_cohort_report = async () => {
	// 	try {
	// 		let end_ts = new Date();
	// 		let start_ts = get_last_monday({now: end_ts});
	// 		let timestamps = [];
	// 		let all_onboarding_clients = all_stores && all_stores.length ? all_stores.filter(o => o.premium_customer && o.type==='business') : []

	// 		all_onboarding_clients.forEach(client => client.license_start_date = new Date(client.license_start_date));
	// 		set_page_loader(true, 'Building report...')
	// 		let temp_final_report = {}
	// 		let temp_final_dates = []
	// 		let temp_all_onboarded_clients = []
	// 		for (let i = 0; i<13; i++) {
	// 			let timezone_offset = new Date().getTimezoneOffset()
	// 			end_ts.setTime(start_ts.getTime() + (timezone_offset * 60 * 1000) - 1000);
	// 			start_ts = get_last_monday({now: end_ts});
	// 			end_ts.setTime(end_ts.getTime() - timezone_offset * 60 * 1000)
	// 			temp_final_dates.push(date_string(new Date(start_ts)))
	// 			timestamps.push({start_ts: new Date(start_ts), end_ts: new Date(end_ts)});
	// 			let onboarded_clients = all_onboarding_clients.filter(obj => new Date(obj.license_start_date) >= new Date(start_ts) && new Date(obj.license_start_date) <= new Date(end_ts)); 
	// 			temp_all_onboarded_clients = [...temp_all_onboarded_clients, ...onboarded_clients]
	// 			for (let ts of timestamps) {
	// 				let resp = await find_weekly_usage({start_dt: ts.start_ts, end_dt: ts.end_ts, org_ids: onboarded_clients.map(obj => obj.id)});
	// 				if(temp_final_report[date_string(new Date(start_ts))]){
	// 					temp_final_report[date_string(new Date(start_ts))].push(resp)
	// 				}else{
	// 					temp_final_report[date_string(new Date(start_ts))] = [resp]
	// 				}
	// 			}
	// 			temp_final_report[date_string(new Date(start_ts))].reverse()
	// 		}
	// 		console.log(temp_final_report)
	// 		set_final_report(temp_final_report)
	// 		temp_final_dates.sort(date_sort)
	// 		set_final_dates(temp_final_dates)
	// 		set_cohort_analysis_orgs(temp_all_onboarded_clients)
	// 		set_fetched_cohort_analysis_data({final_report: temp_final_report, final_dates: temp_final_dates, cohort_analysis_orgs: temp_all_onboarded_clients})
	// 		set_page_loader(false)
	// 	} catch (err) {
	// 		set_page_loader(false)
	// 		console.error('error in generate_cohort', err);
	// 		throw err;
	// 	}
	// }
	
	const generate_cohort_report = async () => {
		try {
			let end_ts = new Date();
			let start_ts = get_last_monday({now: end_ts});
			let timestamps = [];
			
			let all_onboarding_clients = all_stores && all_stores.length ? all_stores.filter(o => o.premium_customer && o.type==='business') : []

			all_onboarding_clients.forEach(client => client.license_start_date = new Date(client.license_start_date));
			set_page_loader(true, 'Building report...')
			let temp_final_report = {}
			let temp_final_dates = []
			let temp_all_onboarded_clients = []
			for (let i = 0; i<13; i++) {
				let timezone_offset = new Date().getTimezoneOffset()
				end_ts.setTime(start_ts.getTime() + (timezone_offset * 60 * 1000) - 1000);
				start_ts = get_last_monday({now: end_ts});
				end_ts.setTime(end_ts.getTime() - timezone_offset * 60 * 1000)
				temp_final_dates.push(date_string(new Date(start_ts)))
				timestamps.push({start_ts: new Date(start_ts), end_ts: new Date(end_ts)});
				let onboarded_clients = all_onboarding_clients.filter(obj => new Date(obj.license_start_date) >= new Date(start_ts) && new Date(obj.license_start_date) <= new Date(end_ts)); 
				temp_all_onboarded_clients = [...temp_all_onboarded_clients, ...onboarded_clients]
				let promises = [];
				for (let i = 0; i < timestamps.length; i++) {
					let ts = timestamps[i];
					promises.push(
						find_weekly_usage({start_dt: ts.start_ts, end_dt: ts.end_ts, org_ids: onboarded_clients.map(obj => obj.id), idx: i})
					);
				}
				let responses = await Promise.all(promises);
				for (let i = 0; i < responses.length; i++) {
					let resp = responses[i];
					if(temp_final_report[date_string(new Date(start_ts))]){
						temp_final_report[date_string(new Date(start_ts))].push(resp)
					}else{
						temp_final_report[date_string(new Date(start_ts))] = [resp]
					}
				}
				temp_final_report[date_string(new Date(start_ts))].reverse()
			}
			console.log(temp_final_report)
			set_final_report(temp_final_report)
			temp_final_dates.sort(date_sort)
			set_final_dates(temp_final_dates)
			set_cohort_analysis_orgs(temp_all_onboarded_clients)
			set_fetched_cohort_analysis_data({final_report: temp_final_report, final_dates: temp_final_dates, cohort_analysis_orgs: temp_all_onboarded_clients})
			set_page_loader(false)
		} catch (err) {
			set_page_loader(false)
			console.error('error in generate_cohort', err);
			throw err;
		}
	}

	const date_sort = (a, b) => {
		return new Date(a) - new Date(b)
	}

	const filter_orgs = (given_orgs) => {
		let temp_orgs = given_orgs && given_orgs.length ? JSON.parse(JSON.stringify(given_orgs)) : []

		if(org_filter && org_filter.length){
			let org_filter_ids = org_filter.map(x => x.value)
			temp_orgs = temp_orgs.filter(o => org_filter_ids.includes(o.id))
			console.log(temp_orgs, org_filter_ids)
		}

		if(selected_domains && selected_domains.length){
			temp_orgs = temp_orgs.filter(o => selected_domains.find(x => x.value === o.domain))
		}

		if(selected_add_ons && selected_add_ons.length > 0){
			let selected_add_on_ids = selected_add_ons.map(x => x.value)
			if(selected_add_on_ids.length == 1 && selected_add_on_ids[0] == 'none'){
				temp_orgs = temp_orgs.filter(o => !(o.active_add_on_ids && o.active_add_on_ids.length))
			}else{
				temp_orgs = temp_orgs.filter(o => o.active_add_on_ids.some(item => selected_add_on_ids.includes(item)))
			}
		}

		if(selected_cses && selected_cses.length){
			temp_orgs = temp_orgs.filter(o => selected_cses.find(x => x.value === o.customer_success_executive_id))
		}

		if(selected_countries && selected_countries.length){
			temp_orgs = temp_orgs.filter(o => selected_countries.find(x => x.value === o.country))
		}

		if(payment_cycle_filter){
			temp_orgs = temp_orgs.filter(o => o.payment_cycle == payment_cycle_filter)
		}
		
		return temp_orgs
	}

	const fetch_cs_executives = async () => {
		try {
			let account_details = cs_org_users
			let resp = await general_fetch({ url: 'customer_success_executive/get' })

			if (resp && resp.length) {
				for (let i = 0; i < resp.length; i++) {
					let user = account_details.find(o => o.id === resp[i].user_id)
					resp[i].first_name = user ? user.first_name : ''
					resp[i].email = user ? user.email : ''
				}
				if (user_details && resp.find(o => o.user_id === user_details.id)) {
					set_selected_cses([{ value: user_details.id, label: user_details.first_name }])
				}
				set_cs_executives(resp)
			}
		} catch (err) {
			console.error(err)
		}
	}

	const Weekly_Orgs = ({this_week_orgs, last_week_orgs}) => {
		let newly_added_orgs = this_week_orgs && this_week_orgs.length > 0 ? this_week_orgs.filter(o => !(last_week_orgs && last_week_orgs.length && last_week_orgs.find(x => x.id === o.id))) : []
		let dropped_orgs = last_week_orgs && last_week_orgs.length > 0 ? last_week_orgs.filter(o => !(this_week_orgs && this_week_orgs.length && this_week_orgs.find(x => x.id === o.id))) : []

		return(
			<div className="p-3 flex_between" style={{alignItems:'flex-start'}}>
				<div  style={{width:'46%'}}>
					<div style={{width:'100%', display:'block', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}} className="bold mb-2" >Dropped Orgs</div>
					<div style={{overflow:'auto', maxHeight:'300px'}}>
						{
							dropped_orgs && dropped_orgs.length ? dropped_orgs.map(single_org => (
								<div>
									<a target="_blank" href={`/home/store_dashboard/${single_org.id}`} style={{width:'100%', display:'block', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}} className="mb-2">{single_org.name}</a>
								</div>
							)):''
						}
					</div>
				</div>
				<div style={{width:'46%'}}>
					<div style={{width:'100%', display:'block', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}} className="bold mb-2" >Added Orgs</div>
					<div style={{overflow:'auto', maxHeight:'300px'}}>
						{
							newly_added_orgs && newly_added_orgs.length ? newly_added_orgs.map(single_org => (
								<div>
									<a target="_blank" href={`/home/store_dashboard/${single_org.id}`} style={{width:'100%', display:'block', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}} className="mb-2">{single_org.name}</a>
								</div>
							)):''
						}
					</div>
				</div>
			</div>
		)
	}

	useEffect(() => {
		if(cs_org_users && cs_org_users.length > 0 && user_details && user_details.id) fetch_cs_executives()
	}, [cs_org_users, user_details]);

	useEffect(() => {
		if (document.getElementById('cohort_analysis_header') && document.getElementById('cohort_analysis_table')) {
			document.getElementById('cohort_analysis_table').style.height = window.innerHeight - document.getElementById('cohort_analysis_header').offsetHeight + 'px'
		}
	}, []);

	useEffect(() => {
		if(!fetched_cohort_analysis_data){
			if(all_stores && all_stores.length) generate_cohort_report()
		}else{
			set_final_report(fetched_cohort_analysis_data.final_report)
			set_final_dates(fetched_cohort_analysis_data.final_dates)
			set_cohort_analysis_orgs(fetched_cohort_analysis_data.cohort_analysis_orgs)
		}
	}, [all_stores]);

	return (
		<>
			<div id='cohort_analysis_header'>
				<div className="flex_center px-4 py-3" style={{ justifyContent: 'space-between' }}>
					<div><b>Cohort Analysis</b></div>
				</div>
			</div>
			<div className="mx-2 p-4" id='cohort_analysis_table' style={{ overflow: 'auto', position: 'relative', backgroundColor: 'white' }}>
				<div className="flex_between mb-2" style={{ fontSize: '12px', position:'relative', zIndex:'2' }}>
					<div className="flex_center" style={{ fontSize: '12px' }}>
						<div className="mr-2">Events:</div>
						<Select
							// closeMenuOnSelect={false}
							components={animatedComponents}
							value={selected_usage_events}
							isMulti
							options={mixpanel_event_types}
							styles={colourStyles}
							onChange={(selectedOptions) => set_selected_usage_events(selectedOptions)}
						/>
						<div className="mx-2">Threshold:</div>
						<Input type='number' style={{width:'100px'}} onChange={e => set_specified_threshold(e.target.value)} value={specified_threshold} className='standard_input'/>
					</div>
					<div className="flex_center" style={{ fontSize: '12px' }}>
						<div className="mr-2">Domain:</div>
						<Select
							closeMenuOnSelect={false}
							components={animatedComponents}
							value={selected_domains}
							isMulti
							options={domain_options}
							placeholder='Select...'
							styles={{ colourStyles1 }}
							onChange={(selectedOptions) => { set_selected_domains(selectedOptions) }}
						/>
						<div className="mx-2" style={{fontSize:'13px'}}>Org:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								components={animatedComponents}
								value={org_filter}
								isMulti
								options={cohort_analysis_orgs && cohort_analysis_orgs.length ? cohort_analysis_orgs.filter(o => o.premium_customer).map(x => { return { value: x.id, label: (x.name + ' (' + x.id + ')') } }) : []}
								styles={colourStyles}
								onChange={(selectedOptions) => set_org_filter(selectedOptions)}
							/>
						</div>
					</div>
				</div>
				<div className="flex_between mb-4" style={{position:'relative', zIndex:'1'}}>
					<div className="flex_center" style={{fontSize:'12px'}}>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								components={animatedComponents}
								value={{ value: payment_cycle_filter, label: payment_cycle_filter==3 ? 'Quarterly' : (payment_cycle_filter==12 ? 'Annual' : 'All') }}
								options={[{ value: '', label: 'All' }, { value: 3, label: 'Quarterly' }, { value: 12, label: 'Annual' }]}
								styles={colourStyles1}
								onChange={(selectedOptions) => set_payment_cycle_filter(selectedOptions.value)}
							/>
						</div>
						<div className='mr-2'>CS Rep: </div>
						<Select
							closeMenuOnSelect={false}
							components={animatedComponents}
							value={selected_cses}
							isMulti
							options={cs_executives && cs_executives.length ? cs_executives.map(x => { return { value: x.user_id, label: x.first_name } }) : []}
							styles={colourStyles}
							onChange={(selectedOptions) => set_selected_cses(selectedOptions)}
						/>
						<div className='mx-2'>Country: </div>
						<Select
							closeMenuOnSelect={false}
							components={animatedComponents}
							value={selected_countries}
							isMulti
							options={country_list.filter(o => countries_array.includes(o.code)).map(x => ({value:x.code, label:x.name}))}
							styles={colourStyles}
							onChange={(selectedOptions) => set_selected_countries(selectedOptions)}
						/>
					</div>
					<div className="flex_center" style={{fontSize:'12px'}}>
						<div className='mr-2'>Add Ons: </div>
						<Select
							closeMenuOnSelect={false}
							components={animatedComponents}
							value={selected_add_ons}
							isMulti
							options={[{ value: 'none', label: 'Only Design' }, ...infurnia_add_ons]}
							styles={colourStyles}
							onChange={(selectedOptions) => {
								if (selectedOptions && selectedOptions.length > 1 && selectedOptions.find(x => x.value === 'none')) {
									toastr.error(`Can't select 'Only Design' plan along with other Add-ons`)
								} else {
									set_selected_add_ons(selectedOptions)
								}
							}}
						/>
						<div className="ml-2">Numbers</div>
						<Switch className="mx-1" value={view_percentage} onChange={e => set_view_percentage(!view_percentage)} size="small" />
						<div>Percentages</div>
					</div>
				</div>
				<div className="flex_center bold">
					<div style={{width:'100px'}} className="text-center">Week</div>
					<div style={{minWidth:'100px'}} className="ml-2 text-center">New Orgs</div>
				{
					final_report && final_dates && final_dates.length ? final_dates.map((single_date, idx) => (
						<div className="ml-2 px-2 py-1 text-center" style={{width:'60px'}}>{'W'+(idx+1)}</div>
					)):''
				}
				</div>
				<div className="flex_center">
					<div>
					{
					final_report && final_dates && final_dates.length ? final_dates.map((single_date, idx) => {
						weekly_orgs_json[single_date] = {}
						let single_date_array = final_report[single_date]
						let total_orgs = single_date_array && single_date_array.length && single_date_array[0]
						let filtered_total_orgs = filter_orgs(total_orgs) 
						return (
							<div className="flex_property mt-2">
								<div style={{width:'100px'}} className="text-center">{single_date}</div>
								<div style={{minWidth:'100px'}} className="ml-2 text-center">{filtered_total_orgs.length}</div>
								{
									single_date_array && single_date_array.length ? single_date_array.map((orgs, index) => {
										let this_week_orgs = []
										let above_threshold_count = 0, all_count = filtered_total_orgs.length
										let filtered_orgs = filter_orgs(orgs) 
										if(filtered_orgs && filtered_orgs.length){
											for(let single_org of filtered_orgs){
												let usage_count = 0
												if(single_org.usage && selected_usage_events && selected_usage_events.length){
													selected_usage_events.forEach(event => {if(single_org.usage[event.value]) usage_count += single_org.usage[event.value]})
												}
												if(usage_count >= specified_threshold){
													this_week_orgs.push(single_org)
													above_threshold_count++
												}
											}
										}
										weekly_orgs_json[single_date]['W'+(index+1)] = this_week_orgs
										return(
											<Tooltip 
												placement="top" 
												color='white' 
												overlayStyle={{borderRadius:'16px', marginRight:'30px', maxWidth:'unset'}}
												overlayInnerStyle={{ marginBottom:'10px', width:'500px', color:'black'}}
												title={<Weekly_Orgs this_week_orgs={weekly_orgs_json[single_date]['W'+(index+1)]} last_week_orgs={weekly_orgs_json[single_date]['W'+index]}/>} >
												<div className="ml-2 px-2 py-1 text-center cp" 
													style={{width:'60px', textAlign:'center', border:'1px solid gray', borderRadius:'6px', backgroundColor:colorCellFromValue(gradentParams, all_count ? above_threshold_count/all_count : 0)}}>
													{all_count ? (view_percentage ? (String(Math.round(100 * (above_threshold_count/all_count))) + '%') : above_threshold_count) : '-'}
												</div>
											</Tooltip>
										)
									}):''
								}
							</div>
						)
					}):''
				}
					</div>
				</div>
			</div>
		</>
	)
}

export default CohortAnalysis