import general_fetch from "../fetch";

export const createFeatureGuide = async(jira_version_id, feature_guide_steps, status = "Draft", guide_name, type = "Blink") => {
    try{
        if(!feature_guide_steps) feature_guide_steps = [];
        feature_guide_steps.map((x, idx) => x.serial_no = idx);
        feature_guide_steps = JSON.stringify(feature_guide_steps);
        let body = {jira_version_id, feature_guide_steps, status, guide_name, type}
        console.log("Body", body);
        let response = await general_fetch({ url: 'feature_guide/create', body});
        return response
    }catch(err){
        console.error(`Error in createFeatureGuide `, err)
        return Promise.reject(err);
    }
}

export const updateFeatureGuide = async(id, jira_version_id, feature_guide_steps, status = "Draft", guide_name, type = "Blink") => {
    try{

        if(!feature_guide_steps) feature_guide_steps = [];
        feature_guide_steps.map((x, idx) => x.serial_no = idx);
        if(!feature_guide_steps) feature_guide_steps = [];
        feature_guide_steps = JSON.stringify(feature_guide_steps);
        let body = {id, jira_version_id, feature_guide_steps, status, guide_name, type}
        console.log("Body", body);
        let response = await general_fetch({ url: 'feature_guide/update', body});
        return response
    }catch(err){
        console.error(`Error in updateFeatureGuide `, err)
        return Promise.reject(err);
    }
}

export const deleteFeatureGuide = async(id) => {
    try{
        let body = {id}
        let response = await general_fetch({ url: 'feature_guide/remove', body});
        return response
    }catch(err){
        console.error(`Error in updateFeatureGuide `, err)
        return Promise.reject(err);
    }
}

export const getAllFeatureGuides = async() => {
    try{
        let response = await general_fetch({ url: 'feature_guide/get_all' });
        return response
    }catch(err){
        console.error(`Error in getAllFeatureGuides `, err)
        return Promise.reject(err);
    }
}

export const getLatestVersions = async() => {
    try{
        let response = await general_fetch({ url: 'feature_guide/get_ct_20_versions' });
        return response
    }catch(err){
        console.error(`Error in getLatestVersions `, err)
        return Promise.reject(err);
    }
}

export const upload_gif = async(fd) => {
    try{
        let response = await general_fetch({url : 'image/add', body : fd, is_form_data: true});
        return response;
    }catch(err){
        console.error(`Error in upload_gif`, err);
        return Promise.reject(err);
    }
}