import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import general_fetch from '../../utils/fetch';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, CardBody, Card, Row, CardHeader, Input, Table } from 'reactstrap';
import DeleteNotification from "../../components/DeleteNotification/index.js";
import { confirmAlert } from 'react-confirm-alert';

import './style.css'
import date_sort from '../../utils/date_sort';
import generic_sort from '../../utils/generic_sort';


const validity_options = [
    { name: "No Condition", value: '' },
    { name: "Sunday", value: 0 },
    { name: "Monday", value: 1 },
    { name: "Tuesday", value: 2 },
    { name: "Wednesday", value: 3 },
    { name: "Thursday", value: 4 },
    { name: "Friday", value: 5 },
    { name: "Saturday", value: 6 }
];

const CouponDetails = ({ coupon }) => {
    const [expanded, setExpanded] = useState(true);

    const toggleDetails = () => {
        setExpanded(!expanded);
    };

    const copyToClipboard = async (couponCode) => {
        try {
            await navigator.clipboard.writeText(couponCode);
            toastr.success('Copied to Clipboard')
        } catch (error) {
            console.error('Failed to copy:', error);
            toastr.error('Unable to Copy to Clipboard')
        }

    }

    return (
        <div className="coupon-details">
            <div className="coupon-info">
                <div className="coupon-code"><span style={{ cursor: 'pointer' }} onClick={() => { copyToClipboard(coupon.code) }}><b>{coupon.code}</b><i className='far fa-copy ml-2'></i></span></div>
                <div className="edit-status">
                    {/* <button className="edit-button">Edit</button> */}
                    <i className='far fa-edit mr-2 ml-2'></i>
                    <span className="status">{coupon.status}</span>
                    <i className={(expanded ? 'fa fa-caret-up' : 'fa fa-caret-down') + ' mr-2 ml-2'} onClick={toggleDetails}></i>
                </div>
            </div>

            {/* {!expanded && (
                <div className="summary-info">
                    <div>Discount Percentage: {coupon.discount_percentage}%</div>
                    <div>Maximum Discount: {coupon.max_discount}</div>
                    <div>Minimum Billable Amount: {coupon.min_bill}</div>
                    <div>Validity: {coupon.validity}</div>
                    <div>Validity Start Date: {coupon.validity_start_date}</div>
                    <div>Expiry: {coupon.expiry}</div>
                </div>
            )}

            <button className="expand-button" onClick={toggleDetails}>
                {expanded ? '[-] Less Details' : '[+] More Details'}
            </button>

            {expanded && (
                <div className="expanded-details">
                    <div>Discount Percentage: 20%</div>
                    <div>Maximum Discount: $50</div>
                    <div>Minimum Billable Amount: $100</div>
                    <div>Validity: 30 days</div>
                    <div>Validity Start Date: 2023-05-01</div>
                    <div>Expiry: 2023-05-31</div>
                </div>
            )} */}
            {
                expanded ?
                    <div className="summary-info">
                        <div>Discount Percentage: <b>{coupon.discount_percentage}</b>%</div>
                        <div>Maximum Discount: <b>{coupon.max_discount}</b></div>
                        <div>Minimum Billable Amount: <b>{coupon.min_bill}</b></div>
                        <div>Validity: <b>{coupon.validity}</b></div>
                        <div>Validity Start Date: <b>{coupon.validity_start_date}</b></div>
                        <div>Expiry: <b>{coupon.expiry}</b></div>
                        <div>Usage: <b>{coupon.use_count ? coupon.use_count : '0'}/{coupon.max_count ? coupon.max_count : '-'}</b></div>
                    </div>
                    : ''
            }
        </div>
    );
};

const AddCouponModal = ({ isOpen, toggleModal, create_new_regular_coupon, user_details }) => {
    const [couponCode, setCouponCode] = useState('');
    const [status, setStatus] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [maximumDiscount, setMaximumDiscount] = useState('');
    const [minimumBillableAmount, setMinimumBillableAmount] = useState('');
    const [validity, setValidity] = useState('');
    const [validityStartDate, setValidityStartDate] = useState('');
    const [expiry, setExpiry] = useState('');
    const [max_count, set_max_count] = useState(1);


    const handleAddCoupon = () => {
        // Perform validation and add coupon logic here
        const newCoupon = {
            "code":couponCode,
            // "user_id": user_details && user_details.id ? user_details.id : '',
            // status,
            "discount_percentage":discountPercentage ? discountPercentage : null,
            "max_absolute_discount":maximumDiscount ? maximumDiscount : null,
            "min_billable_amount":minimumBillableAmount ? minimumBillableAmount : null,
            "validity":validity ? validity : null,
            "start_datetime":validityStartDate ? validityStartDate : null,
            "end_date":expiry ? expiry : null,
            "max_count": max_count ? max_count : null
        };
        create_new_regular_coupon(newCoupon);
        handleClose();
    };

    const handleClose = () => {
        setCouponCode('')
        setDiscountPercentage('')
        setMaximumDiscount('')
        setMinimumBillableAmount('')
        setValidity('')
        setValidityStartDate('')
        setExpiry('')
        set_max_count(1)

        toggleModal()
    }

    

    return (
        <Modal isOpen={isOpen} size='xl' toggle={handleClose} className='modal-dialog-centered'>
            <ModalHeader toggle={handleClose}>Add Coupon</ModalHeader>
            <ModalBody style={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormGroup className='col-12 col-lg-6'>
                    <Label for="couponCode">Coupon Code</Label>
                    <Input type="text" id="couponCode" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                </FormGroup>
                {/* <FormGroup className='col-12 col-lg-6'>
                    <Label for="status">Status</Label>
                    <Input type="text" id="status" value={status} onChange={(e) => setStatus(e.target.value)} />
                </FormGroup> */}
                <FormGroup className='col-12 col-lg-6'>
                    <Label for="discountPercentage">Discount Percentage</Label>
                    <Input
                        type="number"
                        id="discountPercentage"
                        value={discountPercentage}
                        onChange={(e) => setDiscountPercentage(e.target.value)}
                    />
                </FormGroup>
                <FormGroup className='col-12 col-lg-6'>
                    <Label for="maximumDiscount">Maximum Discount</Label>
                    <Input
                        type="number"
                        id="maximumDiscount"
                        value={maximumDiscount}
                        onChange={(e) => setMaximumDiscount(e.target.value)}
                    />
                </FormGroup>
                <FormGroup className='col-12 col-lg-6'>
                    <Label for="minimumBillableAmount">Minimum Billable Amount</Label>
                    <Input
                        type="number"
                        id="minimumBillableAmount"
                        value={minimumBillableAmount}
                        onChange={(e) => setMinimumBillableAmount(e.target.value)}
                    />
                </FormGroup>
                <FormGroup className='col-12 col-lg-6'>
                    <Label for="validity">Validity</Label>
                    <Input type="select" id="validity" value={validity} onChange={(e) => setValidity(e.target.value)}>
                        {
                            validity_options && validity_options.length ? validity_options.map((item, idx) => (
                                <option value={item.value} key={idx}>{item.name}</option>
                            ))
                            :''
                        }
                    </Input>
                </FormGroup>
                <FormGroup className='col-12 col-lg-6'>
                    <Label for="validityStartDate">Validity Start Date</Label>
                    <Input
                        type="date"
                        id="validityStartDate"
                        value={validityStartDate}
                        onChange={(e) => setValidityStartDate(e.target.value)}
                    />
                </FormGroup>
                <FormGroup className='col-12 col-lg-6'>
                    <Label for="expiry">Expiry</Label>
                    <Input type="date" id="expiry" value={expiry} onChange={(e) => setExpiry(e.target.value)} />
                </FormGroup>
                <FormGroup className='col-12 col-lg-6'>
                    <Label for="maxCount">Max Utilization</Label>
                    <Input
                        type="number"
                        id="maxCount"
                        value={max_count}
                        onChange={(e) => set_max_count(e.target.value)}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleAddCoupon}>Add Coupon</Button>
                <Button color="secondary" onClick={toggleModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

function RegularCoupons({isMobile, user_details}) {

    const [coupons, set_coupons] = useState([]);
    const [couponCodeAsc, set_couponCodeAsc] = useState(true);
    const [discountPercentageAsc, set_discountPercentageAsc] = useState(true);
    const [maximumDiscountAsc, set_maximumDiscountAsc] = useState(true);
    const [minimumBillableAmountAsc, set_minimumBillableAmountAsc] = useState(true);
    const [validityAsc, set_validityAsc] = useState(true);

    const [add_modal_open, set_add_modal_open] = useState(false);


    const toggle_add_modal = () => set_add_modal_open(!add_modal_open)

    const get_all_regular_coupons = async() => {
        try{
            let coupons_resp = await general_fetch({url: 'billing/coupon/get_for_cs_user'})
            // console.log("couponss", coupons_resp)
            set_coupons(coupons_resp && coupons_resp.coupons && coupons_resp.coupons.length ? coupons_resp.coupons.filter(x => x.type == 'RECURRING').sort((a,b) => date_sort(a, b, 'created_at')) : [])

        }catch(err){
            toastr.error("Could not create coupon")
        }
    }

    const create_new_regular_coupon = async(body) => {
        try{
            let resp = await general_fetch({url: 'billing/coupon/create_recurring', body})
            get_all_regular_coupons()

        }catch(err){
            toastr.error("Could not create coupon")
        }
    }

    const copyToClipboard = async (couponCode) => {
        try {
            await navigator.clipboard.writeText(couponCode);
            toastr.success('Copied to Clipboard')
        } catch (error) {
            console.error('Failed to copy:', error);
            toastr.error('Unable to Copy to Clipboard')
        }

    }

    const sort_table_fields = (field, asc, set_asc, type) => {
        let coupons_copy = JSON.parse(JSON.stringify(coupons))
        coupons_copy = coupons_copy.sort((a, b) => generic_sort(a, b, field, asc ? 1 : -1, type))
        // this.setState({stores: all_stores, sorted_asc_by_premium: asc})
        set_coupons(coupons_copy)
        set_asc(!asc)
    }

    useEffect(() => {
        get_all_regular_coupons()
    }, []);

    
    return (
        <>
            <AddCouponModal isOpen={add_modal_open} toggleModal={toggle_add_modal} create_new_regular_coupon={create_new_regular_coupon} user_details={user_details}/>
            <Row className='mb-3 mr-3' style={{ display: 'flex', justifyContent: 'end' }}><Button color='primary' onClick={toggle_add_modal}>Add</Button></Row>
            <div>
                {
                    isMobile ?
                        <>
                            {
                                coupons && coupons.length ? coupons.map((coupon, idx) => (
                                    <CouponDetails coupon={coupon} />
                                ))
                                    : ''
                            }
                        </>
                        :
                        <Table className="align-items-center table-flush text-center" responsive>
                            <thead className="thead-light text-center">
                                <tr>
                                    {/* <th scope="col"><span><b></b></span><br /></th> */}
                                    <th scope="col">
                                        <span><b>Coupon Code</b> <i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('code', couponCodeAsc, set_couponCodeAsc)}} /></span><br />
                                        {/* <span style={{display:'flex', flexDirection:'column', fontSize:'14px'}}>
                                            <i title='Sort by Coupon Code' onClick={() => sort_table_fields('code', 1)} style={{height:'10px'}} className='fa fa-caret-up'/>
                                            <i title='Sort by Coupon Code' onClick={() => sort_table_fields('code', 0)} className='fa fa-caret-down'/>
                                        </span>  */}
                                    </th>
                                    <th scope="col"><span><b>Status</b>{/*<i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('code', couponCodeAsc, set_couponCodeAsc)}} />*/}</span><br /></th>
                                    <th scope="col"><span><b>Discount Percentage</b><i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('discount_percentage', discountPercentageAsc, set_discountPercentageAsc, 'number')}} /></span><br /></th>
                                    <th scope="col"><span><b>Maximum Discount</b><i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('max_absolute_discount', maximumDiscountAsc, set_maximumDiscountAsc, 'number')}} /></span><br /></th>
                                    <th scope="col"><span><b>Minimum Billable Amount</b><i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('min_billable_amount', minimumBillableAmountAsc, set_minimumBillableAmountAsc, 'number')}} /></span><br /></th>
                                    <th scope="col"><span><b>Validity</b><i className="fa fa-sort" style={{cursor:'pointer'}} onClick={() => {sort_table_fields('validity', validityAsc, set_validityAsc)}} /></span><br /></th>
                                    <th scope="col"><span><b>Validity Start Date</b></span><br /></th>
                                    <th scope="col"><span><b>Expiry</b></span><br /></th>
                                    <th scope="col"><span><b>Usage</b></span><br /></th>
                                    {/* <th scope="col"><span><b>Usage Limit</b></span><br /></th> */}
                                    {/* <th scope="col"><span><b></b></span><br /></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    coupons && coupons.length ? coupons.map((coupon, idx) => (
                                        <tr key={idx}>
                                        {/* <td scope="col"><span><i className='far fa-edit'></i></span><br /></td> */}
                                        <td scope="col"><span style={{ cursor: 'pointer' }} onClick={() => { copyToClipboard(coupon.code) }}><b>{coupon.code}</b><i className='far fa-copy ml-2'></i></span><br /></td>
                                        <td scope="col"><span><b>{coupon.active ? 'Active' : 'Inactive'}</b></span><br /></td>
                                        <td scope="col"><span><b>{coupon.discount_percentage + '%'}</b></span><br /></td>
                                        <td scope="col"><span><b>{(coupon.max_absolute_discount ? coupon.max_absolute_discount : '-')}</b></span><br /></td>
                                        <td scope="col"><span><b>{coupon.min_billable_amount ? coupon.min_billable_amount : '-'}</b></span><br /></td>
                                        <td scope="col"><span><b>{validity_options.find(x => x.value == coupon.validity) ? validity_options.find(x => x.value == coupon.validity).name : '-'}</b></span><br /></td>
                                        <td scope="col"><span><b>{(new Date(coupon.start_datetime)).toLocaleString()}</b></span><br /></td>
                                        <td scope="col"><span><b>{coupon.end_datetime ? (new Date(coupon.end_datetime)).toLocaleString() : '-'}</b></span><br /></td>
                                        {/* <td scope="col"><span><i onClick={() => {copyToClipboard(coupon.code)}} className='far fa-copy'></i></span><br /></td> */}
                                        <td scope="col"><span><b>{coupon.use_count ? coupon.use_count : '0'}/{coupon.max_count ? coupon.max_count : '-'}</b></span><br /></td>
                                        {/* <td scope="col"><span><b>{coupon.max_count ? coupon.max_count : '-'}</b></span><br /></td> */}
                                    </tr>
                                    )) : ''
                                }
                            </tbody>
                        </Table>
                }
            </div>
        </>
    );
}

export default RegularCoupons;