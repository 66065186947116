import general_fetch from "./fetch";

export const get_csp_filter = async(user_id, pathname, set_filter_json_from_backend) => {
	try{
		const apiUrl = global.config.analytics_path + 'api/user_filter/get_all'; 
		const payload = {user_id, pathname, token:'73d6745e-f627-4aaa-aeef-2d6d14682e1c'}

		// fetch(apiUrl, {
		// method: 'POST', 
		// headers: {
		// 	'Content-Type': 'application/json',
		// },
		// body: JSON.stringify(payload),
		// })
		// .then(response => {
		// 	if (!response.ok) {
		// 	throw new Error(`HTTP error! Status: ${response.status}`);
		// 	}
		// 	return response.json();
		// })
		// .then(data => {
		// 	set_filter_json_from_backend(data)
		// })
		// .catch(error => {
		// 	console.error('Error:', error);
		// });
		let response = await general_fetch({full_url:apiUrl, body:payload, req_resp:1})
		set_filter_json_from_backend(response)
	}catch (err) {
		console.error(err)
	}
}