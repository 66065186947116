import React from 'react';
import {Modal} from 'reactstrap'
import './index.css'

export const CspModal = ({open, toggle, size, children}) => {
	return(
		<Modal className="modal-dialog-centered" size= {size ? size : "lg"} isOpen={open} toggle={toggle}>
		{children}
		</Modal>
	)
}

export const CspModalHeader = ({title, toggle}) => {
	return(
		<div className="modal-header  bg-gradient-white">
			<h4 className="modal-title" id="exampleModalLabel"> {title} </h4>
			<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
			<span aria-hidden={true}>×</span>
			</button>
        </div>
	)
}

export const CspModalBody = ({style, overflow, children}) => {
	return(
		<div className="modal-body csp-modal-body bg-gradient-secondary" style={{...style, overflow: overflow == "off" ? "visible" : 'auto'}}>
		{children}
		</div>
	)
}

export const CspModalFooter = ({style, children}) => {
	return(
		<div className="modal-footer" style={{...style, height:'56px'}}>
			{children}
		</div>
	)
}