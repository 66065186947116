import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './style.css';
import { Checkbox, Tooltip } from 'antd';
import ISearch from '../ISearch';

const createHeaders = (headings) => {
	return headings.map((item, idx) => ({
		...item,
		ref: ('resizable-header' + item.column_name),
	}));
}

export const ITableHeader = ({ headers, children, style }) => {
	return (
		<thead style={{ ...style }}>
			<tr className='infurnia-table-header-row'>
				{
					headers ?
						headers.map((header, index) => (
							<ITH key={index}>{header}</ITH>
						))
						:
						children
				}
			</tr>
		</thead>
	);
};

export const ITableRow = ({ rowData, children, className, error_in_row, ...props }) => {
	return (
		<tr className={`infurnia-table-row ${error_in_row ? 'error_in_infurnia_table_row' : ''} ${className} `} {...props}>
			{
				rowData ?
					rowData.map((cell, index) => (
						<ITD key={index}>{cell}</ITD>
					))
					:
					children
			}
		</tr>
	);
};

export const ITD = ({ children, style, className, ...props }) => {
	return <td className={`infurnia-table-cell ${className ? className : ''}`} style={{ ...style }} {...props}>{children}</td>;
};

export const ITH = ({ children, style, ...props }) => {
	return <th {...props} className='infurnia-table-header' style={{ ...style }}>{children}</th>;
};

export const ITableBody = ({ children, style }) => {
	return <tbody className='infurnia-table-body' style={{ ...style }}>{children}</tbody>
}

export const ITable = ({ headings, headers, data, bordered, borderless, striped, dark, hover, responsive, rowSeparated, coloumnSeparated, children, style, columnWidths, style_container, resizable, minCellWidth, tableSettings, column_visibility_json, set_column_visibility_json, TableData, cellHeight }) => {
	const [tableHeight, setTableHeight] = useState('auto');
  	const [activeIndex, setActiveIndex] = useState(null);
	const tableElement = useRef(null);
	const [show_column_selector, set_show_column_selector] = useState(false);
	const columns = useMemo(() => headings ? createHeaders(headings) : [], [headings])
	const [searched_columns, set_searched_columns] = useState(columns);
	const [search_text, set_search_text] = useState('');

	const update_column_visibility_json = (column_name, value) => {
		let a = JSON.parse(JSON.stringify(column_visibility_json))
		a[column_name] = value
		set_column_visibility_json(a)
	}

	const mouseDown = (column_name) => {
		setActiveIndex(column_name);
	}

	const mouseMove = useCallback((e) => {
		const gridColumns = columns.filter(col => column_visibility_json[col.column_name]).map((col, i) => {
		if (col.column_name === activeIndex) {
			// Calculate the column width
			// const width = e.clientX - col.ref.current.offsetLeft;
			const width = e.clientX - document.getElementById('resizable-header'+col.column_name).getBoundingClientRect().left

			if (width >= minCellWidth) {
			return `${width}px`;
			}
		}

			// Otherwise return the previous width (no changes)
		// return `${col.ref.current.offsetWidth}px`;
		return (document.getElementById('resizable-header'+col.column_name) ? document.getElementById('resizable-header'+col.column_name).offsetWidth : 0) + 'px'
		});

		// Assign the px values to the table
		tableElement.current.style.gridTemplateColumns =
		`${gridColumns.join(' ')}`;
	}, [activeIndex, columns, minCellWidth]);
		
	const mouseUp = useCallback(() => {
		setActiveIndex(null);
		removeListeners();
	}, [setActiveIndex, removeListeners]);

	const removeListeners = useCallback(() => {
		window.removeEventListener('mousemove', mouseMove);
		window.removeEventListener('mouseup', removeListeners);
	}, [mouseMove]);  
  
	useEffect(() => {
		setTableHeight(tableElement.current.offsetHeight);
	});

	useEffect(() => {
		if (activeIndex !== null) {
		window.addEventListener('mousemove', mouseMove);
		window.addEventListener('mouseup', mouseUp);
		}
	
		return () => {
		removeListeners();
		}
	}, [activeIndex, mouseMove, mouseUp, removeListeners]);

	// useEffect(() => {
	// 	if(!column_visibility_json){
	// 		if(columns && columns.length > 0) {
	// 			columns.map(column => {
	// 				column_visibility_json[column.column_name] = 1
	// 			})
	// 		}
	// 		set_column_visibility_json(JSON.parse(JSON.stringify(column_visibility_json)))
	// 	}
	// }, [columns]);

	// useEffect(() => {
	// 	let array = tableElement.current.style.gridTemplateColumns.split(' ')
	// 	let index = display_columns.findIndex(o => !column_visibility_json[o.column_name])

	// 	if(index != -1){
	// 		array.splice(index, 1)
	// 		tableElement.current.style.gridTemplateColumns = array.join(' ')
	// 	}

	// 	let gridColumns = column_visibility_json && columns && columns.length ? columns.filter(x => column_visibility_json[x.column_name]) : []
	// 	gridColumns = gridColumns.map((col, i) => {
	// 		return '100px'
	// 	})
	// 	tableElement.current.style.gridTemplateColumns =`${gridColumns.join(' ')}`;
	// 	console.log(tableElement.current.style.gridTemplateColumns)
	// }, [columns, column_visibility_json]);

	const column_selector = (
		<div className="p-3" style={{ color: '#323338', display:'flex', flexDirection:'column', height:'380px' }}>
			<div>
				<div className="mb-3" style={{ color: '#A7A8B2', color:'black', fontSize:'15px'}}>Cutomize Columns</div>
				<div className="flex_property justify-between mb-3">
					<ISearch style_outer={{width:'300px'}} style_inner={{width:'300px'}} search_text={search_text} set_search_text={set_search_text} list={columns} set_list={set_searched_columns} search_parameters={['column_name']}/>
				</div>
				<hr className='mb-3 mt-0' />
			</div>
			<div style={{flex:1, overflow:'auto'}}>
			{
				searched_columns.map(column => (
					<div className="flex_property justify-between" style={{marginBottom:'12px'}}>
						<div className="flex_property">
							<Checkbox checked={column_visibility_json[column.column_name]} onClick={() => {update_column_visibility_json(column.column_name, !column_visibility_json[column.column_name])}}>
								<div>{column.column_name}</div>
							</Checkbox>
						</div>
					</div>
				))
			}
			</div>
		</div>
	)


	const tableClassName = `infurnia-table${bordered ? ' infurnia-table-bordered' : ''}${borderless ? ' infurnia-table-borderless' : ''}${striped ? ' infurnia-table-striped' : ''
		}${dark ? ' infurnia-table-dark' : ''}${hover ? ' infurnia-table-hover' : ''}${rowSeparated ? ' infurnia-table-row-separated' : ''}${coloumnSeparated ? ' infurnia-table-column-separated' : ''}${resizable ? ' infurnia_resizable_table' : ''}`;

	return (
		<div className={`${responsive ? 'table-responsive' : ''} infurnia-table-container`} style={{ ...style_container }}>
			{
				headers && data ?
					<table id='itable' className={tableClassName} style={{ ...style, gridTemplateColumns: column_visibility_json && columns && columns.length ? columns.filter(o => !column_visibility_json[o.column_name]).map(o => o.columnWidth).join(' ') : columnWidths }} ref={tableElement}>
						<ITableHeader headers={headers} />
						<tbody>
							{data.map((row, index) => (
								<ITableRow key={index} rowData={row} />
							))}
						</tbody>
					</table>
					:(
						headings?
						<>
							{tableSettings ? 
									<Tooltip
										title={column_selector}
										color='white'
										placement='left'
										trigger={'click'}
										overlayStyle={{borderRadius:'16px', marginRight:'30px', maxWidth:'unset'}}
										overlayInnerStyle={{ maxHeight:'380px', overflow:'hidden', marginBottom:'10px'}}
										style={{borderRadius:'16px', width:'400px'}}
										fresh={true}
										open={show_column_selector}
										onOpenChange={() => set_show_column_selector(!show_column_selector)}
									>
										<div className='flex_center p-2' style={{ position:'absolute', left:'24px', top:document.getElementById('itable') ? (document.getElementById('itable').offsetTop + 5 + 'px') : '', zIndex:'4', backgroundColor:'white'}}>
											<i className='fa fa-cog cp' onClick={() => set_show_column_selector(!show_column_selector)} style={{fontSize:'18px'}} />
										</div>  
									</Tooltip>
								:''
							}
							<table id='itable' className={tableClassName} style={{ ...style, gridTemplateColumns: columnWidths }} ref={tableElement}>
								<thead style={{}}>
									<ITableRow>
										{columns.map((column, i) => (
											column_visibility_json[column.column_name]?
											<ITH id={column.ref} key={column.column_name} style = {{...column.styles, height: cellHeight ? cellHeight : 'auto'}}>
												<span>{column.column_name != "Manage" ? column.column_name : ''}</span>
												{column.show_sort ? 
													(column.show_sort_order==='asc' ? <i className="fas fa-long-arrow-alt-down ml-2 cp" style={{color:'#0fd30f', fontSize:'16px'}} onClick={column.sort_fn}/> : (
														column.show_sort_order==='desc' ?  <i className="fas fa-long-arrow-alt-up ml-2 cp" style={{color:'#0fd30f', fontSize:'16px'}} onClick={column.sort_fn}/> :  
														<i className='fa fa-sort ml-2' onClick={column.sort_fn}/>)) : ''
												} 
												<div
													style={{ height: tableHeight }}
													onMouseDown={() => mouseDown(column.column_name)}
													className={`resize-handle ${activeIndex === column.column_name ? 'active' : 'idle'}`}
												/>
											</ITH>:''
										))}
									</ITableRow>
								</thead>
								{TableData && TableData.length ? 
                                        <>
                                            <ITableBody>
                                                {
                                                    TableData.map((single_row, id) => (
                                                        <ITableRow >
                                                            {
                                                                columns.map((column, i) => (
                                                                    column_visibility_json[column.column_name]?single_row[column.column_name]:''
                                                                ))
                                                            }
                                                        </ITableRow>
                                                    ))
                                                }
                                            </ITableBody>
                                    </>
                                    :children
								}
							</table>
						</>
						:
						<table id='itable' className={tableClassName} style={{ ...style, gridTemplateColumns: columnWidths }} ref={tableElement}>
							{children}
						</table>
					)
			}

		</div>
	);
};